import * as graphicRuleService from "api/graphicRule"

const getTemplateColorOptions = async (accessToken: any, templateId: any) => {
  try {
    const { data } = await graphicRuleService.fetchGraphicRules(accessToken, { customerId: 0, templateId })
    return extractColorOptions(data?.data.rules)
  } catch (err) {
    console.error(err)
  } finally {
    console.log('finally')
  }
}

function extractColorOptions(rules: any) {
  const result = rules.reduce((a: any, c: any) => {
    if (!a.bodyMap[c.bodyColorName]) a.bodyMap[c.bodyColorName] = true
    a.graphicMap[`${c.graphicType}_${c.bodyColorName}_${c.graphicColorName}`] = true
    return a
  }, {
    bodyMap: {},
    graphicMap: {}
  })
  return result
}

const templateSettingUtils = {
  getTemplateColorOptions
}
export default templateSettingUtils