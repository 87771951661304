import { client } from "utils/client";

type PreSignedUrlResult = { preSignedUrl: string, objectUrl: string }

export interface BodyPreSignUrlParams {
    blankTemplateId: number;
    front_body_url: string;
    front_label_url: string;
    front_texture_url: string;
    back_body_url: string;
    back_label_url: string;
    back_texture_url: string;
}

export interface BodyPreSignUrlResult {
    front_body_url: PreSignedUrlResult;
    front_label_url: PreSignedUrlResult;
    front_texture_url: PreSignedUrlResult;
    back_body_url: PreSignedUrlResult;
    back_label_url: PreSignedUrlResult;
    back_texture_url: PreSignedUrlResult;
}

export const getBodyPreSignedUrls = (params: BodyPreSignUrlParams) => {
    return client.get('/svg-templates/body/pre-signed-urls', { params })
    .then((response) => response?.data.data as BodyPreSignUrlResult)
};

export const uploadBodyTemplateFiles = (payload: any) => {
    return client.put('/svg-templates/body/upload-files', payload)
    .then((response) => response?.data.data)
};

export const getGraphicPreSignedUrls = (params: any) => {
    return client.get('/svg-templates/graphic/pre-signed-urls', { params })
    .then((response) => response?.data.data)
};

export const uploadGraphicTemplateFiles = (payload: any) => {
    return client.put('/svg-templates/graphic/upload-files', payload)
    .then((response) => response?.data.data)
};

export const getGraphicTemplates = (params: any) => {
    return client.get('/svg-templates/graphic/templates', { params })
    .then((response) => response?.data.data)
};

export const getGraphicTemplateById = (id: number) => {
    return client.get(`/svg-templates/graphic/templates/${id}`)
    .then((response) => response?.data.data)
};