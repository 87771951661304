import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";

import {
  deleteBuyerAdminService,
  fetchContactListService,
  fetchParentAccountDetailsService,
  updateBuyerAdminService,
} from "api/parentService";

import {
  AccoutDetailsProps,
  IParentAccountDetails,
} from "types/components/AccountDetails";
import { IContactDetails } from "api/requestTypes";
import { USER_ROLE } from "utils/constants";
import Account from "components/CustomerAccount/Account";
import SpinnerLoader from "../../ui/loader/SpinnerLoader";
import { deleteCustomerContactService } from "api/customerService";
import UpdateAccount from "components/CustomerAccount/UpdateAccount";
import AddSection from "components/addSection/AddSection";

import { deleteUserService, sendInviteEmailService } from "api/userService";
import AddUserModal from "pages/salesPerson/AddUserModal";
import Assign from "components/CustomerAccount/Assign";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import ParentUpdateModal from "components/modal/ParentUpdateModal";
import { toast } from "react-toastify";
import GraphicsRuleModal from "pages/graphicRule/GraphicsRuleModal";
import ShareLinkModal from "./ShareLinkModal";

const AccountDetails: React.FC<AccoutDetailsProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [bname, setBname] = useState("");
  const [bemail, setBemail] = useState("");
  const [bphone, setBphone] = useState("");
  const [refetch, setRefetch] = useState<boolean>(true);
  const [deleteUpdate, setDeleteUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const pathArray = location.pathname.split("/");
  const id = pathArray[pathArray.length - 1];
  const [toggle, setToggle] = useState(false);
  const [openId, setOpenId] = useState<string | number>("");
  const [openIdUpdate, setOpenIdUpdate] = useState<string | number>("");
  const [openIdAssign, setOpenIdAssign] = useState<string | number>("");
  const { accessToken, role } = useAppSelector(getUserDetails);
  const [showContactAccountModal, setShowContactAccountModal] = useState(false);
  const [contactPersonList, setContactPersonList] = useState<IContactDetails[]>(
    []
  );

  const [salesPersonListIds, setSalesPersonListIds] = useState<
    (string | number)[]
  >([]);
  const [marketingPersonListIds, setMarketingPersonListIds] = useState<
    (string | number)[]
  >([]);

  const [salesPersonList, setSalesPersonList] = useState<IContactDetails[]>([]);
  const [marketingPersonList, setMarketingPersonList] = useState<
    IContactDetails[]
  >([]);

  const [info, setInfo] = useState<IParentAccountDetails>();
  const [name, setName] = useState(info?.ParentAccount.accountName || "");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState<string | number>("");
  const [deleteEntity, setDeleteEntity] = useState<string>("");
  const [isGraphiListModal, setIsGraphiListModal] = useState(false);
  const [isLinkModal, setIsLinkModal] = useState(false);

  const updateParentAccount = async (data: any) => {
    const payload = {
      updateInfo: {
        accountName: data.name,
        contactName: data.contactName,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
      },
      salesPersonIds: data.assignIds,
      removeSalesPersonIds: data.removedSalesPersonIds,
      marketingPersonIds: data.assignMPIds,
      removeMarketingPersonIds: data.removedMarketingPersonIds,
    };
    try {
      setLoading(true);
      const { data } = await updateBuyerAdminService(id, payload, accessToken);
      if (data?.data) {
        toast.success("Account Updated successfully");
        setLoading(false);
        getAccountDetails();
        //dispatch(clearBuyerAdminAccountDetails());
        //navigate('/customer-account');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    setToggle((prev) => !prev);
  };
  const deleteCustomerAccount = async (id: string | number) => {
    try {
      setLoading(true);

      const { data } = await deleteCustomerContactService(id, accessToken);

      if (data.data) {
        setLoading(false);
        setDeleteUpdate((prevState) => !prevState);
        toast.success("Account Deleted Successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to delete account ");
    } finally {
      setLoading(false);
    }
  };
  const cancelParentAccount = () => {
    setName(info?.ParentAccount.accountName || "");
    const sp = info?.salesPersons?.map((sp) => sp.id);
    if (sp && sp.length) {
      const toRemove = new Set(sp);
      const difference = salesPersonList.filter((x) => !toRemove.has(x.id));
      setSalesPersonList(difference);
    }
    setToggle((prev) => !prev);
  };

  const handleAddContact = () => {
    setShowContactAccountModal(true);
  };

  const onClickChildDetails = (id: string | number) => {
    if (openId === id) setOpenId("");
    else setOpenId(id);
  };

  const onClickUpdateCustomer = (id: string | number) => {
    //if (openIdAssign === id) setOpenIdAssign('');
    //else setOpenIdAssign(id);
    //if (openIdUpdate === id) setOpenIdUpdate('');
    //else setOpenIdUpdate(id);
    setOpenId("");
    setOpenIdAssign(id);
    setOpenIdUpdate(id);
  };
  const handleConfirmCallBack = () => {
    if (deleteEntity === "Parent") {
      // deletion not allowed
      // deleteParentAccount(deleteId);
    } else if (deleteEntity === "Customer") deleteCustomerAccount(deleteId);
    else if (deleteEntity === "Contact") {
      // deletion not allowed
      // deleteContactPerson(deleteId);
    } else {
      console.log("No entity");
    }
  };
  const deleteParentAccount = async (id: string | number) => {
    // ________________not in use______________________
    // try {
    //   setLoading(true);
    //   const { data } = await deleteBuyerAdminService(id, accessToken);
    //   if (data?.data) {
    //     toast.success('Account Deleted successfully');
    //     //dispatch(clearBuyerAdminAccountDetails());
    //     setLoading(false);
    //     navigate('/');
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.error(error);
    // }
  };

  // const handleRemoveSalesPerson=(email:string, state:boolean)=>{
  //     console.log(email);
  // }
  const handleAddedUser = (contact: IContactDetails) => {
    setContactPersonList([...contactPersonList, contact]);
  };

  const deleteContactPerson = async (id: number | string) => {
    // ________________not in use______________________
    // try {
    //   setLoading(true);
    //   const { data } = await deleteUserService(id, accessToken);
    //   if (data.data) {
    //     toast.success('Contact person deleted successfully');
    //     setContactPersonList(
    //       contactPersonList.filter((contact) => contact.id !== id)
    //     );
    //     setLoading(false);
    //   }
    // } catch (error) {
    //   toast.error('Unable to delete');
    //   setLoading(false);
    // }
  };
  useEffect(() => {
    if (!refetch) return;
    const getAccountDetails = async () => {
      try {
        setLoading(true);
        const { data } = await fetchParentAccountDetailsService(
          accessToken,
          id
        );

        if (data.data) {
          const details: IParentAccountDetails = data.data;
          setInfo((prevState) => ({
            ...prevState,
            ...details,
          }));

          if (details.contactPersons)
            setContactPersonList(details.contactPersons);
          if (details.salesPersons)
            setSalesPersonListIds(details.salesPersons.map((dp) => dp.id));
          if (details.marketingPersons)
            setMarketingPersonListIds(
              details.marketingPersons.map((dp) => dp.id)
            );
          if (details.ParentAccount) {
            setName(details.ParentAccount.accountName);
            setBname(details.ParentAccount.contactName);
            setBemail(details.ParentAccount.contactEmail);
            setBphone(details.ParentAccount.contactPhone);
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("Could not fetch the account details");
      } finally {
        setLoading(false);
        setRefetch(false);
      }
    };

    getAccountDetails();
  }, [accessToken, id, deleteUpdate, refetch]);
  const getAccountDetails = async () => {
    try {
      setLoading(true);
      const { data } = await fetchParentAccountDetailsService(accessToken, id);

      if (data.data) {
        const details: IParentAccountDetails = data.data;
        setInfo((prevState) => ({
          ...prevState,
          ...details,
        }));
        if (details.contactPersons)
          setContactPersonList(details.contactPersons);
        if (details.salesPersons)
          setSalesPersonListIds(details.salesPersons.map((dp) => dp.id));
        if (details.marketingPersons)
          setMarketingPersonListIds(
            details.marketingPersons.map((dp) => dp.id)
          );
        if (details.ParentAccount) {
          setName(details.ParentAccount.accountName);
          setBname(details.ParentAccount.contactName);
          setBemail(details.ParentAccount.contactEmail);
          setBphone(details.ParentAccount.contactPhone);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Could not fetch the account details");
    } finally {
      setLoading(false);
    }
  };
  const getUpdatedList = () => {
    //setOpenId('');
    getAccountDetails();
  };
  useEffect(() => {
    setLoading(true);
    const getContactAndSalesList = async () => {
      try {
        const { data } = await fetchContactListService(accessToken);

        if (data.data) {
          const { contactPersons, salesPersons, marketingPersons } = data.data;
          //setContacPersontList(contactPersons);

          setSalesPersonList(salesPersons);
          setMarketingPersonList(marketingPersons);
          setLoading(false);
        }
      } catch (error) {
        toast.error("Could not fetch the contact and sales persons list");
        setLoading(false);
      }
    };
    getContactAndSalesList();
  }, [accessToken, id]);

  const sendInviteEmail = async (payload: {
    userId?: number | string;
    parentId?: number | string;
  }) => {
    try {
      setLoading(true);
      await sendInviteEmailService(payload, accessToken);
      toast.success("Invite email send successfully");
      setLoading(false);
    } catch (error) {
      toast.warn("Unable to send invite email");
      setLoading(false);
    }
  };

  if (loading) return <SpinnerLoader />;

  return (
    <div className="content-sm page-bottom-space">
      <div className="border-bottom d-flex justify-content-between align-items-center">
        <h4 className="mb-0 heading4-bold">Parent Account</h4>
      </div>
      <div className="form-wrap">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="mb-0 text-capitalize">{name}</p>
          {role === USER_ROLE.SUPER_ADMIN && ( //only super admin can edit/delete parent account
            <div className="action-wrap">
              <button
                title="link"
                className="btn btn-link-danger me-3"
                type="button"
                onClick={() => {
                  setIsLinkModal(true);
                }}
              >
                <i className="fa-solid fa-link"></i>
              </button>
              <button
                title="Graphics Rules List"
                className="btn btn-link-danger me-3"
                type="button"
                onClick={() => {
                  setIsGraphiListModal(true);
                }}
              >
                <i className="fa-solid fa-palette"></i>
              </button>
              <button
                title="Send Account Confirmation Email"
                className="btn btn-link-danger me-3"
                type="button"
                onClick={() => sendInviteEmail({ parentId: id })}
              >
                <i className="fa-solid fa-envelope"></i>
              </button>
              <button
                onClick={cancelParentAccount}
                className="btn btn-link-danger"
                type="button"
              >
                <i className="fa-solid fa-pen">{""}</i>
              </button>

              {/* <button
                //onClick={() => deleteParentAccount(id)}
                onClick={() => {
                  setDeleteId(id);
                  setDeleteEntity("Parent");
                  setOpenConfirmation(true);
                }}
                className="btn btn-link-danger ms-3"
                type="button"
              >
                <i className="fa-solid fa-trash-can">{""}</i>
              </button> */}
            </div>
          )}
          {/* <button className="btn btn-link-danger" type="button" onClick={cancelParentAccount}>
            <i className="fa-solid fa-pen">{''}</i>
          </button>
          <button className="btn btn-link-danger" type="button" onClick={cancelParentAccount}>
            <i className="fa-solid fa-pen">{''}</i>
          </button> */}
        </div>
        <div className="mb-3">
          <p className="mb-0">Contact Info</p>
          {info?.ParentAccount && (
            <div>
              <p className="mb-0 text-secondary text-capitalize">
                {info.ParentAccount.contactName}
              </p>
              <p className="mb-0 text-secondary">
                {info.ParentAccount.contactEmail}
              </p>
              <p className="mb-0 text-secondary">
                {info.ParentAccount.contactPhone}
              </p>
            </div>
          )}
        </div>
        {showContactAccountModal && (
          <AddUserModal
            title={"Add Contact Person"}
            setList={handleAddedUser}
            open={showContactAccountModal}
            setOpen={setShowContactAccountModal}
            parentId={id}
            setRefetch={setRefetch}
          />
        )}
        <div className="mb-1">
          {/* <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center"> */}
          {/* <h4 className="mb-0 heading4-bold">Contact Person</h4> */}
          <div className="pb-1 mb-1 d-flex justify-content-between align-items-center">
            {(role === USER_ROLE.SUPER_ADMIN ||
              role === USER_ROLE.BUYER_ADMIN) && (
              <AddSection
                label="Add Contact Person"
                onClick={handleAddContact}
              />
            )}
          </div>

          <ul className="list-group list-group-flush list-style-none">
            {contactPersonList.length > 0 &&
              React.Children.toArray(
                contactPersonList.map((contact, key) => (
                  <li className="has-right-action mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{contact.name}</p>
                    {(role === USER_ROLE.SUPER_ADMIN ||
                      role === USER_ROLE.BUYER_ADMIN) && (
                      <div className="action-wrap">
                        <button
                          title="Send Account Confirmation Email"
                          className="btn btn-link-danger me-3"
                          type="button"
                          onClick={() =>
                            sendInviteEmail({ userId: contact.id })
                          }
                        >
                          <i className="fa-solid fa-envelope"></i>
                        </button>
                        <button
                          title={""}
                          type="button"
                          className="btn btn-link-danger"
                          onClick={() => {
                            setDeleteId(contact.id);
                            setDeleteEntity("Contact");
                            setOpenConfirmation(true);
                          }}
                          //onClick={() => deleteContactPerson(contact.id)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </div>
                    )}
                  </li>
                  // <p key={key} className="mb-0 text-capitalize">
                  //   {contact.name}
                  // </p>
                ))
              )}
          </ul>
        </div>

        <div>
          <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Sales Person</h4>
          </div>

          <ul className="list-group list-group-flush list-style-none">
            {info?.salesPersons &&
              React.Children.toArray(
                info?.salesPersons.map((sales, key) => (
                  <li className="has-right-action mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{sales.name}</p>
                  </li>
                ))
              )}
          </ul>
        </div>
        <div>
          <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Marketing Person</h4>
          </div>

          <ul className="list-group list-group-flush list-style-none">
            {info?.marketingPersons &&
              React.Children.toArray(
                info?.marketingPersons.map((sales, key) => (
                  <li className="has-right-action mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{sales.name}</p>
                  </li>
                ))
              )}
          </ul>
        </div>

        <div className="mb-3">
          <div className="border-bottom pb-1">
            <p className="mb-0 font-weight-semi-bold">Customer Accounts</p>
          </div>
          <ul className="list-group list-group-flush list-style-none mt-2">
            {info?.ChildAccounts &&
              React.Children.toArray(
                info.ChildAccounts.map((child, key) => (
                  <li className="mb-2" key={key}>
                    <div className="has-right-action">
                      <p
                        onClick={() => onClickChildDetails(child.id)}
                        className="mb-0 text-capitalize heading4-bold"
                      >
                        <i
                          className={`fa-solid text-danger me-2 ${
                            openId === child.id
                              ? "fa-caret-down"
                              : "fa-caret-right"
                          }`}
                        ></i>
                        {/* {findName(child.childData!)} */}
                        {child.customerAccountName}
                      </p>
                      {/* Action buttons UI*/}
                      {
                        <div className="action-wrap">
                          {(role === USER_ROLE.SUPER_ADMIN ||
                            role === USER_ROLE.CONTACT_PERSON ||
                            role === USER_ROLE.BUYER_ADMIN) && (
                            <button
                              onClick={() => onClickUpdateCustomer(child.id)}
                              className="btn btn-link-danger"
                              type="button"
                            >
                              <i className="fa-solid fa-pen">{""}</i>
                            </button>
                          )}
                          {role === USER_ROLE.SUPER_ADMIN && (
                            <button
                              //onClick={() => onClickDeleteCustomer(child.id)}
                              onClick={() => {
                                setOpenId("");
                                setDeleteId(child.id);
                                setDeleteEntity("Customer");
                                setOpenConfirmation(true);
                              }}
                              className="btn btn-link-danger ms-3"
                              type="button"
                            >
                              <i className="fa-solid fa-trash-can">{""}</i>
                            </button>
                          )}
                        </div>
                      }
                    </div>
                    {openId === child.id && <Account {...child} />}

                    {(role === USER_ROLE.SUPER_ADMIN ||
                      role === USER_ROLE.BUYER_ADMIN ||
                      role === USER_ROLE.CONTACT_PERSON) &&
                      openIdAssign === child.id && (
                        <Assign
                          initialCustomInfo={child}
                          onCancel={setOpenIdAssign}
                          onCancelUpdate={setOpenIdUpdate}
                          getUpdatedList={getUpdatedList}
                        />
                      )}
                    {(role === USER_ROLE.SUPER_ADMIN ||
                      role === USER_ROLE.CONTACT_PERSON) &&
                      openIdUpdate === child.id && (
                        <UpdateAccount
                          initialCustomInfo={child}
                          onCancel={setOpenIdUpdate}
                          onCancelAssign={setOpenIdAssign}
                          getUpdatedList={getUpdatedList}
                        />
                      )}
                  </li>
                ))
              )}
          </ul>
        </div>
        {role === USER_ROLE.SUPER_ADMIN && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate(`/customer-account`, { state: { id: id } })}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add New Customer</span>
          </button>
        )}

        {/* {toggle && <AccountForm isUpdate={true} initialInfo={info}/>} */}
        <ParentUpdateModal
          defaultValues={{
            bname,
            bemail,
            bphone,
            name,
            salesPersonList,
            salesPersonListIds,
            marketingPersonList,
            marketingPersonListIds,
          }}
          onSave={(d) => updateParentAccount(d)}
          title={"Update Parent"}
          open={toggle}
          setOpen={setToggle}
        />
      </div>
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
      {isGraphiListModal && (
        <GraphicsRuleModal
          show={isGraphiListModal}
          handleSuccess={() => {
            setIsGraphiListModal(false);
          }}
          onHide={() => setIsGraphiListModal(false)}
        />
      )}
      {isLinkModal && (
        <ShareLinkModal
          show={isLinkModal}
          parentId={id}
          handleSuccess={() => {
            setIsLinkModal(false);
          }}
          onHide={() => setIsLinkModal(false)}
        />
      )}
    </div>
  );
};

export default AccountDetails;
