import { Routes, Route } from "react-router-dom";

import Layout from "pages/Layout";
import LoginPage from "pages/login/LoginPage";
import AccountForm from "pages/createParentAccount/CreateAccountPage";
import HomePage from "pages/home/HomePage";
import NotFoundPage from "pages/notFound/NotFoundPage";
import ResetPassword from "pages/resetPassword/ResetPassword";
import Customer from "pages/Customer/Customer";
import RequiredAuth from "components/requiredAuth/RequiredAuth";
import UnAuthorize from "pages/unAuthorize/UnAuthorize";
import ForgotPassword from "pages/forgotPassword/ForgotPassword";
import { URLS, USER_ROLE } from "utils/constants";
import AccountDetails from "components/parentAccount/AccountDetails";
import SalesPerson from "./pages/salesPerson/SalesPerson";
import MarketingPerson from "./pages/marketingPerson/MarketingPerson";
import UsersLog from "./pages/usersLog/UsersLog";
import { useAppSelector } from "./store/hooks";
import { getUserDetails } from "./store/User.slice";
import CustomerAttributes from "./pages/customerAttributes/CustomerAttributes";
import Locations from "./pages/locations/Locations";
import Countries from "./pages/locations/countries/Countries";
import States from "./pages/locations/states/States";
import Templates from "./pages/templates/Templates";
import Styles from "./pages/templates/styles/Styles";
import "./App.scss";
import CustomSize from "pages/templates/custom-size/CustomSize";
import OrderTable from "pages/orderHistory/OrderTable";
import FailedOrders from "pages/orderHistory/FailedOrders/FailedOrders";
import OrderStatus from "pages/orderHistory/OrderStatus";
import CartTable from "pages/orderHistory/CartTable";
import PaymentTable from "pages/orderHistory/PaymentTable";
import ShipmentDetails from "pages/orderHistory/ShipmentDetails";
import SingleOrderTable from "pages/orderHistory/SingleOrderTable";
import ApparelMagicToAdobeIllustratorColorRelation from "pages/apparelMagicToAdobeIllustratorColorRelation/ApparelMagicToAdobeIllustratorColorRelation";
import ShippingChargeConfig from "pages/shippingChargeConfig/ShippingChargeConfig";
import ToastAlert from "components/Alert/Toast";
import StripeConfig from "pages/stripeConfig/StripeConfig";
import Inventory from "pages/inventory/Inventory";
import StylesPricing from "pages/inventory/Pricing/StylesPricing";
import GlobalGraphicRule from "pages/graphicRule/GlobalGraphicRule";
import GraphicsRuleColors from "pages/graphicRule/GraphicsRuleColors";
import UserTable from "pages/users/UserTable/UserTable";
import ProductCategory from "pages/category/CategoryList";
import AltStyleDetailList from "pages/AltStyleDetail/AltStyleDetailList";
import GraphicColors from "pages/graphicRule/GraphicColors";

const App = () => {
  const { role } = useAppSelector(getUserDetails);
  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path={URLS.LOGIN} element={<LoginPage />} />
        <Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={URLS.UNAUTHORIZED} element={<UnAuthorize />} />
        <Route path={URLS.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={URLS.HOME} element={<Layout />}>
          {/* Protected routes */}

          <Route element={<RequiredAuth />}>
            <Route index element={<HomePage />} />
            <Route path={URLS.CREATE_ACCOUNT} element={<AccountForm />} />
            <Route path={URLS.CUSTOMER_ACCOUNT} element={<Customer />} />
            <Route
              path={URLS.PARENT_ACCOUNT_DETAILS}
              element={<AccountDetails />}
            />
            <Route
              path={URLS.CUSTOMER_ACCOUNT_DETAILS}
              element={<AccountDetails />}
            />

            {role === USER_ROLE.SUPER_ADMIN && (
              <>
                {/* <Route path={URLS.SALES_PERSON} element={<SalesPerson />} />
                <Route path={URLS.MARKETING_PERSON} element={<MarketingPerson />} /> */}
                <Route path={URLS.USERS_LOG} element={<UsersLog />} />
                <Route
                  path={URLS.CUSTOMER_ATTRIBUTES}
                  element={<CustomerAttributes />}
                />
                <Route path={URLS.LOCATIONS} element={<Locations />} />
                <Route
                  path={URLS.APPAREL_ADOBE_COLOR_RELATION}
                  element={<ApparelMagicToAdobeIllustratorColorRelation />}
                />
                <Route
                  path={URLS.GRAPHICS_RULES}
                  element={<GlobalGraphicRule />}
                />
                <Route
                  path={URLS.COLORS_LIST(':category')}
                  element={<GraphicsRuleColors />}
                />
                <Route
                  path={URLS.GRAPHIC_COLORS}
                  element={<GraphicColors />}
                />
                <Route
                  path={URLS.SHIPPING_CHARGE_CONFIG}
                  element={<ShippingChargeConfig />}
                />
                <Route path={URLS.STRIPE_CONFIG} element={<StripeConfig />} />
                <Route path={URLS.COUNTRIES} element={<Countries />} />
                <Route path={URLS.STATES} element={<States />} />
                <Route path={URLS.TEMPLATES} element={<Templates />} />
                <Route path={URLS.STYLES} element={<Styles />} />
                <Route path={URLS.CUSTOM_SIZE} element={<CustomSize />} />
                <Route path={URLS.ORDER_TABLE} element={<OrderTable />} />
                <Route path={URLS.FAILED_ORDERS} element={<FailedOrders />} />
                {/* <Route path={URLS.ORDER_TABLE} element={<OrderHistory />} /> */}
                <Route
                  path={URLS.SINGLE_ORDER_TABLE}
                  element={<SingleOrderTable />}
                />
                <Route path={URLS.ORDER_STATUS} element={<OrderStatus />} />
                <Route path={URLS.CART_TABLE} element={<CartTable />} />
                <Route path={URLS.PAYMENT_TABLE} element={<PaymentTable />} />
                <Route
                  path={URLS.SHIPMENT_DETAILS}
                  element={<ShipmentDetails />}
                />
                <Route path={URLS.INVENTORY_TABLE} element={<Inventory />} />
                <Route path={URLS.STYLES_PRICING} element={<StylesPricing />} />
                <Route path={URLS.USERS} element={<UserTable />} />
                <Route path={URLS.CATEGORIES} element={<ProductCategory />} />
                <Route path={URLS.ALT_STYLE_DESCRIPTION} element={<AltStyleDetailList />} />
                {/* <Route path={URLS.ALT_STYLE_DESCRIPTION} element={<AltStyleDetailList />} /> */}
              </>
            )}
          </Route>
        </Route>
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
      <ToastAlert />
    </>
  );
};

export default App;
