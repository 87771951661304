import * as React from 'react'
import { toast } from 'react-toastify'
import { Container, Modal } from 'react-bootstrap'
import { deleteOrder } from 'api/orderService'
import { getUserDetails } from 'store/User.slice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const DeleteOrderConfirm = ({ id, orderNumber, open, onHide }) => {

    const navigate = useNavigate()
    const { accessToken } = useSelector(getUserDetails)
    const [val, setVal] = React.useState('')
    const [loading, setLoading] = React.useState('')

    const handleHide = () => {
        setVal('')
        onHide()
    }

    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteOrder(id, accessToken)
            toast.success('Order deleted successfully.')
            setLoading(false)
            handleHide()
            navigate('/order-table')
        } catch (err) {
            setLoading(false)
            toast.error('Failed to delete the order')
        }
    }

    return (
        <Modal
            className="custom-modal"
            show={open}
            onHide={handleHide}
            aria-labelledby="custom-modal"
            // backdrop="static"
        >
            <form>
                <Modal.Header>
                    <h5 className="mb-0">Delete Order {orderNumber}</h5>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className='input-wrap'>
                            <label className='mb-1' >Re-enter the order number to verify.</label>
                            <input
                                className='form-control'
                                value={val}
                                onChange={(e) => setVal(e.target.value)}
                                placeholder='order number'
                            />
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-outline-danger me-2"
                            onClick={handleHide}
                            disabled={loading}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="btn btn-danger"
                            disabled={loading || val !== orderNumber}>
                            {loading ? 'Deleting...' : 'Delete'}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default DeleteOrderConfirm