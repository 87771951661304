import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, Popover } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react'
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import { fetchAllOrders } from 'api/orderService';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/constants';

const ErrorPopupCellRenderer = (props: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const toggle = () => setIsOpen(prev => !prev)
    return (
        <>
            <span onClick={toggle} >{props.value}</span>
            <Modal show={isOpen} onHide={toggle}>
                <Modal.Header>
                    <strong>AM Sync Failed Reason</strong>
                </Modal.Header>
                <Modal.Body>
                    <pre>{props.value}</pre>
                </Modal.Body>
            </Modal>
        </>
    )
}

const columnDefs = [
    { field: 'orderNumber', search: true,  headerName: "Order Number", },
    { field: 'orderDate',  headerName: "Order Date", },
    { field: 'userName', headerName: 'Ordered By' },
    // { field: 'userEmail', headerName: 'Email' },
    { field: 'customerAccount', headerName: 'Customer' },
    // { field: 'amtTotal', headerName: 'Total' },
    {
        field: 'syncError', headerName: 'Reason', cellRenderer: ErrorPopupCellRenderer,
    },
    { field: 'syncAttempts', headerName: 'Attempts' },
];

const defaultColDef = {
    resizable: true,
    width: 170,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
};

const FailedOrders: FC<any> = (): JSX.Element =>  {
    const navigate = useNavigate()
    const { accessToken } = useAppSelector(getUserDetails);
    const [isLoading, setIsLoading] = useState(false)
    const [rowData, setRowData] = useState([])

    const cellClickedListener = useCallback(
        (event: any) => {
            if (event.colDef.field === 'orderNumber') {
                navigate(`${URLS.ORDER_TABLE}/${event.data.id}`);
            }
        },
        [navigate]
    );

    const containerStyle = useMemo(
        () => ({ width: "100%", height: "calc(100vh - 180px)" }),
        []
    );
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    useEffect(() => {
        async function fetchFailedOrders(){
            try{
                setIsLoading(true)
                const result = await fetchAllOrders(accessToken)
                if(result?.data?.data){
                    const _data = formatResponse(result?.data?.data?.rows)
                    console.log(_data)
                    setRowData(_data)
                }
                setIsLoading(false)
            }catch(err){
                console.error(err)
                setIsLoading(false)
            }
        }

        if(accessToken) fetchFailedOrders()
    }, [accessToken])

    if(isLoading){
        return <SpinnerLoader />
    }

    return (
        <div className="me-2 page-bottom-space">

            <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
                <h4 className="mb-0 heading4-bold">AM Sync Failed Order</h4>
            </div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onCellClicked={cellClickedListener}
                        pagination={true}
                        paginationPageSize={10}
                    >
                    </AgGridReact>
                </div>
            </div>
        </div>
    )
}

export default FailedOrders


function formatResponse(rows: any){
    return rows.map((row: any) => ({
        id: row?.id,
        orderNumber: row.orderNumber,
        userName: row?.userInfo?.name,
        userEmail:  row?.userInfo?.email,
        orderDate:  new Date(row?.orderDate,).toLocaleDateString(),
        customerAccount:  row?.parentInfo?.accountName,
        customerName:  row?.parentInfo?.contactName,
        amtTotal:  row?.amountTotal,
        syncAttempts:  row?.AMSyncAttempts,
        syncError:  row?.AMSyncError.replace(/^\"/, "").replace(/\"$/, ""),
    }))
}