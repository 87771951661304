import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface IProp {
  name: string;
  label: string;
  placeholder?: string;
}

const RHFFileUpload: React.FC<IProp> = ({
  name,
  label,
  placeholder = "",
}): JSX.Element => {
  const { setValue, watch } = useFormContext();
  const [file, setFile] = React.useState<any>("");
  const blobUrl = watch(name);
  const showUpload = !blobUrl;

  const removeFile = () => {
    setFile("");
    setValue(name, "", { shouldValidate: true, shouldDirty: true });
    setValue("extension", "", { shouldValidate: true, shouldDirty: true });
  };

  React.useEffect(() => {
    if (file) {
      const _blobUrl = URL.createObjectURL(file);
      setValue(name, _blobUrl, { shouldValidate: true, shouldDirty: true });
      setValue("extension", file.name.split(".")[1], {
        shouldValidate: true,
        shouldDirty: true,
      });
      return function cleanup() {
        URL.revokeObjectURL(_blobUrl);
      };
    }
  }, [file]);

  return (
    <div className="mb-3">
      <p className="mb-2">{label}</p>
      <div className="upload__image-wrapper mb-3">
        {showUpload ? (
          <button type="button" className="btn btn-upload position-relative">
            <span>
              <i className="fa-solid fa-cloud-arrow-up heading1"></i>
              <span className="d-block">{placeholder}</span>
              <input
                className="hidden-input"
                accept=".png, .jpg, .jpeg"
                type="file"
                onChange={(e) => setFile(e.target.files?.[0])}
              />
            </span>
          </button>
        ) : (
          <div className="mt-3">
            <div className="row g-3">
              <div className="col-auto">
                <div className="avatar position-relative overflow-visible">
                  <img className="rounded" src={blobUrl} alt="..." />
                  <button
                    type="button"
                    className="btn btn-link-danger btn-x"
                    onClick={removeFile}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RHFFileUpload;
