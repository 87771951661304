export const URLS = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  UNAUTHORIZED: "/unauthorized",
  USER_DETAILS: "/user-details",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  CREATE_ACCOUNT: "/create-account",
  CUSTOMER_ACCOUNT: "/customer-account",
  PARENT_ACCOUNT_DETAILS: "/parent-account/:id",
  CUSTOMER_ACCOUNT_DETAILS: "/customer-account/:id",
  SALES_PERSON: "/sales-person",
  USERS_LOG: "/users-log",
  MARKETING_PERSON: "/marketing-person",
  CUSTOMER_ATTRIBUTES: "/customer-attributes",
  LOCATIONS: "/locations",
  COUNTRIES: "/countries",
  STATES: "/states",
  TEMPLATES: "/templates",
  CREATE_TEMPLATES: "/create-templates",
  TEMPLATES_DETAIL: "/templates-detail",
  STYLES: "/styles",
  CUSTOM_SIZE: "/custom-size",
  ORDER_TABLE: "/order-table",
  FAILED_ORDERS: "/failed-orders",
  SINGLE_ORDER_TABLE: "/order-table/:id",
  ORDER_STATUS: "/order-status",
  CART_TABLE: "/cart-table",
  PAYMENT_TABLE: "/payment-table",
  SHIPMENT_DETAILS: "/shipment-details",
  APPAREL_ADOBE_COLOR_RELATION: "/apparel-magic-adobe-color-relation",
  SHIPPING_CHARGE_CONFIG: "/shipping-charge-config",
  STRIPE_CONFIG: "/stripe-config",
  INVENTORY_TABLE: "/inventory-table",
  STYLES_PRICING: "/styles-pricing",
  GRAPHICS_RULES: "/graphics-rules",
  USERS: "/users",
  CATEGORIES: "/categories",
  ALT_STYLE_DESCRIPTION: "/alt-style-description",
  COLORS_LIST: (category: string) => `/colors-list/${category}`,
  GRAPHIC_COLORS: 'graphic-colors',
};
export enum USER_ROLE {
  SUPER_ADMIN = "SA",
  BUYER_ADMIN = "BA",
  CONTACT_PERSON = "CP",
  SALES_PERSON = "SP",
  MARKETING_PERSON = "MP",
}

export const AVAILABLE_ROLE = [
  { title: "Super Admin", shortCode: "SA" },
  { title: "Sales", shortCode: "SP" },
  { title: "Marketing", shortCode: "MP" },
]