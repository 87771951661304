import { fetchJSONFromURLService } from "api/templateService"
import { useEffect, useState } from "react"

function useTemplateColors(template: any, mapper: any, globalRules: any) {

    const [isLoading, setIsLoading] = useState(true)
    const [templateColors, setTemplateColors] = useState<any>({ body: [], graphic: [], graphic2: [], mapper: {} })

    useEffect(() => {
        async function fetchTemplateColors(template: any) {
            const [front, back] = await fetchTemplateJSONColors(template)
            const colors = extractColorsFromSwatches(front, back, mapper, globalRules)
            return colors

        }
        if (mapper && globalRules) {
            fetchTemplateColors(template)
                .then(colors => {
                    setTemplateColors(colors)
                }).catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setIsLoading(false)
                    console.log('Done....')
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalRules, mapper])

    return { isLoading, templateColors }
}

export default useTemplateColors

function extractColorsFromSwatches(frontColors: any, backColors: any, mapper: any, globalRules: any) {

    const bodyColors = frontColors.find((color: any) => color.name === 'body color') || []
    const frontGraphicColors = frontColors.find((color: any) => color.name === 'graphic color' || color.name === 'graphic color 1') || []
    const frontGraphicColors2 = frontColors.find((color: any) => color.name === 'graphic color 2') || []
    const backGraphicColors = backColors?.find((color: any) => color.name === 'graphic color' || color.name === 'graphic color 1') || []
    const backGraphicColors2 = backColors?.find((color: any) => color.name === 'graphic color 2') || []

    const body = bodyColors.LtDk ? [...(bodyColors.dark || []), ...(bodyColors.light || [])] : [...(bodyColors.options || [])]
    const frontGraphic = frontGraphicColors.LtDk ? [...(frontGraphicColors.dark || []), ...(frontGraphicColors.light || [])] : [...(frontGraphicColors.options || [])]
    const frontGraphic2 = frontGraphicColors2.LtDk ? [...(frontGraphicColors2.dark || []), ...(frontGraphicColors2.light || [])] : [...(frontGraphicColors2.options || [])]
    const backGraphic = backGraphicColors.LtDk ? [...(backGraphicColors.dark || []), ...(backGraphicColors.light || [])] : [...(backGraphicColors.options || [])]
    const backGraphic2 = backGraphicColors2.LtDk ? [...(backGraphicColors2.dark || []), ...(backGraphicColors2.light || [])] : [...(backGraphicColors2.options || [])]

    let graphic = [...frontGraphic, ...backGraphic]
    let graphic2 = [...frontGraphic2, ...backGraphic2]
    // let uniqueGraphic: { [key: string]: any } = {}, uniqueGraphic2: { [key: string]: any } = {}

    body.forEach(item => {
        item.colorName = item.actual_name.split("-")?.[0].trim()
    })
    graphic.forEach(item => {
        item.colorName = item.actual_name.split("-")?.[0].trim()
        item.colorValue = item.name
        item.category = 'graphic'
        // if (!uniqueGraphic[item.colorName]) uniqueGraphic[item.colorName] = item
    })
    graphic2.forEach(item => {
        item.colorName = item.actual_name.split("-")?.[0].trim()
        item.colorValue = item.name
        item.category = 'graphic2'
        // if (!uniqueGraphic2[item.colorName]) uniqueGraphic2[item.colorName] = item
    })
    // graphic = Object.values(uniqueGraphic)
    // graphic2 = Object.values(uniqueGraphic2)

    const commonBodyColors: any = {}
    const commonGaphicColors: any = {}
    const commonGaphicColors2: any = {}

    for (let _bodyColor of body) {
        for (let _graphicColor of graphic) {
            const graphicRuleKey = `["${_graphicColor.category}"]["${_bodyColor.colorName}"]["${_graphicColor.colorName}"]`
            const globalRule = mapper[graphicRuleKey]
            if (!globalRule) continue

            _bodyColor.colorHex = globalRule.bodyColorHex
            _graphicColor.colorHex = globalRule.graphicColorHex
            
            if (mapper[graphicRuleKey].disabled === undefined || mapper[graphicRuleKey].disabled === true) {
                mapper[graphicRuleKey].bodyColor = _bodyColor.name
                mapper[graphicRuleKey].graphicColor = _graphicColor.name
                mapper[graphicRuleKey].disabled = _bodyColor.shade !== _graphicColor.shade
                mapper[graphicRuleKey].shade = _bodyColor.shade
            }

            if (!commonBodyColors[_bodyColor.colorName]) {
                commonBodyColors[_bodyColor.colorName] = _bodyColor
            }
            if (!commonGaphicColors[_graphicColor.colorName]) {
                commonGaphicColors[_graphicColor.colorName] = _graphicColor
            }
        }
        for (let _graphicColor of graphic2) {
            const graphicRuleKey = `["${_graphicColor.category}"]["${_bodyColor.colorName}"]["${_graphicColor.colorName}"]`
            const globalRule = mapper[graphicRuleKey]
            if (!globalRule) continue

            _bodyColor.colorHex = globalRule.bodyColorHex
            _graphicColor.colorHex = globalRule.graphicColorHex

            if (mapper[graphicRuleKey].disabled === undefined || mapper[graphicRuleKey].disabled === true) {
                mapper[graphicRuleKey].bodyColor = _bodyColor.name
                mapper[graphicRuleKey].graphicColor = _graphicColor.name
                mapper[graphicRuleKey].disabled = _bodyColor.shade !== _graphicColor.shade
                mapper[graphicRuleKey].shade = _bodyColor.shade
            }

            if (!commonBodyColors[_bodyColor.colorName]) {
                commonBodyColors[_bodyColor.colorName] = _bodyColor
            }
            if (!commonGaphicColors2[_graphicColor.colorName]) {
                commonGaphicColors2[_graphicColor.colorName] = _graphicColor
            }
        }
    }

    const missingBodyColors: any = []
    const missingGaphicColors: any = []
    const missingGaphicColors2: any = []

    for (let item of body) {
        if (!commonBodyColors[item.colorName]) missingBodyColors.push(item)
    }
    for (let item of graphic) {
        if (!commonGaphicColors[item.colorName]) missingGaphicColors.push(item)
    }
    for (let item of graphic2) {
        if (!commonGaphicColors2[item.colorName]) missingGaphicColors2.push(item)
    }

    console.log('----', mapper)

    return {
        body: Object.values(commonBodyColors),
        graphic: Object.values(commonGaphicColors),
        graphic2: Object.values(commonGaphicColors2),
        missingBody: missingBodyColors,
        missingGaphic: missingGaphicColors,
        missingGaphic2: missingGaphicColors2,
        mapper: mapper
    }
}

async function fetchTemplateJSONColors(template: any) {

    const promises = [
        fetchJSONFromURLService(template.frontDescription).then(res => res.data.swatches)
    ]
    if (template.backDescription) promises.push(
        fetchJSONFromURLService(template.backDescription).then(res => res.data.swatches)
    )
    return Promise.all(promises)

} 