import React, { ChangeEvent, FC, useEffect, useState } from "react";
// import SpinnerLoader from "ui/loader/SpinnerLoader";
import { IMAGES } from "assets/images/images";
import Input from "ui/input/Input";
import * as bodyColorService from "api/bodyColorService";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import { toast } from "react-toastify";

export interface IData {
  colorAbbr: string;
  colorName: string;
  id: number;
  createdAt: string;
  updatedAt: string;
}

type IUpdateData = Partial<IData>;
type IAddData = Partial<IData>;

const ApparelMagicToAdobeIllustratorColorRelation: FC = () => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [data, setData] = useState<IData[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [dataToUpdate, setDataToUpdate] = useState<null | IUpdateData>();
  const [dataToAdd, setDataToAdd] = useState<null | IAddData>();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (dataToUpdate?.id) {
      setDataToUpdate((prev) => ({ ...prev, [name]: value }))
    } else {
      setDataToAdd((prev) => ({ ...prev, [name]: value }))
    }
  };

  const handleUpdate = async () => {
    if (!dataToUpdate?.id) return
    if (!dataToUpdate?.colorName || !dataToUpdate?.colorAbbr) {
      toast.error("Please provide color name & abbr")
      return
    }
    try {
      setIsUpdating(true)
      await bodyColorService.updateBodyColor(dataToUpdate, dataToUpdate.id, accessToken);
      toast.success("Color config updated successfully.")
      setIsUpdating(false)
      setDataToUpdate(null)
      fetchBodyColors()
    } catch (error) {
      setIsUpdating(false)
      toast.error("Failed to update color config.")
      console.log(error)
    }
  };

  const handleAdd = async () => {
    if (!dataToAdd?.colorName || !dataToAdd?.colorAbbr) {
      toast.error("Please provide color name & abbr")
      return
    }
    try {
      setIsAdding(true)
      await bodyColorService.addBodyColor(dataToAdd, accessToken);
      toast.success("Color config updated successfully")
      setIsAdding(false)
      setDataToAdd(null)
      fetchBodyColors()
    } catch (error) {
      console.log(error)
      setIsAdding(false)
      toast.error("Failed to add config.")
    }
  };

  const fetchBodyColors = async () => {
    try {
      const { data } = await bodyColorService.fetchAllBodyColor(accessToken);
      setData(data.data);
    } catch (e) {
    }
  };

  useEffect(() => {
    if(accessToken){
      fetchBodyColors()
    }
  }, [accessToken]);

  // if (loading) return <SpinnerLoader />;

  return (
    <div className="content-sm page-bottom-space">
      <div>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4-bold">Color Relation</h4>
        </div>

        <div className="border-bottom pb-1 d-flex justify-content-start align-items-center">
          <p className="mb-0">Apparel Magic Colors</p>
          <div className="ms-3 me-3 d-flex justify-content-center  align-items-center">
            <img src={IMAGES.ArrowRight} alt="" />
          </div>
          <p className="mb-0">Adobe Illustrator Colors</p>
        </div>

        <div className="mt-2">
          {data.map((item, i) => (
            <div
              key={item.colorAbbr}
              className="d-flex justify-content-start align-items-center"
            >
              {(item.id === dataToUpdate?.id) ? (
                <>
                  <Input
                    sectionClass="mb-3"
                    value={dataToUpdate?.colorAbbr}
                    onChange={onInputChange}
                    label=""
                    type="text"
                    name="colorAbbr"
                    placeholder="Abc"
                  />
                  <div className="ms-3 me-3 mb-3 d-flex justify-content-center  align-items-center">
                    <img src={IMAGES.ArrowRight} alt="" />
                  </div>
                  <Input
                    sectionClass="mb-3"
                    value={dataToUpdate?.colorName}
                    onChange={onInputChange}
                    label=""
                    type="text"
                    name="colorName"
                    placeholder="Abc"
                  />
                  <div>

                    <button
                      className="btn btn-danger ms-3 mb-3"
                      type="button"
                      onClick={handleUpdate}
                      disabled={isUpdating}
                    >
                      {isUpdating ? 'Updating' : 'update'}
                    </button>
                    <button
                      className="btn btn-danger-outline ms-3 mb-3"
                      type="button"
                      onClick={() => {
                        setDataToUpdate(null)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <Input
                    sectionClass="mb-3"
                    value={item.colorAbbr}
                    label=""
                    type="text"
                    name="colorAbbr"
                    placeholder="Abc"
                    disabled
                    readOnly
                  />
                  <div className="ms-3 me-3 mb-3 d-flex justify-content-center  align-items-center">
                    <img src={IMAGES.ArrowRight} alt="" />
                  </div>
                  <Input
                    sectionClass="mb-3"
                    value={item.colorName}
                    label=""
                    type="text"
                    name="colorName"
                    placeholder="Abc"
                    disabled
                    readOnly
                  />
                  <div>
                    <button
                      className="btn btn-danger ms-3 mb-3"
                      type="button"
                      onClick={() => {
                        setDataToUpdate(item)
                      }}
                      disabled={!!dataToAdd || !!dataToUpdate}
                    >
                      <i className="fa-solid fa-pen"></i>
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
          {dataToAdd && (
            <div
              className="d-flex justify-content-start align-items-center"
            >
              <Input
                sectionClass="mb-3"
                value={dataToAdd?.colorAbbr}
                onChange={onInputChange}
                label=""
                type="text"
                name="colorAbbr"
                placeholder="Abc"
              />
              <div className="ms-3 me-3 mb-3 d-flex justify-content-center  align-items-center">
                <img src={IMAGES.ArrowRight} alt="" />
              </div>
              <Input
                sectionClass="mb-3"
                value={dataToAdd?.colorName}
                onChange={onInputChange}
                label=""
                type="text"
                name="colorName"
                placeholder="Abc"
              />
              <div>

                <button
                  className="btn btn-danger ms-3 mb-3"
                  type="button"
                  onClick={handleAdd}
                  disabled={isAdding}
                >
                  {isAdding ? 'Adding' : 'Add'}
                </button>
                <button
                  className="btn btn-danger-outline ms-3 mb-3"
                  type="button"
                  onClick={() => {
                    setDataToAdd(null)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

        </div>
        <div>
          <button
            className="btn btn-danger px-4"
            type="button"
            disabled={!!dataToAdd || !!dataToUpdate}
            onClick={() => {
              setDataToAdd({ colorName: '', colorAbbr: '' })
            }}
          >
            Add More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApparelMagicToAdobeIllustratorColorRelation;
