import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const fetchShipping = (
    token: string,
    parentId: number
  ) => {
    return client.get(`/shipping-addresses?parentId=${parentId}`)
    // return axios({
    //   method: "get",
    //   url: BASE_URL + `/shipping-addresses?parentId=${parentId}`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: token,
    //   },
    // });
  };