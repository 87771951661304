import { useNavigate } from 'react-router-dom';

const UnAuthorize = () => {
  const navigate = useNavigate();
  const backHandler = () => {
    navigate('/');
  };
  return (
    <>
      <div>You are unauthorized</div>
      <button onClick={backHandler}>Back to Home</button>
      <button onClick={() => navigate(-1)}>Back</button>
    </>
  );
};

export default UnAuthorize;
