import { getSettings, updateSettings } from "api/settingsService";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";

type Props = {};

const StripeConfig = (props: Props) => {
  const { accessToken } = useAppSelector(getUserDetails);
  const methods = ["Live", "Test"];
  const [selected, setSelected] = useState("Live");

  const changeMethod = async () => {
    try {
      await updateSettings(
        {
          paymentMode: selected,
        },
        accessToken
      );
      toast.success('Settings updated')
    } catch (e) {
      console.log(e);
      toast.error('Failed to update settings')
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getSettings(accessToken);
        setSelected(data.data.paymentMode);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, [accessToken]);

  return (
    <div className="content-md page-bottom-space">
      <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
        <h4 className="mb-0 heading4-bold">Stripe Charge Setup</h4>
      </div>
      {methods.map((method) => (
        <div key={method}>
          <input
            className="custom-control-input"
            type="radio"
            name="design"
            id={`stripe-${method}`}
            value={method}
            onChange={() => {
              setSelected(method);
            }}
            checked={selected === method}
          />
          <label
            className="custom-control-label text-capitalize"
            htmlFor={`stripe-${method}`}
            style={{ marginLeft: "10px" }}
          >
            {" "}
            {method}
          </label>
        </div>
      ))}
      <div className="mt-3">
        <button
          type="button"
          className="btn btn-danger px-4"
          onClick={changeMethod}
        >
          {`Update`}
        </button>
      </div>
    </div>
  );
};

export default StripeConfig;
