import React, { ChangeEvent, FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ICustomSizeAddUpdateModalProps } from 'types/components/customerAttributesType';
import Input from 'ui/input/Input';
import SpinnerLoader from 'ui/loader/SpinnerLoader';



const CustomSizeAddUpdateModal: FC<ICustomSizeAddUpdateModalProps> = ({
  open,
  setOpen,
  customSize:defaultCustomSize,
  onSave,
  loading
}) => {
  const [error, setError] = useState({name:'',xs:'',s:'',m:'',l:'',xl:'',xxl:''});
  const [customSize, setCustomSize] = useState({ ...defaultCustomSize });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    let errors = error;
    switch (name) {
      case 'name':
        errors.name =
          value.length < 1
            ? `Name can not be empty`
            : ``;
        break;
      default:
        break;
    }
    setError(errors);
    if(name==='name')
      setCustomSize({ ...customSize,[name]: value })
    if(name!=='name' && (Number.isInteger(Number(value))) && Number(value)>=0 )
      setCustomSize({ ...customSize,[name]: +value });
  };
  const isButtonDisabled = () => {
        
    let data = {...customSize};
    let total=
    (customSize.xs)
    +
    (customSize.s)
    +
    (customSize.m)
    +
    (customSize.l)
    +
    (customSize.xl)
    +
    (customSize.xxl)
    if(total!==12)return false;
    return true;
}
  const handleSubmit = () => {
    if(!customSize.name.trim())
      {
        setError({...error,name:'Name can not be empty'})
        return;
      }
      onSave(customSize);
  }
  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'sm'}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="border-bottom mb-3">
          <p className="mb-0">
            {customSize.id
              ? `Update Custom Size`
              : `Add Custom Size`}
          </p>
        </div>
        <Input
              sectionClass="mb-3"
              value={customSize.name}
              onChange={handleChange}
              label={"Name*"}
              name={'name'}
              placeholder="Abc"
              errorMessage={error.name} 
        />
        <p className="mb-1">Enter a size scale that equals 12*</p>
        <div className="custom-size-input-wrap">
         <Input
              sectionClass="mb-3"
              value={customSize.xs}
              onChange={handleChange}
              label={"XS"}
              name={'xs'}
              placeholder="123"
              errorMessage={error.xs} 
        />
        <Input
              sectionClass="mb-3"
              value={customSize.s}
              onChange={handleChange}
              label={"S"}
              name={'s'}
              placeholder="123"
              errorMessage={error.s} 
        />
        <Input
              sectionClass="mb-3"
              value={customSize.m}
              onChange={handleChange}
              label={"M"}
              name={'m'}
              placeholder="123"
              errorMessage={error.m} 
        />
        <Input
              sectionClass="mb-3"
              value={customSize.l}
              onChange={handleChange}
              label={"L"}
              name={'l'}
              placeholder="123"
              errorMessage={error.l} 
        />
        <Input
              sectionClass="mb-3"
              value={customSize.xl}
              onChange={handleChange}
              label={"XL"}
              name={'xl'}
              placeholder="123"
              errorMessage={error.xl} 
        />
        <Input
              sectionClass="mb-3"
              value={customSize.xxl}
              onChange={handleChange}
              label={"XXL"}
              name={'xxl'}
              placeholder="123"
              errorMessage={error.xxl} 
        />
        </div>
        <div className="mt-3">
          <button className="btn btn-danger px-4" onClick={handleSubmit} disabled={!isButtonDisabled()}>
            {customSize?.id ? 'Update' : 'Save'}
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => {//setCustomSize()};
            setOpen(false)}}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomSizeAddUpdateModal;
