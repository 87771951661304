import Cookies from 'js-cookie';
import React, { useState } from 'react';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import {Dropdown} from "react-bootstrap";

import CompanyLogo from 'svgs/CompanyLogo.svg';
import StandardRecLogo from '../../assets/images/standardRecLogo.svg';
import SearchBar from 'components/searchBar/SearchBar';
import styles from './NavBar.module.scss';
import Profile from '../../assets/images/profile.svg'
import {clearUserDetails, getUserDetails} from "../../store/User.slice";
import {clearBuyerAdminAccountDetails} from "../../store/Accounts.slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";

const NavBar = () => {
  const [search, setSearch] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accessToken, name, email } = useAppSelector(getUserDetails);
  const [isLoggedIn, setIsLoggedIn] = useState(accessToken ? true : false);

  const handleLoginLogout = () => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      sessionStorage.clear();
      Cookies.remove('access_token')
      dispatch(clearUserDetails());
      dispatch(clearBuyerAdminAccountDetails());
      setIsLoggedIn(false);
      navigate('/login');
    }
  };

  return (
    <header className={`fixed-top ${styles.header}`}>
      <div className="container-fluid">
        <nav className={styles.navBar}>
          <div className="d-flex justify-content-center align-items-center">
            <NavLink className={`${styles.navBarBrand}`} to='/'>
              <img className={`img-fluid ${styles.logo}`}
                   src={StandardRecLogo}
                   alt="logo"
              />
            </NavLink>
            <div className="search-wrap">
              <SearchBar value={search} onChnage={handleChange} />
            </div>
          </div>
          <Dropdown className="custom-dropdown">
            <Dropdown.Toggle className="btn btn-light no-caret-icon border" id="profile">
              Admin Panel
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.menu}>
              <div className="p-3">
                <div className={styles.profileInfo}>
                  <div className={`avatar avatar-circle ${styles.profile}`}>
                    <img className="img-fluid" src={Profile} alt="profile"/>
                  </div>
                  <div>
                    <h4 className="font-size-base font-weight-normal mb-0 text-capitalize">{name}</h4>
                    <p className={`mb-1 ${styles.email}`}>{email}</p>
                    {accessToken &&
                    <Dropdown.Item onClick={()=>navigate('/reset-password')} className={`btn btn-link-danger font-size-xs text-underline ${styles.changePassword}`}>Change Password</Dropdown.Item>}
                  </div>
                </div>
              </div>
              <Dropdown.Item className={styles.signOut} onClick={handleLoginLogout}><i className="fa-solid fa-right-from-bracket mx-4 me-3"></i>Sign out</Dropdown.Item>
              <div className="py-2 text-center">
                <Link className={styles.menuLink} to="">Privacy policy</Link> • <Link className={styles.menuLink} to="">Terms of Service</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
