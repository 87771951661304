import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";

import { getUserDetails } from "../../store/User.slice";
import { useAppSelector } from "../../store/hooks";
import { URLS, USER_ROLE } from "../../utils/constants";
import styles from "./Sidebar.module.scss";

const Sidebar: FC = () => {
  const { role } = useAppSelector(getUserDetails);
  const [isLocationsOpen, setIsLocationsOpen] = useState(true);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(true);
  const [isOrderHistoryOpen, setIsOrderHistoryOpen] = useState(true);
  const [isGraphicsRuleOpen, setIsGraphicsRuleOpen] = useState(true);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);

  return (
    <div
      className={`${styles.sidebar} ${styles.scrollBarHide} ${styles.overflowYAuto}`}
    >
      <ul
        className={`list-group list-group-flush list-style-none ${styles.sidebarMenu}`}
      >
        <li>
          <NavLink
            to={URLS.HOME}
            className={`${styles.menuLink} ${styles.hasLeftIcon}`}
          >
            <i
              className={`fa-solid fa-store text-danger ${styles.leftIcon}`}
            ></i>
            Accounts
          </NavLink>
        </li>
        {role === USER_ROLE.SUPER_ADMIN && (
          <>
            <li>
              <NavLink
                to={URLS.USERS_LOG}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-user-group text-danger ${styles.leftIcon}`}
                ></i>
                Users Log
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to={URLS.SALES_PERSON}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-user-group text-danger ${styles.leftIcon}`}
                ></i>
                Sales Team
              </NavLink>
            </li>
            <li>
              <NavLink
                to={URLS.MARKETING_PERSON}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-user-group text-danger ${styles.leftIcon}`}
                ></i>
                Marketing Team
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to={URLS.CUSTOMER_ATTRIBUTES}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-user-group text-danger ${styles.leftIcon}`}
                ></i>
                Marketing Team
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to={URLS.CUSTOMER_ATTRIBUTES}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-user-group text-danger ${styles.leftIcon}`}
                ></i>
                Marketing Team
              </NavLink>
            </li> */}
            <li className="position-relative">
              <i
                onClick={() => setIsGraphicsRuleOpen(!isGraphicsRuleOpen)}
                className={`fa-solid text-danger ${styles.leftIcon} ${
                  styles.caretIcon
                } 
                ${isGraphicsRuleOpen ? "fa-caret-down" : "fa-caret-right"}`}
              ></i>
              <NavLink
                to={URLS.GRAPHICS_RULES}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-paint-brush text-danger ${styles.leftIcon}`}
                ></i>
                Color Rule
              </NavLink>
              {isGraphicsRuleOpen && (
                <div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.APPAREL_ADOBE_COLOR_RELATION}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Color Config
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.COLORS_LIST("body")}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Body Colors
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.COLORS_LIST("graphic")}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Graphic Colors
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.GRAPHIC_COLORS}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Graphic Colors V2
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.GRAPHICS_RULES}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Global Graphic Rules
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
            <li>
              <NavLink
                to={URLS.SHIPPING_CHARGE_CONFIG}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-truck text-danger ${styles.leftIcon}`}
                ></i>
                Shipping Charge
              </NavLink>
            </li>
            <li>
              <NavLink
                to={URLS.STRIPE_CONFIG}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa fa-credit-card text-danger ${styles.leftIcon}`}
                ></i>
                Stripe Config
              </NavLink>
            </li>
            <li className="position-relative">
              <i
                onClick={() => setIsLocationsOpen(!isLocationsOpen)}
                className={`fa-solid text-danger ${styles.leftIcon} ${
                  styles.caretIcon
                } 
                ${isLocationsOpen ? "fa-caret-down" : "fa-caret-right"}`}
              ></i>
              <NavLink
                to={URLS.LOCATIONS}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-regular fa-map text-danger ${styles.leftIcon}`}
                ></i>
                Locations
              </NavLink>
              {isLocationsOpen && (
                <div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.STATES}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      States
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.COUNTRIES}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Countries
                    </NavLink>
                  </div>
                </div>
              )}
            </li>

            <li className="position-relative">
              <i
                onClick={() => setIsTemplatesOpen(!isTemplatesOpen)}
                className={`fa-solid text-danger ${styles.leftIcon} ${
                  styles.caretIcon
                } 
                ${isTemplatesOpen ? "fa-caret-down" : "fa-caret-right"}`}
              ></i>
              <NavLink
                to={URLS.TEMPLATES}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-regular fa-image text-danger ${styles.leftIcon}`}
                ></i>
                Templates
              </NavLink>
              {isTemplatesOpen && (
                <div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.CUSTOMER_ATTRIBUTES}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Attributes
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.STYLES}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Filter Styles
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
            <li className="position-relative">
              <i
                onClick={() => setIsOrderHistoryOpen(!isOrderHistoryOpen)}
                className={`fa-solid text-danger ${styles.leftIcon} ${
                  styles.caretIcon
                } 
                ${isOrderHistoryOpen ? "fa-caret-down" : "fa-caret-right"}`}
              ></i>
              <NavLink
                to={URLS.ORDER_TABLE}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-shopping-cart text-danger ${styles.leftIcon}`}
                ></i>
                Order History
              </NavLink>
              {isOrderHistoryOpen && (
                <div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.ORDER_TABLE}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Order Table
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.FAILED_ORDERS}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Failed Order
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
            <li className="position-relative">
              <i
                onClick={() => setIsInventoryOpen((prev) => !prev)}
                className={`fa-solid text-danger ${styles.leftIcon} ${
                  styles.caretIcon
                } 
                ${isInventoryOpen ? "fa-caret-down" : "fa-caret-right"}`}
              ></i>
              <div className={`${styles.menuLink} ${styles.hasLeftIcon}`}>
                <i
                  className={`fa-solid fa-box-open text-danger ${styles.leftIcon}`}
                ></i>
                Inventory
              </div>

              {isInventoryOpen && (
                <div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.CUSTOM_SIZE}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Custom Sizes
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.INVENTORY_TABLE}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Blank Inventory
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.ALT_STYLE_DESCRIPTION}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Alt Style Description
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.CATEGORIES}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Alt Style Categories
                    </NavLink>
                  </div>
                  <div className="mt-1">
                    <NavLink
                      to={URLS.STYLES_PRICING}
                      className={`${styles.menuLink} ${styles.hasLeftIcon}`}
                    >
                      Styles Pricing
                    </NavLink>
                  </div>
                </div>
              )}
            </li>
            <li>
              <NavLink
                to={URLS.USERS}
                className={`${styles.menuLink} ${styles.hasLeftIcon}`}
              >
                <i
                  className={`fa-solid fa-users text-danger ${styles.leftIcon}`}
                ></i>
                Activity
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
