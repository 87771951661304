import React, {FC} from "react";
import styles from "./Templates.module.scss";

type TemplateDetailBoxType = {
    title: string;
    imgUrl: string;
    name: string;
}

const TemplateDetailBox: FC<TemplateDetailBoxType> = (props) => {
    const {title, imgUrl, name} = props;
    return(
        <div className="card mb-3">
            <div className="card-body p-2">
                <div className="row g-3">
                    <div className="col-md-4">
                        <p className="mb-0 text-secondary">{title}</p>
                    </div>
                    <div className="col-md-8">
                        <div className={`d-flex align-items-center flex-nowrap ${styles.thumbnailWrap}`}>
                            <div className="avatar avatar-xs">
                                <img className="img-fluid object-fit-contain" src={imgUrl} alt={name}/>
                            </div>
                            <p className="text-truncate mb-0">{name}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TemplateDetailBox;