import axios from "axios";
import { client } from "utils/client";

export const getPreSignedUrl = (params: { [key: string]: string }) => {
  return client.get("/utils/pre-signed-url", { params }).then((res) => res.data?.data);
};

export const uploadFileToPreSignedUrl = (url: string, file: File | Blob) => {
  const requestOptions = {
    method: "PUT",
    body: file,
    redirect: "follow",
  } as any;
  return fetch(url, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => {
      console.log(error)
      throw new Error(`Failed upload file`)
    });
};

const getContentTypeFromQueryParams = (url: string) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const contentType = params.get('content-type')
  return contentType;
}

export const uploadFileToPreSignedUrlWithProgress = (url: string, file: File | Blob, onUploadProgress: (progressEvent: any) => void) => { 
  const config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: url,
    headers: { 
      'Content-Type': file.type
    },
    data : file,
    onUploadProgress
  };
  
  return axios.request(config as any)
};
