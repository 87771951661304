import * as React from 'react'
import ManageLabelModal from './ManageLabelModal'
import * as templateService from 'api/templateService'

const TemplateLabelModal = (props: any) => {
  const { accessToken, templateList, templateSelected, labels, setRefetchLabel } = props
  const [stats, setStats] = React.useState<{ [key: string]: string }>({})
  const [labelSelected, setLabelSelected] = React.useState<{ [key: string]: boolean }>({})
  const [templateLabels, setTemplateLabels] = React.useState([])
  const [labelTemplateMap, setLabelTemplateMap] = React.useState<{ [key: string]: any }>({})
  const [labelVal, setLabelVal] = React.useState('')
  const [manageLabel, setManageLabel] = React.useState(false)
  const [isApplying, setIsApplying] = React.useState(false)
  const [addingLabel, setAddingLabel] = React.useState(false)
  const [newStats, setNewStats] = React.useState<{ [key: string]: string | null }>({})


  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const labelId = e.target.name
    const checked = e.target.checked

    if (stats[labelId] === 'some' && !checked) {
      if (!newStats[labelId]) setNewStats((prev) => ({ ...prev, [labelId]: 'all' }))
      else setNewStats((prev) => ({ ...prev, [labelId]: 'none' }))
    }
    else if (stats[labelId] === 'some' && checked) {
      if (newStats[labelId] === 'none') setNewStats((prev) => ({ ...prev, [labelId]: null }))
    }
    else {
      setNewStats((prev) => ({ ...prev, [labelId]: checked ? 'all' : 'none' }))
      setLabelSelected((prev) => ({ ...prev, [labelId]: checked }))
    }
  }


  const handleLabelInputKeyUp = async (e: any) => {
    if (e.key !== "Enter") return;
    setAddingLabel(true);
    try {
      const payload = { title: labelVal };
      await templateService.createLabel(props.accessToken, payload);
      setLabelVal("");
    } catch (err) {
      console.error(err);
    } finally {
      setAddingLabel(false);
      setRefetchLabel(true)
    }
  };


  const configureTemplateLabel = async () => {
    const templateIds = Object.entries(templateSelected).filter(([k, v]) => !!v).map(([k]) => Number(k))
    const labelIds = Object.entries(labelSelected).filter(([k, v]) => !!v).map(([k]) => Number(k))
    const templateLabels: any = []

    labelIds.forEach((labelId) => {
      if (newStats[labelId] === 'all') {
        templateIds.forEach((templateId) => (
          templateLabels.push({ labelId, templateId })
        ))
      } else if (newStats[labelId] !== 'none') {
        templateIds.forEach((templateId) => {
          if (labelTemplateMap?.[labelId]?.[templateId]) templateLabels.push({ labelId, templateId })
        })
      }
    })

    const toBeRomoved = Object.entries(newStats).reduce((a: any, c: any) => {
      const [k, v] = c
      if (v === 'none' && labelTemplateMap?.[k]) {
        templateIds.forEach((templateId) => {
          if (labelTemplateMap?.[k]?.[templateId]) a.push(templateId)
        })
      }
      return a
    }, [])

    try {
      setIsApplying(true)
      await templateService.confiureTemplateLabel(props.accessToken, { templateLabels, toBeRomoved: toBeRomoved })
      props.handleClose()
      props.setRefetchTemplate(true)
    } catch (err) {
      console.error(err);
    } finally {
      setIsApplying(false)

    }
  };

  React.useEffect(() => {
    async function fetchTemplateLabels() {
      try {
        const payload = { templateIds: templateList.map((temp: any) => temp.id) }
        const response = await templateService.fetchTemplateLabel(props.accessToken, payload)
        setTemplateLabels(response.data.data)
      } catch (err) {
        console.error(err)
      }
    }
    if (templateList.length) {
      fetchTemplateLabels()
    }
  }, [templateList, accessToken])

  React.useEffect(() => {
    const { ltMap } = templateLabels.reduce((a: any, c: any) => {
      if (!a.ltMap[c.labelId]) a.ltMap[c.labelId] = {}
      // if (!a.tlMap[c.templateId]) a.tlMap[c.templateId] = {}
      a.ltMap[c.labelId][c.templateId] = true
      // a.tlMap[c.templateId][c.labelId] = true
      return a
    }, { ltMap: {}, tlMap: {} })
    setLabelTemplateMap(ltMap)
  }, [templateLabels])

  React.useEffect(() => {
    const templateIds = Object.entries(templateSelected).filter(([k, v]) => !!v).map(([k]) => Number(k))

    const toBeChecked = labels.reduce((a: any, c: any) => {
      const foundIdx = templateIds.findIndex((id) => labelTemplateMap?.[c.id]?.[id] === true)
      if (foundIdx > -1) a[c.id] = true
      else a[c.id] = false
      return a
    }, {})

    setLabelSelected((prev) => ({ ...prev, ...toBeChecked }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelTemplateMap, templateSelected, labels])


  React.useEffect(() => {
    const templateIds = Object.entries(templateSelected).filter(([k, v]) => !!v).map(([k]) => Number(k))
    const labelIds = Object.entries(labelSelected).filter(([k, v]) => !!v).map(([k]) => Number(k))
    if (templateIds.length && labelIds.length) {
      const _stats = labelIds.reduce((a: any, c: any) => {
        a[c] = 'none'
        const tempMap = labelTemplateMap[c]
        if (tempMap) {
          const sum = templateIds.reduce((a: number, c: number) => {
            a += tempMap[c] ? 1 : 0
            return a
          }, 0)
          a[c] = sum === templateIds.length ? 'all' : 'some'
        }
        return a
      }, {})
      setStats(_stats)
    }
  }, [labelTemplateMap, templateSelected, labelSelected])

  return (
    <>
      <div className="dropdown-menu py-2">
        <div className="d-flex justify-content-between align-items-center mb-2 px-2">
          <p className="mb-0">Label as:</p>
          <div>
            <button
              className="btn btn-link-danger text-danger me-2"
              onClick={() => setManageLabel(true)}
            >
              <span className="fa-solid fa-pen"></span>
            </button>
            <button
              className="btn btn-link-danger text-danger"
              onClick={() => props.handleClose()}
            >
              <span className="fa-solid fa-window-close"></span>
            </button>
          </div>
        </div>
        <div
          className="px-2"
          style={{
            maxHeight: "250px",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          {labels.map((item: any) => (
            <div
              key={item.id}
              className="custom-checkbox grey-shade mb-2"
            >
              <input
                className={`custom-control-input ${(!newStats[item.id] && stats[item.id] === 'some') && 'mulitple-input-mark'}`}
                type="checkbox"
                id={item.id}
                name={item.id}
                onChange={handleCheck}
                checked={newStats[item.id] ? (newStats[item.id] !== 'none') : labelSelected[item.id]}
              />
              <label className="custom-control-label" htmlFor={item.id}>
                {item.title}
              </label>
            </div>
          ))}
        </div>
        <div className="form-group px-2 mb-2">
          <hr className="seperator my-1" />
          <label className="form-label">Add new</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter label title"
            value={labelVal}
            onChange={(e) => {
              const val = e.target.value.replace(/[^a-z0-9]/gi, "")
              setLabelVal(val)
            }}
            onKeyUp={handleLabelInputKeyUp}
            disabled={addingLabel}
          />
        </div>
        <hr className="seperator my-1" />
        <button
          className="btn btn-link-danger text-dark p-0 mx-2"
          onClick={configureTemplateLabel}
          disabled={isApplying}
        >
          {isApplying ? 'Applying...' : 'Apply'}
        </button>
      </div >
      {manageLabel && (
        <ManageLabelModal
          show={manageLabel}
          onHide={() => setManageLabel(false)}
          accessToken={props.accessToken}
          labels={labels}
          refetchLabel={false}
          setRefetchLabel={setRefetchLabel}
          setRefetchTemplate={props.setRefetchTemplate}
        />
      )
      }
    </>
  )
}

export default TemplateLabelModal