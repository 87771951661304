import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { checkBOMEmailStatus, checkOrderSyncStatus, getAllOrders, sendBOMEmail, startOrderSyncToAM } from "api/orderService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { URLS } from "utils/constants";
import "./OrderHistory.module.scss";
import {
  columnDefs,
  // gridOptionN,
  convertOrderTableDataToAgGridData,
  orderPageDefaultColDefs,
} from "./orderTable.utils";
import { toast } from "react-toastify";

const OrderTable = () => {
  const navigate = useNavigate();

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "calc(100vh - 180px)" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState<any[]>([]);

  const defaultColDef = useMemo<ColDef>(() => {
    return orderPageDefaultColDefs;
  }, []);

  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState<boolean>(false);

  const cellClickedListener = useCallback(
    (event: any) => {
      navigate(`${URLS.ORDER_TABLE}/${event.data.id}`);
    },
    [navigate]
  );

  const sendBOmEmail = async() => {
    try {
      setLoading(true);
      await sendBOMEmail(accessToken);
      checkBOMEmailStatusByPP()
    } catch (err) {
      setLoading(false);
      toast.error('Unable to send BOM email')
      console.error(err);
    }
  }

  const triggerOrdersSyncToAM = async() => {
    try {
      setLoading(true);
      await startOrderSyncToAM(accessToken);
      checkOrderSyncStatusByPP()
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const checkOrderSyncStatusByPP = async () => {
    const maxTry = 7
    let _try = 1
    setLoading(true)
    const intervalRef = setInterval(async() => {
      const result = await checkOrderSyncStatus(accessToken).catch(() => {
        clearInterval(intervalRef)
        setLoading(false)
      })
      if(_try > maxTry || result?.data?.data === "INACTIVE"){
        clearInterval(intervalRef)
        setLoading(false)
        toast.success('Successfully Orders Synced')
      }
    }, 3000)
  }

  const checkBOMEmailStatusByPP = async () => {
    const maxTry = 7
    let _try = 1
    setLoading(true)
    const intervalRef = setInterval(async() => {
      const result = await checkBOMEmailStatus(accessToken).catch(() => {
        clearInterval(intervalRef)
        setLoading(false)
      })
      if(_try > maxTry || result?.data?.data === "INACTIVE"){
        clearInterval(intervalRef)
        setLoading(false)
        toast.success('Successfully Orders Synced')
      }
    }, 3000)
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const {
          data: { data },
        } = await getAllOrders(accessToken);
        setLoading(false);

        setRowData(convertOrderTableDataToAgGridData(data.rows));
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    getData();
  }, [accessToken]);

  if (loading) return <SpinnerLoader />;

  const gridReady = (params: any) => {
    let gridApi = params.api;
    sizeToFit(gridApi);
  };

  const sizeToFit = (gridApi: any) => {
    gridApi.sizeColumnsToFit();
  };

  return (
    <div className="me-2 page-bottom-space">
      {/* <p>Order Table</p>11 */}
      <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
        <h4 className="mb-0 heading4-bold">Order Table</h4>
        <button
          type="button"
          className="btn btn-link"
          onClick={sendBOmEmail}
        >
          <i className="fa-solid fa-envelope text-danger me-2"></i>
          <span className="text-dark">Send BOM Email</span>
        </button>
        <button
          type="button"
          className="btn btn-link"
          onClick={triggerOrdersSyncToAM}
        >
          <i className="fa-solid fa-sync text-danger me-2"></i>
          <span className="text-dark">Sync Order TO AM</span>
        </button>
      </div>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            // gridOptions={gridOptionN}
            onGridReady={(params) => gridReady(params)}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowTransform={true}
            onCellClicked={cellClickedListener}
            // rowModelType={'serverSide'}
            pagination={true}
            paginationPageSize={20}
            cacheBlockSize={10}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderTable;
