import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "ui/input/Input";
import { AccountInfo } from "types/AccountsSlice";
import { validateEmail } from "utils/helperFunctions";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import InputPhone from "ui/phone/InputPhone";
import { Modal } from "react-bootstrap";
import Heading from "ui/Heading/Heading";
import { IContactDetails } from "api/requestTypes";
import { fetchContactListService } from "api/parentService";
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";
import { fetchApparelContactPeople } from "api/parentService";
import { useLocation, useParams } from "react-router-dom";
interface IModal {
  title?: string;
  onSave: (data: any) => void;
  open: boolean;
  setOpen: Function;
  defaultValues?: any;
}

const ParentUpdateModal: React.FC<IModal> = ({
  title,
  onSave,
  open,
  setOpen,
  defaultValues,
}) => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [info, setInfo] = useState({
    name: "",
    contactEmail: "",
    contactPhone: "",
    contactName: "",
    salesPersonIds: [],
    marketingPersonIds: [],
    assignIds: [],
  });
  const [customerPersonsList, setCustomerPersonsList] = useState<any>([]);
  const [selectedCustomerPerson, setSelectedCustomerPerson] = useState<any[]>(
    []
  );
  const [salesPersons, setSalesPersons] = useState<IContactDetails[]>([]);
  const [marketingPersons, setMarketingPersons] = useState<IContactDetails[]>(
    []
  );

  const [salesPersonsSelected, setSalesPersonsSelected] = useState<
    IContactDetails[]
  >([]);
  const [marketingPersonsSelected, setMarketingPersonsSelected] = useState<
    IContactDetails[]
  >([]);

  const [error, setError] = useState({
    name: "",
    contactEmail: "",
    contactPhone: "",
    contactName: "",
  });
  const handleInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errors = { ...error };
    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Name can not be empty" : "";
        break;
      case "contactEmail":
        errors.contactEmail = !validateEmail(value)
          ? "Please enter valid email"
          : "";
        break;
      default:
        break;
    }
    setError(errors);
    setInfo({ ...info, [name]: value });
  };
  const handlePhoneInfo = (val: any) => {
    if (val && !isValidPhoneNumber(val))
      setError({ ...error, contactPhone: "Invalid Phone Number" });
    else {
      setError({ ...error, contactPhone: "" });
      setInfo({ ...info, contactPhone: val });
    }
  };
  const handleSubmit = () => {
    if (
      info.name &&
      !(info.contactEmail.trim() && error.contactEmail) &&
      !error.contactPhone
    ) {
      const sp = defaultValues?.salesPersonListIds;
      let newlyAssignedSalesPersonIds: (string | number)[] = [];
      let removedSalesPersonsIds: (string | number)[] = [];

      // if (sp && sp.length) {
      const existingSalesPersonsIds = new Set(sp || []);
      const selectedSalesPersonIds = new Set(
        salesPersonsSelected.map((e) => e.id)
      );
      newlyAssignedSalesPersonIds = salesPersonsSelected
        .filter((x: any) => !existingSalesPersonsIds.has(x.id))
        .map((e) => e.id);

      removedSalesPersonsIds = sp.filter(
        (x: any) => !selectedSalesPersonIds.has(x)
      );

      const mp = defaultValues?.marketingPersonListIds;
      let newlyAssignedMarketingPersonIds: (string | number)[] = [];
      let removedMarketingPersonsIds: (string | number)[] = [];

      // if (sp && sp.length) {
      const existingMarketingPersonsIds = new Set(mp || []);
      const selectedMarketingPersonIds = new Set(
        marketingPersonsSelected.map((e) => e.id)
      );
      newlyAssignedMarketingPersonIds = marketingPersonsSelected
        .filter((x: any) => !existingMarketingPersonsIds.has(x.id))
        .map((e) => e.id);

      removedMarketingPersonsIds = mp.filter(
        (x: any) => !selectedMarketingPersonIds.has(x)
      );

      // }

      const saveInfo = {
        ...info,
        assignIds: newlyAssignedSalesPersonIds,
        removedSalesPersonIds: removedSalesPersonsIds,
        assignMPIds: newlyAssignedMarketingPersonIds,
        removedMarketingPersonIds: removedMarketingPersonsIds,
      };
      onSave(saveInfo);
    } else {
      alert("Please fill all required fields");
    }
    return;
  };

  const removeSales = (id: any) => {
    const removed = salesPersonsSelected.filter((obj) => {
      return obj.id == id;
    });
    const result = salesPersonsSelected.filter((obj) => {
      return obj.id != id;
    });
    setSalesPersonsSelected(result);
    setSalesPersons((prev) => [...prev, ...removed]);
  };
  const removeMarketing = (id: any) => {
    const removed = marketingPersonsSelected.filter((obj) => {
      return obj.id == id;
    });
    const result = marketingPersonsSelected.filter((obj) => {
      return obj.id != id;
    });
    setMarketingPersonsSelected(result);
    setMarketingPersons((prev) => [...prev, ...removed]);
  };
  const removeCustomer = (id: any) => {
    const removed = selectedCustomerPerson.filter((obj) => {
      return obj.id == id;
    });
    const result = selectedCustomerPerson.filter((obj) => {
      return obj.id != id;
    });
    setSelectedCustomerPerson(result);
    setCustomerPersonsList((prev: any) => [...prev, ...removed]);
  };
  const handleChangeSales = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = salesPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setSalesPersonsSelected([...salesPersonsSelected, ...res]);
    const result = salesPersons.filter((obj) => {
      return obj.id != e.target.value;
    });

    setSalesPersons(result);
    //setAddSalesPerson(false);
  };
  const handleChangeMarketing = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = marketingPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setMarketingPersonsSelected([...marketingPersonsSelected, ...res]);
    const result = marketingPersons.filter((obj) => {
      return obj.id != e.target.value;
    });

    setMarketingPersons(result);
    //setAddSalesPerson(false);
  };

  const handleChangeCustomer = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = customerPersonsList.filter((obj: any) => {
      return obj.id == e.target.value;
    });
    setSelectedCustomerPerson([...selectedCustomerPerson, ...res]);
    const result = customerPersonsList.filter((obj: any) => {
      return obj.id != e.target.value;
    });

    setCustomerPersonsList(result);
  };

  useEffect(() => {
    setInfo({
      name: defaultValues?.name || "",
      contactEmail: defaultValues?.bemail || "",
      contactPhone: defaultValues?.bphone || "",
      contactName: defaultValues?.bname || "",
      salesPersonIds: defaultValues?.salesPersonListIds,
      marketingPersonIds: defaultValues?.marketingPersonListIds,
      assignIds: [],
    });
  }, [defaultValues]);

  const { id: parentId } = useParams();

  useEffect(() => {
    const getContactAndSalesList = async () => {
      try {
        const { data } = await fetchContactListService(accessToken);
        // const { data: contactPersonData } = await fetchApparelContactPeople(
        //   `${parentId}`,
        //   accessToken
        // );
        // setCustomerPersonsList(contactPersonData.data);
        if (data.data) {
          const { contactPersons, salesPersons, marketingPersons } = data.data;
          //setContacPersontList(contactPersons);

          {
            const sp = defaultValues?.salesPersonListIds;
            let difference = [...salesPersons];
            let current = [];
            if (sp && sp.length) {
              const assignedSalesPersonIds = new Set(sp);
              difference = salesPersons.filter(
                (x: any) => !assignedSalesPersonIds.has(x.id)
              );

              current = salesPersons.filter((x: any) =>
                assignedSalesPersonIds.has(x.id)
              );
            }
            setSalesPersons(difference);
            setSalesPersonsSelected(current);

            const mp = defaultValues?.marketingPersonListIds;
            let differenceMP = [...marketingPersons];
            let currentMP = [];
            if (mp && mp.length) {
              const assignedMarketingPersonIds = new Set(mp);
              differenceMP = marketingPersons.filter(
                (x: any) => !assignedMarketingPersonIds.has(x.id)
              );

              currentMP = marketingPersons.filter((x: any) =>
                assignedMarketingPersonIds.has(x.id)
              );
            }
            setMarketingPersons(differenceMP);
            setMarketingPersonsSelected(currentMP);
          }
        }
      } catch (error) {
        alert("Could not fetch the contact and sales persons list");
      }
    };
    getContactAndSalesList();
  }, [
    accessToken,
    defaultValues?.salesPersonListIds,
    defaultValues?.marketingPersonListIds,
  ]);

  return (
    <div>
      <Modal
        className="custom-drawer date-picker-modal"
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="custom-modal"
        backdrop="static"
        size={"lg"}
      >
        <Modal.Body>
          <div className="border-bottom mb-3">
            <p className="mb-0">{title}</p>
          </div>
          <Input
            sectionClass="mb-3"
            value={info.name}
            onChange={handleInfo}
            label="Parent Account Name*"
            name="name"
            placeholder={"Abc"}
            disabled
          />
          <Input
            sectionClass="mb-3"
            value={info.contactName}
            onChange={handleInfo}
            label="Parent Contact Name"
            name="contactName"
            placeholder={"Abc"}
            disabled
          />
          <Input
            sectionClass="mb-3"
            value={info.contactEmail}
            onChange={handleInfo}
            label="Parent Contact Email"
            name="contactEmail"
            placeholder={"Abc"}
            errorMessage={error.contactEmail}
            disabled
          />

          <InputPhone
            sectionClass="mb-3"
            label={"Parent Contact Number*"}
            value={info.contactPhone}
            onChange={handlePhoneInfo}
            errorMessage={error.contactPhone}
            disabled
          />
          <section className="mb-3">
            {/* ---pending: */}
            {/* <Heading
              headingTitle="Customer from Apparel Magic"
              wrapperClassName="mb-2"
            />
            <select
              className="form-select"
              title={""}
              onChange={handleChangeCustomer}
              value={""}
              name={"customer"}
            >
              {<option value="">{"Select Customer from Apparel Magic"}</option>}
              {customerPersonsList.map((el: any) => (
                <option value={el.id}>{el.name}</option>
              ))}
            </select>

            {selectedCustomerPerson.length > 0 && (
              <ul className="list-group list-group-flush list-style-none mt-2">
                {React.Children.toArray(
                  selectedCustomerPerson.map((salePerson, key) => (
                    <li className="has-right-action list-item-mb-2" key={key}>
                      <p className="mb-0 text-capitalize">{salePerson.name}</p>
                      <div className="action-wrap">
                        <button
                          type="button"
                          className="btn btn-link-danger"
                          onClick={() => removeCustomer(salePerson.id)}
                        >
                          <i className="fa-solid fa-xmark">{""}</i>
                        </button>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            )} */}

            <Heading headingTitle="Sales Person" wrapperClassName="mt-2 mb-2" />
            <select
              className="form-select"
              title={""}
              onChange={handleChangeSales}
              value={""}
              name={"sales"}
            >
              {<option value="">{"Select Sales Person"}</option>}
              {React.Children.toArray(
                salesPersons.map((el) => (
                  <option value={el.id}>{el.name}</option>
                ))
              )}
            </select>

            {salesPersonsSelected.length > 0 && (
              <ul className="list-group list-group-flush list-style-none mt-2">
                {React.Children.toArray(
                  salesPersonsSelected.map((salePerson, key) => (
                    <li className="has-right-action list-item-mb-2" key={key}>
                      <p className="mb-0 text-capitalize">{salePerson.name}</p>
                      <div className="action-wrap">
                        <button
                          type="button"
                          className="btn btn-link-danger"
                          onClick={() => removeSales(salePerson.id)}
                        >
                          <i className="fa-solid fa-xmark">{""}</i>
                        </button>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            )}
          </section>
          <section className="mb-3">
            <Heading
              headingTitle="Marketing Person"
              wrapperClassName="mt-2 mb-2"
            />
            <select
              className="form-select"
              title={""}
              onChange={handleChangeMarketing}
              value={""}
              name={"sales"}
            >
              {<option value="">{"Select Marketing Person"}</option>}
              {React.Children.toArray(
                marketingPersons.map((el) => (
                  <option value={el.id}>{el.name}</option>
                ))
              )}
            </select>

            {marketingPersonsSelected.length > 0 && (
              <ul className="list-group list-group-flush list-style-none mt-2">
                {React.Children.toArray(
                  marketingPersonsSelected.map((marketingPerson, key) => (
                    <li className="has-right-action list-item-mb-2" key={key}>
                      <p className="mb-0 text-capitalize">
                        {marketingPerson.name}
                      </p>
                      <div className="action-wrap">
                        <button
                          type="button"
                          className="btn btn-link-danger"
                          onClick={() => removeMarketing(marketingPerson.id)}
                        >
                          <i className="fa-solid fa-xmark">{""}</i>
                        </button>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            )}
          </section>
          <div className="mt-3">
            <button
              className="btn btn-danger px-4"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
            <button
              className="btn btn-outline-danger px-4 ms-3"
              onClick={() => {
                setError({
                  name: "",
                  contactEmail: "",
                  contactName: "",
                  contactPhone: "",
                });
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ParentUpdateModal;
