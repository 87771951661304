import * as React from "react";
import { Modal } from "react-bootstrap";
import * as templateService from "api/templateService";

const ManageLabelModal = (props: any) => {
  const { show, onHide, accessToken, labels, refetchLabel, setRefetchLabel } = props
  const deletedCount = React.useRef(0)
  const updatedCount = React.useRef(0)
  const [labelTitle, setLabelTitle] = React.useState('')
  const [editId, setEditId] = React.useState(null)
  const [deleteId, setDeleteId] = React.useState(null)
  const [isUpdating, setIsUpdating] = React.useState(false)

  const updateLabel = async () => {
    try {
      setIsUpdating(true)
      await templateService.updateLabel(accessToken, editId, { title: labelTitle })
      updatedCount.current += 1
    } catch (err) {
      console.error(err)
    } finally {
      setIsUpdating(false)
      setEditId(null)
      setRefetchLabel(true)
    }
  }

  const deleteLabel = async (id: any) => {
    setDeleteId(id)
    try {
      await templateService.deleteLabel(accessToken, id)
      deletedCount.current += 1
    } catch (err) {
      console.error(err)
    } finally {
      setDeleteId(null)
      setRefetchLabel(true)
    }
  }

  React.useEffect(() => {
    deletedCount.current = 0
    updatedCount.current = 0
    return () => {
      if(deletedCount.current || updatedCount.current){
        console.log(updatedCount.current)
      }
      if(deletedCount.current || updatedCount.current) props.setRefetchTemplate(true)
    }
  }, [])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="label-edit-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Manage Label</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {refetchLabel && 'Loading...'}
          {labels.map((item: any) => (
            <li key={item.id}>
              <div className="d-flex align-items-center justify-content-between">
                {editId === item.id ? (
                  <input
                    type="text"
                    className='form-control editable-input'
                    value={labelTitle}
                    onChange={(e) => setLabelTitle(e.target.value)}
                  />
                ) : (
                  <input
                    type="text"
                    className='form-control'
                    defaultValue={item.title}
                  />
                )}
                <div className="btn-controls">
                  {editId === item.id ? (
                    <button
                      className="btn btn-link-danger p-1"
                      onClick={() => {
                        console.log('=', editId)
                        setEditId(null)
                        setLabelTitle('')
                      }}
                      disabled={isUpdating}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-link-danger p-1"
                      onClick={() => {
                        setEditId(item.id)
                        setLabelTitle(item.title)
                      }}
                      disabled={isUpdating}
                    >
                      <i className="fa-solid fa-pen"></i>
                    </button>
                  )}
                  <button className="btn btn-link-danger p-1"
                    onClick={() => deleteLabel(item.id)}
                    disabled={deleteId === item.id}
                  >
                    <i className="fa-solid fa-trash-can"></i>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <button 
        className="btn btn-danger px-4" 
        onClick={updateLabel} 
        disabled={!editId}
        >
        { isUpdating ? 'Updating...' : 'Update'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageLabelModal;
