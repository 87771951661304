import styles from "./ImageCell.module.scss";

const ImageCell = (props: any) => {
  // const {
  //   backgroundImageUrl = "",
  //   graphicImageUrl = "",
  //   backBackgroundImageUrl = "",
  //   backGraphicImageUrl = "",
  //   design,
  // } = props.getValue();
  // return (
  //   <div className={styles.image_container}>
  //     {design === "Front and Back" && (
  //       <div className={styles.back_img}>
  //         <img src={backBackgroundImageUrl} alt="" />
  //         <img src={backGraphicImageUrl} alt="" />
  //       </div>
  //     )}
  //     <div
  //       className={`${
  //         design === "Front and Back" ? styles.front_img : styles.noBackImage
  //       }`}
  //     >
  //       <img src={backgroundImageUrl} alt="" />
  //       <img src={graphicImageUrl} alt="" />
  //     </div>
  //   </div>
  // );

  const {
    imageUrl,
  } = props.getValue();
  return (
    <div className={styles.image_container}>
      <div className={styles.noBackImage}>
        <img src={imageUrl} alt="Ordered Item" />
      </div>
    </div>
  );
};

export default ImageCell;
