import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { getUserDetails } from "../../store/User.slice";
import AddUserModal from "./AddUserModal";
import EmptyList from "../../ui/empty-list/EmptyList";
import { fetchContactListService } from "api/parentService";
import { sendInviteEmailService } from "api/userService";
import { IContactDetails } from "api/requestTypes";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { deleteUserService } from "api/userService";
import UpdateUserModal from "./UpdateUserModal";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import styles from "./UserTable.module.scss";
import { AVAILABLE_ROLE, USER_ROLE } from "utils/constants";

const UsersLog: FC = () => {
  const [loading, setLoading] = useState(false);
  const [showAddRegisteredPerson, setShowAddRegisteredPerson] = useState(false);
  const [showUpdateRegisteredPerson, setShowUpdateRegisteredPerson] =
    useState(false);

  const gridReady = (params: any) => {
    let gridApi = params.api;
    sizeToFit(gridApi);
  };

  const sizeToFit = (gridApi: any) => {
    gridApi.sizeColumnsToFit();
  };
  const [user, setUser] = useState<IContactDetails>({
    name: "",
    email: "",
    phone: "",
    id: "",
    role: "",
    createdAt: "",
    updatedAt: "",
  });
  const { accessToken } = useAppSelector(getUserDetails);

  const [refetch, setRefetch] = useState<boolean>(true);
  const [registeredPersonList, setRegisteredPersonList] = useState<
    IContactDetails[]
  >([]);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState<string | number>("");

  const setList = (data: IContactDetails) => {
    setRegisteredPersonList([...registeredPersonList, data]);
  };
  const updateList = (data: IContactDetails) => {
    let user = registeredPersonList.filter(
      (registered) => registered.id !== data.id
    );
    setRegisteredPersonList([...user, data]);
  };

  const deleteUsers = async (id: number | string) => {
    try {
      setLoading(true);

      const { data } = await deleteUserService(id, accessToken);
      if (data.data) {
        toast.success("Registered person deleted successfully");
        setRegisteredPersonList(
          registeredPersonList.filter((registered) => registered.id !== id)
        );
        setLoading(false);
      }
    } catch (error) {
      toast.warn("Unable to delete");
      setLoading(false);
    }
  };
  const sendInviteEmail = async (id: number | string) => {
    try {
      setLoading(true);
      await sendInviteEmailService({ userId: id }, accessToken);
      toast.success("Invite email send successfully");
      setLoading(false);
    } catch (error) {
      toast.warn("Unable to send invite email");
      setLoading(false);
    }
  };
  const handleConfirmCallBack = () => {
    deleteUsers(deleteId);
  };

  useEffect(() => {
    if (!refetch) return;
    const getContactAndRegisteredList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchContactListService(accessToken);
        if (data.data) {
          const {
            marketingPersons = [],
            salesPersons = [],
            superAdminPersons = [],
          } = data.data;
          setRegisteredPersonList([
            ...marketingPersons,
            ...salesPersons,
            ...superAdminPersons,
          ]);
          setLoading(false);
          setRefetch(false);
        }
      } catch (error) {
        toast.warn("Could notfetch the contact and sales persons list");
        setLoading(false);
        setRefetch(false);
      }
    };
    getContactAndRegisteredList();
  }, [accessToken, refetch]);
  if (loading) return <SpinnerLoader />;
  return (
    <div className="page-bottom-space">
      <div>
        <div className="me-2 page-bottom-space">
          <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Users</h4>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setShowAddRegisteredPerson(true)}
            >
              <i className="fa-solid fa-circle-plus text-danger me-2"></i>
              <span className="text-dark">Add New User</span>
            </button>
          </div>
          <div className={styles.agGridContainer}>
            <div className={`ag-theme-alpine ${styles.agGridWrap}`}>
              <AgGridReact
                onGridReady={(params) => gridReady(params)}
                rowData={registeredPersonList}
                columnDefs={[
                  {
                    field: "name",
                    sortable: true,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                  },
                  {
                    field: "E-mail",
                    sortable: true,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    cellRenderer: (param: { data: IContactDetails }) => (
                      <>
                        <span>{param.data.email}</span>
                      </>
                    ),
                  },
                  {
                    field: "role",
                    sortable: true,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    cellRenderer: (param: { data: IContactDetails }) => (
                      <>
                        <span>
                          {
                            AVAILABLE_ROLE.find(
                              (x) => x.shortCode == param.data.role
                            )?.title
                          }
                        </span>
                      </>
                    ),
                  },
                  {
                    field: "Action",
                    cellRenderer: (param: { data: IContactDetails }) => (
                      <>
                        <div className="action-wrap">
                          <button
                            title="Send Account Confirmation Email"
                            className="btn btn-link-danger"
                            type="button"
                            onClick={() => sendInviteEmail(param.data.id)}
                          >
                            <i className="fa-solid fa-envelope"></i>
                          </button>
                          <button
                            className="btn btn-link-danger ms-3"
                            type="button"
                            onClick={() => {
                              setUser(param.data);
                              setShowUpdateRegisteredPerson(true);
                            }}
                          >
                            <i className="fa-solid fa-pen">{""}</i>
                          </button>
                          <button
                            className="btn btn-link-danger ms-3"
                            type="button"
                            //onClick={()=>deleteUsers(registeredPerson.id)}
                            onClick={() => {
                              setDeleteId(param.data.id);
                              setOpenConfirmation(true);
                            }}
                          >
                            <i className="fa-solid fa-trash-can">{""}</i>
                          </button>
                        </div>
                      </>
                    ),
                  },
                ]}
                suppressRowTransform={true}
                pagination={true}
                paginationPageSize={20}
                cacheBlockSize={10}
              />
            </div>
          </div>
        </div>

        {/* Show this when list is empty */}
        {registeredPersonList.length <= 0 && <EmptyList title="Nothing Here" />}
        <div>
          {/* <button
                        type="button"
                        className="btn btn-link"
                        onClick={()=> setShowAddRegisteredPerson(true)}
                    >
                        <i className="fa-solid fa-circle-plus text-danger me-2"></i>
                        <span className="text-dark">Add Sales Person</span>
                    </button> */}
          {showAddRegisteredPerson && (
            <AddUserModal
              title="Add New Person"
              open={showAddRegisteredPerson}
              setOpen={setShowAddRegisteredPerson}
              setList={setList}
              setRefetch={setRefetch}
            />
          )}
          {showUpdateRegisteredPerson && (
            <UpdateUserModal
              title="Update Registered Person"
              open={showUpdateRegisteredPerson}
              setOpen={setShowUpdateRegisteredPerson}
              updateList={updateList}
              user={user}
              setRefetch={setRefetch}
            />
          )}
        </div>
      </div>
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
    </div>
  );
};

export default UsersLog;
