import clsx from "clsx";
import * as React from "react";

interface Props {
  files: File[] | null;
  setFiles: (files: File[] | null) => void;
}

const UploadFiles: React.FC<Props> = ({ files, setFiles }): JSX.Element => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const hide = !!files?.length;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      setFiles(Array.from(files) as File[]);
    }
  };

  return (
    <button
      className={clsx("btn btn-upload position-relative", { "d-none": hide })}
    >
      <span>
        <i className="fa-solid fa-cloud-arrow-up heading1"></i>
        <span className="d-block">Upload your files here</span>
        <input
          ref={inputRef}
          onClick={() => {
            if (inputRef.current) inputRef.current.value = "";
          }}
          name="files"
          type="file"
          className="hidden-input"
          onChange={handleChange}
          accept=".svg,.png"
          multiple
          disabled={hide}
        />
      </span>
    </button>
  );
};

export default UploadFiles;
