const Colour = (props: any) => {
    const { color } = props;
    return (
        <li className="mb-2">
            <div className="has-right-action">
                <p className="mb-0 text-capitalize">
                    {color.name}
                </p>
                <div className="action-wrap">
                    <button className="btn btn-link-danger" type="button" onClick={() => {
                        props.setSelColor(color)
                        props.setOpenColorModal(true)
                    }}>
                        <i className="fa-solid fa-pen">{""}</i>
                    </button>
                    <button className="btn btn-link-danger ms-3" type="button" onClick={() => {
                        props.setSelColor(color)
                        props.setOpenConfirmModal(true)
                    }}>
                        <i className="fa-solid fa-trash-can">{""}</i>
                    </button>
                </div>
            </div>
        </li>
    )
}

export default Colour