import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const updateBodyColor = (data: any, id: number, token: string) => {
  return client.put(`/body-color-map/${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/body-color-map/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const addBodyColor = (data: any, token: string) => {
  return client.post(`/body-color-map`, data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/body-color-map`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchAllBodyColor = (token: string) => {
  return client.get(`/body-color-map`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/body-color-map",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
