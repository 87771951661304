import { useEffect, useState, ChangeEvent } from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import { clone } from "utils/helperFunctions";
import MapVariable from "./tabs/MapVariable";
import { ITemplate, ITemplateSettingAddPayload, ITemplateSettingUpdatePayload, IkeyValuePair } from "api/requestTypes";
import { TemplateSettingAddService, TemplateSettingGetService, TemplateSettingUpdateService, createTemplateDefaultColors, fetchJSONFromURLService, fetchTemplateColorsService, fetchTemplateDefaultColors, updateTemplateDefaultColors } from "api/templateService";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import { toast } from "react-toastify";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import ColorConfig from "./tabs/ColorConfig";
import  templateSettingUtils from './templateSettingUtils';
import useTemplateJSONColors from "../templateConfigDialog/tabs/useTemplateJSONColors";

enum TabKeys {
  MAP_VARIABLE,
  CHOOSE_LOGOS,
  COLOR_CONFIG,
}

type ILayer = {
    id: string | number;
    layerName: string;
    keyName: string;
    design?: string
}

type ILayerMap = {
    [key: string]: ILayer
}

export type NameValuePair = {
  id?: string | number;
  type?: string;
  layerName: string;
  keyName: string;
  value?: string;
  defaultLayerValue?: string;
  design?: string;
};

type Props = {
  isOpen: boolean;
  setIsOpen: Function;
  template: ITemplate;
  currentTemplateId: string | number;
  keys: IkeyValuePair[];
};

export enum KEY_TYPE {
  LOCATION_KEYS = "Location Key",
  FIXED_KEYS = "Fixed Key",
}

export type IColor = {
  actual_name: string;
  black: string;
  category: string;
  color: string;
  createdAt: string;
  cyan: string;
  deletedAt: any;
  design: string;
  id: number;
  magenta: string;
  name: string;
  shade: "lt" | "dk" | "NA";
  swatchGroupIndex: string;
  swatchIndex: string;
  templateId: number;
  updatedAt: Date;
  yellow: string;
}

export type IColorOption = {
  [key: string]: IColor[]
}

export type SelectedColor = {
  [key: string]: string
}



const COLOR_OPTION_SCHEMA: IColorOption = {
  body: [],
  front_graphic_dk: [],
  front_graphic_lt: [],
  front_graphic_NA: [],
  front_graphic2_dk: [],
  front_graphic2_lt: [],
  front_graphic2_NA: [],
  back_graphic_dk: [],
  back_graphic_lt: [],
  back_graphic_NA: [],
  back_graphic2_dk: [],
  back_graphic2_lt: [],
  back_graphic2_NA: [],
}

export const keyTypes = () => [
  {
    label: KEY_TYPE.LOCATION_KEYS,
  },
  {
    label: KEY_TYPE.FIXED_KEYS,
  },
];

const GlobalTemplateConfigDialog = (props: Props): JSX.Element => {
  const { isOpen, setIsOpen, template, currentTemplateId, keys } = props;
  const { accessToken } = useAppSelector(getUserDetails)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [defaultColorId, setDefaultColorId] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [frontLayers, setFrontLayers] = useState<ILayer[]>([])
  const [backLayers, setBackLayers] = useState<ILayer[]>([])
  const [selectedColors, setSelectedColors] = useState<SelectedColor>({ body:'', shade: 'NA', graphic: '', graphic2: '', backGraphic: '', backGraphic2: '' })
  const [colorOptions, setColorOptions] = useState<IColorOption>(() => clone(COLOR_OPTION_SCHEMA))
  const [graphicRules, setGraphicRules] = useState<any>(null)
  const templateColors = useTemplateJSONColors(template)

  const handleChange = (e: ChangeEvent<HTMLSelectElement>, design: 'front'|'back') => {
    const { name: layerName, value: keyName } = e.target;
    const tempLayers = design === 'front' ? frontLayers : backLayers
    const modified = [...tempLayers]?.map((layer) => {
        if(layer.layerName === layerName){
            return { id: layer.id, layerName, keyName, design } as ILayer
        }
        return layer
    })
    if(design === 'front'){
        setFrontLayers(modified)
    }else{
        setBackLayers(modified)
    }
  };

  const addOrUpdateTemplateSetting = async () => {
    let payload: ITemplateSettingAddPayload | ITemplateSettingUpdatePayload
    const layers = [...frontLayers, ...backLayers] as ILayer[]
    try{
      setIsLoading(true)
      if(isUpdate){
        payload = {
          data: layers?.map(({ id, keyName, layerName, design }) => ({
            id,
            value: { keyName, layerName, design },
          })),
        };
        await TemplateSettingUpdateService(currentTemplateId, payload, accessToken)
      }else{
        payload = {
          templateId: currentTemplateId,
          data: layers.map(({ keyName, layerName, design }) => ({ keyName, layerName, design }))
        }
        await TemplateSettingAddService(payload, accessToken)
      }
      setIsLoading(false)
      const msg = `Template setting ${isUpdate ? 'updated' : 'added'} successfully`
      toast.success(msg)
    }catch(err){
      setIsLoading(false)
      const msg = `Unable to ${isUpdate ? 'update' : 'add'} template Settings`
      toast.error(msg)
      throw err
    }


  };

  const addOrUpdateTemplateDefaultColors = async () => {
    try{
      setIsLoading(true)
      if(defaultColorId){
        const payload = {
          body: selectedColors.body,
          shade: selectedColors.shade,
          graphic: selectedColors.graphic,
          graphic2: selectedColors.graphic2,
          backGraphic: selectedColors.backGraphic,
          backGraphic2: selectedColors.backGraphic2,
        };
        await updateTemplateDefaultColors(accessToken, defaultColorId, payload)
      } else {
        const payload = {
          templateId: currentTemplateId,
          design: template?.design,
          body: selectedColors.body,
          shade: selectedColors.shade,
          graphic: selectedColors.graphic,
          graphic2: selectedColors.graphic2,
          backGraphic: selectedColors.backGraphic,
          backGraphic2: selectedColors.backGraphic2,
        }
        await createTemplateDefaultColors(accessToken, payload)
      }
      setIsLoading(false)
      const msg = `Template default colors ${defaultColorId ? 'updated' : 'added'} successfully`
      toast.success(msg)
    }catch(err){
      setIsLoading(false)
      const msg = `Unable to ${defaultColorId ? 'update' : 'add'} template default colors`
      toast.error(msg)
      throw err
    }
  };

  useEffect(() => {
    async function fetchColors() {
      const _colorOptions: any = clone(COLOR_OPTION_SCHEMA)
        setIsLoading(true)
        const { data } = await fetchTemplateColorsService(accessToken, currentTemplateId)
        data?.data?.colors.forEach((color: any) => {
          const key = `${color.design}_${color.category}_${color.shade}`
          if(color.category === 'body' && color.design === 'front'){
            _colorOptions.body.push(color)
          }else if(_colorOptions[key]){
            _colorOptions[key]?.push(color)
          }
        })
        return _colorOptions
    }
    async function fetchDefaultColors() {
      const { data } = await fetchTemplateDefaultColors(accessToken, currentTemplateId)
      const defaultColors = data?.data
      if(defaultColors){
        setDefaultColorId(defaultColors.id)
        setSelectedColors({
          ...selectedColors,
          body: defaultColors.body,
          shade: defaultColors.shade,
          graphic: defaultColors.graphic,
          graphic2: defaultColors.graphic2,
          backGraphic: defaultColors.backGraphic,
          backGraphic2: defaultColors.backGraphic2,
        })
      }
    }
    async function getTemplateExistingSetting(){
      const { data } = await TemplateSettingGetService(currentTemplateId, accessToken)
      const layers = [] as ILayer[]

      data?.data?.forEach((layer: ILayer) => {
        layers.push(layer)
      })

      return layers
    }
    async function getLayersFromUrl(url: string, design: 'front' | 'back'){
        const { data: _data } = await fetchJSONFromURLService(url);
        const layers = [] as ILayer[]

        _data?.layers.forEach((layer: any) => {
            layers.push({
                id: '',
                layerName: layer.name,
                keyName: '',
                design: design,
            })
        })
        return layers
    }
    async function fetchTemplateLayers(){
        // alway fetch front layers
        const layerData = {
          frontLayers: [] as ILayer[],
          frontLayerMap: {} as ILayerMap,
          backLayers: [] as ILayer[],
          backLayerMap: {} as ILayerMap,
        };

        const existLayers = await getTemplateExistingSetting()
        if(existLayers?.length){
          setIsUpdate(true)
        }
        
        existLayers?.forEach((layer: ILayer) => {
          if(layer.design === 'front'){
            layerData.frontLayers.push(layer)
            layerData.frontLayerMap[layer.layerName] = layer
          }else{
            layerData.backLayers.push(layer)
            layerData.backLayerMap[layer.layerName] = layer
          }
        })
        
        const frontLayers = await getLayersFromUrl(template.frontDescription, 'front')
        
        frontLayers.forEach((layer) => {
          if(!layerData.frontLayerMap[layer.layerName]){
            layerData.frontLayers.push(layer)
          }
        })
        // fetch back layers if design is 'Front and Back'
        if(template.design === "Front and Back"){
            const backLayers = await getLayersFromUrl(template.backDescription, 'back')
            backLayers.forEach((layer) => {
              if(!layerData.backLayerMap[layer.layerName]){
                layerData.backLayers.push(layer)
              }
            })
        }
        return layerData
    }
    if (accessToken) {

      fetchTemplateLayers().then((layersData) => {
        setIsLoading(false)
        setFrontLayers(layersData.frontLayers);
        setBackLayers(layersData.backLayers);
      }).catch((err) => {
        console.error(err)
        setIsLoading(false)
      })

      fetchColors().then((_colorOptions) => {
        setIsLoading(false)
        setColorOptions(_colorOptions)
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      })

      fetchDefaultColors().catch((err) => {
        console.error(err)
        setIsLoading(false)
      })
    }

  }, [accessToken, currentTemplateId, template.backDescription, template.design, template.frontDescription])

  useEffect(() => {
    async function fetchInitialData() {
      const _colorOptions = await templateSettingUtils.getTemplateColorOptions(accessToken, currentTemplateId)
      setGraphicRules(_colorOptions)
    }
    fetchInitialData()
  }, [accessToken, currentTemplateId])

  async function handleSave() {
    try {
      await addOrUpdateTemplateSetting()
      await addOrUpdateTemplateDefaultColors()
      setIsOpen(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Header>
        {template.name}
      </Modal.Header>
      <Modal.Body>
        {isLoading && <SpinnerLoader />}
        <div className="custom-tabs">
          <Tab.Container defaultActiveKey={TabKeys.MAP_VARIABLE}>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey={TabKeys.MAP_VARIABLE}>
                  Map Variable
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={TabKeys.COLOR_CONFIG}>
                  Color Config
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={TabKeys.MAP_VARIABLE}>
                <MapVariable
                  design={template?.design}
                  frontLayers={frontLayers}
                  backLayers={backLayers}
                  keys={keys}
                  handleChange={handleChange}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={TabKeys.CHOOSE_LOGOS}>Tab 2</Tab.Pane>
              <Tab.Pane eventKey={TabKeys.COLOR_CONFIG}>
                <ColorConfig
                  accessToken={accessToken}
                  template={template}
                  setSelectedColors={setSelectedColors}
                  graphicRules={graphicRules}
                  isGraphicLinked={template.isGraphicLinked}
                  isGraphic2Linked={template.isGraphic2Linked}
                  colors={templateColors}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="me-3">
          <button
            className="btn btn-danger px-4"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GlobalTemplateConfigDialog;
