import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";

import Input from "ui/input/Input";
import { AccountInfo } from "types/AccountsSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import {
  Attribute,
  IContactDetails,
  ICustomerDetails,
  IkeyValuePair,
  ITemplate,
} from "api/requestTypes";
import {
  fetchCustomerContactDetailsService,
  updateCustomerContactService,
  uploadCustomerContactLogosService,
  assignTemplateToCustomer
} from "api/customerService";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { fetchAllCustomerAttributeService } from "api/masterDataService";
import KeyValuePairInput from "components/keyValuePair/KeyValuePairInput";
import {
  fetchAllLocationService,
  fetchAllStateService,
} from "api/locationService";
import { ILocation } from "pages/locations/Locations";
import TemplateList from "pages/Customer/templateList/TemplateList";
import { fetchAllTemplatesService } from "api/templateService";
import InfoColList from "pages/Customer/infoColList/infoColList";
import { IState } from "pages/locations/states/States";
import FileUploadPrviewer from "ui/file-upload/FileUploadPreviewer";
import Heading from "ui/Heading/Heading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccountLogos from "./AccountLogos";

const initialInfo: ICustomerDetails = {
  id: "",
  category: "",
  locationId: "",
  logoUrls: "",
  parentId: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: "",
  childData: [],
  customerAccountName: "",
};
interface ILogo {
  logoUrl: string;
  id: string | number;
}
interface UpdateAccountProps {
  initialCustomInfo: ICustomerDetails;
  onCancel: Function;
  getUpdatedList: Function;
  onCancelAssign: Function;
}
const UpdateAccount: React.FC<UpdateAccountProps> = ({
  initialCustomInfo,
  onCancel,
  onCancelAssign,
  getUpdatedList,
}) => {
  const { accessToken } = useAppSelector(getUserDetails);

  const [loading, setLoading] = useState(false);
  const [customInfo, setCustomInfo] =
    useState<ICustomerDetails>(initialCustomInfo);
  const [customInfoError, setCustomInfoError] = useState<ICustomerDetails>({
    ...initialInfo,
  });
  const [files, setFiles] = useState<File[]>([]);

  const [logoList, setLogoList] = useState<ILogo[]>([]);
  const [removedLogoIds, setRemovedLogoIds] = useState<(string | number)[]>([]);
  const [categoryList, setCategoryList] = useState<Attribute[]>([]);

  const [locationList, setLocationList] = useState<ILocation[]>([]);
  const [stateList, setStateList] = useState<IState[]>([]);
  const [customData, setCustomData] = useState<IkeyValuePair[]>(
    initialCustomInfo.childData!
  );
  const [customDataExisted, setCustomDataExisted] = useState<IkeyValuePair[]>(
    initialCustomInfo.childData!
  );

  // const [parrentAccounts, setParentAccounts] = useState<
  //   IAllParentAccountResponse[]
  // >([]);
  const [templateListAll, setTemplateListAll] = useState<ITemplate[]>([]);
  const [addContactPerson, setAddContactPerson] = useState(false);
  const [contactPersonsSelected, setContactPersonsSelected] = useState<
    IContactDetails[]
  >([]);
  const [contactPersons, setContactPersons] = useState<IContactDetails[]>([]);
  const [contactPersonAccountInfo, setContactPersonAccountInfo] = useState<
    AccountInfo[]
  >([]);

  //const [addSalesPerson, setAddSalesPerson] = useState(false);
  const [salesPersonsSelected, setSalesPersonSelected] = useState<
    IContactDetails[]
  >([]);
  const [salesPersons, setSalesPersons] = useState<IContactDetails[]>([]);
  //const [salesPersonAccountInfo,setSalesPersonAccountInfo] = useState<AccountInfo[]>([])
  const [templateSelected, setTemplateSelected] = useState<ITemplate[]>([]);
  const [templateList, setTemplateList] = useState<ITemplate[]>([]);
  const [templateExisted, setTemplateExisted] = useState<ITemplate[]>([]);
  const [toggleLocationDetails, setToggleLocationDetails] = useState(false);
  // const onChangeImageUploadPreview = (
  //   imageList: ImageListType,
  //   addUpdateIndex: number[] | undefined
  // ) => {
  //   setImages(imageList);

  // };
  const onChangeFileUploadPreview = (
    files: File[],
    addUpdateIndex: number[] | undefined
  ) => {
    setFiles(files);
  };

  const handleChange = (
    e:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let errors = { ...customInfoError };

    switch (name) {
      case "parentId":
        errors.parentId = value.length < 1 ? "Please select a parent" : "";
        break;
      case "locationId":
        errors.locationId = value.length < 1 ? "Please select a location" : "";
        break;
      case "category":
        errors.category = value.length < 1 ? "Please select a category" : "";
        break;
      case "customerAccountName":
        errors.customerAccountName =
          value.length < 1 ? "Customer Account Name can not be empty" : "";
        break;
      default:
        break;
    }
    setCustomInfoError(errors);

    setCustomInfo({ ...customInfo, [name]: value });
  };

  const validateForm = (errors: ICustomerDetails, values: ICustomerDetails) => {
    if (
      values.parentId &&
      values.customerAccountName &&
      values.locationId &&
      values.category
      // values.fullName &&
      // contactPersonsSelected.length && salesPersonsSelected.length
    ) {
      let valid = true;
      Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
      return valid;
    }
    return false;
  };
  const save = () => {
    if (validateForm(customInfoError, customInfo)) {
      updateHandler();
    } else {
      toast.error("Please fill required fields");
    }
  };

  const handleLogoRemove = (id: any) => {
    const filtered = logoList.filter((el) => el.id !== id);
    setLogoList(filtered);
    setRemovedLogoIds((prevIds) => [...prevIds, id]);
  }

  const uploadCustomerLogos = async () => {
    const logos = [];
    for (let i = 0; i < files.length; i++) {
      try {
        const formData = new FormData();
        formData.append("file", files[i]);

        const res = await uploadCustomerContactLogosService(
          formData,
          accessToken
        );
        const data = res?.data?.data;
        if (data) {
          logos.push(data.url);
        }
      } catch (error) {
        console.error(error);
      }
    }
    return logos.join(","); //returns "" if logos=[]
  };
  const updateChildAccount = async (logourl: string) => {
    const contactPersons = contactPersonsSelected.map((obj) => obj.id);
    const salesPersons = salesPersonsSelected.map((obj) => obj.id);

    // key value pair returns 2 types of data with or without id, with id is updated ones and without is new ones
    const updatedCustomData = customData.filter((d) => d.id != ""); //updated ones
    const childData = updatedCustomData.map((d) => ({
      id: d.id,
      customInfo: { key: d.key, value: d.value },
    }));
    const nonDeleteSet = new Set(childData.map((d) => d.id)); //non deleted ids

    const newChildData = customData.filter((d) => d.id == "");
    const removedChildData = customDataExisted.filter(
      (d) => !nonDeleteSet.has(d.id)
    );
    const removedChildDataIds = removedChildData.map((d) => d.id);

    const setE = new Set(templateExisted.map((t) => t.id));
    const setS = new Set(templateSelected.map((t) => t.id));
    const templateSelectedIds = templateSelected.map((t) => t.id);
    const templateExistedIds = templateExisted.map((t) => t.id);
    const newTemplates = templateSelectedIds.filter((id) => !setE.has(id));
    const removedTemplates = templateExistedIds.filter((id) => !setS.has(id));
    const payload = {
      accountInfo: {
        ...customInfo,
      },
      childData: childData,
      newChildData: newChildData.map((item) => ({
        key: item.key,
        value: item.value,
      })),
      removedChildDataIds: removedChildDataIds,
      contactPersons: contactPersons,
      salesPersons: salesPersons,
      newTemplates: [], // newTemplates,
      removedTemplates: removedTemplates,
      newLogoUrls: logourl ? logourl.split(",") : [],
      removedLogoIds: removedLogoIds,
      newTemplateOrder: templateSelectedIds,
    };
    try {
      setLoading(true);
      const response = await assignTemplateToCustomer(accessToken, customInfo.id, { templates: newTemplates })
      console.log(response.data.data)
      const { data } = await updateCustomerContactService(
        customInfo.id,
        payload,
        accessToken
      );

      if (data?.data) {
        toast.success("Customer Account updated successfully");
        // onCancel("");
        getUpdatedList();
      }
    } catch (error: any) {
      console.error(error)
      toast.error(error.response?.data?.msg || 'Failed to updated customer account');
    }finally{
      setLoading(false)
    }
  };
  const updateHandler = async () => {
    setLoading(true);
    // onCancelAssign("");
    uploadCustomerLogos().then((logourl) => {
      setLoading(false);
      updateChildAccount(logourl);
    });
  };

  useEffect(() => {
    const getAttributeList = async (childData: IkeyValuePair[]) => {
      try {
        setLoading(true);
        const { data } = await fetchAllCustomerAttributeService(accessToken);
        if (data.data) {
          const { categories, keys } = data.data;
          setCategoryList(categories);
          const st = new Set(childData.map((d) => d.key));
          const kys: Attribute[] = keys;
          const ks: Attribute[] = kys.filter((k) => !st.has(k.attributeName));
          const customDataKey = ks.map((k) => ({
            id: "",
            key: k.attributeName,
            value: "",
          }));
          setCustomData([...childData, ...customDataKey]);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Could not fetch the attributes");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getLocationList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllLocationService(accessToken);
        if (data.data) {
          setLocationList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Could not fetch the locations");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getStateList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllStateService(accessToken);
        if (data.data) {
          setStateList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Could not fetch the locations");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getTemplateList = async (templates: ITemplate[]) => {
      try {
        setLoading(true);
        const { data } = await fetchAllTemplatesService(accessToken);

        if (data.data) {
          const d: ITemplate[] = data.data;
          setTemplateListAll(d);
          const set = new Set(templates.map((t) => t.id));
          const n = d.filter((e) => !set.has(e.id));
          setTemplateList(n);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Could not fetch the templates");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getChildAccountDetails = async () => {
      try {
        setLoading(true);
        const { data } = await fetchCustomerContactDetailsService(
          accessToken,
          customInfo.id
        );

        if (data.data) {
          const { ChildAccount, templates, logos } = data.data;
          const { childData } = ChildAccount;
          setCustomInfo({
            ...initialCustomInfo,
            logoUrls: logos
              .map((l: { logoUrl: string; id: string | number }) => l.logoUrl)
              .join(),
          });

          setLogoList(logos);
          setCustomData(childData);
          setCustomDataExisted(childData);
          setTemplateSelected(templates);
          setTemplateExisted(templates);
          setLoading(false);
          return { templates, childData };
        }
      } catch (error) {
        setLoading(false);
        toast.error("Could not fetch the contact and sales persons list");
        return { templates: [], childData: [] };
      }
      return { templates: [], childData: [] };
    };
    getChildAccountDetails().then(({ templates, childData }) => {
      getTemplateList(templates);
      getAttributeList(childData);
      getLocationList();
      getStateList();
    });
  }, [accessToken, customInfo.id, initialCustomInfo]);

  if (loading) return <SpinnerLoader />;
  return (
    <div className="content-sm page-bottom-space">
      <div className="form-wrap">
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-3">
          <h4 className="mb-0 heading4-bold">Update Customer Account</h4>
          <p className="mb-0 font-size-13">*required field</p>
        </div>
        <Input
          sectionClass="mb-3"
          value={customInfo.customerAccountName}
          onChange={handleChange}
          label="Customer Account Name*"
          name="customerAccountName"
          placeholder="Abc"
          errorMessage={customInfoError.customerAccountName}
        />
        <section className="mb-3">
          <p className="mb-0">Category*</p>
          <select
            className="mb-3 form-select"
            title={""}
            onChange={handleChange}
            value={customInfo.category}
            name={"category"}
          >
            {<option value="">{"Select Category"}</option>}
            {React.Children.toArray(
              categoryList.map((el) => (
                <option value={el.attributeName}>{el.attributeName}</option>
              ))
            )}
          </select>
          {customInfoError.category && (
            <span style={{ color: "red" }}>{customInfoError.category}</span>
          )}
        </section>
        <section className="mb-3">
          <p className="mb-0">Location*</p>
          <select
            className="mb-3 form-select"
            title={""}
            onChange={handleChange}
            value={customInfo.locationId}
            name={"locationId"}
          >
            {<option value="">{"Select Location*"}</option>}
            {React.Children.toArray(
              locationList.map((el) => (
                <option value={el.id}>{el.location}</option>
              ))
            )}
          </select>

          {customInfoError.locationId && (
            <span style={{ color: "red" }}>{customInfoError.locationId}</span>
          )}
          {customInfo.locationId && (
            <div className="has-right-action">
              <p
                onClick={() =>
                  setToggleLocationDetails((prevState) => !prevState)
                }
                className="mb-0 text-capitalize"
              >
                <i
                  className={`fa-solid text-danger me-2 ${toggleLocationDetails ? "fa-caret-down" : "fa-caret-right"
                    }`}
                ></i>
                {/* {findName(child.childData!)} */}
                {customInfo.locationId &&
                  locationList.length &&
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .location}
              </p>
              {/* Action buttons UI*/}
            </div>
          )}
          {toggleLocationDetails &&
            customInfo.locationId &&
            locationList.length > 0 && (
              <div className="row mt-2">
                {stateList.length && (
                  <InfoColList
                    label="country:"
                    value={
                      stateList.find(
                        (s) =>
                          s.id ==
                          locationList.find(
                            (e) => e.id == customInfo.locationId
                          )!.stateId
                      )!.country!.name
                    }
                  />
                )}
                {stateList.length && (
                  <InfoColList
                    label="state:"
                    value={
                      stateList.find(
                        (s) =>
                          s.id ==
                          locationList.find(
                            (e) => e.id == customInfo.locationId
                          )!.stateId
                      )!.name
                    }
                  />
                )}
                <InfoColList
                  label="location:"
                  value={
                    locationList.find((e) => e.id == customInfo.locationId)!
                      .location
                  }
                />
                {/* <InfoColList
                label="name:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!.name
                }
              /> */}
                <InfoColList
                  label="abbreviation:"
                  value={
                    locationList.find((e) => e.id == customInfo.locationId)!
                      .abbreviation
                  }
                />
                <InfoColList
                  label="establishedDate:"
                  value={
                    locationList.find((e) => e.id == customInfo.locationId)!
                      .establishedDate
                  }
                />
                <InfoColList
                  label="establishedDateFirstTwo:"
                  value={
                    locationList.find((e) => e.id == customInfo.locationId)!
                      .establishedDateFirstTwo
                  }
                />
                <InfoColList
                  label="establishedDateLastTwo:"
                  value={
                    locationList.find((e) => e.id == customInfo.locationId)!
                      .establishedDateLastTwo
                  }
                />

                <InfoColList
                  label="stackedName:"
                  value={
                    locationList.find((e) => e.id == customInfo.locationId)!
                      .stackedName
                  }
                />
                {/* <InfoColList
                label="destination:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .destination
                }
              /> */}
              </div>
            )}
        </section>
        <Heading headingTitle="Keys" wrapperClassName="mb-2" />
        {
          <KeyValuePairInput
            sectionClass="mb-3"
            customData={customData}
            setCustomData={setCustomData}
          />
        }
        {/* <ImageUploadPrviewer
          onChange={onChangeImageUploadPreview}
          images={images}
        /> */}
        {/* <div className="logos-template-wrap">
          <Heading headingTitle="Logos" wrapperClassName="mb-2" />
          <FileUploadPrviewer
            onChange={onChangeFileUploadPreview}
            files={files}
          />
            <div className="row mt-3 mb-3 g-3">
            {logoList.length > 0 &&
              logoList.map((logo, index) =>
                logo.logoUrl.includes("pdf") ||
                logo.logoUrl.includes("ai") ||
                logo.logoUrl.includes("json") ? (
                  <ul className="list-group list-group-flush list-style-none mt-2">
                    <li className="has-right-action list-item-mb-2" key={index}>
                      <p className="mb-0 text-capitalize">
                        {logo.logoUrl.split("/").pop()}
                      </p>
                      <div className="action-wrap">
                        <button
                          type="button"
                          className="btn btn-link-danger"
                          onClick={() => handleImageRemove(logo.id)}
                        >
                          <i className="fa-solid fa-xmark">{""}</i>
                        </button>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <div className="col-md-4 col-lg-2 d-flex justify-content-center align-items-center">
                    <div
                      key={index}
                      className="avatar position-relative overflow-visible"
                    >
                      <img className="rounded " src={logo.logoUrl} alt="" />
                      <button
                        className="btn btn-link-danger btn-x"
                        onClick={() => handleImageRemove(logo.id)}
                      >
                        {""}
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>
                )
              )}
          </div>
        </div> */}
        <AccountLogos
          logos={logoList}
          files={files}
          setFiles={setFiles}
          setLogoList={setLogoList}
          handleRemove={handleLogoRemove}
        />
        <TemplateList
          logoList={logoList}
          templateSelected={templateSelected as any[]}
          setTemplateSelected={setTemplateSelected as SetStateAction<any>}
          templateList={templateList}
          setTemplateList={setTemplateList}
          templateListAll={templateListAll}
          keyListAll={customDataExisted}
          customInfo={customInfo}
          locationList={locationList}
        />
        <div className="mt-3">
          <button type="button" className="btn btn-danger px-4" onClick={save}>
            {`Update`}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => onCancel("")}
          >
            {`Cancel`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateAccount;
