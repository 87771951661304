import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import './Input.css';

export interface IInputProps {
  value: any;
  placeholder?: string;
  label?: string;
  inputClassName?: string;
  sectionClass?: string;
  // type?: 'text' | 'number ' | 'radio' | 'password';
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  errorMessage?: string;
  disabled?:boolean;
}
const Input: React.FC<IInputProps & InputHTMLAttributes<any>> = ({
  value,
  label,
  inputClassName,
  sectionClass,
  type = 'text',
  id,
  onChange,
  name,
  placeholder,
  checked,
  errorMessage,
  disabled = false,
  ...props
}) => {
  return (
    <div className={`input-wrap ${sectionClass ? sectionClass : ''}`}>
      {label && <label className="text-capitalize" htmlFor={id}>{label}</label>}
      <input
        className={`form-control ${inputClassName ? inputClassName : ''} ${errorMessage && "is-invalid"}`}
        value={value}
        type={type}
        onChange={onChange}
        id={id}
        name={name}
        placeholder={placeholder}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      {errorMessage && errorMessage.length > 0 && (
        <span style={{ color: 'red' }}>{errorMessage}</span>
      )}
    </div>
  );
};

export default Input;
