import * as Yup from 'yup'
import { FormValues } from "./interface";

export const FIT_TYPES: ["Shoulder", "Sleeve", "Chest", "Length"] = [
  "Shoulder",
  "Sleeve",
  "Chest",
  "Length",
];
export const SIZES: ["XS", "S", "M", "L", "XL", "XXL"] = [
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
];

  export const schema = Yup.object({
    // image: Yup.string().required('Image is required'),

    Shoulder_XS: Yup.string().required('Shoulder XS is required'),
    Shoulder_S: Yup.string().required('Shoulder S is required'),
    Shoulder_M: Yup.string().required('Shoulder M is required'),
    Shoulder_L: Yup.string().required('Shoulder L is required'),
    Shoulder_XL: Yup.string().required('Shoulder XL is required'),
    Shoulder_XXL: Yup.string().required('Shoulder XXL is required'),
  
    Sleeve_XS: Yup.string().required('Sleeve XS is required'),
    Sleeve_S: Yup.string().required('Sleeve S is required'),
    Sleeve_M: Yup.string().required('Sleeve M is required'),
    Sleeve_L: Yup.string().required('Sleeve L is required'),
    Sleeve_XL: Yup.string().required('Sleeve XL is required'),
    Sleeve_XXL: Yup.string().required('Sleeve XXL is required'),
  
    Chest_XS: Yup.string().required('Chest XS is required'),
    Chest_S: Yup.string().required('Chest S is required'),
    Chest_M: Yup.string().required('Chest M is required'),
    Chest_L: Yup.string().required('Chest L is required'),
    Chest_XL: Yup.string().required('Chest XL is required'),
    Chest_XXL: Yup.string().required('Chest XXL is required'),
  
    Length_XS: Yup.string().required('Length XS is required'),
    Length_S: Yup.string().required('Length S is required'),
    Length_M: Yup.string().required('Length M is required'),
    Length_L: Yup.string().required('Length L is required'),
    Length_XL: Yup.string().required('Length XL is required'),
    Length_XXL: Yup.string().required('Length XXL is required'),
})

export const DEFAULT_VALUES: FormValues = {
  image: "",
  extension: "",
  Shoulder_XS: "",
  Shoulder_S: "",
  Shoulder_M: "",
  Shoulder_L: "",
  Shoulder_XL: "",
  Shoulder_XXL: "",

  Sleeve_XS: "",
  Sleeve_S: "",
  Sleeve_M: "",
  Sleeve_L: "",
  Sleeve_XL: "",
  Sleeve_XXL: "",

  Chest_XS: "",
  Chest_S: "",
  Chest_M: "",
  Chest_L: "",
  Chest_XL: "",
  Chest_XXL: "",

  Length_XS: "",
  Length_S: "",
  Length_M: "",
  Length_L: "",
  Length_XL: "",
  Length_XXL: "",
}