import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import GraphicRuleTable from "./GraphicRuleTable";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import * as GraphicRuleService from "../../api/graphicRule";
import { toast } from "react-toastify";
import useTemplateColors from "./useTemplateColors";

interface iBody {
  name: string;
  category: "body";
  shade: "dk" | "lt" | "NA";
}
interface iGraphic {
  name: string;
  category: "graphic" | "graphic2";
  shade: "dk" | "lt" | "NA";
}

interface iDb {
  [key: string]: boolean;
}

interface Props {
  id: any,
  body: iBody[];
  graphic: iGraphic[];
  db: iDb | null;
  title: string;
  show: any;
  onHide: any;
}

interface iMapper {
  [key: string]: iBody | iGraphic;
}

const GraphicsRuleModal = (props: any) => {
  const { accessToken } = useAppSelector(getUserDetails);
  const templateId = parseInt(props.template.id)
  const [colors, setColors] = useState<any>({ body: [], graphic: [] })
  const [mapper, setMapper] = useState<iMapper | null>(null);
  const [hasOwnRules, setHasOwnRules] = useState<boolean>(false);
  const [globalRules, setGlobalRules] = useState<iDb | null>(null);
  const [rules, setRules] = useState<iDb | null>(null);
  const { isLoading, templateColors } = useTemplateColors(props.template, mapper, globalRules)
  const [refech, setRefetch] = useState(true);
  const [loading, setLoading] = useState(false);

  const categoriseColors = (colors: any) => colors.reduce((a: any, c: any) => {
    a?.[c.category].push(c)
    return a
  }, { body: [], graphic: [] })

  useEffect(() => {
    function fetchColors() {
      setLoading(true)
      GraphicRuleService.fetchColorsService(accessToken, {})
        .then((res) => {
          const categorised = categoriseColors(res?.data.data)
          const mapper = generateMapper(categorised)
          setColors(categorised)
          setMapper(mapper)
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          setLoading(false)
        })
    }
    fetchColors()
  }, [])

  useEffect(() => {
    function fetchGlobalGraphicRule() {
      GraphicRuleService.fetchGraphicRules(accessToken, {
        templateId: 0,
        customerId: 0
      })
        .then((res: any) => {
          const rules = res?.data.data.rules.reduce((a: any, c: any) => {
            a[`["${c.graphicType}"]["${c.bodyColor}"]["${c.graphicColor}"]`] = {
              bodyColorHex: c.bodyColorHex,
              graphicColorHex: c.graphicColorHex
            };
            return a;
          }, {});
          setGlobalRules(rules);
        })
        .catch((err) => {
          console.error(err);
          setGlobalRules({})
        })
        .finally(() => {
          setRefetch(false);
        });
    }
    fetchGlobalGraphicRule()
  }, []);

  useEffect(() => {
    function fetchTemplateGraphicRule() {
      GraphicRuleService.fetchGraphicRules(accessToken, {
        templateId: templateId,
        customerId: 0
      })
        .then((res: any) => {
          const rules = res?.data.data.rules.reduce((a: any, c: any) => {
            const key = `["${c.graphicType}"]["${c.bodyColorName}"]["${c.graphicColorName}"]`
            console.log(key)
            a[key] = true;
            return a;
          }, {});
          setHasOwnRules(res?.data.data.hasRules)
          setRules(rules);
        })
        .catch((err) => {
          console.error(err);
          setRules({})
        })
        .finally(() => {
          setRefetch(false);
        });
    }
    if (refech) {
      fetchTemplateGraphicRule();
    }
  }, [refech]);



  const onSubmit = (e: any) => {
    e.preventDefault();
    const rules = [];
    const mapper = templateColors.mapper as iMapper
    const form = new FormData(e.target);
    for (const [key] of form.entries() as any) {
      if (mapper[key]) {
        rules.push(mapper[key]);
      }
    }
    setLoading(true);
    GraphicRuleService.updateGraphicRules(accessToken, {
      templateId: templateId,
      customerId: 0,
      rules
    })
      .then((res) => {
        console.log(res?.data.data);
        toast.success("Graphic rules updated.");
        props.handleSuccess();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setRefetch(true);
      });
  };


  if (refech || loading || isLoading) {
    return <SpinnerLoader />;
  }


  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="xl"
        className="custom-drawer"
        aria-labelledby="custom-modal"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title>Graphic Rule Table - {props.template.name}(ID - {props.template.id})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            {templateColors.graphic.length > 0 && (
              <GraphicRuleTable
                title="Graphic"
                body={templateColors.body}
                graphic={templateColors.graphic}
                graphicType={'graphic'}
                rules={rules}
                parentRules={globalRules}
                hasOwnRules={hasOwnRules}
                mapper={templateColors.mapper}
              />
            )}
            {templateColors.graphic2.length > 0 && (
              <GraphicRuleTable
                title="Graphic II"
                body={templateColors.body}
                graphic={templateColors.graphic2}
                graphicType={'graphic2'}
                rules={rules}
                parentRules={globalRules}
                hasOwnRules={hasOwnRules}
                mapper={templateColors.mapper}
              />
            )}

            {templateColors.missingBody.length ?
              <p className="text-danger" ><i>Missing Body Colors:</i> {templateColors.missingBody.map(({colorName}: any) => <span>{colorName},&nbsp;</span>)}</p> : null
            }
            {templateColors.missingGaphic.length ?
              <p className="text-danger" ><i>Missing Graphic Colors 1:</i> {templateColors.missingGaphic.map(({colorName}: any) => <span>{colorName},&nbsp;</span>)}</p> : null
            }
            {templateColors.missingGaphic2.length ?
              <p className="text-danger" ><i>Missing Graphic Colors 2:</i> {templateColors.missingGaphic2.map(({colorName}: any) => <span>{colorName},&nbsp;</span>)}</p> : null
            }

            <div className="d-flex justify-content-end">
              <button className="btn btn-danger" type="submit">
                Submit
              </button>

              <button
                type="button"
                className="btn btn-light ms-3"
                onClick={props.onHide}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GraphicsRuleModal;

function generateMapper(data: any) {
  const _mapper: any = {};
  for (let bodyColor of data.body) {
    for (let graphicColor of data.graphic) {
      _mapper[
        `["graphic"]["${bodyColor.colorName}"]["${graphicColor.colorName}"]`
      ] = {
        bodyColor: bodyColor.colorName,
        bodyColorName: bodyColor.colorName,
        bodyColorHex: bodyColor.colorHex,
        graphicColor: graphicColor.colorName,
        graphicColorName: graphicColor.colorName,
        graphicColorHex: graphicColor.colorHex,
        graphicType: "graphic",
      };
    }
    for (let graphicColor of data.graphic) {
      _mapper[
        `["graphic2"]["${bodyColor.colorName}"]["${graphicColor.colorName}"]`
      ] = {
        bodyColor: bodyColor.colorName,
        bodyColorName: bodyColor.colorName,
        bodyColorHex: bodyColor.colorHex,
        graphicColor: graphicColor.colorName,
        graphicColorName: graphicColor.colorName,
        graphicColorHex: graphicColor.colorHex,
        graphicType: "graphic2",
      };
    }
  }
  return _mapper;
}