import * as React from "react"
import { useController, useFormContext } from "react-hook-form"

interface IObj { [key: string]: any }
interface IProps extends IObj { name: string }

const RHFInput: React.FC<IProps> = ({ name, label, ...props }): JSX.Element => {
    const { control } = useFormContext()
    const { field, fieldState } = useController({ control, name })
    return (
        <div className='input-wrap mb-3'>
            <label className="text-capitalize" htmlFor={name}>{label}</label>
            <input
                className='form-control'
                onChange={field.onChange} // send value to hook form 
                onBlur={field.onBlur} // notify when input is touched/blur
                value={field.value} // input value
                name={field.name} // send down the input name
                ref={field.ref}
                {...props}
            />
            {fieldState.error && (
                <span className='text-danger'>{fieldState.error?.message}</span>
            )}
        </div>)
}

export default RHFInput