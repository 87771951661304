import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Accordion, Modal, Tab, Tabs } from "react-bootstrap";
import * as svgTemplateService from "api/svgTemplateService";
import GraphicTemplateArt from "./GraphicTemplateArt";

type Side = 'FR' | 'BK';

const GraphicDefaultModal = (props: any) => {
  const [arts, setArts] = React.useState<any>([])
  const [categorizedArts, setCategorizedArts] = React.useState<any>({ FR: [], BK: [] })
  
  React.useEffect(() => {
    async function fetchInitialData(){
      const data = await svgTemplateService.getGraphicTemplateById(props.graphicTemplate.id as number)
      const artSuffixRegex = /(FR|BK)\d*\.svg$/
      const _categorizedArts = { FR: [], BK: [] } as { FR: any[], BK: any[] }
      data.arts.forEach((art: any) => {
        const key = art.art_name.match(artSuffixRegex)?.[1] as Side
        if(_categorizedArts[key]){
          _categorizedArts[key].push(art)
        }
      })
      setArts(data.arts)
      setCategorizedArts(_categorizedArts)
    }
    fetchInitialData()
  }, [props.graphicTemplate.id])

  
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="graphic-setup-content">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="mb-0 heading4-bold">
              {props.graphicTemplate.graphic_name} Default Graphic Setup
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-tabs-wrapper">
            <Tabs defaultActiveKey="variables" id="uncontrolled-tab-example">
              <Tab eventKey="variables" title="Variables">
                <Accordion defaultActiveKey="0" className="default-accordion">
                  {arts.map((item: any, idx: number) => (
                    <Accordion.Item eventKey={String(idx)}>
                      <GraphicTemplateArt art={item} />
                    </Accordion.Item>
                  ))}

                  {/* <Accordion.Item eventKey="1">
                    <Accordion.Header>T022-FR1</Accordion.Header>
                    <Accordion.Body>
                      <div className="default-graphics-table">
                        <div className="default-graphics-img">
                          <img
                            className="item-img"
                            src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                            alt=""
                          />
                        </div>
                        <div className="table-wrapper">
                          <table>
                            <thead>
                              <tr>
                                <th>Variable</th>
                                <th>Visiblity</th>
                                <th>Text Variables</th>
                                <th>Color Variables</th>
                                <th>Add Strokes</th>
                                <th>Stroke Color</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="variable-text">Text_1</td>
                                <td>
                                  <button className="btn btn-outline-danger bg-transparent text-danger border-0">
                                    <span className="fa-regular fa-eye"></span>
                                  </button>
                                  <button className="btn btn-outline-danger bg-transparent text-danger border-0">
                                    <span className="fa-regular fa-eye-slash"></span>
                                  </button>
                                </td>
                                <td>
                                  <Dropdown className="icon-dropdown with-text">
                                    <Dropdown.Toggle className="edit-name-btn">
                                      Stacked Name
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-container">
                                        <Dropdown.Item href="#/action-1">
                                          Graphic color 1
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Graphic color 2
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Body color
                                        </Dropdown.Item>
                                      </div>
                                      <div className="graphics-add-holder">
                                        <button className="add-graphic-btn">
                                          Add graphic color
                                        </button>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>
                                  <Dropdown className="icon-dropdown with-text">
                                    <Dropdown.Toggle className="edit-name-btn">
                                      Stacked Name
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-container">
                                        <Dropdown.Item href="#/action-1">
                                          Graphic color 1
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Graphic color 2
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Body color
                                        </Dropdown.Item>
                                      </div>
                                      <div className="graphics-add-holder">
                                        <button className="add-graphic-btn">
                                          Add graphic color
                                        </button>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td>
                                  <div className="btn-group">
                                    <button className="btn btn-sm">
                                      <span className="fa-solid fa-minus"></span>
                                    </button>
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                    />
                                    <button className="btn btn-sm">
                                      <span className="fa-solid fa-plus"></span>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <Dropdown className="icon-dropdown with-text">
                                    <Dropdown.Toggle className="edit-name-btn">
                                      Stacked Name
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-container">
                                        <Dropdown.Item href="#/action-1">
                                          Graphic color 1
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Graphic color 2
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Body color
                                        </Dropdown.Item>
                                      </div>
                                      <div className="graphics-add-holder">
                                        <button className="add-graphic-btn">
                                          Add graphic color
                                        </button>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </Tab>
              <Tab eventKey="graphicColors" title="Graphic colors">
                <div className="grapghic-color-top">
                  <div className="graphic-color-col">
                    <p className="graphic-color-heads">Front</p>
                    <ul className="card-list">
                      {categorizedArts.FR.map((art: any) => (
                        <li className="card-item" key={art.id}>
                        <div className="media-holder">
                          <img
                            className="item-img"
                            src={art.art_url}
                            alt={art.art_name}
                          />
                        </div>
                      </li>
                      ))}
                    </ul>
                  </div>
                  <div className="graphic-color-col">
                    <p className="graphic-color-heads">Back</p>
                    <ul className="card-list">
                      {categorizedArts.BK.map((art: any) => (
                        <li className="card-item" key={art.id}>
                        <div className="media-holder">
                          <img
                            className="item-img"
                            src={art.art_url}
                            alt={art.id}
                          />
                        </div>
                      </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="graphic-color-bottom">
                  <Accordion defaultActiveKey="0" className="default-accordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Front</Accordion.Header>
                      <div className="graphic-color-outer">
                        <Accordion.Body>
                          <Accordion
                            defaultActiveKey="0"
                            className="default-accordion"
                          >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <div className="graphic-heads-inner">
                                  <div className="graphic-heads-left">
                                    Graphic color 1
                                  </div>
                                  <div className="graphic-beads-right">
                                    <div className="custom-checkbox grey-shade">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="allFrontLogos"
                                        name="allFrontLogos"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="allFrontLogos"
                                      >
                                        Link front & back
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <div className="graphic-color-list">
                                <Accordion.Body>
                                  <div className="graphic-color-content">
                                    <Accordion
                                      defaultActiveKey="0"
                                      className="default-accordion"
                                    >
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                          <div className="custom-checkbox grey-shade">
                                            <input
                                              className="custom-control-input"
                                              type="checkbox"
                                              id="allFrontLogos"
                                              name="allFrontLogos"
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="allFrontLogos"
                                            >
                                              Default
                                            </label>
                                          </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <ul className="color-list-wrap">
                                            <li>
                                              <div className="custom-checkbox grey-shade">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  id="allFrontLogos"
                                                  name="allFrontLogos"
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="allFrontLogos"
                                                >
                                                  <span
                                                    className="color-name"
                                                    style={{
                                                      backgroundColor: "black",
                                                    }}
                                                  ></span>
                                                  <span>Navy</span>
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="custom-checkbox grey-shade">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  id="allFrontLogos"
                                                  name="allFrontLogos"
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="allFrontLogos"
                                                >
                                                  <span
                                                    className="color-name"
                                                    style={{
                                                      backgroundColor:
                                                        "#e34427",
                                                    }}
                                                  ></span>
                                                  <span>Red Snapper</span>
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </div>
                                </Accordion.Body>
                              </div>
                            </Accordion.Item>
                          </Accordion>
                        </Accordion.Body>
                      </div>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Tab>
              <Tab eventKey="contact" title="Sized Art">
                <div className="size-content-inner">
                  <div className="svg-template"></div>

                  <div className="size-content-top">
                    <ul className="size-content-list">
                      <li className="content-item"></li>
                      <li className="content-item">Location</li>
                      <li className="content-item">Embellishment</li>
                      <li className="content-item">Size</li>
                      <li className="content-item special-notes">
                        Special Notes
                      </li>
                    </ul>
                  </div>

                  <div className="size-content-bottom">
                    <ul className="size-bottom-list">
                      <li className="size-bottom-item">
                        <div className="size-bottom-content">
                          <div className="size-bottom-col">
                            <div className="image-wrapper">
                              <img
                                src="https://stdrec.s3.amazonaws.com/assets/images/U101-T002.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="size-bottom-col">
                            <Dropdown className="icon-dropdown with-text">
                              <Dropdown.Toggle className="edit-name-btn">
                                Center Chest
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div className="dropdown-container">
                                  <Dropdown.Item href="#/action-1">
                                    Graphic color 1
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Graphic color 2
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Body color
                                  </Dropdown.Item>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="size-bottom-col">
                            <ul className="embellishment-list-wrap">
                              <li>
                                <div className="embellishment-wrap">
                                  <Dropdown className="icon-dropdown with-text">
                                    <Dropdown.Toggle className="edit-name-btn">
                                      Vintage Print
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div className="dropdown-container">
                                        <Dropdown.Item href="#/action-1">
                                          Graphic color 1
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Graphic color 2
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Body color
                                        </Dropdown.Item>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <button className="add-btn">
                                    <span className="material-symbols-outlined">
                                      add_circle
                                    </span>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="size-bottom-col">
                            <div className="enter-size-wrapper">
                              <div className="size-input-holder">
                                <input type="text" className="size-input" />
                              </div>
                              <div className="size-categories">
                                <ul className="size-categories-list">
                                  <li>
                                    <div className="custom-checkbox grey-shade">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="wide"
                                        name="check-all"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="wide"
                                      >
                                        Wide
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-checkbox grey-shade">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="tall"
                                        name="check-all"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="tall"
                                      >
                                        Tall
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="size-bottom-col special-notes-col">
                            <textarea placeholder="enter special notes here"></textarea>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={props.onHide}>
            Done
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default GraphicDefaultModal;
