import { addBlankGarment, fetchBlankGarmentInventory, fetchBlankGarments, fetchLocalBlankGarmentInventory } from "api/inventoryService";
import { FC, ChangeEvent, useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import SpinnerLoader from "ui/loader/SpinnerLoader";

const SIZE_LIST = ['XS', 'S', 'M', 'L', 'XL', 'XXL']

type ACTION = 'Add' | 'View'

type InventoryModalType = {
  open: boolean;
  closeModal: () => void;
  accessToken: string;
  colorMap: any;
  action: ACTION;
  selectedId: number | null;
  fetchBlankGarments: Function;
  garmentMap: any
};

type errorMsgType = {
  productId?: string | null;
  other?: string | null;
};

type TemplateType = {
  productId: number;
  styleNumber: string;
  description: string;
};


const InventoryModal: FC<InventoryModalType> = (props): JSX.Element => {
  const { open, closeModal, colorMap, action, selectedId, fetchBlankGarments: refetchBlankGarments, garmentMap } = props;
  const [blankTemplates, setBlankTemplates] = useState<TemplateType[]>([]);
  const [sortedColor, setSortedColor] = useState<string[]>([]);
  const [productId, setProductId] = useState<number | null>(null);
  const [inventory, setInventory] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<errorMsgType>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    let errors = { ...errorMsg };
    switch (name) {
      case "productId":
        errors.productId = Number.isNaN(parseInt(value))
          ? "Template must be selected"
          : null;
        break;
      default:
        break;
    }

    setErrorMsg(errors);
    setProductId(parseInt(value))
  };


  const handleSubmit = async() => {
    const blankGarment = blankTemplates.find((item) => item.productId == productId)
    try{
      // eslint-disable-next-line no-throw-literal
      if(!blankGarment) throw 'Please select Garment First'
      setIsLoading(true)
      await addBlankGarment(blankGarment, props.accessToken)
      refetchBlankGarments()
      closeModal()
      setIsLoading(false)
    }catch(err){
      setIsLoading(false)
      console.error(err)
    }
  };

  useEffect(() => {
    async function _setBlankTemplates() {
      try {
        setIsLoading(true)
        const result = await fetchBlankGarments(props.accessToken);
        if (!result?.data?.data?.length) {
          setErrorMsg({ productId: "Blank Garments Not Found" });
        }
        if(action === 'View'){
          const temp = result.data.data.find((item: any) => item.productId == selectedId)
          if(temp) setProductId(selectedId)
          fetchInventory(selectedId)
        }
        let _blankTemplates = result.data.data.filter((item: any) => {
          if(action === 'Add'){
            return !garmentMap[item.productId]
          }
          return true
        })
        setBlankTemplates(_blankTemplates);
        setIsLoading(false)
      } catch (err) {
        console.error(err);
        setErrorMsg({ productId: "Something Went Wrong" });
        setIsLoading(false)
      }
    }
    _setBlankTemplates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accessToken]);

  const fetchInventory = async(productId: any) => {
    try{
        setIsLoading(true)
        let result: any = null
        if(action === 'Add'){
          result = await fetchBlankGarmentInventory(productId, props.accessToken)
        }else{
          result = await fetchLocalBlankGarmentInventory(productId, props.accessToken)
        }
        if(!result?.data?.data){
            setErrorMsg({ other: 'Failed to fetch inventory' })
        }
        const _sortedColors = Object.keys(result.data.data).sort((a: string, b: string) => a.localeCompare(b))
        setSortedColor(_sortedColors)
        setInventory(result.data.data)
        setIsLoading(false)
    }catch(err){
        setErrorMsg({ other: 'Something Went Wrong' })
        setIsLoading(false)
    }
  }

  const fetchLocalInventory = async(productId: number | null) => {
    try{
        setIsLoading(true)
        const result = await fetchBlankGarmentInventory(productId, props.accessToken)
        if(!result?.data?.data){
            setErrorMsg({ other: 'Failed to fetch inventory' })
        }
        const _sortedColors = Object.keys(result.data.data).sort((a: string, b: string) => a.localeCompare(b))
        setSortedColor(_sortedColors)
        setInventory(result.data.data)
        setIsLoading(false)
    }catch(err){
        setErrorMsg({ other: 'Something Went Wrong' })
        setIsLoading(false)
    }
  }

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={closeModal}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">
              {action === "Add"
                ? "Add New Blank Garment"
                : "View Blank Garment Inventory"}
            </h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <p className="mb-2">Blank Garment Name</p>
          <div className="row">
            <div className="col-lg-8">
              <select
                name={"productId"}
                defaultValue={""}
                onChange={handleChange}
                className="mb-3 form-select"
                title={"Select Style"}
                disabled={action === "View"}
              >
                <option disabled value={""} selected={!productId}>
                  Select
                </option>
                {blankTemplates.map((el) => (
                  <option
                    key={el.styleNumber}
                    value={el.productId}
                    selected={productId == el.productId}
                  >
                    {el.styleNumber} - {el.description}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-lg-4"
              style={{ display: action === "Add" ? "block" : "none" }}
            >
              <button
                className="btn btn-outline-danger px-4 ms-3"
                type="button"
                disabled={!productId}
                onClick={() => fetchInventory(productId)}
              >
                {" "}
                Fetch Inventory
              </button>
            </div>
          </div>
          <div>
            {isLoading && <SpinnerLoader message={"Loading..."} />}
            <table className="table" style={{ visibility: Object.keys(inventory).length ? 'visible' : 'hidden' }} >
              <tr>
                <th>Color</th>
                {SIZE_LIST.map((head) => (
                  <th>{head}</th>
                ))}
              </tr>
              
              {sortedColor.map((color) => {
                  return (
                    <tr>
                      <td>{color}</td>
                      {SIZE_LIST.map((size) => {
                        const data = inventory?.[color]?.[size] || {};
                        return <td>{parseInt(data?.qtyInventory)}</td>;
                      })}
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
          <button
            className="btn btn-danger px-4 me-3"
            onClick={handleSubmit}
            style={(action === 'View' ? { display: 'none' } : {})}
            disabled={!productId || isLoading}
          >
            Save
          </button>
          <button
            className="btn btn-outline-danger px-4"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryModal;
