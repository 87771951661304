import React from 'react';
import SearchLogo from 'svgs/icon-action-search.svg';
import styles from  './SearchBar.module.scss';

export interface ISearchBarProps {
  value: string;
  onChnage: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: React.FC<ISearchBarProps> = ({ value, onChnage }) => {
  return (
    <div className={`content-sm ${styles.searchBar}`}>
      <img className={styles.iconLeft} src={SearchLogo} alt="" />
      <input
          className="form-control"
        type="text"
        name="searchbar"
        id="searchbar"
        placeholder="Search everything"
        value={value}
        onChange={onChnage}
      />
    </div>
  );
};

export default SearchBar;
