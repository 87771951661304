import * as React from "react";
import { Stack } from "react-bootstrap";
import { useController, useFormContext } from "react-hook-form";

const RHFEditable: React.FC<any> = ({
  fieldName,
  rules = { required: true },
  ...props
}) => {
  const { control } = useFormContext();
  const [edit, setEdit] = React.useState(false);

  const startEdit = () => setEdit(true);
  const stopEdit = () => setEdit(false);

  const {
    field,
    fieldState: { error },
  } = useController({ control, name: fieldName, rules });

  return (
    <>
      {edit ? (
        <>
            <input
              type="text"
              className="ms-1"
              value={field.value}
              onChange={field.onChange}
              {...props}
            />
            {error && <small className="text-danger">{error?.message}</small>}
          <button
            type="button"
            className="icon-btn"
            onClick={stopEdit}
          >
            <i className="fa-solid fa-xmark text-danger"></i>
          </button>
        </>
      ) : (
        <>
          <span>{field.value}</span>
          {error && <small className="text-danger">{error?.message}</small>}
          <button type="button" className="icon-btn" onClick={startEdit}>
            <i className="fa fa-pencil text-danger"></i>
          </button>
        </>
      )}
    </>
  );
};

export default RHFEditable;
