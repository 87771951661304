import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import PhoneInput from 'react-phone-number-input';

export interface IInputPhoneProps {
  value: any;
  placeholder?: string;
  label?: string;
  inputClassName?: string;
  sectionClass?: string;
  // type?: 'text' | 'number ' | 'radio' | 'password';
  id?: string;
  name?: string;
  onChange: (val: any) => void;
  errorMessage?: string;
}
const InputPhone: React.FC<IInputPhoneProps & InputHTMLAttributes<any>> = ({
  value,
  label,
  inputClassName,
  sectionClass,
  type = 'text',
  id,
  onChange,
  name,
  placeholder,
  errorMessage,
  ...props
}) => {
  return (
    <>
      <section className={`input-field ${sectionClass ? sectionClass : ''}`}>
        {label && <label htmlFor={id}>{label}</label>}
        <PhoneInput
          international={false}
          defaultCountry="US"
          value={value}
          placeholder={"(###) ###-####"}
          onChange={onChange}
          {...props}
        />
        {errorMessage && errorMessage.length > 0 && (
        <span style={{ color: 'red' }}>{errorMessage}</span>
      )}
        
      </section>
    </>
  );
};

export default InputPhone;
