import * as React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface IProp {
    value: string; onChange: any
}

const RichTextEditor: React.FC<IProp> = ({ value, onChange }): JSX.Element => {

    return (
        <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
        />
    )
}

export default RichTextEditor