import axios from "axios";
import { BASE_URL } from "./apiConstants";
import {
  ITemplate,
  ITemplateConfigPayload,
  ITemplateSettingAddPayload,
  ITemplateSettingUpdatePayload,
} from "./requestTypes";
import { client } from "utils/client";

export const createTemplatesService = (data: ITemplate, token: string) => {
  return client.post('/templates/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/save",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteTemplatesService = (id: string | number, token: string) => {
  return client.put(`/templates/remove/${id}`)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templates/remove/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const fetchAllTemplatesService = (token: string) => {
  return client.get('/templates/list')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/templates/list",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const fetchTemplateDetailsService = (
  token: string,
  id: string | number
) => {
  return client.get(`/templates/template-by-id/${id}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templates/template-by-id/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const updateTemplateService = (
  id: string | number,
  data: ITemplate,
  token: string
) => {
  return client.put(`/templates/update/${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templates/update/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteTemplateService = (id: string | number, token: string) => {
  return client.put(`/templates/remove/${id}`)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templates/remove/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const fetchJSONFromURLService = (url: string) => {
  return axios({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const configureTemplateService = (
  data: ITemplateConfigPayload | any,
  token: string
) => {
  return client.post('/templates/mapping', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/mapping",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const configureTemplateLogosService = (data: any, token: string) => {
  return client.post('/templates/add-logos', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/add-logos",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const getApparelInventoryStock = (
  data: { sku_ids: number[] },
  token: string
) => {
  return client.post('/templates/apparel-inventory', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/apparel-inventory",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const downloadTemplateConfigCSVService = (data: any, token: string) => {
  return client.post('/templates/csv-download', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/csv-download",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const downloadTemplateDatabaseCSVService = (data: any, token: string) => {
  return client.post('/templates/csv-download-for-template', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/csv-download-for-template",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const downloadTemplateConfigLogoCSVService = (
  data: any,
  token: string
) => {
  return client.post('/templates/csv-download-logos', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/csv-download-logos",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const downloadTemplateLogosCSVService = (
  data: any,
  token: string
) => {
  return client.post('/templates/csv-download-logos-for-template', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/csv-download-logos-for-template",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const getS3BucketUrl = (
  data: {
    parentId: string | number;
    childId: string | number;
    templateId: string | number;
  },
  token: string
) => {
  return client.post('/templates/upload-link', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/upload-link",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const changeTemplateStatus = (
  data: {
    childId: string | number;
    templateId: string | number;
    status: "Draft" | "Ready";
  },
  token: string
) => {
  return client.patch('/templates/status', data)
  // return axios({
  //   method: "patch",
  //   url: BASE_URL + "/templates/status",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const changeTemplateStatusMany = (
  data: {
    childId: number;
    templateIds: number[];
    status: "Draft" | "Ready";
  },
  token: string
) => {
  return client.patch('/templates/status', data)
  // return axios({
  //   method: "patch",
  //   url: BASE_URL + "/templates/status",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const uploadZipFileService = (data: FormData, token: string) => {
  return client.post('/templateSetting/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templates/upload-zip",
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const TemplateSettingAddService = (
  data: ITemplateSettingAddPayload | any,
  token: string
) => {
  return client.post('/templateSetting/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templateSetting/save",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const TemplateSettingGetService = (
  id: string | number,
  token: string
) => {
  return client.get(`/templateSetting/${id}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templateSetting/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const TemplateSettingUpdateService = (
  id: string | number,
  data: ITemplateSettingUpdatePayload | any,
  token: string
) => {
  return client.put(`/templateSetting/update/${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templateSetting/update/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const colorSwatchesAddService = (data: any, token: string) => {
  return client.post('/templateColor/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/templateColor/save`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const createTemplatePricingService = (data: any, token: string) => {
  return client.post('/templatePrice/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templatePrice/save",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const updateTemplatePricingService = (
  id: string | number,
  data: any,
  token: string
) => {
  return client.put(`/templatePrice/update/${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templatePrice/update/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteTemplatePricingService = (
  id: string | number,
  token: string
) => {
  return client.put(`/templatePrice/remove/${id}`)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templatePrice/remove/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const fetchAllTemplatePricingService = (
  token: string,
  templateId: string | number
) => {
  return client.get(`/templatePrice/templateId/${templateId}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templatePrice/templateId/${templateId}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const fetchAllTemplatePricingByIdsService = (
  token: string,
  ids: number[]
) => {
  const query = ids.map(id => `ids=${id}`).join('&')
  return client.get(`/templatePrice?${query}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templatePrice?${query}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
export const fetchTemplatePricingService = (
  token: string,
  id: string | number
) => {
  return client.get(`/templatePrice/${id}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templatePrice/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const fetchTemplateColorsService = (
  token: string,
  id: string | number
) => {
  return client.get(`/templateColor/${id}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templateColor/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const fetchTemplateDefaultSettings = (
  token: string,
  childId: string| number,
  templateId: string | number
) => {
  return client.get(`/templateDefault/${childId}/${templateId}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templateDefault/${childId}/${templateId}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const createTemplateDefaultSettings = (
  token: string,
  childId: string | number,
  templateId: string | number,
  data: any,
  shade?: "lt" | "dk",
  logo?: string,
) => {
  return client.post(`/templateDefault/${childId}/${templateId}`, data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/templateDefault/${childId}/${templateId}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const createTemplateDefaultColors = (
  token: string,
  data: any
) => {
  return client.post('/templateDefaultColor/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/templateDefaultColor/save`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const updateTemplateDefaultColors = (
  token: string,
  id: number | string,
  data: any
) => {
  return client.put(`/templateDefaultColor/${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/templateDefaultColor/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchTemplateDefaultColors = (
  token: string,
  id: number | string
) => {
  return client.get(`/templateDefaultColor/by-template-id/${id}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/templateDefaultColor/by-template-id/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
}

export const fetchLabels = ( token: string ) => {
  return client.get('/templateLabel')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/templateLabel",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // })
}

export const createLabel = ( token: string, payload: any) => {
  return client.post(`/templateLabel`, payload)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templateLabel",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload
  // })
}

export const updateLabel = ( token: string, id: any, payload: any) => {
  return client.put(`/templateLabel/${id}`)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + "/templateLabel/" + id,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload
  // })
}

export const deleteLabel = ( token: string, id: any) => {
  return client.delete(`/templateLabel/${id}`)
  // return axios({
  //   method: "delete",
  //   url: BASE_URL + "/templateLabel/" + id,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // })
}

export const confiureTemplateLabel = ( token: string, payload: any) => {
  return client.post('/templateLabel/apply', payload)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templateLabel/apply",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload
  // })
}

export const fetchTemplateLabel = ( token: string, payload: any) => {
  return client.post('/templateLabel/list', payload)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/templateLabel/list",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload
  // })
}