import React, {FC} from "react";

const EmptyList: FC<{title?: string}> = ({title = 'Nothing Here'}) => {
    return(
        <div className="card mb-3">
            <div className="card-body">
                <p className="text-center mb-0">{title}</p>
            </div>
        </div>
    )
}

export default EmptyList