import React, { ChangeEvent, FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SpinnerLoader from '../../ui/loader/SpinnerLoader';
import Input from '../../ui/input/Input';
import { IAttributeModal } from '../../types/components/customerAttributesType';

const AttributeModal: FC<IAttributeModal> = ({
  open,
  setOpen,
  attribute,
  setAttribute,
  onSave,
  loading
}) => {
  const [error, setError] = useState('');
  const [attributeInfo, setAttributeInfo] = useState({ ...attribute });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errors = error;
    switch (name) {
      case attributeInfo.attributeType:
        errors =
          value.length < 1
            ? `${attributeInfo.attributeType} can not be empty`
            : ``;
        break;
      default:
        break;
    }
    setError(errors);
    setAttributeInfo({ ...attributeInfo, attributeName: value });
  };
  const isButtonDisabled = () => {
        
    let data = {...attributeInfo};
    //return data.name.trim() && data.email.trim() && validateEmail(data.email.trim()) && data.password.trim().length > 7
    return data.attributeName.trim() ;
}
  const handleSubmit = () => {
      onSave(attributeInfo);
  }
  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="border-bottom mb-3">
          <p className="mb-0">
            {attributeInfo.id
              ? `Update ${attributeInfo.attributeType}`
              : `Add ${attributeInfo.attributeType}`}
          </p>
        </div>
        <Input
              sectionClass="mb-3"
              value={attributeInfo.attributeName}
              onChange={handleChange}
              label={attributeInfo.attributeType+"*"}
              name={attributeInfo.attributeType}
              placeholder="Abc"
              errorMessage={error}
          />
        <div className="mt-3">
          <button className="btn btn-danger px-4" onClick={handleSubmit} disabled={!isButtonDisabled()}>
            {attribute?.id ? 'Update' : 'Save'}
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => {setAttribute({id:'',attributeType:'',attributeName:''});setOpen(false)}}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AttributeModal;
