import clsx from "clsx";
import * as React from "react";
import { toast } from "react-toastify";

import { AltStyle } from "./interface";
import * as Service from "api/inventoryService";
import AddUpdateAltStyleDetail from "./AddUpdateAltStyleDetail";

const AltStyleDetailList: React.FC = (): JSX.Element => {
  const [refetch, setRefetch] = React.useState(true);
  const [isSyncing, setIsSyncing] = React.useState<boolean>(false);
  const [altStyle, setAltStyle] = React.useState<AltStyle | null>(null);
  const [altStyles, setAltStyles] = React.useState<AltStyle[]>([]);

  const open = Boolean(altStyle);

  const handleOpen = (altStyle: AltStyle) => setAltStyle(altStyle!);
  const handleClose = () => setAltStyle(null);
  const handleSuccess = () => {
    setAltStyle(null);
    setRefetch(true)
  }

  React.useEffect(() => {
    async function fetchAMAltStyles() {
      if (!refetch) return;
      try {
        const list = (await Service.getAllAltStyle()) as unknown as AltStyle[];
        setRefetch(false);
        setAltStyles(list);
      } catch (err) {
        console.error(err);
        setRefetch(false);
      }
    }
    fetchAMAltStyles();
  }, [refetch]);

  const startSyncing = async () => {
    setIsSyncing(true);
    Service.startStyleSync()
      .then(() => {
        setIsSyncing(true);
        toast.success("Style sync started...");
      })
      .catch((err) => {
        toast.error("failed to sync");
      });
  };

  React.useEffect(() => {
    if (isSyncing) {
      const controller = new AbortController();
      const signal = controller.signal;
      let id = setInterval(() => {
        (
          Service.checkStyleSync({ signal }) as unknown as Promise<
            "ACTIVE" | "INACTIVE"
          >
        )
          .then((status) => {
            if (status === "INACTIVE") {
              controller.abort();
              setRefetch(true);
              setIsSyncing(false);
              toast.success("Styles synced.");
            }
          })
          .catch((err) => console.log(err));
      }, 500);
      return function cleanup() {
        clearInterval(id);
      };
    }
  }, [isSyncing]);

  return (
    <div className="content-sm page-bottom-space">
      <div>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4-bold">Alt Style Description</h4>
          <button
            className="btn btn-danger"
            disabled={isSyncing}
            onClick={startSyncing}
          >
            <i
              className={clsx("fas fa-sync me-2", { "fa-spin": isSyncing })}
            ></i>
            Alt Styles
          </button>
        </div>
        <ul className="list-group list-group-flush list-style-none">
          {altStyles.map((style) => (
            <li className="mb-2" key={style.id}>
              <div className="has-right-action">
                <p className="mb-0 text-capitalize">
                  {style.altCode} - {style.name}
                </p>
                <div className="action-wrap">
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    onClick={() => handleOpen(style)}
                  >
                    <span className="icon-description"></span>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {open && altStyle && (
        <AddUpdateAltStyleDetail
          altStyle={altStyle}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
        />
      )}
    </div>
  );
};

export default AltStyleDetailList;
