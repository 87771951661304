import axios from "axios";
import { IShippingChargeType } from 'pages/shippingChargeConfig/ShippingChargeConfig';
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const updateShippingCharge = (data: IShippingChargeType, id: number, token: string) => {
  return client.put(`/shipping-cost//${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/shipping-cost//${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const getShippingCost = (token: string) => {
  return client.get('/shipping-cost')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/shipping-cost",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};
