import { ICellRendererParams } from "ag-grid-community";

const ShowCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  const showName = value ? `${value}` : "";

  return (
    <div>{<div className="show-name">{showName?.split("-")?.[0]}</div>}</div>
  );
};

export default ShowCellRenderer;
