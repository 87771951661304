import Cookies from "js-cookie";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./LoginPage.module.scss";
import Input from "ui/input/Input";
import Button from "ui/button/Button";
import { loginService } from "api/userService";
import { setUserDetails } from "store/User.slice";
import { AxiosResponse } from "axios";
import { useAppDispatch } from "store/hooks";
import StandardRecLogo from "../../assets/images/standardRecLogo.svg";
import Logo from "../../assets/images/logo.svg";
import { URLS } from 'utils/constants';
import { validateEmail } from "utils/helperFunctions";
import SpinnerLoader from "../../ui/loader/SpinnerLoader";

export interface ILoginPageProps {}
const LoginPage: React.FC<ILoginPageProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  // @ts-ignore
  const from = location.state?.from?.pathname || "/";

  const [info, setInfo] = useState({ email: "", password: "" });
  const [submit, setSubmit] = useState(false);
  const [passwordError,setPasswordError]=useState(false);
  const [emailError,setEmailError]=useState(false);
  const [loading,setLoading] = useState(false);

  const handleInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
    if(name==='password')setPasswordError(false);
    else setEmailError(false);
  };

  const handleLogin = (e?: React.FormEventHandler) => {
    if (info.email && info.password) {
      // do what your functions here
      if(!validateEmail(info.email)) setEmailError(true);
      else
      setSubmit(true);
    }
  };

  useEffect(() => {
    const login = async () => {
      
      try {
        setLoading(true);
        const { data }: AxiosResponse = await loginService(info);
        if (data?.data) {
          
          let payload = { ...data.data };
          delete payload.password;
          Cookies.set('access_token', payload.accessToken)
          Cookies.set('refresh_token', payload.refreshToken)
          setLoading(false);
          dispatch(setUserDetails(payload));
          navigate('/');

           //navigate('/', { replace: true });
          //navigate(from, { replace: true });
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        setPasswordError(true);
      } 

    };

    if (submit) {
      login();
      setSubmit(false);
    }
  }, [dispatch, from, info, navigate, submit]);

  if (loading)
    return (
      <SpinnerLoader/>
    );
  return (
    <div className={styles.loginWrap}>
      <div className={`row g-0 ${styles.loginRow}`}>
        <div className="col-12">
          <div className={`d-flex justify-content-center align-items-center h-100 p-3 py-5`}>
            <form onSubmit={e=> e.preventDefault()} className={styles.loginForm}>
              <div className="text-center mb-3">
                <img className={`img-fluid ${styles.logo}`} src={StandardRecLogo} alt="logo"/>
              </div>
              <div className={`card ${styles.formCard}`}>
                <div className={`card-body`}>
                  <h3 className="heading3 mb-3 font-weight-normal">Login to your account</h3>
                  <div className="mb-3">
                    <Input
                        value={info.email}
                        name="email"
                        placeholder="Enter email"
                        onChange={handleInfo}
                    />
                    { emailError && <p className="text-danger mt-1 font-size-xs mb-0">Please enter a valid email address</p>}
                  </div>
                  <div className="mb-3">
                    <Input
                        type="password"
                        value={info.password}
                        name="password"
                        placeholder="Enter password"
                        onChange={handleInfo}
                    />
                    { passwordError && <p className="text-danger mt-1 font-size-xs mb-0">Incorrect email or password</p>}
                  </div>
                  <div className="mb-3 d-flex justify-content-end">
                    <button type="button" className="btn btn-link font-size-sm" onClick={() => navigate(URLS.FORGOT_PASSWORD)}>
                    Forgot email or password?
                    </button>
                    {/* <button type="button" className="btn btn-link font-size-sm" onClick={() => navigate(URLS.REGISTER)}>
                    Register Here
                    </button> */}
                  </div>
                  <Button label="Login" onClick={handleLogin} type="danger" className="btn-danger w-100" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
