import * as React from 'react'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { columnDefs, transformUserList } from './UserTable.utils';
import * as userService from '../../../api/userService'
import { getUserDetails } from 'store/User.slice';
import { useAppSelector } from 'store/hooks';
import styles from './UserTable.module.scss'

const UserTable: React.FC<any> = (): JSX.Element => {
    const { accessToken } = useAppSelector(getUserDetails)
    const [rowData, setRowData] = React.useState([])

    const gridReady = (params: any) => {
        let gridApi = params.api
        sizeToFit(gridApi)
    }

    const sizeToFit = (gridApi: any) => {
        gridApi.sizeColumnsToFit()
    }

    React.useEffect(() => {
       async function fetchAllUsers (){
            try{
                const params = {}
                const response = await userService.getUsers(accessToken, params)
                const users = response.data?.data
                const data = transformUserList(users)
                setRowData(data)                
            }catch(err){
                console.log(err);
            }
        }
        fetchAllUsers()
    }, [])

    return (
        <div className="me-2 page-bottom-space">
            <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
                <h4 className="mb-0 heading4-bold">Users Table</h4>
            </div>
            <div className={styles.agGridContainer}>
                <div className={`ag-theme-alpine ${styles.agGridWrap}`}>
                    <AgGridReact
                        onGridReady={(params) => gridReady(params)}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        suppressRowTransform={true}
                        pagination={true}
                        paginationPageSize={20}
                        cacheBlockSize={10}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserTable