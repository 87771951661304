import { ICellRendererParams } from "ag-grid-community";
import { downloadOrderInfoCSV } from "api/orderService";
import { IMAGES } from "assets/images/images";
import CustomTooltip from "components/tooltip/Tooltip";
import { useState } from "react";
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";
import SpinnerLoader from "ui/loader/SpinnerLoader";

const CSVCellContainer: React.FC<ICellRendererParams> = ({ value }) => {
  const [loading, setLoading]= useState(false)
  const { accessToken } = useAppSelector(getUserDetails);

  const downloadCSV = async () => {
    console.log(value)
    const [orderNumber, orderItemId, skuConcat] = `${value}`?.split('>>>')
    try {
      setLoading(true)
      const { data } = await downloadOrderInfoCSV(
        {
          orderItemId,
        },
        accessToken
      );
      setLoading(false)
      let csvContent = "data:text/csv;charset=utf-8," + data;
      var encodedUri = encodeURI(csvContent);
      //window.open(encodedUri);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${orderNumber}_${skuConcat}`);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  };

  if (loading) return <SpinnerLoader/>;

  return (
    <div>
      <CustomTooltip msg={"Download CSV"}>
        <button
          className="btn btn-link text-secondary position-relative btn-img ms-2"
          onClick={downloadCSV}
          // disabled={temp.status === 'Ready' ? false : true}
        >
          <img
            className="img-fluid"
            src={IMAGES.DownloadCloud}
            alt="download"
          />
         {' '} CSV
        </button>
      </CustomTooltip>
    </div>
  );
};

export default CSVCellContainer;
