import { festchAllParentsService, startParentSync, checkParentSyncStatus, resetEvent } from "api/parentService";
import {
  IAllParentAccountResponse,
  ICustomerDetails,
  IkeyValuePair,
} from "api/requestTypes";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fetchAllCustomerContactService } from "api/customerService";
import Account from "components/CustomerAccount/Account";
import { Nav, Tab } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import { URLS, USER_ROLE } from "utils/constants";
import SpinnerLoader from "../../ui/loader/SpinnerLoader";

interface IHomePageProps {}
const HomePage: React.FC<IHomePageProps> = () => {
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [syncTime, setSyncTime] = useState(() => Date.now());
  const { role, accessToken } = useAppSelector(getUserDetails);
  const [parrentAccounts, setParentAccounts] = useState<
    IAllParentAccountResponse[]
  >([]);
  const [customerAccounts, setCustomerAccounts] = useState<ICustomerDetails[]>(
    []
  );
  const [openId, setOpenId] = useState<string | number>("");
  const [byCategory, setByCategory] = useState<boolean>(false);
  const handleCreateparentAccount = () => {
    navigate(URLS.CREATE_ACCOUNT);
  };

  const checkParentSyncStatusByPP = async () => {
    const maxTry = 7
    let _try = 1
    setLoading(true)
    const intervalRef = setInterval(async() => {
      const result = await checkParentSyncStatus(accessToken).catch(() => {
        clearInterval(intervalRef)
        setLoading(false)
      })
      if(_try > maxTry || result?.data?.data === "INACTIVE"){
        clearInterval(intervalRef)
        setSyncTime(Date.now())
        setLoading(false)
      }
    }, 3000)
  }

  const handleReset = async () => {
    try {
      setLoading(true);
      await resetEvent();
      toast.success('Refresh button is ready')
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Failed to reset')
    }
  }

  const fetchParentAccountsFromApparelMagic = async () => {
    try {
      setLoading(true);
      await startParentSync(accessToken);
      await checkParentSyncStatusByPP()
    } catch (err) {
      setLoading(false);
      toast.error('Failed to refresh accounts')
    }
  };


  const handleAccountDetails = (id: string | number, index: number) => {
    //navigate(`/parent-account/${id}`);
    navigate(`/parent-account/${id}`);
  };
  const handleCustomerAccountDetails = (id: string | number, index: number) => {
    //navigate(`/parent-account/${id}`);

    navigate(`/parent-account/${customerAccounts[index].parentId}`);
  };

  useEffect(() => {
    const fetchCustomerAccountdetails = async () => {
      try {
        setLoading(true);
        const res = await fetchAllCustomerContactService(accessToken);
        const data: ICustomerDetails[] | undefined = res?.data?.data;
        //console.log("Customer:",data);
        if (data?.length) {
          data.sort((a, b) =>
            a.customerAccountName.toUpperCase() <
            b.customerAccountName.toUpperCase()
              ? -1
              : 1
          );
          setCustomerAccounts(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    const fettchAccountdetails = async () => {
      try {
        setLoading(true);
        const res = await festchAllParentsService(accessToken);
        const data: IAllParentAccountResponse[] | undefined = res?.data?.data;
        if (data?.length) {
          setParentAccounts(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fettchAccountdetails();
    fetchCustomerAccountdetails();
  }, [accessToken, syncTime]);

  const sortedListCustomer = (letter: string) => {
    return (
      <li className="mb-1">
        <p className="text-danger mb-2">{letter.toUpperCase()}</p>
        {customerAccounts.map((account, index) => {
          if (account.category === letter)
            return (
              <div className="mb-1" key={account.id}>
                <button
                  className="btn btn-link text-dark"
                  onClick={() =>
                    handleCustomerAccountDetails(account.id, index)
                  }
                >
                  {account.customerAccountName}
                </button>
              </div>
            );
          else return <></>;
        })}
      </li>
    );
  };
  const sortedList = (letter: string, i: number) => {
    return (
      <li className="mb-1" key={letter}>
        <p className="text-danger mb-2">{letter}</p>
        {parrentAccounts.map((account, index) => {
          if (account.accountName.charAt(0).toUpperCase() === letter)
            return (
              <div key={account.id} className="mb-1">
                <button
                  className="btn btn-link text-dark text-capitalize"
                  onClick={() => handleAccountDetails(account.id, index)}
                >
                  {account.accountName}
                </button>
              </div>
            );
        })}
      </li>
    );
  };
  const listAZ = () => {
    let rows = [];
    let letters = new Set<string>();
    for (var i = 0; i < parrentAccounts.length; i++) {
      letters.add(parrentAccounts[i].accountName.charAt(0).toUpperCase());
    }
    let l = Array.from(letters);
    l.sort((a, b) => (a < b ? -1 : 1));
    for (i = 0; i < l.length; i++) {
      rows.push(sortedList(l[i], i));
    }
    return (
      <ul className="list-group list-group list-style-none mt-1">{rows}</ul>
    );
  };
  const listAZCustomer = () => {
    let rows = [];
    let letters = new Set<string>();
    for (var i = 0; i < customerAccounts.length; i++) {
      letters.add(customerAccounts[i].category);
    }
    let l = Array.from(letters);
    l.sort((a, b) => (a < b ? -1 : 1));
    for (var i = 0; i < l.length; i++) {
      rows.push(sortedListCustomer(l[i]));
    }
    return (
      <ul className="list-group list-group list-style-none mt-1">{rows}</ul>
    );
  };

  const onClickChildDetails = (id: string | number) => {
    if (openId === id) setOpenId("");
    else setOpenId(id);
  };

  const customerList = () => {
    return (
      <ul className="list-group list-group list-style-none mt-1">
        {customerAccounts.length > 0 &&
          customerAccounts.map((child, index) => {
            return (
              <li className="mb-2" key={child.id}>
                <div className="has-right-action">
                  <p
                    onClick={() => onClickChildDetails(child.id)}
                    className="mb-0 text-capitalize"
                  >
                    <i
                      className={`fa-solid text-danger me-2 ${
                        openId === child.id ? "fa-caret-down" : "fa-caret-right"
                      }`}
                    ></i>
                    {/* {findName(child.childData!)} */}
                    {child.customerAccountName}
                  </p>
                  {/* Action buttons UI*/}
                </div>
                {openId === child.id && <Account {...child} />}
              </li>
            );
          })}
      </ul>
    );
  };

  if (loading) return <SpinnerLoader />;
  return (
    <>
      <div className="custom-tabs content-sm">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={`parent-account`}
        >
          <Nav variant="tabs">
            {
              <Nav.Item>
                <Nav.Link className="cursor-pointer" eventKey="parent-account">
                  {role === USER_ROLE.SALES_PERSON
                    ? `Customer Account`
                    : `Parent account`}
                </Nav.Link>
              </Nav.Item>
            }
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="parent-account">
              <div>
                <div className="tab-top pb-1 d-flex justify-content-between">
                  <div className="me-2 d-flex justify-content-between sort-wrap">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => setByCategory(false)}
                    >
                      <span className="text-danger me-2">AZ</span>
                      <span className="text-dark">Sort a-z</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link btn-category"
                      onClick={() => setByCategory(true)}
                    >
                      <i className="fa-solid fa-shapes text-danger me-2"></i>
                      <span className="text-dark">By category</span>
                    </button>
                  </div>

                  {/* {role===USER_ROLE.SUPER_ADMIN &&(
                      <button

                        type="button"
                        className="btn btn-link"
                        onClick={handleCreateparentAccount}
                      >
                        <i className="fa-solid fa-circle-plus text-danger me-2"></i>
                        <span className="text-dark">New Parent account</span>
                      </button>
                    )} */}

                  {role === USER_ROLE.SUPER_ADMIN && (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={fetchParentAccountsFromApparelMagic}
                    >
                      <i className="fa-solid fa-refresh text-danger me-2"></i>
                      <span className="text-dark">
                        Refresh Parent Account's
                      </span>
                    </button>
                  )}
                  {role === USER_ROLE.SUPER_ADMIN && (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={handleReset}
                    >
                      <i className="fa-solid fa-wrench text-danger me-2"></i>
                      <span className="text-dark">
                        Reset
                      </span>
                    </button>
                  )}
                  {/*<AddSection className="mb-3" label="Add Sales Person" onClick={handleAddSales} />*/}
                </div>
                {/* Sorting List*/}
                {!byCategory &&
                  (role === USER_ROLE.SALES_PERSON ? customerList() : listAZ())}
                {byCategory && listAZCustomer()}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default HomePage;
