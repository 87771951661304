import { deleteCustomerCountryService, fetchAllCountryService } from 'api/locationService';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import AddContactModal from 'components/modal/Modal';
import { count } from 'console';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import AddCountries from './AddCountries';
import AddCountryModal from './AddCountryModal';
import { toast } from 'react-toastify';
export interface ICountry {
  id?: string | number;
  name: string;
  logos: string;
  createdAt?: string;
  updatedAt?: string;
}
const Countries: FC = (): JSX.Element => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [isAddCountries, setIsAddCountries] = useState(false);
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [currentIndex,setCurrentIndex] = useState<number>(-1);
  const [openConfirmation,setOpenConfirmation] = useState(false);
  const [deleteId,setDeleteId] = useState<number|string>('');

  const handleDeleteCountry = async (id: number | string) => {
    try {
      setLoading(true);
      const { data } = await deleteCustomerCountryService(id, accessToken);
      if (data.data) {
        
        let rslt = countryList.filter((el) => el.id != id);
        setCountryList(rslt);
        setLoading(false);
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
      toast.warn('Unable to delete');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getCountryList = async () => {
      try {
        setLoading(true);
        
        const { data } = await fetchAllCountryService(accessToken);
        if (data.data) {
          setCountryList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the countries');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCountryList();
  }, [accessToken]);
  const handleConfirmCallBack = () => {
    handleDeleteCountry(deleteId);
  };

  if (loading) return <SpinnerLoader />;
  return (
    <div className="content-sm page-bottom-space">
      <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
        <h4 className="mb-0 heading4-bold">Countries</h4>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => setIsAddCountries(true)}
        >
          <i className="fa-solid fa-circle-plus text-danger me-2"></i>
          <span className="text-dark">Add Countries</span>
        </button>
      </div>
      {countryList.length>0 &&
      <ul className="list-group list-group-flush list-style-none mb-2">
      {React.Children.toArray(
        
          countryList.map((country, index) => {
            return (
              <li className="mb-2" key={country.id}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">{country.name}</p>
                  <div className="action-wrap">
                    <button className="btn btn-link-danger" type="button"
                    onClick={() => {setCurrentIndex(index);setIsAddCountries(true)}}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button className="btn btn-link-danger ms-3" type="button"
                    onClick={()=>{setCurrentIndex(-1);setIsAddCountries(false);setOpenConfirmation(true);setDeleteId(country.id!)}}>
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            );
          }))
        }
      </ul>
        }
      {/* {isAddCountries && (
        <AddCountries
          setIsAddCountries={setIsAddCountries}
          countryList={countryList}
          setCountryList={setCountryList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )} */}
      {
        isAddCountries && (
          <AddCountryModal
          open={isAddCountries}
          setOpen={setIsAddCountries}
          countryList={countryList}
          setCountryList={setCountryList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          />
        )
      }
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
    </div>
  );
};

export default Countries;
