import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserDetails, IUserState } from 'types/UserSlice';
import { RootState } from './store';

const initialState: IUserState = {
  info: {
    accessToken: '',
    id: '',
    name: '',
    email: '',
    phone: '',
    role: '',
    createdAt: '',
    updatedAt: '',
  },
};

const UserDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (
      state: IUserState,
      { payload }: PayloadAction<IUserDetails>
    ) => {
      state.info = { ...payload };
    },
    clearUserDetails: (state: IUserState) => {
      state.info = initialState.info;
    },
  },
});

// Selectors
export const getUserDetails = (state: RootState) => state.userDetails.info;

// Actions
export const { setUserDetails, clearUserDetails } = UserDetailsSlice.actions;

export default UserDetailsSlice.reducer;
