import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import * as parentService from "api/parentService";

const ShareLinkModal = (props: any) => {
  const { show, onHide, parentId } = props;
  const [isLoading, setIsLoading] = React.useState(false)
  const [webLinkUser, setWebLinkUser] = React.useState<any>(null)
  const { accessToken } = useAppSelector(getUserDetails)


  const copyToClipborad = () => {
    navigator.clipboard.writeText(webLinkUser?.webLink);
    toast.success('Link is copied clipboard')
  }

  const handleToggle = async (isActive: boolean) => {
    try {
      setIsLoading(true)
      await parentService.toggleWebLink(accessToken, {
        webLinkId: webLinkUser?.id, isActive: isActive
      })
      toast.success(`Web link ${isActive ? 'enabled' : 'disabled'} successfully.`)
      setWebLinkUser((prev: any) => ({ ...prev, isActive: isActive }))
      setIsLoading(false)

    } catch (err: any) {
      console.log(err)
      toast.error(`Failed to ${isActive ? 'enable' : 'disable'} web link`)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    async function fetchWebLink() {
      try {
        const response = await parentService.generateWebLink(accessToken, { parentId })
        const data = response.data?.data
        setWebLinkUser(data)
      } catch (err) {
        console.log(err);
        toast.error('Failed to fetch web link')
      }
    }
    fetchWebLink()
  }, [accessToken])

  return (
    <Modal
      show={show}
      onHide={onHide} 
      size="lg"
      aria-labelledby="link-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Web Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="link-modal-wrap mb-2">
          <div className="link-modal-left">
            <input
              type="text"
              className="form-control text-truncate"
              placeholder="generating..."
              value={webLinkUser?.webLink || ''}
              readOnly={true}
            />
          </div>
          <div className="link-modal-right">
            <button className="btn btn-danger" onClick={copyToClipborad}>
              Copy
            </button>
          </div>
        </div>
        {/* <div className="mb-4 ps-2">
          <p className="form-error text-danger" style={{ fontSize: "14px" }}>
            Link is expired
          </p>
        </div> */}
        <div className="d-flex justify-content-between align-items-center">
          <div className="custom-checkbox grey-shade me-1 ms-2">
            <input
              className="custom-control-input"
              type="checkbox"
              id="link-sharing"
              name="link-sharing"
              onChange={(e) => handleToggle(e.target.checked)}
              disabled={isLoading || !webLinkUser}
              checked={isLoading ? !webLinkUser?.isActive : webLinkUser?.isActive}
            />
            <label className="custom-control-label" htmlFor="link-sharing">
              {isLoading ? `${webLinkUser?.isActive ? 'Disabling...' : 'Enabling...'}` : 'Enable'}
            </label>
          </div>
          <div>
            {/* <button className="btn btn-danger">Generate Link</button> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          {/* <button className="btn btn-danger" type="submit">
            Submit
          </button> */}

          <button
            type="button"
            className="btn btn-light ms-3"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareLinkModal;
