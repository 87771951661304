import { client } from "utils/client";


export const addColor = (payload: any) => {
    return client.post('/graphic-colors', payload)
    .then((response) => response?.data.data)
    .catch((error) => { throw new Error(error.response?.data.msg || 'Failed to add graphic color') })
};

export const getColor = (id: number) => {
    return client.get(`/graphic-colors/${id}`)
    .then((response) => response?.data.data)
};

export const updateColor = ({ id, dataToUpdate }: { id: number, dataToUpdate: object }) => {
    return client.put(`/graphic-colors/${id}`, dataToUpdate)
    .then((response) => response?.data.data)
    .catch((error) => { throw new Error(error.response?.data.msg || 'Failed to update graphic color') })
};

export const deleteColor = (id: number) => {
    return client.delete(`/graphic-colors/${id}`)
    .then((response) => response?.data.data)
};

export const addGroup = (payload: any) => {
    return client.post('/graphic-colors/groups', payload)
    .then((response) => response?.data.data)
    .catch((error) => { throw new Error(error.response?.data.msg || 'Failed to add color group') })
};

export const getGroup = (id: number) => {
    return client.get(`/graphic-colors/groups/${id}`).then((response) => response?.data.data)
};

export const getGroups = () => {
    return client.get('/graphic-colors/groups').then((response) => response?.data.data)
};

export const getGroupItems = () => {
    return client.get('/graphic-colors/groups/items').then((response) => response?.data.data)
};

export const updateGroup = ({ id, dataToUpdate }: { id: number, dataToUpdate: object }) => {
    return client.put(`/graphic-colors/groups/${id}`, dataToUpdate)
    .then((response) => response?.data.data)
    .catch((error) => { throw new Error(error.response?.data.msg || 'Failed to update color group') })
};

export const deleteGroup = (id: number) => {
    return client.delete(`/graphic-colors/groups/${id}`).then((response) => response?.data.data)
};

export const getColors = () => {
    return client.get('/graphic-colors').then((response) => response?.data.data)
};

export const moveColorToGroup = (payload: any) => {
    return client.put('/graphic-colors/groups/move-color-to-group', payload)
}
