import React, { useEffect, useState } from "react";
import GraphicRuleTable from "./GraphicRuleTable";
import * as GraphicRuleService from "../../api/graphicRule";
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { toast } from "react-toastify";

interface iBody {
  name: string;
  category: "body";
  shade: "dk" | "lt" | "NA";
}
interface iGraphic {
  name: string;
  category: "graphic" | "graphic2";
  shade: "dk" | "lt" | "NA";
}

interface iDb {
  [key: string]: boolean;
}

interface iMapper {
  [key: string]: iBody | iGraphic;
}

const GlobalGraphicRule: React.FC = (): JSX.Element => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [mapper, setMapper] = useState<iMapper | null>(null);
  const [db, setDb] = useState<iDb>({});
  const [refech, setRefetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState<any>({ body: [], graphic: [] });

  const categoriseColors = (colors: any) =>
    colors.reduce(
      (a: any, c: any) => {
        c.actual_name = c.colorName;
        a?.[c.category].push(c);
        return a;
      },
      { body: [], graphic: [] }
    );

  useEffect(() => {
    function fetchGraphicRuleDetail() {
      GraphicRuleService.fetchGraphicRules(accessToken, {
        templateId: 0,
        customerId: 0,
      })
        .then((res) => {
          const rules = res?.data.data.rules.reduce((a: any, c: any) => {
            a[`["${c.graphicType}"]["${c.bodyColor}"]["${c.graphicColor}"]`] =
              true;
            return a;
          }, {});
          setDb(rules);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setRefetch(false);
        });
    }
    if (refech) {
      fetchGraphicRuleDetail();
    }
  }, [refech]);

  useEffect(() => {
    function fetchColors() {
      setLoading(true);
      GraphicRuleService.fetchColorsService(accessToken, {})
        .then((res) => {
          const categorised = categoriseColors(res?.data.data);
          const mapper = generateMapper(categorised);
          setColors(categorised);
          setMapper(mapper);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchColors();
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const rules = [];
    const form = new FormData(e.target);
    for (const [key] of form.entries() as any) {
      if (mapper![key]) {
        const key2 = key.replace("graphic", "graphic2")
        rules.push(mapper![key]);
        rules.push(mapper![key2]);
      }
    }
    setLoading(true);
    GraphicRuleService.updateGraphicRules(accessToken, {
      templateId: 0,
      customerId: 0,
      rules,
    })
      .then((res) => {
        console.log(res?.data.data);
        toast.success("Graphic rules updated.");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setRefetch(true);
      });
  };

  if (refech || loading) {
    return <SpinnerLoader />;
  }

  return (
    <form onSubmit={onSubmit}>
      {colors.graphic.length > 0 && (
        <GraphicRuleTable
          title="Global Graphic Rules"
          body={colors.body}
          graphic={colors.graphic}
          graphicType={"graphic"}
          rules={db}
          hasOwnRules={true}
        />
      )}
      <button className="btn btn-danger" type="submit">
        Submit
      </button>
    </form>
  );
};

export default GlobalGraphicRule;

function generateMapper(data: any) {
  const _mapper: any = {};
  for (let bodyColor of data.body) {
    for (let graphicColor of data.graphic) {
      _mapper[
        `["graphic"]["${bodyColor.colorName}"]["${graphicColor.colorName}"]`
      ] = {
        bodyColor: bodyColor.colorName,
        bodyColorName: bodyColor.colorName,
        bodyColorHex: bodyColor.colorHex,
        graphicColor: graphicColor.colorName,
        graphicColorName: graphicColor.colorName,
        graphicColorHex: graphicColor.colorHex,
        graphicType: "graphic",
        shade: "NA"
      };
    }
    for (let graphicColor of data.graphic) {
      _mapper[
        `["graphic2"]["${bodyColor.colorName}"]["${graphicColor.colorName}"]`
      ] = {
        bodyColor: bodyColor.colorName,
        bodyColorName: bodyColor.colorName,
        bodyColorHex: bodyColor.colorHex,
        graphicColor: graphicColor.colorName,
        graphicColorName: graphicColor.colorName,
        graphicColorHex: graphicColor.colorHex,
        graphicType: "graphic2",
        shade: "NA"
      };
    }
  }
  return _mapper;
}
