import { ChangeEvent, FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { isValidPhoneNumber } from "react-phone-number-input";

import Input from "../../ui/input/Input";
import InputPhone from "../../ui/phone/InputPhone";
import { validateEmail } from "../../utils/helperFunctions";
import { useAppSelector } from "../../store/hooks";
import { getUserDetails } from "../../store/User.slice";
import { updateUserService } from "../../api/userService";
import { AVAILABLE_ROLE, USER_ROLE } from "../../utils/constants";
import SpinnerLoader from "../../ui/loader/SpinnerLoader";
import { IContactDetails } from "api/requestTypes";
import { toast } from "react-toastify";
import Select from "ui/select/Select";

interface IAddUser {
  title?: string;
  open: boolean;
  setOpen: Function;
  updateList?: Function;
  user: IContactDetails;
  setRefetch?: Function;
}

const UpdateUserModal: FC<IAddUser> = ({
  title,
  open,
  setOpen,
  updateList,
  user,
  setRefetch,
}) => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    name: user.name,
    email: user.email,
    role: user.role,
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    role: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
    switch (name) {
      case "name":
        return !value.trim()
          ? setError({ ...error, [name]: "Enter Full Name" })
          : setError({
              ...error,
              [name]: "",
            });
      default:
        break;
    }
  };

  const handleChangeRole = (val: string) => {
    if (!val) {
      setError({ ...error, role: "Must select a role" });
      setInfo({ ...info, role: "" });
    } else if (val) {
      setError({ ...error, role: "" });

      setInfo({
        ...info,
        role: AVAILABLE_ROLE.find((x) => x.title == val)?.shortCode || "MP",
      });
    }
  };
  const isButtonDisabled = () => {
    let data = { ...info };
    //return data.name.trim() && data.email.trim() && validateEmail(data.email.trim()) && data.password.trim().length > 7
    return (
      data.role.trim() &&
      data.name.trim() &&
      data.email.trim() &&
      validateEmail(data.email.trim())
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await updateUserService(info, user.id, accessToken);
      if (data.data) {
        // if(updateList) updateList({...user,name:info.name,phone:info.phone});
        toast.success("User updated Successfully");
        setOpen(false);
        setLoading(false);
        typeof setRefetch === "function" && setRefetch(true);
      }
    } catch (e) {
      toast.error("Unable to update");
      setLoading(false);
    }
  };

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="border-bottom mb-3">
          <p className="mb-0">{title}</p>
        </div>
        <Input
          sectionClass="mb-3"
          value={info.name}
          onChange={handleChange}
          label="Contact Account Name*"
          name="name"
          placeholder="Abc"
          errorMessage={error.name}
        />
        <Input
          sectionClass="mb-3"
          value={info.email}
          onChange={handleChange}
          label="Contact Email*"
          name="email"
          placeholder="email@web.com"
          errorMessage={error.email}
          // disabled={true}
        />
        <Select
          startOption="User Role*"
          onChange={(e) => {
            handleChangeRole(e.target.value);
          }}
          name="role"
          data={AVAILABLE_ROLE.map((x) => x.title)}
          value={AVAILABLE_ROLE.find((x) => x.shortCode == info.role)?.title}
          errorMessage={error.role}
        />

        <div className="mt-3">
          <button
            className="btn btn-danger px-4"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isButtonDisabled()}
          >
            Update
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateUserModal;
