import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { IStyle } from "./requestTypes";
import { client } from "utils/client";

export const addStyleService = (data: IStyle, token: string) => {
  return client.post('/styles/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/styles/save",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchAllStyleService = (token: string) => {
  return client.get('/styles/list')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/styles/list",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const postChangeTemplateOrder = (data: any, token: string) => {
  return client.post('/styles/changeOrder', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/styles/changeOrder`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const updateStyleService = (
  id: string | number,
  data: IStyle, //TODO to include other fields
  token: string
) => {
  return client.put(`/styles/update/${id}`, data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/styles/update/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteStyleService = (id: string | number, token: string) => {
  return client.put(`/styles/remove/${id}`)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/styles/remove/${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const fetchApparelMagicStyles = (
  data: { altCode: string },
  token: string
) => {
  return client.post('/styles/apparel-magic', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/styles/apparel-magic`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
