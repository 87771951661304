import * as React from 'react'
import { getItemInfo, getItemQuantities, getOrderData, getShippingRates, getTemplatePriceSlabs, setStats } from 'store/OrderUpdateData.slice'
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isEmptyObj, fCurrency } from 'utils/helperFunctions';
import { generatePricing } from './UpdateOrder.utils'

interface IProp {}
interface Stats {
  amountSubTotal: number;
  amountDiscount: number;
  amountShipping: number;
  amountTotal: number
}

const Calculation: React.FC<IProp> = (): null => {
  const dispatch = useAppDispatch()
  const orderData = useAppSelector(getOrderData)
  const shippingRates = useAppSelector(getShippingRates)
  const itemInfo = useAppSelector(getItemInfo)
  const templatePriceSlabs = useAppSelector(getTemplatePriceSlabs)
  const itemQuantities = useAppSelector(getItemQuantities)


  const templatePriceGenerator = React.useMemo(() => {
    const _templatePricing = {} as any
    for(let templateId in templatePriceSlabs){
      _templatePricing[templateId] = generatePricing({ dozenWisePrice: templatePriceSlabs[Number(templateId)] })
    }
    return _templatePricing
  }, [templatePriceSlabs])

  React.useEffect(() => {

    function calculate(){
      if(isEmptyObj(orderData) || isEmptyObj(itemQuantities) || isEmptyObj(shippingRates) || isEmptyObj(templatePriceGenerator)){
        return
      }
      const _templatesQty = {} as {[key: number]: number }
      const templatePricing = {} as {[key: number]: { baseRate: number, rate: number,  } }
      for(let itemId in itemQuantities){
        const key = itemInfo[itemId].templateId
        if(typeof _templatesQty[key] === 'undefined')
          _templatesQty[key] = 0
        _templatesQty[key] += itemQuantities[itemId]
      }
      for(let templateId in _templatesQty){
        const qty = _templatesQty[templateId]
        templatePricing[templateId] = templatePriceGenerator[templateId].forQty(qty)
      }
      const poStats = {} as { [key: number]: Stats };
      const itemStats = {} as {[key: number]: { quantity: number, price: number, shipping: number }}
      const orderStats = { amountSubTotal: 0, amountDiscount: 0, amountShipping: 0, amountTotal: 0  } as Stats
      orderData?.purchaseOrders.forEach((po) => {
        if(typeof poStats[po.id] === 'undefined'){
          poStats[po.id] = { amountSubTotal: 0, amountDiscount: 0, amountShipping: 0, amountTotal: 0 }
        }
        po.orderItems.forEach((item) => {
          
          if(!templatePricing[item.templateId]) return

          const shippingRate = shippingRates?.[item.blankGarmentStyleNumber] ?? 0
          const retailPrice = fCurrency(templatePricing[item.templateId].baseRate * itemQuantities[item.id])
          const discountedPrice = fCurrency(templatePricing[item.templateId].rate * itemQuantities[item.id])
          const shipping = fCurrency(shippingRate * itemQuantities[item.id])
          const discount = fCurrency(retailPrice - discountedPrice)
          
          itemStats[item.id] = { quantity: itemQuantities[item.id], price: discountedPrice, shipping: shipping }
  
          poStats[po.id].amountSubTotal = fCurrency(poStats[po.id].amountSubTotal + discountedPrice)
          poStats[po.id].amountDiscount = fCurrency(poStats[po.id].amountDiscount + discount)
          poStats[po.id].amountShipping = fCurrency(poStats[po.id].amountShipping + shipping)
          poStats[po.id].amountTotal = fCurrency(poStats[po.id].amountTotal + discountedPrice + shipping)
        })
  
        
        orderStats.amountSubTotal = fCurrency(orderStats.amountSubTotal + poStats[po.id].amountSubTotal)
        orderStats.amountDiscount = fCurrency(orderStats.amountDiscount + poStats[po.id].amountDiscount)
        orderStats.amountShipping = fCurrency(orderStats.amountShipping + poStats[po.id].amountShipping)
        orderStats.amountTotal = fCurrency(orderStats.amountTotal + poStats[po.id].amountTotal)
      })
      dispatch(setStats({ itemStats, poStats, orderStats }))
    }
    calculate()
  }, [orderData, itemQuantities, shippingRates, templatePriceGenerator])

  return null
}

export default Calculation