import axios from 'axios';
import { BASE_URL } from './apiConstants';
import {
  ICreateCustomerAccountPayload,
} from './requestTypes';
import { client } from 'utils/client';



export const createCustomerContactService = (
  data: ICreateCustomerAccountPayload,
  token: string
) => {
  return client.post('/childAccount/save', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/childAccount/save',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchAllCustomerContactService = (token: string) => {
  return client.get('/childAccount/list')
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/childAccount/list',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const fetchCustomerContactDetailsService = (
  token: string,
  id: string | number
) => {
  return client.get(`/childAccount/child-by-id/${id}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + `/childAccount/child-by-id/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const updateCustomerContactService = (
  id: string | number,
  data: any,//TODO to include other fields
  token: string
) => {
  return client.put(`/childAccount/update/${id}`, data)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/childAccount/update/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteCustomerContactService = (
  id: string | number,
  token: string
) => {
  return client.put(`/childAccount/remove/${id}`)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/childAccount/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const uploadCustomerContactLogosService = (
  data: FormData,
  token: string
) => {
  return client.post(`/childAccount/aws`, data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + `/childAccount/aws`,
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //     Authorization:token
  //   },
  //   data
  // });
};

export const updateLogoActiveAndDefault = (
  token: string,
  data: any
) => {
  return client.put(`/childAccount/updateLogoActiveAndDefault`, data)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/childAccount/updateLogoActiveAndDefault`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization:token
  //   },
  //   data
  // });
}

export const assignTemplateToCustomer = (
  token: string,
  childId: any,
  data: any
) => {
  return client.post(`/childAccount/${childId}/assign-templates`, data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + `/childAccount/${childId}/assign-templates`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization:token
  //   },
  //   data
  // });
};
