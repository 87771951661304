/* eslint-disable */
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getColors,
  getColor,
  deleteColor,
  updateColor,
  addColor,
  getGroupItems,
  moveColorToGroup,
  getGroups,
  getGroup,
  deleteGroup,
  updateGroup,
  addGroup,
} from "api/graphicColorService";

/* Graphic Color  */

export const useGraphicColorQuery = (id: number) => {
  return useQuery({
    queryKey: ["graphic-colors", id],
    queryFn: () => getColor(id),
    enabled: id !== null,
  });
};

export const useGraphicColorsQuery = () => {
  return useQuery({
    queryKey: ["graphic-colors"],
    queryFn: getColors,
  });
};

export const useGraphicColorCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addColor,
    // Always refetch after error or success:
    onSettled: (payload, error, variables, context) => {
      if (error) {
        toast.error(error.message || "Failed To create graphic color");
      } else {
        toast.success("Graphic color created");
      }
      queryClient.invalidateQueries({
        queryKey: ["graphic-colors"],
      });
    },
  });
};

export const useGraphicColorUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateColor,
    // Always refetch after error or success:
    onSettled: (payload, error, variables, context) => {
      if (error) {
        toast.error(error.message || "Failed To update graphic color");
      } else {
        toast.success("Graphic color updated");
      }
      queryClient.invalidateQueries({
        queryKey: ["graphic-colors"],
      });
      queryClient.invalidateQueries({
        queryKey: ["graphic-colors-groups-items"],
      });
    },
  });
};

export const useGraphicColorDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteColor,
    // Always refetch after error or success:
    onSettled: (payload, error, variables, context) => {
      if (error) {
        toast.error(error.message || "Failed To delete graphic color");
      } else {
        toast.success("Graphic color deleted");
      }
      queryClient.invalidateQueries({
        queryKey: ["graphic-colors"],
      });
      queryClient.invalidateQueries({
        queryKey: ["graphic-colors-groups-items"],
      });
    },
  });
};


export const useColorGroupQuery = (id: number) => {
    return useQuery({
      queryKey: ["graphic-colors-groups", id],
      queryFn: () => getGroup(id),
      enabled: id !== null,
    });
  };
  
  export const useColorGroupsQuery = () => {
    return useQuery({
      queryKey: ["graphic-colors-groups"],
      queryFn: getGroups,
    });
  };

    
  export const useColorGroupCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: addGroup,
      // Always refetch after error or success:
      onSettled: (payload, error, variables, context) => {
        if (error) {
          toast.error(error.message || "Failed To create color group");
        } else {
          toast.success("Color group created");
        }
        queryClient.invalidateQueries({
          queryKey: ["graphic-colors-groups"],
        });
      },
    });
  };

  export const useColorGroupUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: updateGroup,
      // Always refetch after error or success:
      onSettled: (payload, error, variables, context) => {
        if (error) {
          toast.error(error.message || "Failed To update color group");
        } else {
          toast.success("Color group updated");
        }
        queryClient.invalidateQueries({
          queryKey: ["graphic-colors-groups"],
        });
      },
    });
  };

  export const useColorGroupDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: deleteGroup,
      // Always refetch after error or success:
      onSettled: (payload, error, variables, context) => {
        if (error) {
          toast.error(error.message || "Failed To delete color group");
        } else {
          toast.success("color group deleted");
        }
        queryClient.invalidateQueries({
          queryKey: ["graphic-colors-groups"],
        });
        queryClient.invalidateQueries({
          queryKey: ["graphic-colors-groups-items"],
        });
      },
    });
  };


/* Graphic Color Items  */
export const useGraphicColorGroupItemsQuery = () => {
  return useQuery({
    queryKey: ["graphic-colors-groups-items"],
    queryFn: getGroupItems,
  });
};

export const useGraphicColorMoveToGroup = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: moveColorToGroup,
        // Always refetch after error or success:
        onSettled: (payload, error, variables, context) => {
          if (error) {
            toast.error(error.message || "Failed To move graphic color");
          } else {
            toast.success("Graphic color successfully moved");
          }
          queryClient.invalidateQueries({
            queryKey: ["graphic-colors"],
          });
          queryClient.invalidateQueries({
            queryKey: ["graphic-colors-groups-items"],
          });
        },
    });
  };
