import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountInfo, AcountState } from 'types/AccountsSlice';
import { RootState } from './store';

const initialAccountDetails = {
  name: '',
  phone: '',
  email: '',
  role:'',
};

const initialState: AcountState = {
  account: {
    accountInfo: { name: '' },
    buyerAdminInfo: initialAccountDetails,
    contactPersons: [],
    salesPersons: [],
    customerInfo: { info: initialAccountDetails, otherDetails: {} },
  },
};

const AccountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    clearBuyerAdminAccountDetails: (state) => {
      state.account = initialState.account;
    },
    setPrimaryAccountName: (
      state: AcountState,
      { payload }: PayloadAction<string>
    ) => {
      state.account.accountInfo.name = payload;
    },
    setBuyerAdminInfo: (
      state,
      { payload }: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = payload;
      state.account.buyerAdminInfo = {
        ...state.account.buyerAdminInfo,
        [name]: value,
      };
    },

    setCustomerInfo: (
      state,
      { payload }: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = payload;
      state.account.customerInfo.info = {
        ...state.account.customerInfo.info,
        [name]: value,
      };
    },

    addBuyer: (state) => {
      state.account.contactPersons.push(initialAccountDetails);
    },
    deleteBuyer: (state, { payload }: PayloadAction<number>) => {
      state.account.contactPersons.splice(payload, 1);
    },
    addSalesPerson: (state) => {
      state.account.salesPersons.push(initialAccountDetails);
    },
    deleteSalesPerson: (state, { payload }: PayloadAction<number>) => {
      state.account.salesPersons.splice(payload, 1);
    },
    setContactPersonsInfo: (state, { payload }: PayloadAction<AccountInfo>) => {
      const index = state.account.contactPersons.length - 1;
      state.account.contactPersons[index] = {
        ...state.account.contactPersons[index],
        ...payload,
      };
    },
    setSalesPersonsInfo: (state, { payload }: PayloadAction<AccountInfo>) => {
      const index = state.account.salesPersons.length - 1;
      state.account.salesPersons[index] = {
        ...state.account.salesPersons[index],
        ...payload,
      };
    },
    removeContactPerson: (state, { payload }: PayloadAction<string>) => {
      const updatedList = state.account.contactPersons.filter(
        (person) => person.email !== payload
      );
      state.account.contactPersons = updatedList;
    },
    removeSalesPerson: (state, { payload }: PayloadAction<string>) => {
      const updatedList = state.account.salesPersons.filter(
        (person) => person.email !== payload
      );
      state.account.salesPersons = updatedList;
    },
  },
});

// Actions
export const {
  clearBuyerAdminAccountDetails,
  setPrimaryAccountName,
  setBuyerAdminInfo,
  setCustomerInfo,
  addBuyer,
  deleteBuyer,
  addSalesPerson,
  deleteSalesPerson,
  setContactPersonsInfo,
  setSalesPersonsInfo,
  removeContactPerson,
  removeSalesPerson,
} = AccountsSlice.actions;

// Selectors
export const getPrimaryAccountDetails = (state: RootState) =>
  state.acounts.account;
export const getcustomerDetails = (state: RootState) =>
  state.acounts.account.customerInfo;
export const getSalespersonsList = (state: RootState) =>
  state.acounts.account.salesPersons;

export default AccountsSlice.reducer;
