import React, { ChangeEvent,useState } from 'react';
import Input from 'ui/input/Input';
import { AccountInfo } from 'types/AccountsSlice';
import { validateEmail} from 'utils/helperFunctions';
import 'react-phone-number-input/style.css';
import {
  isValidPhoneNumber,
} from 'react-phone-number-input';
import InputPhone from 'ui/phone/InputPhone';
import {Modal} from "react-bootstrap";
import { toast } from 'react-toastify';
interface IModal {
  title?: string;
  onSave: (data: AccountInfo) => void;
  open: boolean;
  setOpen: Function;
}

const AddContactModal: React.FC<IModal> = ({ title, onSave, open, setOpen }) => {
  const [info, setInfo] = useState({
    name: '',
    email: '',
    phone: '',
    role:'',
  });
  const [error, setError] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const handleInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errors = {...error};
    switch (name) {
      case 'name':
        errors.name = value.length < 1 ? 'Name can not be empty' : '';
        break;
      case 'email':
        errors.email= !validateEmail(value)? 'Please enter valid email' : '';
        break;
      default:
        break;
    }
    setError(errors);
    setInfo({...info,[name]: value});
  };
  const handlePhoneInfo=(val:any)=>{
    if(val && !isValidPhoneNumber(val))
      setError({...error,phone:"Invalid Phone Number"});
    else{
      setError({...error,phone:''})
      setInfo({...info,phone:val});
    }
  }
  const handleSubmit = ()=>{
    if(info.email && info.name && info.phone)
    {
      let valid = true;
      Object.values(error).forEach(val => val.length > 0 && (valid = false));
      if(valid)
      onSave(info);
    }
    else {
      toast.error('Please fill all required fields');
    }
    return;
    
  }
  
  return (
    <div >
      <Modal
          className="custom-drawer date-picker-modal"
          show={open}
          onHide={() => setOpen(false)}
          aria-labelledby="custom-modal"
          backdrop="static"
          size={"lg"}
      >
        <Modal.Body>
          <div className="border-bottom mb-3">
            <p className="mb-0">{title}</p>
          </div>
          <Input
              sectionClass="mb-3"
              value={info.name}
              onChange={handleInfo}
              label="Contact Account Name*"
              name="name"
              placeholder="Abc"
              errorMessage={error.name}
          />
          <Input
              sectionClass="mb-3"
              value={info.email}
              onChange={handleInfo}
              label="Contact Email*"
              name="email"
              placeholder="email@web.com"
              errorMessage={error.email}
          />
          <InputPhone
              sectionClass="mb-3"
              label={"Input Phone Number*"}
              value={info.phone}
              onChange={handlePhoneInfo}
              errorMessage={error.phone}
          />
          <div className="mt-3">
            <button  className="btn btn-danger px-4" onClick={() => {
              handleSubmit();
            }}>
              Save
            </button>
            <button className="btn btn-outline-danger px-4 ms-3" onClick={() => setOpen(false)}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddContactModal;
