import * as React from "react";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { niceBytes } from "utils/helperFunctions";
import UploadFileList from "./UploadFileList";
import UploadFiles from "./UploadFiles";
import * as svgTemplateService from "api/svgTemplateService";

import { uploadFileToPreSignedUrlWithProgress } from "api/utilService";
import clsx from "clsx";

interface Props {
  show: boolean;
  onHide: () => void;
}

type FileInfo = {
  id?: string; name: string; size: string; type: string, status: string, isThumbnail: boolean
}
export interface FormValues {
  progress: number;
  currentFile: string;
  isFilesUploaded: boolean;
  graphicName: string;
  graphicThumbnail: string;
  files: FileInfo[];

}

const schema = Yup.object({
  graphicName: Yup.string().required(),
  graphicThumbnail: Yup.string().required(),
  files: Yup.array().of(Yup.object({
    id: Yup.string(),
    name: Yup.string(),
    size: Yup.string(),
    status: Yup.string().oneOf(["valid"]),
  })).required().min(1, 'Must have at least one graphic art')
})


const GraphicTemplateFilesUpload: React.FC<Props> = ({ show, onHide }): JSX.Element => {

  const [files, setFiles] = React.useState<File[] | null>(null)

  const methods = useForm<FormValues>({
    defaultValues: {
      isFilesUploaded: false,
      graphicName: '',
      graphicThumbnail: '',
      progress: 0,
      currentFile: '',
      files: []
    }
  })

  const isFilesUploaded = methods.watch("isFilesUploaded")
  const isSubmitting = methods.formState.isSubmitting


  React.useEffect(() => {
    if(!show){
      methods.reset()
      setFiles(null)
    }
  }, [show])

  React.useEffect(() => {
    if (!!files?.length) {
      const __files = [] as FileInfo[];
      let graphicName = '', graphicThumbnail = ''
      for (let i = 0; i < files.length; i++) {

        const file = files[i];
        let fileStatus = "invalid", isThumbnail = false
        const graphicThumbnailMatch = file.name.match(/^(\w+).png$/)
        const graphicArtMatch = file.name.match(/^(\w+)-\w+(\d+)?\.svg$/)
  
        if(graphicArtMatch){
          if(!graphicName) graphicName = graphicArtMatch[1]
          if(graphicName === graphicArtMatch[1]) fileStatus = "valid"
        }
        else if(graphicThumbnailMatch){
          if(!graphicName) graphicName = graphicThumbnailMatch[1]
          if(graphicName === graphicThumbnailMatch[1]) fileStatus = "valid"
          graphicThumbnail = file.name
          isThumbnail = true
        }

        __files.push({
          name: file.name,
          size: niceBytes(file.size),
          type: file.type,
          status: fileStatus,
          isThumbnail
        });
    
      }
      const __values = { graphicName, graphicThumbnail } as FormValues;
      methods.reset(__values);
      methods.setValue('files', __files);
    }
  }, [files]);

  const handleUpload = async (values: FormValues) => {
    const onUploadProgress = (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      methods.setValue('progress', percentCompleted, { shouldDirty: true })
    }
    try{
      await schema.validate(values)   
      const nameToFileMap: Record<string, File> = values.files.reduce((acc, curr, idx) => {
        acc[curr.name] = (files as File[])[idx]
        return acc
      }, {} as Record<string, File>)

      const params = { 
        graphicName: values.graphicName,
        graphicThumbnail: values.graphicThumbnail,
        arts: values.files.filter(({ isThumbnail }) => !isThumbnail).map(({ name }) => name)
      }
    
      const urlInfoObj = await svgTemplateService.getGraphicPreSignedUrls(params)
      const payload = { graphicName: values.graphicName, arts: [] } as any
      for (const fileName in urlInfoObj) {
        const info = urlInfoObj[fileName]
        methods.setValue('currentFile', fileName)
        await uploadFileToPreSignedUrlWithProgress(info.preSignedUrl, nameToFileMap[fileName], onUploadProgress)
        methods.resetField('progress')
        methods.resetField('currentFile')
        if(fileName === values.graphicThumbnail){
          payload.graphicThumbnail = info.objectUrl
        }else{
          const name = fileName.split(".")[0]
          payload.arts.push({ name, url: info.objectUrl })
        }
      }
      console.log(payload)
      await svgTemplateService.uploadGraphicTemplateFiles(payload)
      methods.setValue('isFilesUploaded', true)
    }catch(err){
      console.error(err)
      if(err instanceof Yup.ValidationError){
        const match = err.message.match(/^files\[\d+\]\.status/)
        if(match){
          methods.setError('root', { type: 'custom', message: 'Please follow correct file naming and use correct file extension' })
          return
        }
      }
      methods.setError('root', { type: 'custom', message: 'Something is not correct with these files' })
      return
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleUpload)}>
      <Modal.Header>
        <Modal.Title>Upload Graphic Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {files?.length && <UploadFileList /> }
        <UploadFiles files={files} setFiles={setFiles} />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-outline-danger" onClick={onHide} disabled={isSubmitting}>
          {isFilesUploaded ? "Done" : "Cancel"}
        </button>
        <button type="submit" className={clsx("btn btn-danger", { "d-none": isFilesUploaded })} disabled={isSubmitting}>
          {isSubmitting ? "Uploading..." : "Upload"}
        </button>
      </Modal.Footer>
      </form>
      </FormProvider>
    </Modal>
  );
};

export default GraphicTemplateFilesUpload;
