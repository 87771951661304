import * as Yup from "yup";
import * as React from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import * as utilService from "api/utilService";
import { AltStyle, FormValues } from "./interface";
import * as inventoryService from "api/inventoryService";
import { RHFFileUpload, RHFRichTextEditor } from "components/form-hook";
import { yupResolver } from "@hookform/resolvers/yup";

interface IProp {
  altStyle: AltStyle;
  handleClose: () => void;
  handleSuccess: () => void;
}

const schema = Yup.object({
  image: Yup.string().required("Image is required"),
  detail: Yup.string().required("Description is required."),
});

const AddUpdateAltStyleDetail: React.FC<IProp> = (props): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false); 
  const methods = useForm<FormValues>({
    defaultValues: {
      image: "",
      detail: "",
    },
  });

  const isSubmitting = methods.formState.isSubmitting;

  const handleSubmit = async (values: FormValues) => {
    try {
      await schema.validate(values);
      const parsedUrl = new URL(values.image);
      const payload = {
        altStyleId: props.altStyle.id,
        altStyle: props.altStyle.altCode,
        detail: values.detail,
      } as { detail: string; image?: string };
      if (parsedUrl?.protocol === "blob:") {
        const blob = await fetch(parsedUrl.href).then((r) => r.blob());
        const params = {
          contentType: blob.type,
          resourceType: "ALT_STYLE_DETAIL_IMAGE",
          altStyleNumber: props.altStyle.altCode,
          extension: values.extension,
        };
        const urls = (await utilService.getPreSignedUrl(params).catch((err) => {
          toast.error(err.message);
          throw err;
        })) as unknown as {
          preSignedUrl: string;
          objectUrl: string;
        };
        await utilService.uploadFileToPreSignedUrl(urls.preSignedUrl, blob);
        payload.image = urls.objectUrl;
      }
      await inventoryService.updateAltStyleDetail(payload);
      toast.success("Saved successfully");
      props.handleSuccess();
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    async function populateForm(){
      if(!props.altStyle?.id) return
      try{
        setIsLoading(true)
        const data = await inventoryService.getAltStyleDetail(props.altStyle.id)
        methods.reset({ image: data.image, detail: data.detail })
        setIsLoading(false)
      }catch(err){
        console.error(err)
        setIsLoading(false)
      }
    }
    populateForm();
  }, [props.altStyle.id]);

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={true}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
      onHide={props.handleClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Modal.Body>
            <div className="border-bottom mb-3">
              <p className="mb-0">
                {props.altStyle.altCode} - {props.altStyle.name}
              </p>
            </div>
            <RHFFileUpload
              name="image"
              label="Image"
              placeholder="Upload Image File"
            />
            <RHFRichTextEditor name="detail" label="description" />
            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-danger px-4"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </button>
              <button
                className="btn btn-outline-danger px-4 ms-3"
                disabled={isSubmitting || isLoading}
                onClick={props.handleClose}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddUpdateAltStyleDetail;
