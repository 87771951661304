import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { forgotPasswordService } from 'api/userService';
import Input from 'ui/input/Input';
import styles from '../login/LoginPage.module.scss';
import Logo from "../../assets/images/logo.svg";
import StandardRecLogo from "../../assets/images/standardRecLogo.svg";
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submit, setSubmit] = useState(false);
  const [valid, setValid] = useState(false);

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.trim());
  };

  const handleSetPassword = (e: FormEvent) => {
    e.preventDefault();
    setSubmit(true);
  };

  useEffect(() => {
    const handleForgotPassword = async () => {
      try {
        const data = await forgotPasswordService({ email });
        if (data.status === 200 && data.data) {
          alert(`${data.data.data}`);
        }
      } catch (error) {
        console.error(error);
        alert(error);
      }
    };

    if (submit) {
      handleForgotPassword();
      setEmail('');
      setSubmit(false);
    }
  }, [email, submit]);

  return (
      <div className={styles.loginWrap}>
        <div className="container h-100 d-flex align-items-center justify-content-center">
          <form className={styles.loginForm} onSubmit={handleSetPassword}>
            <div className="text-center mb-3">
              <img className={`img-fluid ${styles.logo}`} src={StandardRecLogo} alt="logo"/>
            </div>
            <div className={`card ${styles.formCard}`}>
              <div className={`card-body`}>
                <h3 className="heading3 mb-0 font-weight-normal">Can’t log in?</h3>
                <p>We’ll send a recovery link to</p>
                <div className="mb-3">
                  <Input
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Enter email"
                      onChange={handleEmail}
                  />
                  { false && <p className="text-danger mt-1 font-size-xs mb-0">Please enter a valid email address</p>}
                </div>
                <button type="submit" className="btn btn-danger w-100" disabled={valid}>
                  Send Link
                </button>
                <div className="text-center pt-1">
                  <Link className="btn btn-link text-danger mt-3" to="/login">Return to login</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  );
};

export default ForgotPassword;
