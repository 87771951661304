import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as GraphicRuleService from "../../api/graphicRule";
import Input from "ui/input/Input";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";

type ModalAction = 'Add' | 'Update'

const AddGraphicsRuleModal = (props: any) => {
  const modalAction: ModalAction = props.modalAction
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({ colorName: "", colorHex: "", category: props.colorCategory });

  const handleColorNameChange = (e: any) =>
    setFields((prevState) => ({
      ...prevState,
      colorName: e.target.value,
      name: e.target.value,
    }));
  const handleColorHexChange = (e: any) => {
    if (!/^[A-Fa-f0-9]*$/.test(e.target.value)) return false
    if(e.target.value.length > 6) return false
    setFields((prevState) => {
      return {
        ...prevState,
        colorHex: e.target.value.toUpperCase(),
      }
    });
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!fields.colorName || !fields.colorHex || !fields.category)
        throw new Error("All fields are required.");
      setLoading(true);
      if(modalAction === 'Add'){
        await GraphicRuleService.createColorService(fields, accessToken);
        toast.success("Colour added successfully.");
      }else if(modalAction === 'Update'){
        await GraphicRuleService.updateColorsService(accessToken, props.selColor?.id, fields);
        toast.success("Colour updated successfully.");
      }
      props.handleSuccess();
    } catch (err: any) {
      toast.error(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(modalAction === 'Update'){
      setFields((prev: any) => ({
        ...prev,
        colorName: props.selColor?.colorName || '',
        colorHex: props.selColor?.colorHex || '',
        name: props.selColor?.name || ''
      }))
    }
  }, [])

  return (
    <>
      <Modal
        show
        onHide={props.onHide}
        className="custom-drawer date-picker-modal"
        aria-labelledby="custom-modal"
        backdrop="static"
        size={"lg"}
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="border-bottom pb-1 mb-3">
              <h4 className="mb-0 heading4-bold">{modalAction} Color</h4>
            </div>
            <div className="row g-3 align-items-center mb-3">
              <div className="col-md-3">
                <label className="text-capitalize">Color Name</label>
              </div>
              <div className="col-md-9">
                <Input
                  name="colorName"
                  value={fields.colorName}
                  onChange={handleColorNameChange}
                  placeholder="Enter Color Name"
                />
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3">
              <div className="col-md-3">
                <label className="text-capitalize">Color Hex</label>
              </div>
              <div className="col-md-9">
                <Input
                  name="colorHex"
                  value={fields.colorHex}
                  onChange={handleColorHexChange}
                  placeholder="Enter Color Hex Code"
                />
              </div>
            </div>

            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-danger px-4"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger px-4 ms-3"
                onClick={props.onHide}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddGraphicsRuleModal;
