import React, { useEffect, useState } from "react";

interface iBody {
  name: string;
  category: "body";
  shade: "dk" | "lt" | "NA";
}
interface iGraphic {
  name: string;
  category: "graphic" | "graphic2";
  shade: "dk" | "lt" | "NA";
}

interface iDb {
  [key: string]: boolean;
}

interface Props {
  body: iBody[];
  graphic: iGraphic[];
  graphicType: "graphic" | "graphic2";
  title: string;
  rules: iDb | null;
  parentRules?: iDb | null;
  hasOwnRules?: boolean;
  mapper?: { [key: string]: any }
}

type FieldMap = { rows: { [key: string]: string[] }, columns: { [key: string]: string[] }, fields: { [key: string]: any } }

const GraphicRuleTable: React.FC<Props> = ({
  title,
  body,
  graphic,
  graphicType,
  rules,
  parentRules,
  hasOwnRules,
  mapper
}): JSX.Element => {

  const [values, setValues] = useState<any>({})
  const [fieldMap, setFieldMap] = useState<FieldMap>({ rows: {}, columns: {}, fields: {} })

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const checked = e.target.checked
    const list = fieldMap.rows[name] || fieldMap.columns[name] || null
    const _values = {...values} as any
    _values[name] = checked
    if(list){
      list.forEach((item) => _values[item] = checked)
    }else{
      const rowName = fieldMap.fields[name].rowName
      const colName = fieldMap.fields[name].colName
      if(checked){
          let rowItems = fieldMap.rows[rowName]
          _values[rowName] = rowItems.every((item) => _values[item])
          let colItems = fieldMap.columns[colName]
          _values[colName] = colItems.every((item) => _values[item])
      }else{
        _values[rowName] = checked
        _values[colName] = checked
      }
    }
    setValues((prev: any) => ({...prev, ..._values}))
  }

  useEffect(() => {
    function genFieldMap(bodyColors: any, graphicColors: any) {
      const state = { rows: {}, columns: {}, fields: {} } as FieldMap
      const _values = {} as any
      graphicColors.forEach((graphicColor: any) => {
        const rowName = `r_${graphicColor.colorName}` as string
        if (!state.rows[rowName]) state.rows[rowName] = []
        bodyColors.forEach((bodyColor: any) => {
          const colName = `c_${bodyColor.colorName}` as string
          if (!state.columns[colName]) state.columns[colName] = []
          const name = `["${graphicType}"]["${bodyColor.colorName}"]["${graphicColor.colorName}"]`
          if(!mapper?.[name]?.disabled){
            state.rows[rowName].push(name)
            state.columns[colName].push(name)
            state.fields[name] = { rowName, colName }
          }
          _values[name] = mapper?.[name].disabled ? false : (hasOwnRules ? !!rules![name] : !!parentRules?.[name])
        })
      })
      setValues(_values)
      setFieldMap(state)
    }
    genFieldMap(body, graphic)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, parentRules, mapper])

  return (
    <>
      <div className="graphics-rule-box">
        <h5>{title}</h5>
        <div className="graphics-scroll">
          <table border={1}>
            <thead>
              <tr>
                <th colSpan={body.length + 1}>Body Colors</th>
              </tr>
              <tr>
                <th colSpan={1}>Graphic Colors</th>
                {body?.map((bodyColor: any) => (
                  <th key={bodyColor.id}>
                    <div className="form-check-wrap">
                      <input
                        type="checkbox"
                        name={`c_${bodyColor.colorName}`}
                        id={"title" + bodyColor.colorName}
                        onChange={handleCheckbox}
                        checked={values[`c_${bodyColor.colorName}`]}
                      />
                      <label htmlFor={"title" + bodyColor.colorName}>
                        {bodyColor.colorName}
                      </label>
                    </div>
                    {/* {bodyColor.colorName} */}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {graphic?.map((graphicColor: any) => (
                <tr key={graphicColor.id}>
                  <th>
                    <div className="form-check-wrap">
                      <input
                        type="checkbox"
                        name={`r_${graphicColor.colorName}`}
                        id={"title" + graphicColor.colorName}
                        onChange={handleCheckbox}
                        checked={values[`r_${graphicColor.colorName}`]}
                      />
                      <label htmlFor={"title" + graphicColor.colorName}>
                        {graphicColor.colorName}
                      </label>
                    </div>
                    {/* {graphicColor.colorName} */}
                  </th>
                  {body?.map((bodyColor: any) => {
                    const name = `["${graphicType}"]["${bodyColor.colorName}"]["${graphicColor.colorName}"]`;
                    return (
                      <td
                        className={`${bodyColor.colorHex} ${bodyColor.name} ${graphicColor.colorHex} ${mapper?.[name].disabled ? "disabled-rule" : ""}`}
                        key={`${bodyColor.id}_${graphicColor.id}`}
                        style={{
                          textAlign: "center",
                          backgroundColor: `#${bodyColor.colorHex}d9`,
                        }}
                      >
                        <div className="form-check-wrap">
                          <input
                            type="checkbox"
                            id={graphicColor.actual_name}
                            name={name}
                            // defaultChecked={
                            //   mapper?.[name].disabled ? false : (hasOwnRules ? rules![name] : parentRules?.[name])
                            // }
                            onChange={handleCheckbox}
                            checked={values[name]}
                            // disabled={mapper?.[name].disabled}
                          />
                          <label
                            htmlFor={name}
                            style={{ color: `#${graphicColor.colorHex}` }}
                          >
                            {mapper?.[name].graphicColor || graphicColor.actual_name}
                          </label>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GraphicRuleTable;
