import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const getSettings = (token: string) => {
  return client.get('/settings')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/settings`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const updateSettings = (
  data: { paymentMode: string },
  token: string
) => {
  return client.put('/settings', data)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + "/settings",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};
