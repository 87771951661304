import { useNavigate } from 'react-router-dom';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import './CreateAccountPage.css';
import Input from 'ui/input/Input';
import Button from 'ui/button/Button';
import { getUserDetails } from 'store/User.slice';
import { AccountInfo } from 'types/AccountsSlice';
import { IContactDetails } from 'api/requestTypes';
import AddContactModal from 'components/modal/Modal';
import AddSection from 'components/addSection/AddSection';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import ContactForm from 'components/contactForm/ContactForm';
import {
  createBuyerAdminService,
  updateBuyerAdminService,
  fetchContactListService,
} from 'api/parentService';
import {
  getPrimaryAccountDetails,
  setPrimaryAccountName,
  addBuyer,
  setContactPersonsInfo,
  addSalesPerson,
  setSalesPersonsInfo,
  setBuyerAdminInfo,
  clearBuyerAdminAccountDetails,
  removeContactPerson,
} from 'store/Accounts.slice';
import { isEmailAlreadyExist } from 'utils/helperFunctions';
import { USER_ROLE } from 'utils/constants';
import { IParentAccountDetails } from 'types/components/AccountDetails';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import { toast } from 'react-toastify';

export interface CreateAccountPageProps {
  isUpdate?: boolean;
  initialInfo?: IParentAccountDetails;
}

const AccountForm: React.FC<CreateAccountPageProps> = ({ isUpdate }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [contactPersonsSelected, setContacPersonsSelected] = useState<IContactDetails[]>(
    []
  );
  const [salesPersonsSelected, setSalesPersonsSelected] = useState<IContactDetails[]>([]);
  const [salesPersons,setSalesPersons] = useState<IContactDetails[]>([]);
  const [showContactAccountModal, setShowContactAccountModal] = useState(false);
  const [showNewSalesPersonModal, setShowNewSalesPersonModal] = useState(false);
  const { id } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const { accountInfo, buyerAdminInfo, contactPersons} =
    useAppSelector(getPrimaryAccountDetails);
  const { accessToken } = useAppSelector(getUserDetails);
  const [submit, setSubmit] = useState(false);

  const handeleForm = (e: FormEvent) => {
    e.preventDefault();
  };
  
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    dispatch(setPrimaryAccountName(value));
  };

  const handleBuyerAdminInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    dispatch(setBuyerAdminInfo({ name, value }));
  };

  const handleContactPerson = async (
    datas: AccountInfo,
    isNew: boolean = false
  ) => {
    if (isNew) {
        setLoading(true);
        const { email } = datas;
        const isExist  = await isEmailAlreadyExist(accessToken,email);
        if(isExist){
          setLoading(false);
          alert(
            `User with ${email} email id is already existed. Please use different email`
          );
        }
       else{
          dispatch(addBuyer());
          dispatch(
            setContactPersonsInfo({
              ...datas,
              role: USER_ROLE.CONTACT_PERSON,
            })
          );
          setLoading(false);
          setShowContactAccountModal(false);
        }
        
      

      // const emailList = contactPersonsList.map((item) => item.email);
      // if (!isAlreadyExisted(data.email, emailList)) {
      //   dispatch(addBuyer());
      //   dispatch(setContactPersonsInfo({...data,role:USER_ROLE.CONTACT_PERSON}));
      //   setShowContactAccountModal(false);
      // } else {
      //   alert(`Contact person with ${data.email} is already existed`);
      // }
    } else {
      dispatch(addBuyer());
      dispatch(
        setContactPersonsInfo({ ...datas, role: USER_ROLE.CONTACT_PERSON })
      );
      setShowContactAccountModal(false);
    }
  };

  const handleAddSalesPerson = async (
    datas: AccountInfo,
    isNew: boolean = false
  ) => {
    if (isNew) {
      
        //setLoading(true);
        const { email } = datas;
        const isExist = await isEmailAlreadyExist(accessToken,email);
        if (isExist) {
          
            alert(
              `User with ${email} email id is already existed. Please use different email`
            );
            setLoading(false);
        } else {
            setLoading(false);
            dispatch(addSalesPerson());
            dispatch(
              setSalesPersonsInfo({ ...datas, role: USER_ROLE.SALES_PERSON })
            );
            setShowNewSalesPersonModal(false);
        }
    } else {
      dispatch(addSalesPerson());
      dispatch(setSalesPersonsInfo({ ...datas, role: USER_ROLE.SALES_PERSON }));
      setShowNewSalesPersonModal(false);
    }
  };

  const removeSales=(id:any)=>{
    const removed = salesPersonsSelected.filter((obj) => {
        return obj.id == id;
      });
      const result = salesPersonsSelected.filter((obj) => {
        return obj.id != id;
      });
      setSalesPersonsSelected(result);
      setSalesPersons([...salesPersons, ...removed]);
  }

  const validateFormFields = () => {
    if (accountInfo.name.trim())
      return true;
    else return false;
  };
  const handleSave = async () => {
    if (!validateFormFields()) {
      toast.warn('please fill all required fiedls');
      return;
    }
    setSubmit(true);
  };
  const handleUpdate = async () => {
    if (id) {
      setSubmit(true);
    }
  };
  const handleAddContact = () => {
    setShowContactAccountModal(true);
  };


  const handleRemoveContactPerson = (email: string, New: boolean) => {
    if (New) dispatch(removeContactPerson(email));
    else {
      const newList = contactPersonsSelected.filter((c) => c.email !== email);
      setContacPersonsSelected(newList);
    }
  };

  
  const handleChangeSales = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = salesPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setSalesPersonsSelected([...salesPersonsSelected, ...res]);
    const result = salesPersons.filter((obj) => {
      return obj.id != e.target.value;
    });

    setSalesPersons(result);
  }

  useEffect(() => {
    setLoading(true);
    const getContactAndSalesList = async () => {
      try {
        const { data } = await fetchContactListService(accessToken);
        
        if (data.data) {
          const { contactPersons, salesPersons } = data.data;
          //setContacPersontList(contactPersons);
          setSalesPersons(salesPersons);
          setLoading(false);
        }
      } catch (error) {
        toast.warn('Could notfetch the contact and sales persons list');
        setLoading(false);
      }
    };
    getContactAndSalesList();
  }, [accessToken]);

  
  useEffect(() => {
    const createParentAccount = async () => {
      setLoading(true);
      const selectedCP:AccountInfo[]=contactPersonsSelected.map(({name, email,phone,role}) => ({name,email,phone,role}));
      const selectedSP:AccountInfo[]=salesPersonsSelected.map(({name, email,phone,role}) => ({name,email,phone,role}));
      const payload = {
        accountInfo:{
          accountName:accountInfo.name,
          contactName:buyerAdminInfo.name || '',
          contactEmail:buyerAdminInfo.email || '',
          contactPhone:buyerAdminInfo.phone || ''
        },
        contactPersons: [...contactPersons, ...selectedCP],
        salesPersons: [...selectedSP],
      };
      try {
        
        const { data } = await createBuyerAdminService(payload, accessToken);
        if (data?.data) {
          
          toast.warn('Account created successfully');
          dispatch(clearBuyerAdminAccountDetails());
          setLoading(false);
          navigate('/');
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    const updateParentAccount = async () => {};

    if (submit) {
      if (isUpdate) {
        updateParentAccount();
      } else createParentAccount();
      setSubmit(false);
    }
  }, [
    accountInfo,
    buyerAdminInfo,
    contactPersons,
    salesPersons,
    submit,
    id,
    dispatch,
    navigate,
    accessToken,
    isUpdate,
    contactPersonsSelected,
    salesPersonsSelected,
  ]);
  if (loading) return <SpinnerLoader />;
  return (
    <div className="content-sm page-bottom-space">
      <div className="border-bottom pb-1 d-flex justify-content-between align-items-center">
        <h4 className="mb-0 heading4-bold">Parent Account</h4>
        <p className="mb-0 font-size-13">*required field</p>
      </div>
      <form className="form-wrap" onSubmit={handeleForm}>
        <Input
          sectionClass="mb-3"
          value={accountInfo.name}
          onChange={handleInput}
          label="Parent Account Name*"
          name="name"
          placeholder="Abc"
        />
        <ContactForm info={buyerAdminInfo} onChange={handleBuyerAdminInfo} />
        <div className="mb-1">
          {/* <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center"> */}
            {/* <h4 className="mb-0 heading4-bold">Contact Person*</h4> */}
          <div className="pb-1 mb-1 d-flex justify-content-between align-items-center">
            <AddSection label="Add Contact Person" onClick={handleAddContact} />
          </div>
          {contactPersonsSelected.length > 0 && (
            <ul className="list-group list-group-flush list-style-none">
              {React.Children.toArray(
                contactPersonsSelected.map((contact, key) => (
                  <li className="has-right-action mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{contact.name}</p>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="btn btn-link-danger"
                        onClick={() =>
                          handleRemoveContactPerson(contact.email, false)
                        }
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
          {contactPersons.length > 0  && (
            <ul className="list-group list-group-flush list-style-none">
              {React.Children.toArray(
                contactPersons.map((contact, key) => (
                  <li className="has-right-action mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{contact.name}</p>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="btn btn-link-danger"
                        onClick={() =>
                          handleRemoveContactPerson(contact.email, true)
                        }
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
        </div>
        <div className="mb-1">
          <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Sales Person</h4>
            {/* <AddSection label="Add Sales Person" onClick={handleAddSales} /> */}
          </div>
          <select
            className="form-select"
            title={''}
            onChange={handleChangeSales}
            value={''}
            name={'sales'}
          >
            {<option value="">{'Select Sales Person'}</option>}
            {React.Children.toArray(
              salesPersons.map((el) => <option value={el.id}>{el.name}</option>)
            )}
          </select>

          {salesPersonsSelected.length > 0 && (
            <ul className="list-group list-group-flush list-style-none mt-2">
              {React.Children.toArray(
                salesPersonsSelected.map((salePerson, key) => (
                  <li className="has-right-action list-item-mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{salePerson.name}</p>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="btn btn-link-danger"
                        onClick={() => removeSales(salePerson.id)}
                      >
                        <i className="fa-solid fa-xmark">{''}</i>
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
         
        </div>
        <div className="mt-3">
          {isUpdate && (
            <Button
              className="btn-danger px-4 me-2"
              label="Update"
              onClick={handleUpdate}
            />
          )}
          {!isUpdate && (
            <Button
              className="btn-danger px-4"
              label="Save"
              onClick={handleSave}
            />
          )}
        </div>
      </form>
      {showContactAccountModal && (
        <AddContactModal
          title={'Add Contact Person'}
          onSave={(data: AccountInfo) => handleContactPerson(data, true)}
          open={showContactAccountModal}
          setOpen={setShowContactAccountModal}
        />
      )}
      {showNewSalesPersonModal && (
        <AddContactModal
          title={'Add Sales Person'}
          onSave={(data: AccountInfo) => handleAddSalesPerson(data, true)}
          open={showNewSalesPersonModal}
          setOpen={setShowNewSalesPersonModal}
        />
      )}
    </div>
  );
};

export default AccountForm;
