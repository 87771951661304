import { FC, ReactElement } from "react";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

interface ITooltipProps {
  children: ReactElement;
  msg: string;
}

const CustomTooltip: FC<ITooltipProps> = (props) => {
  const { children, msg } = props;
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">{msg}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
