import React, {FC} from "react";

type Props = {
    label: string;
    value: string;
}

const InfoColList: FC<Props> = (props): JSX.Element => {
    const {label, value} = props;
    return (
        <>
            <div className="col-md-4 mb-1">
                <p className="mb-0 text-capitalize">{label}</p>
            </div>
            <div className="col-md-8 mb-1">
                <p className="mb-0 text-capitalize">{value}</p>
            </div>
        </>
    )
}

export default InfoColList;