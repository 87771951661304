import * as React from 'react'

interface ModalStats {
    open: boolean;
    onShow: () => void;
    onHide: () => void;
    onToggle: () => void;
}

export const useModal = (): ModalStats => {

  const [open, setOpen] = React.useState<boolean>(false)

  const onShow = React.useCallback(() => setOpen(true), [])
  const onHide = React.useCallback(() => setOpen(false), [])
  const onToggle = React.useCallback(() => setOpen((prev) => !prev), [])

  const modalStats = React.useMemo(() => {
    return { open, onShow, onHide, onToggle }
  }, [open, onShow, onHide, onToggle])

  return modalStats
}