import * as React from "react";
import DOMPurify from "dompurify";
import { Accordion, Dropdown } from "react-bootstrap";
const VariableRegex = new RegExp('cvar\\d*$')

const GraphicTemplateArt = ({ art }: any) => {

  const [baseDocument, setBaseDocument] = React.useState<any>(null)
  const [updatedDocument, setUpdatedDocument] = React.useState<any>(null)
  const [components, setComponents] = React.useState<any>([])

  const handleVisibilityChange = (value: number) => {
    setComponents((prevComponents: any) => {
      return prevComponents.map((item: any, index: number) => {
        if(value !== index) return item
        return { ...item, visibility: !(item.visibility) }
      })
    })
  }

  React.useEffect(() => {
    async function initialize() {
      const svgText = await fetch(art.art_url).then((response) => response.text())
      const parser = new DOMParser()
      const svgDocument = parser.parseFromString(svgText, "image/svg+xml");
      const svgElement = svgDocument.querySelector("svg")
      if(svgElement){
        const elements = svgElement.querySelectorAll("[id]")
        const elementIds = Array.from(elements, ((el) => el.id)).filter((id) => VariableRegex.test(id))
        const _components = []
        for(let id of elementIds){
          const matched = id.match(/(?<prefix>var_)?(?<length>\d+_)?(?<name>.+)(?<cvar>_cvar\d*)/)
          if(matched){
            _components.push({
              c_id: matched[0],
              c_name: matched.groups?.['name'] ?? 'Unnamed',
              c_var: matched.groups?.['cvar'] ?? 'cvar',
              prefix: matched.groups?.['prefix'] ?? null,
              length: matched.groups?.['length'] ?? null,
              visibility: true
            })
          }
        }
        setComponents(_components.sort((a, b) => a.c_name.localeCompare(b.c_name)))
        setBaseDocument(svgDocument)
      }
    }
    initialize()
  }, [])

  React.useEffect(() => {
    function manipulateSvg(){
      if(!baseDocument || !components.length) return
      const svgElement = baseDocument.querySelector("svg")
      if(svgElement){
        const distressingEl = svgElement.querySelector('image')
        if(distressingEl && distressingEl.hasAttribute('xlink:href')){
          distressingEl.removeAttribute('xlink:href')
          distressingEl.setAttribute('href', 'https://stdrecdev.s3.us-east-1.amazonaws.com/svg-test/distressing1_sm.jpg')
        }
        components.forEach((item: any) => {
          const element = svgElement.querySelector(`#${item.c_id}`)
          const visibility = item.visibility ? 'visible' : 'hidden'
          element.setAttribute('visibility', visibility)
        })
        // Serialize the updated SVG content
        const serializer = new XMLSerializer();
        const updatedContent = serializer.serializeToString(svgElement);
        // Sanitize the SVG content
        const sanitizedContent = DOMPurify.sanitize(updatedContent);
        setUpdatedDocument(sanitizedContent)
      }
    }
    manipulateSvg()
  }, [components])

  return (
    <>
      <Accordion.Header>{art.art_name}</Accordion.Header>
      <Accordion.Body>
        <div className="default-graphics-table">
          <div className="default-graphics-img">
            <div dangerouslySetInnerHTML={{ __html: updatedDocument }} />
            {/* <img className="item-img" src={art.art_url} alt={art.art_name} /> */}
          </div>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Variable</th>
                  <th>Visibility</th>
                  <th>Text Variables</th>
                  <th>Color Variables</th>
                  <th>Add Strokes</th>
                  <th>Stroke Color</th>
                </tr>
              </thead>
              <tbody>
                {components.map((item: any, i: number) => (
                  <tr key={item.c_id}>
                    <td className="variable-text">{item.c_name}</td>
                    <td className="visibility-btn-holder">
                      <button className="btn btn-outline-danger bg-transparent text-danger border-0" onClick={() => handleVisibilityChange(i)}>
                        {item.visibility ? (
                          <span className="fa-regular fa-eye"></span>
                        ) : (
                          <span className="fa-regular fa-eye-slash"></span>
                        )}
                      </button>
                    </td>
                    <td>
                      <Dropdown className="icon-dropdown with-text">
                        <Dropdown.Toggle className="edit-name-btn">
                          Stacked Name
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="dropdown-container">
                            <Dropdown.Item href="#/action-1">
                              Graphic color 1
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Graphic color 2
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Body color
                            </Dropdown.Item>
                          </div>
                          <div className="graphics-add-holder">
                            <button className="add-graphic-btn">
                              Add graphic color
                            </button>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      <Dropdown className="icon-dropdown with-text">
                        <Dropdown.Toggle className="edit-name-btn">
                          Stacked Name
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="dropdown-container">
                            <Dropdown.Item href="#/action-1">
                              Graphic color 1
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Graphic color 2
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Body color
                            </Dropdown.Item>
                          </div>
                          <div className="graphics-add-holder">
                            <button className="add-graphic-btn">
                              Add graphic color
                            </button>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      <div className="btn-group">
                        <button className="btn btn-sm">
                          <span className="fa-solid fa-minus"></span>
                        </button>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                        />
                        <button className="btn btn-sm">
                          <span className="fa-solid fa-plus"></span>
                        </button>
                      </div>
                    </td>
                    <td>
                      <Dropdown className="icon-dropdown with-text">
                        <Dropdown.Toggle className="edit-name-btn">
                          Stacked Name
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="dropdown-container">
                            <Dropdown.Item href="#/action-1">
                              Graphic color 1
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Graphic color 2
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Body color
                            </Dropdown.Item>
                          </div>
                          <div className="graphics-add-holder">
                            <button className="add-graphic-btn">
                              Add graphic color
                            </button>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Accordion.Body>
    </>
  );
};

export default GraphicTemplateArt;
