import axios from 'axios';
import { BASE_URL } from './apiConstants';
import {
  ICreateParentAccountPayload,
} from './requestTypes';
import { client } from 'utils/client';



export const createBuyerAdminService = (
  data: ICreateParentAccountPayload,
  token: string
) => {
  return client.post('/parentAccount/save')
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/parentAccount/save',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const refreshParentAccountFromApparelMagic = (
  token: string
) => {
  return client.post('/parentAccount/refresh')
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/parentAccount/refresh',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};

export const festchAllParentsService = (token: string) => {
  return client.get('/parentAccount/list')
};
export const fetchParentAccountDetailsService = (
  token: string,
  id: string | number
) => {
  return client.get(`/parentAccount/parent-by-id/${id}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + ,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const updateBuyerAdminService = (
  id: string | number,
  data: any,
  token: string
) => {
  return client.put(`/parentAccount/update/${id}`, data)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/parentAccount/update/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteBuyerAdminService = (
  id: string | number,
  token: string
) => {
  return client.put(`/parentAccount/remove/${id}`)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/parentAccount/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};

export const fetchContactListService = (token: string) => {
  return client.get(`/contacts/list`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + `/contacts/list`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};

export const fetchApparelContactPeople = (parentId: string, token: string) => {
  return client.get(`/contacts/apparel-contact-people/${parentId}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + `/contacts/apparel-contact-people/${parentId}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};

export const assignContactSalesCustomerService = (
  data: any,
  token: string
) => {
  return client.post('/contacts/assign-contacts', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/contacts/assign-contacts',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const startParentSync = (
  token: string
) => {
  return client.post('/events/trigger', {eventName: 'SYNC_PARENT_PEOPLE' })
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/events/trigger',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data: { eventName: 'SYNC_PARENT_PEOPLE' },
  // });
};

export const checkParentSyncStatus = (
  token: string
) => {
  const eventName = 'SYNC_PARENT_PEOPLE'
  return client.get(`/events/status/${eventName}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/events/status/' + eventName,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   }
  // });
};

// export const checkParentSyncStatus = (
//   token: string
// ) => {
//   const eventName = 'SYNC_PARENT_PEOPLE'
//   return axios({
//     method: 'get',
//     url: BASE_URL + '/events/status/' + eventName,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     }
//   });
// };

export const generateWebLink = (token: string, payload: any) => {
  return client.post('/parentAccount/generate-web-link', payload)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/parentAccount/generate-web-link',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data: payload
  // });
};

export const toggleWebLink = (token: string, payload: any) => {
  return client.patch('/parentAccount/toggle-web-link', payload)
  // return axios({
  //   method: 'patch',
  //   url: BASE_URL + '/parentAccount/toggle-web-link',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data: payload
  // });
};

export const resetEvent = () => {
  return client.post('/events/reset')
};