import React, { ChangeEvent } from 'react';
import Input from 'ui/input/Input';

export interface IContactInfo {
  name: string;
  email: string;
  phone?: string;
}

export interface IContactForm {
  index?: number;
  info: IContactInfo;
  onChange: (e: ChangeEvent<HTMLInputElement>, index?: number) => void;
}

const ContactForm: React.FC<IContactForm> = ({ info, onChange, index = 0 }) => {
  return (
    <>
      <Input
          sectionClass="mb-3"
        value={info.name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, index)}
        label="Contact Account Name"
        name="name"
        placeholder="Abc"
      />
      <Input
          sectionClass="mb-3"
        value={info.email}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, index)}
        label="Contact Email"
        name="email"
        type="email"
        placeholder="email@web.com"
      />
      <Input
          sectionClass="mb-3"
        value={info.phone}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, index)}
        label="Contact Phone"
        name="phone"
        placeholder="(###) ###-####"
      />
    </>
  );
};

export default ContactForm;
