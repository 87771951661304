import * as templateService from "api/templateService";
import * as inventoryService from "api/inventoryService";
import * as addressService from "api/addressService";

const SIZES = ["XS", "S", "M", "L", "XL", "XXL"];

interface Stats {
  amountSubTotal: number,
  amountDiscount: number,
  amountShipping: number,
  amountTotal: number
}

export const getTemplatePriceSlabs = async(data: any) => {
    const templateIdSet = new Set<number>()
    data?.purchaseOrders.forEach((po: any) =>
      po.orderItems.forEach((item: any) => {
        templateIdSet.add(item.templateId)
      })
    );
    const prices = await templateService.fetchAllTemplatePricingByIdsService('', Array.from(templateIdSet))
    .then((response) => response.data?.data);
    const grouppedPrices = {} as any
    prices.forEach((p: any) => {
       if(!grouppedPrices[p.templateId]) grouppedPrices[p.templateId] = []
       grouppedPrices[p.templateId].push({
        dozenValue: Number(p.dozenValue),
        pricePerUnit: Number(p.pricePerUnit),
        templateId: Number(p.templateId)
       })
    })
    return grouppedPrices
}

export const getShippingCost = async() => {
    const obj = {} as { [key: string]: number }
    const shippingCost = await inventoryService.fetchAllBlankStyleShipping()
    shippingCost.forEach((item: any) => {
        obj[item.blankGarmentStyleNumber] = Number(item.price)
    })
    return obj
}

export const getShippingAddresses = async (args: { parentId: number }) => {
  if (!args.parentId) return [];
  const addresses = await addressService
    .fetchShipping("", args.parentId)
    .then((response) => response.data?.data.map((item: any) => ({
        id: item.id,
        name: item.text,
        shipTo: item.text,
        shipToName: item.name,
        shipToId: item.shipToId,
        addressId: item.addressId,
        address1: item.address1,
        address2: item.address2,
        city: item.city,
        state: item.state,
        country: item.country,
        postalCode: item.postalCode,
    })))
  return addresses;
};

export const transformAsFormValues = (data: any) => {

    const quantities: any = {};
    const purchaseOrders: any = {};

    data?.purchaseOrders.forEach((po: any) =>{
      purchaseOrders[`po_${po.id}`] = {
        PO: po.PO,
        addressId: po.addressId,
      }
      po.orderItems.forEach((item: any) => {
        quantities[`item_${item.id}`] = {};
        SIZES.forEach((s) => (quantities[`item_${item.id}`][s] = item[`skuQty${s}`]));
      })
    });

    const values = {
      purchaseOrders,
      quantities
    };
  return values
}

export const transformAsIntialState = (args: { orderData: any, templatePriceSlabs: any, shippingRates: any, shippingAddresses: any[] }) => {
  const { orderData, templatePriceSlabs, shippingRates, shippingAddresses } = args

  const itemQuantities = {} as { [key: number]: number}
  const itemInfo = {} as { [key: number]: { templateId: number, blankStyleNumber: string, styleNumber: string } }

  orderData.purchaseOrders.forEach((po: any) => {
    po.orderItems.forEach((item: any) => {
      itemQuantities[item.id] = item.quantity
      itemInfo[item.id] = {
        templateId: item.templateId,
        styleNumber: item.styleNumber,
        blankStyleNumber: item.blankGarmentStyleNumber
      }
    })
  })

  return {
    orderData,
    itemInfo,
    itemQuantities,
    templatePriceSlabs,
    shippingRates,
    shippingAddresses
  }
} 
