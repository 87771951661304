import { fetchLocationDetailsService } from "api/locationService";
import { ICustomerDetails, ITemplate } from "api/requestTypes";
import {
  configureTemplateLogosService,
  configureTemplateService,
  fetchJSONFromURLService,
  TemplateSettingGetService,
} from "api/templateService";
import { NameValuePair } from "./TemplateConfigDialog";
import { fetchGraphicRules } from "api/graphicRule";

const getJson = async (
  templateListAll: ITemplate[],
  currentTemplateId: string | number
) => {
  try {
    const template = templateListAll.find(
      (t: any) => t.id == currentTemplateId
    );
    const frontPairs: NameValuePair[] = [];
    const backPairs: NameValuePair[] = [];

    if (template?.frontDescription !== "") {
      const { data: frontData } = await fetchJSONFromURLService(
        template?.frontDescription!
      );
      if (frontData?.layers) {
        const arr = frontData?.layers;
        for (let i = 0; i < arr.length; i++)
          frontPairs.push({
            layerName: arr[i].name,
            keyName: "",
            type: "",
            defaultLayerValue: "",
          });
        //
        // return frontPairs;
      }
    }
    if (template?.backDescription !== "") {
      const { data: backData } = await fetchJSONFromURLService(
        template?.backDescription!
      );
      if (backData?.layers) {
        const arr = backData?.layers;
        for (let i = 0; i < arr.length; i++)
          backPairs.push({
            layerName: arr[i].name,
            keyName: "",
            type: "",
            defaultLayerValue: "",
          });
        //
        // return backPairs;
      }
    }

    return { frontPairs, backPairs };
  } catch (error) {
    console.error(error);
    alert("Something went wrong");

    return [];
  }
};
const getExistingLogos = async (
  logos: string[],
  customInfo: ICustomerDetails,
  currentTemplateId: string | number,
  design: string,
  accessToken: string
): Promise<number[]> => {
  const payload = {
    info: {
      childId: customInfo.id,
      templateId: currentTemplateId,
    },
  };
  try {
    const { data } = await configureTemplateLogosService(payload, accessToken);

    if (data?.data && data?.data.length) {
      const arr: string[] = [...logos];
      let selectedIds: number[] = [];
      for (let i = 0; i <= arr.length; i++) {
        if (
          data.data.find(
            (element: any) =>
              element.logoUrl == arr[i] && element.design === design
          )
        ) {
          selectedIds.push(i + 1);
        }
      }
      // setSelectedLogo(selectedIds);

      return selectedIds;
    } else {
      // setSelectedLogo
      return Array(logos.length)
        .fill(0)
        .map((e, index) => index + 1);
    }
    //setOpen(false);
  } catch (error) {
    console.error(error);
    return []
  }
};

const getTemplateExistingSetting = async (
  res: NameValuePair[],
  currentTemplateId: string | number,
  accessToken: string
) => {
  try {
    const { data } = await TemplateSettingGetService(
      currentTemplateId,
      accessToken
    );
    if (data?.data) {
      const pairs: NameValuePair[] = [];
      const arr = data?.data;
      // console.log(arr)
      for (let i = 0; i < arr.length; i++)
        pairs.push({
          layerName: arr[i].layerName,
          keyName: arr[i].keyName,
          id: arr[i].id,
          type: "Fixed Key",
          defaultLayerValue: "",
        });
      if (res?.length) {
        for (let i = 0; i < res.length; i++)
          if (!pairs.find((e) => e.layerName == res[i].layerName)) {
            pairs.push(res[i]);
          }
      }
      //setVariableList(pairs);

      return pairs;
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  } finally {
  }
};
const getTemplateExistingConfig = async (
  res: any,
  customInfo: ICustomerDetails,
  currentTemplateId: string | number,
  accessToken: string
) => {
  const payload = {
    query: {
      childId: customInfo.id,
      templateId: currentTemplateId,
    },
  };

  try {
    const { data } = await configureTemplateService(payload, accessToken);
    if (data?.data) {
      const pairs: NameValuePair[] = [];
      const arr = data?.data;
      for (let i = 0; i < arr.length; i++)
        pairs.push({
          ...arr[i],
          layerName: arr[i].layerName,
          keyName: arr[i].keyName,
          type: arr[i].type,
          defaultLayerValue: arr[i].defaultLayerValue || "",
        });
      if (res.length) {
        for (let i = 0; i < res.length; i++)
          if (!pairs.find((e) => e.layerName == res[i].layerName)) {
            pairs.push(res[i]);
            //console.log(res[i]);
          }
      }
      // setFrontVariableList(getDefaultMapVariableData(pairs, keyListAll));

      return pairs;
    } else {
      return res;
    }
  } catch (error) {
    console.error(error);
  } finally {
  }
};
const getLocationDetails = async (
  customInfo: ICustomerDetails,
  accessToken: string
) => {
  try {
    const { data } = await fetchLocationDetailsService(
      customInfo.locationId,
      accessToken
    );

    if (data?.data) {

      const locationStateLogos: any[] = data?.data.locationLogos.concat(
        data?.data.state.stateLogos
      );
      const locationStateLogosUrlsList = locationStateLogos.map(
        (e) => e.logoUrl
      );
      const customInfoLogoUrlsList = customInfo.logoUrls.length
        ? customInfo.logoUrls.split(",")
        : [];
      const logos = customInfoLogoUrlsList.concat(locationStateLogosUrlsList);

      // console.log({
      //   data,
      //   locationStateLogos,
      //   locationStateLogosUrlsList,
      //   customInfoLogoUrlsList,
      //   customInfo,
      //   logos,
      // });
      return logos;
    }

    return [];
    //setOpen(false);
  } catch (error) {
    console.error(error);
    return [];
  } finally {
  }
};

const getTemplateLogos = async (
  customInfo: ICustomerDetails,
  template: any,
  logos: any,
  accessToken: string
) => {
  let _logos = logos
  const activeLogos = await configureTemplateLogosService({
    info: { childId: customInfo.id, templateId: template.id }
  }, accessToken).then(res => res.data.data)
  if(activeLogos.length){
    const activeLogoMap = activeLogos.reduce((a: any, c: any) => {
      a[c.design || 'front'][c.logoUrl] = true
      return a
    }, { front: {}, back: {} })

    _logos = logos.map((logo: any) => ({
      ...logo,
      isFrontActive: template.logos && !!activeLogoMap.front?.[logo.logoUrl],
      isBackActive: template.backLogos && !!activeLogoMap.back?.[logo.logoUrl],
    }))
  }

  return _logos

}

function extractColorOptions(rules: any) {
  const result = rules.reduce((a: any, c: any) => {
    if (!a.bodyMap[c.bodyColorName]) a.bodyMap[c.bodyColorName] = true
    a.graphicMap[`${c.graphicType}_${c.bodyColorName}_${c.graphicColorName}`] = true
    return a
  }, {
    bodyMap: {},
    graphicMap: {}
  })
  return result
}

const getTemplateColorOptions = async (accessToken: any, customerId: any, templateId: any) => {
  try {
    let result
    const { data } = await fetchGraphicRules(accessToken, { customerId, templateId })
    result = data?.data.rules
    if (!data?.data.hasRules) {
      const { data } = await fetchGraphicRules(accessToken, { customerId: 0, templateId })
      result = data?.data.rules
    }
    return extractColorOptions(result)
  } catch (err) {
    console.error(err)
  } finally {
    console.log('finally')
  }
}

const fetchLayerFromJSONFile = async (template: any) => {

  let result = { frontLayers: [], backLayers: [] }

  const promises = [
    ...(template?.frontDescription ? [fetchJSONFromURLService(template?.frontDescription)] : []),
    ...(template?.backDescription ? [fetchJSONFromURLService(template?.backDescription)] : []),
  ]
  const [frontDesc, backDesc] = await Promise.all(promises)

  if (frontDesc?.data.layers.length) {
    result.frontLayers = frontDesc.data.layers.map((layer: any) => ({
      design: 'front',
      layerName: layer.name,
      length: layer.length,
      keyName: "",
      type: "",
      defaultLayerValue: "",
    }))
  }

  if (backDesc?.data.layers.length) {
    result.backLayers = backDesc.data.layers.map((layer: any) => ({
      design: 'back',
      layerName: layer.name,
      length: layer.length,
      keyName: "",
      type: "",
      defaultLayerValue: "",
    }))
  }

  return result
}

const fetchLayersFromSetting = async (template: any, accessToken: any, templateLayers: any) => {
  const response = await TemplateSettingGetService(template.id, accessToken)
  const layers = response?.data.data
  const frontLayers: any[] = []
  const backLayers: any[] = []

  const existingLayerMap = layers.reduce((a: any, c: any) => {
    if (c.design === 'front') {
      a.frontLayerMap[c.layerName] = c
    } else if (c.design === 'back') {
      a.backLayerMap[c.layerName] = c
    }
    return a
  }, { frontLayerMap: {}, backLayerMap: {} })

  if (templateLayers.frontLayers.length) {
    templateLayers.frontLayers.forEach((layer: any) => {
      const existingLayer = existingLayerMap.frontLayerMap[layer.layerName]
      if (existingLayer) frontLayers.push({
        id: existingLayer.id,
        layerName: existingLayer.layerName,
        keyName: existingLayer.keyName,
        length: existingLayer.length,
        type: 'Fixed Key',
        defaultLayerValue: '',
        design: 'front'
      })
      else frontLayers.push(layer)
    })
  }
  if (templateLayers.backLayers.length) {
    templateLayers.backLayers.forEach((layer: any) => {
      const existingLayer = existingLayerMap.backLayerMap[layer.layerName]
      if (existingLayer) backLayers.push({
        id: existingLayer.id,
        layerName: existingLayer.layerName,
        keyName: existingLayer.keyName,
        length: existingLayer.length,
        type: 'Fixed Key',
        defaultLayerValue: '',
        design: 'back'
      })
      else backLayers.push(layer)
    })
  }

  return { frontLayers, backLayers }

}

const fetchLayersFromCustomerTemplate = async (customInfo: any, template: any, accessToken: any, templateLayers: any) => {
  const response = await configureTemplateService({ query: { childId: customInfo.id, templateId: template.id } }, accessToken);
  const layers = response?.data.data || []
  const frontLayers: any[] = []
  const backLayers: any[] = []

  const existingLayerMap = layers.reduce((a: any, c: any) => {
    if (c.design === 'front') {
      a.frontLayerMap[c.layerName] = c
    } else if (c.design === 'back') {
      a.backLayerMap[c.layerName] = c
    }
    return a
  }, { frontLayerMap: {}, backLayerMap: {} })

  if (templateLayers.frontLayers.length) {
    templateLayers.frontLayers.forEach((layer: any) => {
      const existingLayer = existingLayerMap.frontLayerMap[layer.layerName]
      if (existingLayer) frontLayers.push({
        id: existingLayer.id,
        layerName: existingLayer.layerName,
        keyName: existingLayer.keyName,
        length: existingLayer.length,
        type: existingLayer.type,
        defaultLayerValue: existingLayer.defaultLayerValue || '',
        design: 'front'
      })
      else frontLayers.push(layer)
    })
  }
  if (templateLayers.backLayers.length) {
    templateLayers.backLayers.forEach((layer: any) => {
      const existingLayer = existingLayerMap.backLayerMap[layer.layerName]
      if (existingLayer) backLayers.push({
        id: existingLayer.id,
        layerName: existingLayer.layerName,
        keyName: existingLayer.keyName,
        length: existingLayer.length,
        type: existingLayer.type,
        defaultLayerValue: existingLayer.defaultLayerValue || '',
        design: 'back'
      })
      else backLayers.push(layer)
    })
  }

  return { frontLayers, backLayers }
}

const fetchTemplateLayers = async (customInfo: any, template: any, accessToken: string) => {
  let jsonLayers = await fetchLayerFromJSONFile(template)
  const settingLayers = await fetchLayersFromSetting(template, accessToken, jsonLayers)
  const customerTemplateLayers = await fetchLayersFromCustomerTemplate(customInfo, template, accessToken, settingLayers)
  return customerTemplateLayers
}

const templateConfigUtils = {
  getJson,
  getExistingLogos,
  getTemplateExistingSetting,
  getTemplateExistingConfig,
  getLocationDetails,
  getTemplateColorOptions,
  fetchLayerFromJSONFile,
  fetchTemplateLayers,
  getTemplateLogos
};
export default templateConfigUtils;
