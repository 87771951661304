import * as React from 'react'
import { Container } from 'react-bootstrap'
import styles from './DefaultTemplateLogo.module.scss'
type Logo = {
    id: number;
    logoUrl: string;
    fileName?: string;
    fileNameFull: string;
    isFrontDefault: boolean;
    isFrontActive: boolean;
    isBackDefault: boolean;
    isBackActive: boolean;
    disabledFR?: boolean;
    disabledBK?: boolean;
};
// type Logo = { logoUrl: string, fileName: string, disabledFR: boolean, disabledBK: boolean };
type GroupedLogo = { name: string; logos: { [key: string]: Logo } };
type Selected = { FR: { [key: string]: string }, BK: { [key: string]: string } }
interface Props {
    logos: any[];
    logoList: any;
    shade: 'lt' | 'dk' | 'NA';
    hasFRLogo: boolean;
    hasBKLogo: boolean;
    selFRLogos: any;
    selBKLogos: any;
    defaultLogos: any;
    setDefaultLogos: any;
}

enum LogoMapper {
    MONO_LT = "1Color_lt.png",
    MONO_DK = "1Color_dk.png",
    MULTI_LT = "FullColor_lt.png",
    MULTI_DK = "FullColor_dk.png",
}

const OneAndFullColorMap = {
    [LogoMapper.MONO_LT]: "1Color",
    [LogoMapper.MONO_DK]: "1Color",
    [LogoMapper.MULTI_LT]: "FullColor",
    [LogoMapper.MULTI_DK]: "FullColor",
} as { [key: string]: string };

const logoShadeMap = {
    [LogoMapper.MONO_LT]: "lt",
    [LogoMapper.MONO_DK]: "dk",
    [LogoMapper.MULTI_LT]: "lt",
    [LogoMapper.MULTI_DK]: "dk",
} as { [key: string]: string };


const DefaultTemplateLogo: React.FC<Props> = (props): JSX.Element => {
    const shadeWiseLogoRef = React.useRef<any>({})
    const { shade, hasFRLogo, hasBKLogo, selFRLogos, selBKLogos, defaultLogos, setDefaultLogos } = props

    const handleLogoSelect = (name: string, value: string, shade: string) => {
        setDefaultLogos((prev: any) => ({ ...prev, [name]: value, shade }))
    }

    const [groupedLogos] = React.useMemo(() => {
        const grouped = props.logoList.reduce((a: any, c: Logo, index: any) => {
            const _idx = index + 1
            if (!selFRLogos[_idx] && !selBKLogos[_idx]) return a;
            const found = Object.entries(LogoMapper).find(([k, v]) =>
                c.logoUrl.endsWith(v)
            );
            if (!found) return a;
            const [_, logoSuffix] = found;
            const _re = new RegExp(`_${logoSuffix}$`);
            let logoName = c.logoUrl.replace(_re, "");
            logoName = logoName.substring(logoName.lastIndexOf("/") + 1);
            const colorType = OneAndFullColorMap[logoSuffix];
            const logoShade = logoShadeMap[logoSuffix];
            const groupName = `${logoName}_${colorType}`;
            const fileNameFull = `${logoName}_${logoSuffix}`;
            const fileName = `${logoName}_${logoSuffix.replace(/.png$/, "")}`;
            if (!a[groupName]) a[groupName] = {};
            a[groupName][logoShade] = {
                logoUrl: c.logoUrl,
                fileName: fileName,
                fileNameFull: fileNameFull,
                isFrontDefault: c.isFrontDefault,
                isFrontActive: c.isFrontActive,
                isBackDefault: c.isBackDefault,
                isBackActive: c.isBackActive,
                disabledFR: !selFRLogos[_idx],
                disabledBK: !selBKLogos[_idx]
            };
            return a;
        }, {});
        return [
            Object.entries(grouped).map(([name, logos]) => ({
                name,
                logos,
            }))
                .sort((a, b) => a.name.localeCompare(b.name)) as GroupedLogo[],
            grouped,
        ];
    }, [props.logoList, selFRLogos, selBKLogos]);

    // To remember previous shade logo selection
    React.useEffect(() => {
        if (!shade) return
        if (shadeWiseLogoRef.current[shade]) {
            setDefaultLogos((prev: any) => ({ ...shadeWiseLogoRef.current[shade] }))
        }
        if (defaultLogos?.shade !== shade) {
            shadeWiseLogoRef.current[defaultLogos?.shade] = { ...defaultLogos }
        }
    }, [shade])

    return (
        <Container>
            <div className="default-logos">
                <table className={styles.table}>
                    <tr>
                        <td align="center" className={styles.logoCol}></td>
                        <td align="center" className={styles.fileNameCol}></td>
                        <td
                            align="center"
                            className={styles.frontLogoCol}
                        >
                            Front Logo
                        </td>
                        <td
                            align="center"
                            className={styles.backLogoCol}
                        >
                            Back Logo
                        </td>
                    </tr>
                </table>

                <table
                    className={`bordered-table ${styles.table}`}
                    border={1}
                >
                    {groupedLogos.map((group) => {
                        let _shade = shade
                        if(_shade === 'NA'){
                           _shade = group.logos?.lt?.logoUrl ? 'lt' : 'dk'
                        }
                        return (
                            <>
                                {group.logos[_shade]?.logoUrl && (
                                    <tr>
                                        <td className={styles.logoCol}>
                                            <div className={styles.logoImgWrap}>
                                                <img
                                                    className={styles.logoImg}
                                                    src={group.logos[_shade]?.logoUrl}
                                                    alt={group.logos[_shade]?.fileName}
                                                />
                                            </div>
                                        </td>
                                        <td className={styles.fileNameCol}>
                                            <span>{group.logos[_shade]?.fileName}</span>
                                        </td>
                                        <td className={styles.frontLogoCol}>
                                            <div className="d-flex justify-content-evenly">
                                                <div className="custom-radio">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        id="logo"
                                                        name="FR"
                                                        value={group.logos[_shade]?.fileNameFull}
                                                        disabled={!hasFRLogo || group.logos[_shade]?.disabledFR}
                                                        onChange={(e) => handleLogoSelect(e.target.name, e.target.value, shade)}
                                                        checked={(hasFRLogo || !group.logos[_shade]?.disabledFR) && defaultLogos.FR === group.logos[_shade]?.fileNameFull}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="logo"
                                                    ></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={styles.backLogoCol}>
                                            <div className="d-flex justify-content-evenly">
                                                <div className="custom-radio">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        id="backLogo"
                                                        name="BK"
                                                        value={group.logos[_shade]?.fileNameFull}
                                                        disabled={!hasBKLogo || group.logos[_shade]?.disabledBK}
                                                        onChange={(e) => handleLogoSelect(e.target.name, e.target.value, shade)}
                                                        checked={(hasBKLogo && !group.logos[_shade]?.disabledBK) && defaultLogos.BK === group.logos[_shade]?.fileNameFull}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="backLogo"
                                                    ></label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )
                    }

                    )}
                </table>
            </div>
        </Container>
    )
}

export default DefaultTemplateLogo