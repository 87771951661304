import React, {FC} from "react";
import {Modal} from "react-bootstrap";

type ConfirmationModalType = {
    open: boolean;
    setOpen: Function;
    handleCallBack:Function;
    confirmationMessage: string;
}

const ConfirmationModal: FC<ConfirmationModalType> = (props): JSX.Element => {
    const { open, setOpen, confirmationMessage,handleCallBack }  = props;
    const handleSubmit = () => {
        setOpen(false);
        handleCallBack();
    }
    return (
        <Modal
            className="custom-modal"
            show={open}
            onHide={() => setOpen(false)}
            aria-labelledby="custom-modal"
            backdrop="static"
            centered={true}
            size={"sm"}
        >
            <Modal.Body>
                <p className="mb-5 heading3">{confirmationMessage}</p>
                <div className="mt-3">
                    <button  className="btn btn-danger px-4" onClick={() => {
                        handleSubmit();
                    }}>
                        Yes
                    </button>
                    <button className="btn btn-outline-danger px-4 ms-3" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal;