import * as React from "react";
import { Modal } from "react-bootstrap";
import SpinnerLoader from "ui/loader/SpinnerLoader";

import * as orderService from "api/orderService";
import { useAppDispatch } from "store/hooks";
import { setOrderUpdateData } from "store/OrderUpdateData.slice";
import * as helper from "./helper";

import UpdateForm from "./UpdateForm";

interface iProps {
  id: any;
  orderNumber: string;
  open: boolean;
  onHide: () => any;
  setRefetch: any;
}

const UpdateOrder: React.FC<iProps> = ({ id, onHide, setRefetch }): JSX.Element => {
  const dispatch = useAppDispatch()
  const [orderData, setOrderData] = React.useState(null)
  const open = Boolean(orderData)

  React.useEffect(() => {
    if (!id) return;
    const getData = async () => {
      try {
        const orderData = await orderService.getSingleOrder(+id, "").then((res) => res?.data.data);
        if(!orderData) return
        const shippingRates = await helper.getShippingCost()
        const shippingAddresses = await helper.getShippingAddresses(orderData)
        const templatePriceSlabs = await helper.getTemplatePriceSlabs(orderData)

        const initialState: any = helper.transformAsIntialState({ orderData, shippingRates, shippingAddresses, templatePriceSlabs })
        dispatch(setOrderUpdateData(initialState))
        setOrderData(initialState.orderData)
      } catch (e) {
        console.error(e);
      }
    };
    getData();
  }, [id]);

  return (
    <Modal
      className="custom-modal"
      show={true}
      onHide={onHide}
      aria-labelledby="custom-modal"
      backdrop="static"
      centered={true}
      size="xl"
    >
      {open ? (
      <UpdateForm orderData={orderData} onHide={onHide} setRefetch={setRefetch} />
      ) : (<SpinnerLoader />)}
    </Modal>
  );
};

export default UpdateOrder;
