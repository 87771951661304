import axios from 'axios';
import { BASE_URL } from './apiConstants';
import {
  Attribute
} from './requestTypes';
import { client } from 'utils/client';



export const addCustomerAttributeService = (
  data: Attribute[],
  token: string
) => {
  return client.post('/customerAttribute/save', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/customerAttribute/save',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchAllCustomerAttributeService = (token: string) => {
  return client.get('/customerAttribute/list')
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/customerAttribute/list',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const fetchAllCustomerAttributeStyleService = (token: string) => {
  return client.get('/customerAttribute/styles')
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/customerAttribute/styles',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const updateCustomerAttributeService = (
  id: string | number,
  data: Attribute,//TODO to include other fields
  token: string
) => {
  return client.put(`/customerAttribute/update/${id}`, data)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/customerAttribute/update/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteCustomerAttributeService = (
  id: string | number,
  token: string
) => {
  return client.put(`/customerAttribute/remove/${id}`)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/customerAttribute/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};