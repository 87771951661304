import * as React from 'react'
import { Card } from 'react-bootstrap'
import styles from './UpdateOrder.module.scss'
import { useAppSelector } from 'store/hooks'
import { getOrderData, getOrderStats } from 'store/OrderUpdateData.slice'

const UpdateOrderSummary = () => {
    const orderData = useAppSelector(getOrderData)
    const orderStats = useAppSelector(getOrderStats)

    return (
        <Card.Body>
            <p><b>Ordered By:</b> {orderData.userInfo.name} (<span className={styles.userEmail}>{orderData.userInfo.email}</span>)</p>
            <p><b>Customer:</b> {orderData.parentAccountName}</p>
            <p>
                <b>Subtotal:</b> ${orderStats.amountSubTotal}
            </p>
            <p>
                <b>Discount:</b> ${orderStats.amountDiscount}
            </p>
            <p>
                <b>Shipping:</b> ${orderStats.amountShipping}
            </p>
            <p>
                <b>Total:</b> ${orderStats.amountTotal}
            </p>
        </Card.Body>
    )
}

export default UpdateOrderSummary