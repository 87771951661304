import { deleteCustomerStateService, fetchAllStateService } from 'api/locationService';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import AddStateModal from './AddStateModal';
import AddStates from './AddStates';
import { toast } from 'react-toastify';
export interface IState {
    id?: string|number,
    name: string,
    abbreviation: string,
    twoLetter: string,
    establishedDate: string,
    establishedDateFirstTwo: string,
    establishedDateLastTwo: string,
    region: string,
    subRegion: string,
    logos: string,
    countryId: string,
    country?:{name:string},
    updatedAt?: string,
    createdAt?: string,
    stateLogos?:{id:string,logoUrl:string}[]
}
const States: FC = (): JSX.Element => {
    const { accessToken } = useAppSelector(getUserDetails);
    const [loading, setLoading] = useState(false);
    const [isAddStates, setIsAddStates] = useState(false);
    const [stateList, setStateList] = useState<IState[]>([]);
    const [currentIndex,setCurrentIndex] = useState<number>(-1);
    const [openConfirmation,setOpenConfirmation] = useState(false);
    const [deleteId,setDeleteId] = useState<number|string>('');
  
    const handleDeleteState = async (id: number | string) => {
      try {
        setLoading(true);
        const { data } = await deleteCustomerStateService(id, accessToken);
        if (data.data) {
          
          let rslt = stateList.filter((el) => el.id != id);
          setStateList(rslt);
          setLoading(false);
          toast.success('Deleted successfully');
        }
      } catch (error) {
        console.error(error);
        toast.warn('Unable to delete');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      const getStateList = async () => {
        try {
          setLoading(true);
          const { data } = await fetchAllStateService(accessToken);
          if (data.data) {
            setStateList(data.data);
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          toast.warn('Could notfetch the states');
          setLoading(false);
        } finally {
          setLoading(false);
        }
      };
  
      getStateList();
    }, [accessToken]);
    const handleConfirmCallBack = () => {
      handleDeleteState(deleteId);
    };
    const getStateList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllStateService(accessToken);
        if (data.data) {
          setStateList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the states');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if(loading)
  return <SpinnerLoader/>
  return (
    <div className="content-sm page-bottom-space">
      <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
        <h4 className="mb-0 heading4-bold">States</h4>
        <button
          type="button"
          className="btn btn-link"
          onClick={() => setIsAddStates(true)}
        >
          <i className="fa-solid fa-circle-plus text-danger me-2"></i>
          <span className="text-dark">Add States</span>
        </button>
      </div>
      {stateList.length>0 &&
      <ul className="list-group list-group-flush list-style-none mb-2">
      {React.Children.toArray(
        
          stateList.map((state, index) => {
            return (
              <li className="mb-2" key={state.id}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">{state.name}</p>
                  <div className="action-wrap">
                    <button className="btn btn-link-danger" type="button"
                    onClick={() => {setCurrentIndex(index);setIsAddStates(true)}}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button className="btn btn-link-danger ms-3" type="button"
                    onClick={()=>{setCurrentIndex(-1);setIsAddStates(false);setOpenConfirmation(true);setDeleteId(state.id!)}}>
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            );
          }))
        }
      </ul>
        }
      {/* {isAddStates && <AddStates 
      setIsAddStates={setIsAddStates}
      stateList={stateList}
      setStateList={setStateList}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
       />} */}
       {isAddStates && <AddStateModal
      open={isAddStates}
      setOpen={setIsAddStates}
      stateList={stateList}
      setStateList={setStateList}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      callBack={getStateList}
       />}
       {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
    </div>
  );
};

export default States;
