import  { FC, useEffect,  useState } from 'react';
import { ICustomSize } from 'api/requestTypes';
import SpinnerLoader from 'ui/loader/SpinnerLoader';

import { getUserDetails } from 'store/User.slice';
import { useAppSelector } from 'store/hooks';

import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import EmptyList from 'ui/empty-list/EmptyList';
import CustomSizeAddUpdateModal from './CustomSizeAddUpdateModal';
import { addCustomSizeService, deleteCustomSizeService, fetchAllCustomSizeService, updateCustomSizeService } from 'api/customSize';
import { toast } from 'react-toastify';
const initCustomSize: ICustomSize = {
  id:'',
  name:'',
  userId:'',
  xs:0,
  s:0,
  m:0,
  l:0,
  xl:0,
  xxl:0,
};
const CustomSize: FC = () => {
  const [loading, setLoading] = useState(false);
  const { accessToken, role } = useAppSelector(getUserDetails);

  const [customSizeList, setCustomSizeList] = useState<ICustomSize[]>([]);
  
  const [isCustomSizeAddUpdateModalOpen, setIsCustomSizeAddUpdateModalOpen] = useState(false);
  

  const [customSize, setCustomSize] = useState<ICustomSize>({...initCustomSize});
  
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId,setDeleteId] = useState<string | number>('');
  
  const handleSaveUpdateCustomSize = async (customSize: ICustomSize) => {
    //add
    if (!customSize.id) {
      try {
        const payload={
          ...customSize,
          id:null,
          userId:null
        };
        setLoading(true);
        const { data } = await addCustomSizeService(
          payload,
          accessToken
        );
        if (data.data) {
          const details: ICustomSize = data.data;
          setCustomSizeList([...customSizeList, details]);
          toast.warn('Added successfully');
          setIsCustomSizeAddUpdateModalOpen(false);
          setCustomSize(initCustomSize);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Unable to add');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    //update
    else {
      try {
        const payload={
          ...customSize,
          userId:null
        };
        setLoading(true);
        const { data } = await updateCustomSizeService(
          customSize.id,
          payload,
          accessToken
        );
        if (data.data) {
          const details = data.data;
          
          let rslt = customSizeList.map((el) => el.id === customSize.id?customSize:el);
          setCustomSizeList([...rslt]);
          
          toast.warn('Updated successfully');
          setLoading(false);
          
          setIsCustomSizeAddUpdateModalOpen(false);
          setCustomSize(customSize);
          
        }
      } catch (error) {
        console.error(error);
        toast.warn('Unable to update');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDeleteCustomSize = async (id: number | string) => {
    try {
      setLoading(true);
      const { data } = await deleteCustomSizeService(id, accessToken);
      if (data.data) {
        let rslta = customSizeList.filter((el) => el.id != id);
        setCustomSizeList([...rslta]);
        setLoading(false);
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
      toast.warn('Unable to delete');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmCallBack =()=>{
    handleDeleteCustomSize(deleteId);
  }

  useEffect(() => {
    const getCustomSizeList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllCustomSizeService(accessToken);
        if (data.data) {
          setCustomSizeList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the size scales');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getCustomSizeList();
  }, [accessToken]);

  if (loading) return <SpinnerLoader />;
  return (
    <div className="content-sm page-bottom-space">
      <section>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4-bold">Custom Size</h4>
          <button
            type="button"
            className="btn btn-link"
            
            onClick={() => {setCustomSize(initCustomSize);setIsCustomSizeAddUpdateModalOpen(true);}}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Custom Size</span>
          </button>
        </div>
        {!!customSizeList.length ? (
          <ul className="list-group list-group-flush list-style-none mb-2">
            {customSizeList.map((customSize, index) => (
              <li className="mb-2" key={customSize.id!}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">
                    {customSize.name}
                  </p>
                  <div className="action-wrap">
                    <button
                      className="btn btn-link-danger"
                      type="button"
                      onClick={() => {
                        setCustomSize(customSize);
                        setIsCustomSizeAddUpdateModalOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button
                      className="btn btn-link-danger ms-3"
                      type="button"
                      //onClick={() => handleDeleteAttribute(customSize.id!)}
                       onClick={()=>{setDeleteId(customSize.id!);setOpenConfirmation(true)}}
                    >
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <EmptyList title="Nothing Here" />
        )}
        {isCustomSizeAddUpdateModalOpen && (
          <CustomSizeAddUpdateModal
            open={isCustomSizeAddUpdateModalOpen}
            setOpen={setIsCustomSizeAddUpdateModalOpen}
            customSize={customSize}
            setCustomSize={setCustomSize}
            onSave={handleSaveUpdateCustomSize}
            loading={loading}
          />
        )}
      </section>
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
    </div>
  );
};

export  default CustomSize;
