import { uploadCustomerContactLogosService } from 'api/customerService';
import { addCustomerLocationStateCountryService, updateCustomerCountryStateLocationService } from 'api/locationService';
import { ICountryStateLocationRequest } from 'api/requestTypes';
import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ImageListType } from 'react-images-uploading';
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import ImageUploadPrviewer from 'ui/image/ImageUploadPreviwer';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import Input from '../../../ui/input/Input';
import { ICountry } from './Countries';
import { toast } from 'react-toastify';


export interface ICountryModal{
  open:boolean;
  setOpen: Function;
  countryList: ICountry[];
  setCountryList: Function;
  currentIndex: number;
  setCurrentIndex:Function;
}
const initCountryInfo: ICountry = {
  name: '',
  logos: '',
  createdAt: '',
  updatedAt: '',
};

const AddCountryModal: FC<ICountryModal> = ({
  open,
  setOpen,
  countryList,
  setCountryList,
  currentIndex,
  setCurrentIndex
}) => {
  
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<ImageListType>([]);

  const [country, setCountry] = useState(
    currentIndex > -1 ? countryList[currentIndex] : initCountryInfo
  );
  const [error, setError] = useState('');
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setCountry({ ...country, [name]: value });
    if (value.trim().length < 1) setError('Name can not be empty');
    else setError('');
  };
  const onChangeImageUploadPreview = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    //console.log('data for submit:', imageList, addUpdateIndex);
    setImages(imageList);
    //if (imageList.length && imageList[0] && imageList[0].file)
    //  uploadCustomerLogos(imageList[0].file);
  };

  const handleImageRemove = (index: number) => {
    let urls = country.logos;
    let array = urls.split(',');
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i != index) newArray.push(array[i]);
    }
    urls = newArray.join(',');
    setCountry({ ...country, logos: urls });
  };

  const uploadCustomerLogos = async () => {
    const logos = [];
    for (let i = 0; i < images.length; i++) {
      try {
        const formData = new FormData();
        formData.append('file', images[i].file!);

        const res = await uploadCustomerContactLogosService(
          formData,
          accessToken
        );
        const data = res?.data?.data;
        if (data) {
          logos.push(data.url);
        }
      } catch (error) {
        console.error(error);
      }
    }
    return logos.join(',');
  };

  const handleSubmit = async () => {
    if(!country.name.trim()){
        toast.warn('please fill all required fields');
        return;}
    setLoading(true);
    uploadCustomerLogos().then((logoUrl) => {
      if (currentIndex <= -1) save(logoUrl);
      else update(logoUrl);
    });
  };

  const update = async (logourl: string) => {
    try {
      const updatedLogoUrl=logourl.trim() ? (logourl + (country.logos?',':'') + country.logos) : country.logos;
      const payload: ICountryStateLocationRequest = {
        type: 'country',
        data: {
          ...country,
          logos: updatedLogoUrl
        },
      };
      const { data } = await updateCustomerCountryStateLocationService(
        country.id!,
        payload,
        accessToken
      );
      if (data.data) {
        let rslt = countryList.filter((el) => el.id != country.id);
        setCountryList([...rslt, {...country,logos:updatedLogoUrl}]);
        toast.success('Country Updated Successfully');
        setCurrentIndex(-1);
        setImages([]);
        setOpen(false);
        setLoading(false);
      }
    } catch (e) {
      toast.warn('Unable to Update Country');
      setLoading(false);
    }
  };
  const save = async (uploadLogoUrl: string) => {
    
    try {
      const payload: ICountryStateLocationRequest = {
        type: 'country',
        data: { ...country, logos: uploadLogoUrl },
      };
      const { data } = await addCustomerLocationStateCountryService(
        payload,
        accessToken
      );
      if (data.data) {
        const country = data.data;
        setCountryList([...countryList, country]);
        toast.success('Country Added Successfully');
        setCurrentIndex(-1);
        setImages([]);
        setOpen(false);
        setLoading(false);
      }
    } catch (e) {
      toast.warn('Unable to Add Country');
      setLoading(false);
    }
  };
  
  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
      <div className="border-bottom pb-1 mb-3">
        <h4 className="mb-0 heading4-bold">Add Countries</h4>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Name*</label>
        </div>
          <Input
            sectionClass="mb-3"
            value={country.name}
            onChange={handleInput}
            name="name"
            placeholder="Abc"
            errorMessage={error}
          />
      </div>
      <div className="row g-3 align-items-center mb-3">
        {/* <div className="col-md-3">
          <label className="text-capitalize">Logo</label>
        </div> */}
        <div className="col-md-9">
          <div className="row g-3">
            {/*TODO: Use the same image uploader component here too */}

            {/* <ImageUploadPrviewer
              onChange={onChangeImageUploadPreview}
              images={images}
            /> */}
            {/* {country.logos?.length > 0 &&
              country.logos.split(',').map((imageSrc, index) => (
                <div className="col-auto" key={index}>
                  <div
                    key={index}
                    className="avatar position-relative overflow-visible"
                  >
                    <img className="rounded " src={imageSrc} alt="logo" />
                    <button
                      className="btn btn-link-danger btn-x"
                      onClick={() => handleImageRemove(index)}
                    >
                      {''}
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              ))} */}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button
          type="button"
          className="btn btn-danger px-4"
          onClick={handleSubmit}
        >
          {currentIndex <= -1 ?`Save`:`Update`}
        </button>
        <button
          type="button"
          className="btn btn-outline-danger px-4 ms-3"
          onClick={() => {
            setCurrentIndex(-1);
            setOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCountryModal;
