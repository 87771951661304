import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { changeTemplateStatusMany } from 'api/templateService'

type StatusUpdateModalType = {
  open: boolean;
  onClose: Function;
  childId: any;
  accessToken: string;
  selection: any;
  setTemplateSelected: any;
};

const StatusUpdateModal: React.FC<StatusUpdateModalType> = ({ open, onClose, accessToken, childId, selection, setTemplateSelected }): JSX.Element => {

  const [status, setStatus] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => setStatus(e.target.value)

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const templateIds = Object.entries(selection.list).filter(([id, isChecked]) => !!isChecked).map(([id]) => Number(id))
      await changeTemplateStatusMany({
        childId: Number(childId),
        templateIds: templateIds as number[],
        status: status as 'Draft' || 'Ready',
      }, accessToken)
      setIsLoading(false)
      setTemplateSelected((templates: any) => {
        return templates.map((temp: any) => {
          if(templateIds.indexOf(temp.id) > -1){
            return { ...temp, status }
          }
          return temp
        })
      })
      onClose()
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => onClose()}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">Draft/Ready</h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Status : </p>
          <select
            className="mb-3 form-select"
            title={""}
            onChange={handleStatusChange}
            value={status}
            name={""}
          >
            <option value="" selected disabled>Select Status</option>
            <option value="Draft">{"Draft"}</option>
            <option value="Ready">{"Ready"}</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
          <button
            type="button"
            className="btn btn-danger px-4"
            onClick={handleSave}
            disabled={!status || isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => onClose()}
            disabled={isLoading}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default StatusUpdateModal