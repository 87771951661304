import ImageUploading, { ImageListType } from 'react-images-uploading';

import UploadImage from 'svgs/Upload-image.svg';

export interface ImageUploadPreviewProps {
  onChange: (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => void;
  images: ImageListType;
}
const ImageUploadPrviewer: React.FC<ImageUploadPreviewProps> = ({
  ...props
}) => {
  const { onChange, images } = props;
  const maxNumber = 100;

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div className="upload__image-wrapper mb-3">
          <button
              className="btn btn-upload "
            style={isDragging ? { color: 'red' } : undefined}
            onClick={onImageUpload}
            {...dragProps}
          >
            <span>
              <i className="fa-solid fa-cloud-arrow-up heading1"></i>
              <span className="d-block">Upload logos</span>
            </span>
          </button>
          <div className="mt-3">
            <div className="row g-3">
              {imageList.map((image, index) => (
                  <div key={index} className="col-auto">
                    <div key={index} className="avatar position-relative overflow-visible">
                      <img className="rounded " src={image['data_url']} alt="" />
                      <button className="btn btn-link-danger btn-x" onClick={() => onImageRemove(index)}>{''}
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </ImageUploading>
  );
};

export default ImageUploadPrviewer;
