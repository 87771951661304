import styles from "./PopupImage.module.scss";

const PopupImage = (props: any) => {

  const {
    backgroundImageUrl = "",
    graphicImageUrl = "",
    backBackgroundImageUrl = "",
    backGraphicImageUrl = "",
    design,
  } = props;

  return (
    <div className={styles.image_container}>
      {design === "Front and Back" && (
        <div className={styles.back_img}>
          <img src={backBackgroundImageUrl} alt="" />
          <img src={backGraphicImageUrl} alt="" />
        </div>
      )}
      <div
        className={`${
          design === "Front and Back" ? styles.front_img : styles.noBackImage
        }`}
      >
        <img src={backgroundImageUrl} alt="" />
        <img src={graphicImageUrl} alt="" />
      </div>
    </div>
  );

};

export default PopupImage;
