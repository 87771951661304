import clsx from 'clsx';

import * as React from 'react'
import { ProgressBar } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { FormValues } from './BodyTemplateSetUp';

interface Props {}

const UploadFileList: React.FC<Props> = () => {

  const { watch, formState: { isSubmitting, errors } } = useFormContext<FormValues>()
  
  const files = watch('files')
  const currentFile = watch('currentFile')
  const progress = watch('progress')
  const isFilesUploaded = watch('isFilesUploaded')

  const showMessage = isFilesUploaded || !!errors?.root


  return (
    <div className="body-set-up-files">
      <div className={clsx({ "d-none": !showMessage })}>
        <small className="text-danger">
          {isFilesUploaded ? "files uploaded successfully." : errors?.root?.message}
        </small>
      </div>
      <div className={clsx("upload-progress", { "d-none": !isSubmitting })}>
        <p>
          Uploading: <span>{currentFile}</span>({progress}%)
        </p>
        {!!currentFile && <ProgressBar now={progress} />}
        <hr />
      </div>
      <table>
        <thead>
          <tr>
            <th>SN</th>
            <th>Name</th>
            <th>Size</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {files?.map((file, i) => (
            <tr key={file.name}>
              <td>{i + 1}</td>
              <td>
                {file.name}{" "}
                <i
                  className={clsx("fa-solid", {
                    "fa-check": file.status === "valid",
                    "fa-circle-xmark": file.status === "invalid",
                  })}
                ></i>
              </td>
              <td>{file.size}</td>
              <td>{file.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UploadFileList