import {
  createTemplatePricingService,
  deleteTemplatePricingService,
  fetchAllTemplatePricingService,
  updateTemplatePricingService,
} from "api/templateService";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import React from "react";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import PricingAddUpdateModal from "./PricingAddUpdateModal";
import { toast } from "react-toastify";
type Pricing = {
  id?: string | number;
  dozenValue: string;
  pricePerUnit: string;
};
type PricingTableProps = {
  templateId: string | number;
};
const defaultValue: Pricing = {
  id: "",
  dozenValue: "",
  pricePerUnit: "",
};

const PricingTable: FC<PricingTableProps> = (props): JSX.Element => {
  const { templateId } = props;
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState<boolean>(false);
  const [pricingList, setPricingList] = useState<Pricing[]>([]);
  const [currentPricing, setCurrentPricing] = useState<Pricing>({
    ...defaultValue,
  });
  const [pricingAddUpdateModalOpen, setPricingAddUpdateModalOpen] =
    useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const handleAddUpdateCallBack = async (pricing: Pricing) => {
    //add
    if (!pricing.id) {
      try {
        setLoading(true);
        const payload = {
          dozenValue: pricing.dozenValue,
          pricePerUnit: pricing.pricePerUnit,
          templateId: templateId,
        };
        const { data } = await createTemplatePricingService(
          payload,
          accessToken
        );
        if (data.data) {
          const details: Pricing = data.data;

          setPricingList([...pricingList, details]);

          alert("Added successfully");

          setPricingAddUpdateModalOpen(false);
          setCurrentPricing(defaultValue);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Unable to add");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    //update
    else {
      try {
        setLoading(true);
        const payload = {
          ...pricing,
          templateId: templateId,
        };
        const { data } = await updateTemplatePricingService(
          pricing.id,
          payload,
          accessToken
        );
        if (data.data) {
          const details = data.data;
          let rslt = pricingList.map((el, index) =>
            el.id == pricing.id ? { ...pricing } : el
          );
          setPricingList([...rslt]);

          alert("Updated successfully");
          setLoading(false);

          setPricingAddUpdateModalOpen(false);
          setCurrentPricing(defaultValue);
        }
      } catch (error) {
        console.error(error);
        alert("Unable to update");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDelete = async () => {
    let id=currentPricing.id!;
    try {
      setLoading(true);
      const { data } = await deleteTemplatePricingService(id, accessToken);
      if (data.data) {
        let rslta = pricingList.filter((el) => el.id != id);
        setPricingList([...rslta]);
        setLoading(false);
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
      toast.warn('Unable to delete');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const getAllTemplatePricing = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllTemplatePricingService(
          accessToken,
          templateId
        );
        if (data?.data) {
          setPricingList(data?.data);
          setLoading(false);
        }
        setLoading(false);
        //setOpen(false);
      } catch (error) {
        console.error(error);
        return [];
      } finally {
        setLoading(false);
      }
    };
    getAllTemplatePricing();
  }, [accessToken, templateId]);

  if (loading) return <SpinnerLoader />;
  return (
    <>
      <p className="mb-2">Pricing Table</p>

      <div className="row g-3">
        <div className="col-md-4">
          <p className="mb-0 text-secondary">{"Number of dozen"}</p>
        </div>
        <div className="col-md-4">
          <p className="mb-0 text-secondary">{"Price per unit($)"}</p>
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setCurrentPricing(defaultValue);
              setPricingAddUpdateModalOpen(true);
            }}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <text className="mb-0 text-secondary">{"New Pricing"}</text>
          </button>
        </div>
      </div>

      {pricingList && pricingList.length > 0 ? (
        <ul className={`list-group list-group-flush list-style-none mb-2`}>
          {React.Children.toArray(
            pricingList.map((pricing, key) => (
              <li key={`var-${key}`}>
                <div className="card mb-3">
                  <div className="card-body p-2">
                    <div className="row g-3">
                      <div className="col-md-4">
                        <p className="mb-0 text-secondary">
                          {pricing.dozenValue}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-0 text-secondary">
                          {pricing.pricePerUnit}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-link-danger ms-3"
                          type="button"
                          onClick={() => {
                            setCurrentPricing(pricing);
                            setPricingAddUpdateModalOpen(true);
                          }}
                        >
                          <i className="fa-solid fa-pen">{""}</i>
                        </button>
                        <button
                          className="btn btn-link-danger ms-3"
                          type="button"
                          onClick={() => {
                            setOpenConfirmationModal(true);
                            setCurrentPricing(pricing);
                          }}
                        >
                          <i className="fa-solid fa-trash-can">{""}</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
      ) : (
        <div className="card mb-3">
          <div className="card-body p-2">
            <div className="row g-3">
              <div className="col-md-4">
                <p className="mb-0 text-secondary">{"0"}</p>
              </div>
              <div className="col-md-4">
                <p className="mb-0 text-secondary">{"0"}</p>
              </div>
            </div>
          </div>
        </div>
      )}
        {   openConfirmationModal && 
            <ConfirmationModal 
            open={openConfirmationModal} 
            setOpen={setOpenConfirmationModal} 
            handleCallBack={handleDelete} 
            confirmationMessage={"Are you sure you want to delete?"}
            />
        }
        
      {pricingAddUpdateModalOpen && (
        <PricingAddUpdateModal
          open={pricingAddUpdateModalOpen}
          setOpen={setPricingAddUpdateModalOpen}
          pricing={currentPricing}
          handleAddUpdateCallBack={handleAddUpdateCallBack}
        />
      )}
    </>
  );
};

export default PricingTable;
