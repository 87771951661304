import { ColDef, RowSpanParams } from "ag-grid-community";
import ShowCellRenderer from "./common/ShowCellContainer";
import ImageCell from "./ImageCell";
import CSVCellContainer from "./common/CSVCellContainer";

export interface PurchaseOrder {
  id: number;
  po: string;
  status: string;
  orderItems: OrderItem[];
  [x: string]: any;
}

export interface OrderItem {
  templateName: string;
  style: string;
  subStyle: string;
  body: string;
  color: string;
  sizeScale: string;
  price: number;
  quantity: number;
  [x: string]: any;
}

interface Order {
  id: string | number;
  id_x: string | number;
  purchaseOrders: PurchaseOrder[];
  userInfo: {
    name: any;
    firstName: string;
    lastName: string;
    email: string;
  };
  total: number;
  orderDate: Date | string;
  [x: string]: any;
}

interface ConvertedOrder {
  id?: string;
  id_x: string | number;
  userName?: string;
  po: string;
  status: string;
  total: number;
  orderDate: Date | string;
  [x: string]: any;
}

const rowSpan = (params: RowSpanParams) => {
  if (params.data.id_x) {
    const [_, totalItems = 1] = params.data.id_x?.split("-");
    return +totalItems;
  } else {
    return 1;
  }
};
export const orderPageDefaultColDefs = {
  resizable: true,
  width: 170,
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
};

export const singleOrderPageDefaultColDefs = {
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  rowHeight: 20,
};

export const singleOrderPageColDefs: ColDef[] = [
  {
    field: "image",
    // flex: 1,
    headerName: "Image",
    // Set ImageRenderer component as cell renderer
    cellRenderer: ImageCell,
  },

  {
    field: "apparelOrderId",
    headerName: "Apparel Order Id",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
  },
  // { field: "id", filter: true },
  { field: "style", filter: true },
  { field: "subStyle", filter: true },
  // { field: "body", filter: true },
  // { field: "color", filter: true },
  { field: "sizeScale", filter: true },
  { field: "PO", headerName: "PO", filter: true },
  { field: "status", filter: true },
  { field: "price", filter: true },
  { field: "quantity", filter: true },
  {
    field: "orderNumber_orderItemId_skuConcat",
    // flex: 1,
    headerName: "Actions",
    // Set ImageRenderer component as cell renderer
    cellRenderer: CSVCellContainer,
  },

  {
    field: "apparelNotes",
    headerName: "Notes",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
  },
];

export const columnDefs: ColDef[] = [
  {
    field: "orderNumber",
    headerName: "Id",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
    width: 100,
    minWidth: 100,
  },
  {
    field: "userName",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
    width: 150,
    minWidth: 150,
    filter: true,
  },
  {
    field: "userEmail",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
    width: 200,
    minWidth: 200,
    filter: true,
  },
  {
    field: "parentAccName", //customerAccName
    headerName: "Customer Name",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
    width: 200,
    minWidth: 200,
    filter: true,
  },
  {
    field: "customerAccName",
    headerName: "Customer Account",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
    width: 200,
    minWidth: 200,
    filter: true,
  },
  {
    field: "orderDate",
    cellRenderer: ShowCellRenderer,
    rowSpan: rowSpan,
    cellClassRules: {
      "show-cell": "value !== undefined",
    },
    width: 100,
    minWidth: 100,
    filter: true,
  },
  { field: "po", headerName: "PO", filter: true, width: 180, minWidth: 180, },
  { field: "status", filter: true, width: 150, minWidth: 150, },
  { field: "total", filter: true, width: 100, minWidth: 100, },
  { field: "synced", filter: true, width: 100, minWidth: 100, },
];


export const convertOrderTableDataToAgGridData = (
  inputData: Order[]
): ConvertedOrder[] => {
  const output: ConvertedOrder[] = [];

  inputData.forEach((order) => {
    order.purchaseOrders.forEach((purchaseOrder, index) => {
      let obj: any = {
        orderNumber: order.orderNumber,
        // id: order.id + "-" + order.purchaseOrders.length.toString(),
        id: order.id,
        ...(index === 0 && {
          id_x: order.id + "-" + order.purchaseOrders.length.toString(),
        }),
        ...(index === 0 && {
          userName:
            ((order.userInfo?.name || '')
              ? (order.userInfo?.name || '')
              : (order.userInfo?.firstName || '') || (order.userInfo?.lastName || '')
                ? (order.userInfo?.firstName || '') + " " + (order.userInfo?.lastName || '')
                : "") +
            "-" +
            order.purchaseOrders.length.toString(),
        }),
        ...(index === 0 && {
          userEmail:
            (order.userInfo?.email || '')+ "-" + order.purchaseOrders.length.toString(),
        }),
        ...(index === 0 && {
          orderDate:
            new Date(order.orderDate).toLocaleDateString() +
            "-" +
            order.purchaseOrders.length.toString(),
        }),
        ...(index === 0 && {
          parentAccName: order.parentInfo?.accountName || '',
        }),
        po: purchaseOrder.po,
        customerAccName: purchaseOrder.childInfo?.customerAccountName || '',
        status: purchaseOrder.status,
        total: order.total,
        synced: order.isAMSynced ? 'Yes' : 'No',
        // orderDate: order.orderDate,
      };
      if (index > 0) {
        delete obj["id"];
      }
      output.push(obj);
    });
  });

  return output;
};

export const convertSingleOrderDataToAgGridData = (data: {
  purchaseOrders: PurchaseOrder[];
  [x: string]: any;
}): OrderItem[] => {
  const res: OrderItem[] = [];
  data.purchaseOrders.forEach((purchaseOrder: PurchaseOrder) => {
    purchaseOrder.orderItems.forEach((item: OrderItem) => {
      console.log({ purchaseOrder, item });
      res.push({
        ...purchaseOrder,
        id: purchaseOrder.id,
        id_orderNumber: purchaseOrder.id + "_" + data.orderNumber,
        orderNumber_orderItemId_skuConcat: `${data.orderNumber}>>>${item.id}>>>${item.skuConcat}`,
        PO: purchaseOrder.PO,
        status: purchaseOrder.status,
        templateName: item.templateName,
        style: item.style,
        subStyle: item.subStyle,
        body: item.body,
        color: item.color,
        sizeScale: item.sizeScale,
        price: item.price,
        quantity: item.quantity,
        image: {
          backBackgroundImageUrl: item.backBackgroundImageUrl,
          backGraphicImageUrl: item.backGraphicImageUrl,
          backgroundImageUrl: item.backgroundImageUrl,
          design: item.design,
          graphicImageUrl: item.graphicImageUrl,
          imageUrl: item.imageUrl
        },
      });
    });
  });
  return res;
};
