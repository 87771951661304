import * as React from 'react'
import * as templateService from 'api/templateService'

const LabelBasedFilter = (props: any) => {

    const [availLabel, setAvailLabels] = React.useState([])
    const [templateLabelMap, setTemplateLabelMap] = React.useState<{ [key: string]: { [key: string]: boolean } }>({})
    const [labelMap, setLabelMap] = React.useState<{ [key: string]: boolean }>({})

    React.useEffect(() => {
        async function fetchTemplateLabels() {
            try {
                const payload = { templateIds: props.templateList.map((temp: any) => temp.id) }
                const response = await templateService.fetchTemplateLabel(props.accessToken, payload)
                const templateLabels = response.data?.data || []
                const _map = templateLabels.reduce((a: any, c: any) => {
                    if (!a[c.templateId]) a[c.templateId] = {}
                    a[c.templateId][c.labelId] = true
                    return a
                }, {})
                const labelMap = templateLabels.reduce((a: any, c: any) => {
                    if (!a[c.labelId])
                        a[c.labelId] = true
                    return a
                }, {})
                setTemplateLabelMap(_map)
                setLabelMap(labelMap)
            } catch (err) {
                console.error(err)
            }
        }
        fetchTemplateLabels()
    }, [props.templateList, props.accessToken])

    React.useEffect(() => {
        setAvailLabels(props.labels.filter((item: any) => !!labelMap[item.id]))
    }, [labelMap, props.labels])

    React.useEffect(() => {
        const labelIds = Object.entries(props.selLabels).filter(([k, v]) => !!v).map(([k]) => k) as string[]
        if (!labelIds.length) {
            props.setFilteredTemplateList(props.templateList)
            return
        }
        const filteredTemplates = props.templateList.reduce((a: any, c: any) => {
            const _map = templateLabelMap[c.id]
            if (!a.map[c.id] && _map) {
                const idx = labelIds.findIndex((id) => !!_map[id])
                if (idx > -1) {
                    a.list.push(c)
                    a.map[c.id] = true
                }
            }
            return a
        }, { map: {}, list: [] })
        props.setFilteredTemplateList(filteredTemplates.list)
    }, [props.templateList, props.selLabels, templateLabelMap])


    return (
        <div className="dropdown-menu py-2">
            <div className="d-flex justify-content-between align-items-center mb-2 px-2">
                <p className="mb-0">Labels:</p>

                <button
                    className="btn btn-link-danger text-danger"
                    onClick={() => props.handleClose()}
                >
                    <span className="fa-solid fa-window-close"></span>
                </button>
            </div>
            <div
                className="px-2"
                style={{
                    maxHeight: "250px",
                    overflow: "hidden",
                    overflowY: "auto",
                }}
            >
                {availLabel.map((item: any) => (
                    <div
                        key={item.id}
                        className="custom-checkbox grey-shade mb-2"
                    >
                        <input
                            className={'custom-control-input'}
                            type="checkbox"
                            id={item.id}
                            name={item.id}
                            onChange={(e) => props.handleFilter(e.target.name, e.target.checked)}
                            checked={props.selLabels[item.id]}
                        />
                        <label className="custom-control-label" htmlFor={item.id}>
                            {item.title}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LabelBasedFilter