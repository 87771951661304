/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { fetchJSONFromURLService } from 'api/templateService'

const useTemplateJSONColors = (template: any) => {
 
  const [colors, setColors] = useState<any[]>([])

  useEffect(() => {
    async function fetchAvailablGraphics(template: any){

      try{
        const [frontColors, backColors] = await fetchTemplateJSONColors(template)
        const bodyColors = frontColors.find((color: any) => color.name === 'body color') || []
        const frontGraphicColors = frontColors.find((color: any) => color.name === 'graphic color' || color.name === 'graphic color 1') || []
        const frontGraphicColors2 = frontColors.find((color: any) => color.name === 'graphic color 2') || []
        const backGraphicColors = backColors?.find((color: any) => color.name === 'graphic color' || color.name === 'graphic color 1') || []
        const backGraphicColors2 = backColors?.find((color: any) => color.name === 'graphic color 2') || []
  
  
        const body = serializeColors(bodyColors, 'front', 'body')
        const frontGraphic = serializeColors(frontGraphicColors, 'front', 'graphic')
        const frontGraphic2 = serializeColors(frontGraphicColors2, 'front', 'graphic2')
        const backGraphic = serializeColors(backGraphicColors, 'back', 'graphic')
        const backGraphic2 = serializeColors(backGraphicColors2, 'back', 'graphic2')
  
        const colors: any[] = []
        colors.push(...body)
        colors.push(...frontGraphic)
        colors.push(...frontGraphic2)
        colors.push(...backGraphic)
        colors.push(...backGraphic2)
        setColors(colors)
      }catch(err){
        console.error(err)
      }

    }
    if(template){
      fetchAvailablGraphics(template)
    }
  }, [template])

  return categorizeColors(colors)
}

export default useTemplateJSONColors

async function fetchTemplateJSONColors(template: any) {

  const promises = [
    fetchJSONFromURLService(template.frontDescription).then((res: any) => res.data.swatches)
  ]
  if (template.backDescription) promises.push(
    fetchJSONFromURLService(template.backDescription).then((res: any) => res.data.swatches)
  )
  return Promise.all(promises)

}

function serializeColors(colors: any, design: any, category: any){
  const serializedColors: any[] = []
  if(colors.LtDk){
    colors.dark?.forEach((color: any) => {
      color.colorName = extractColorName(color.actual_name)
      serializedColors.push({...color, design, category, shade: 'dk'})
    })
    colors.light?.forEach((color: any) => {
      color.colorName = extractColorName(color.actual_name)
      serializedColors.push({...color, design, category, shade: 'lt'})
    })
  }else{
    colors.options?.forEach((color: any) => {
      color.colorName = extractColorName(color.actual_name)
      serializedColors.push({...color, design, category, shade: 'NA'})
    })
  }
  return serializedColors
}

function categorizeColors(_colors: any){
  return _colors.reduce(
    (a: any, c: any) => {
      if (c.design === 'front' && a[c.category]) {
        a[c.category].push(c)
        // } else if (c.design === 'back' && a['back_body'] && c.category === 'body') {
        //   a['back_body'].push(c)
      } else if (c.design === 'front' && a[`${c.category}_${c.shade}`]) {
        a[`${c.category}_${c.shade}`].push(c)
      } else if (c.design === 'back' && a[`back_${c.category}_${c.shade}`]) {
        a[`back_${c.category}_${c.shade}`].push(c)
      }
      return a
    },
    {
      body: [],
      //all lt
      graphic_lt: [],
      graphic2_lt: [],
      back_graphic_lt: [],
      back_graphic2_lt: [],
      // all dk
      graphic_dk: [],
      graphic2_dk: [],
      back_graphic_dk: [],
      back_graphic2_dk: [],
      // all NA
      graphic_NA: [],
      graphic2_NA: [],
      back_graphic_NA: [],
      back_graphic2_NA: [],
    },
  )
}


function extractColorName(name: string){ return name?.split('-')?.[0]?.trim() }