import React from "react";
import * as templateService from "api/templateService"

type ColorConfigProps = {
  accessToken: any;
  template: any;
  setSelectedColors: any;
  graphicRules: any;
  isGraphicLinked: boolean | undefined;
  isGraphic2Linked: boolean | undefined;
  colors: any;

}

interface IColorSelectProps { label: string, name: string, value: string, colorOptions: any, handleChange: any }

const ColorSelect: React.FC<IColorSelectProps> = ({ label, name, value, colorOptions, handleChange }): JSX.Element => {
  return (
    <>
      <div className="col-lg-3 align-self-center mb-2">{label}</div>
      <div className="col-lg-9 mb-2">
        <select
          className="form-select"
          name={name}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={value}
        >
          <option value="">Select</option>
          {colorOptions?.map((color: any) => (
            <option key={color.name} value={color.name}>
              {color.actual_name}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

const ColorConfig: React.FC<ColorConfigProps> = ({ 
  accessToken,
  template,
  graphicRules, 
  setSelectedColors, 
  isGraphicLinked,
  isGraphic2Linked,
  colors,
}): JSX.Element => {

  const [selected, setSelected] = React.useState<any>({
    body: '',
    shade: '',
    graphic: '',
    graphic2: '',
    backGraphic: '',
    backGraphic2: '',
    bodyColorName: ''
  })

  const bodyColorFilterFn = (c: any) => graphicRules?.bodyMap?.[c.colorName] === true
  const graphicColorFilterFn = (c: any) => {
    return graphicRules?.graphicMap?.[`${c.category}_${selected.bodyColorName}_${c.colorName}`] === true
  }

  const handleColorChange = (name: string, value: string) => {
    const obj = { [name]: value }
    if(name === 'main'){
      const [body, shade, bodyColorName] = value.split('<>')
      obj.body = body
      obj.shade = shade
      obj.bodyColorName = bodyColorName
    }

    if(isGraphicLinked && name === 'graphic') obj.backGraphic = value
    if(isGraphic2Linked && name === 'graphic2') obj.backGraphic2 = value

    setSelected((prev: any) => ({ ...prev, ...obj }))
  }

  const bodyColors = colors.body.filter(bodyColorFilterFn)
  const graphicFR = colors[`graphic_${selected.shade}`]?.filter(graphicColorFilterFn)
  const graphicBK = colors[`back_graphic_${selected.shade}`]?.filter(graphicColorFilterFn)
  const graphic2FR = colors[`graphic2_${selected.shade}`]?.filter(graphicColorFilterFn)
  const graphic2BK = colors[`back_graphic2_${selected.shade}`]?.filter(graphicColorFilterFn)

  React.useEffect(() => {
    templateService.fetchTemplateDefaultColors(accessToken, template.id)
    .then((res) => {
      const templateDefaults = res.data.data
      const bodyColor = colors.body.find((color: any) => color.name === templateDefaults.body)
      setSelected({
        main: bodyColor ? `${bodyColor.name}<>${bodyColor.shade}<>${bodyColor.colorName}` : '',
        body: templateDefaults.body,
        shade: templateDefaults.shade,
        graphic: templateDefaults.graphic,
        graphic2: templateDefaults.graphic2,
        backGraphic: templateDefaults.backGraphic,
        backGraphic2: templateDefaults.backGraphic2,
        bodyColorName: bodyColor?.colorName || ''
      })
    })
  }, [colors?.body.length])

  React.useEffect(() => {
    setSelectedColors({
      body: selected.body,
      shade: selected.shade,
      graphic: selected.graphic,
      graphic2: selected.graphic2,
      backGraphic: selected.backGraphic,
      backGraphic2: selected.backGraphic2,
    })
  }, [selected])



  return (
    <div className='row'>
      <ColorSelect
        label='Body Color'
        name='main'
        value={selected.main}
        colorOptions={bodyColors.map((item: any) => ({ ...item, name: `${item.name}<>${item.shade}<>${item.colorName}` }))}
        handleChange={handleColorChange}
      />
      {graphicFR?.length > 0 && (
        <ColorSelect
          label={isGraphicLinked ? 'Graphic 1' : 'Front Graphic 1'}
          name='graphic'
          value={isGraphicLinked ? (selected.graphic === selected.backGraphic ? selected.graphic : '') : selected.graphic}
          colorOptions={graphicFR}
          handleChange={handleColorChange}
        />
      )}
      {!isGraphicLinked && graphicBK?.length > 0 && (
        <ColorSelect
          label='Back Graphic 1'
          name='backGraphic'
          value={selected.backGraphic}
          colorOptions={graphicBK}
          handleChange={handleColorChange}
        />
      )}
      {graphic2FR?.length > 0 && (
        <ColorSelect
          label={isGraphic2Linked ? 'Graphic 2' : 'Front Graphic 2'}
          name='graphic2'
          value={isGraphic2Linked ? (selected.graphic2 === selected.backGraphic2 ? selected.graphic2 : '') : selected.graphic2}
          colorOptions={graphic2FR}
          handleChange={handleColorChange}
        />
      )}
      {!isGraphic2Linked && graphic2BK?.length > 0 && (
        <ColorSelect
          label='Back Graphic 2'
          name='backGraphic2'
          value={selected.backGraphic2}
          colorOptions={graphic2BK}
          handleChange={handleColorChange}
        />
      )}
    </div>
  )
}

export default ColorConfig