import JPG from './jpg.svg';
import AI from './ai.svg';
import JSON from './json.svg';
import ArrowRight from './arrow-right.svg';
import UploadCloud from './upload.svg';
import DownloadCloud from './download.svg';

export const IMAGES = {
    JPG,
    AI,
    JSON,
    ArrowRight,
    UploadCloud,
    DownloadCloud
}