import FileUploading from 'react-files-uploading';

import UploadImage from 'svgs/Upload-image.svg';

interface FilePreviewProps {
  file: File;
  index: number;
  onRemove: (index: number) => void;
}
interface ImagePreviewProps {
  file: File;
  index: number;
  onRemove: (index: number) => void;
}
export interface FileUploadPreviewProps {
  onChange: (fileList: File[], addUpdateIndex: number[] | undefined) => void;
  files: File[];
}
const FileUploadPrviewer: React.FC<FileUploadPreviewProps> = ({ ...props }) => {
  const { onChange, files } = props;
  const maxNumber = 500;

  return (
    <FileUploading
      multiple
      value={files}
      onChange={onChange}
      maxNumber={maxNumber}
    >
      {({
        fileList,
        errors,
        isDragging,
        onFileUpload,
        onFileRemoveAll,
        onFileUpdate,
        onFileRemove,
        dragProps,
      }) => (
        // write your building UI
        <>
          <div className="upload__image-wrapper mb-3">
            <button
              className="btn btn-upload "
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onFileUpload}
              {...dragProps}
            >
              <span>
                <i className="fa-solid fa-cloud-arrow-up heading1"></i>
                <span className="d-block">Upload logos</span>
              </span>
            </button>
            {fileList.length >= 2 ? (
              <button
                type="button"
                className="btn btn-link-danger"
                onClick={onFileRemoveAll}
              >
                {'Remove All'}
              </button>
            ) : (
              <></>
            )}
            <div className="mt-3">
              <div className="row g-3">
                {fileList.map((file, index) =>
                  isImageFile(file) ? (
                    <ImagePreview
                      file={file}
                      index={index}
                      onRemove={onFileRemove}
                    />
                  ) : (
                    <FilePreview
                      file={file}
                      index={index}
                      onRemove={onFileRemove}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </FileUploading>
  );
};

const ImagePreview: React.FC<ImagePreviewProps> = ({ ...props }) => {
  const { file, index, onRemove } = props;

  return (
    <div key={index} className="col-auto">
      <div key={index} className="avatar position-relative overflow-visible">
        <img className="rounded " src={URL.createObjectURL(file)} alt="" />
        <button
          className="btn btn-link-danger btn-x"
          onClick={() => onRemove(index)}
        >
          {''}
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  );
};
const FilePreview: React.FC<FilePreviewProps> = ({ ...props }) => {
  const { file, index, onRemove } = props;
  return (
    <ul className="list-group list-group-flush list-style-none mt-2">
      <li className="has-right-action list-item-mb-2" key={index}>
        <p className="mb-0 text-capitalize">{file.name}</p>
        <div className="action-wrap">
          <button
            type="button"
            className="btn btn-link-danger"
            onClick={() => onRemove(index)}
          >
            <i className="fa-solid fa-xmark">{''}</i>
          </button>
        </div>
      </li>
    </ul>
  );
};
const isImageFile = (file: File): boolean => {
  if (file.type.includes('image')) return true;
  return false;
};
export default FileUploadPrviewer;
