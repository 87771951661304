import React, { ReactNode } from 'react';

import './Button.css';

export interface IButton {
  icon?: ReactNode | undefined;
  label: string;
  onClick?: () => void;
  className?: string;
  disbale?: boolean;
  type?: 'primary' | 'danger' | 'secondary' | 'disabled';
}
const Button: React.FC<IButton> = ({
  label = 'Next',
  icon,
  onClick,
  className,

  ...props
}) => {
  return (
    <>
      {icon && icon}
      {
        <button
          className={`btn ${className ? className : ''}`}
          onClick={onClick}
        >
          {label}
        </button>
      }
    </>
  );
};

export default Button;
