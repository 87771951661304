import { FC } from "react";

  
  const CartTable: FC = (): JSX.Element => {
    
    return (
      <>
        
      </>
    );
  };
  
  export default CartTable;
  