import * as Yup from "yup";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, Resolver } from "react-hook-form";
import { ModalAction } from "./interface";
import * as Service from "api/inventoryService";
import { RHFInput, RHFRichTextEditor } from "components/form-hook";

interface iProp {
  modalAction: ModalAction;
  categoryId: number | null;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
  setModalAction: React.Dispatch<React.SetStateAction<ModalAction>>;
}

type FormValues = {
  title: string;
  subTitle: string;
  hexColor: string;
  description: string;
};

const schema = Yup.object({
  title: Yup.string().required("This field is required."),
  subTitle: Yup.string().required("This field is required."),
  hexColor: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
});

const CategoryModal: React.FC<iProp> = (props): JSX.Element => {
  const [loading, setLoading] = React.useState<boolean | null>(null);

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema) as Resolver<FormValues>,
    defaultValues: {
      title: "",
      subTitle: "",
      hexColor: "",
      description: "",
    },
  });

  const isSubmitting = methods.formState.isSubmitting;

  React.useEffect(() => {
    async function fetchCategory() {
      if (!props.categoryId) return;
      setLoading(true);
      Service.fetchCategory(props.categoryId)
        .then((data: any) => {
          if (data) methods.reset(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err.message);
          setLoading(false);
        });
    }
    fetchCategory();
  }, []);

  const handleSubmit = async (values: FormValues) => {
    try {
      if (props.categoryId) {
        await Service.updateCategory(props.categoryId, values);
      } else {
        await Service.createCategory(values);
      }
      props.setRefetch(true);
      props.setModalAction(null);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      show
      onHide={() => props.setModalAction(null)}
      className="custom-drawer date-picker-modal"
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Body>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <div className="border-bottom pb-1 mb-3">
              <h4 className="mb-0 heading4-bold">
                {props.modalAction} Category
              </h4>
            </div>

            <RHFInput name="title" label="title" />
            <RHFInput name="hexColor" label="hex color" />
            <RHFInput name="subTitle" label="subtitle" />
            <RHFRichTextEditor name="description" label="description" />

            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-danger px-4"
                disabled={loading || isSubmitting}
              >
                {isSubmitting ? "saving" : "save"}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger px-4 ms-3"
                onClick={() => props.setModalAction(null)}
                disabled={loading || isSubmitting}
              >
                Cancel
              </button>
            </div>
          </form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default CategoryModal;
