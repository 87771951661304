import * as React from 'react'
import Heading from "../../../ui/Heading/Heading";

interface IProps {
  templateList: any;
  handleLabelClick: any;
  handleTemplateClick: any;
}


const TemplateSelector: React.FC<IProps> = (props): JSX.Element => {

  const { labels, labelTemplateMap } = React.useMemo(() => {
    const labelTemplateMap = props.templateList.reduce((a: any, c: any) => {
      if (c.TemplateLabels?.length) {
        a = c.TemplateLabels.reduce((acc: any, curr: any) => {
          if (!acc[curr.Label.id])
            acc[curr.Label.id] = {
              id: curr.Label.id,
              name: curr.Label.title,
              children: {},
            };
          if (!acc[curr.Label.id].children[c.id])
            acc[curr.Label.id].children[c.id] = { id: c.id, name: c.name };
          return acc;
        }, a);
      }
      return a;
    }, {})

    const labels = Object.values(labelTemplateMap).map((item: any) => ({
      id: item.id,
      name: item.name,
    }))

    return {
      labels, labelTemplateMap
    }

  }, [props.templateList]) as any

  const sortedTemplateList = React.useMemo(() => {
    return [ ...props?.templateList || []].sort((a, b) => a.id - b.id)
  }, [props.templateList])

  return (
    <>
      <Heading headingTitle="Templates" wrapperClassName="mb-2" />
      <select
        className='form-select mb-3'
        title={'Select Template'}
        onChange={(e) => props.handleTemplateClick(Number(e.target.value))}
        value={''}
        name={'template'}
      >
        {<option value='' disabled selected>Select template</option>}
        {React.Children.toArray(
          sortedTemplateList.map((el: any) => (
            <option key={el.id} value={el.id}>{el.name} (ID - {el.id})</option>
          ))
        )}
      </select>

      <Heading headingTitle="Labels" wrapperClassName="mb-2" />
      <select
        className='form-select mb-3'
        title={'Select Label'}
        onChange={(e) => props.handleLabelClick(labelTemplateMap[e.target.value]?.children)}
        value={''}
        name={'label'}
      >
        {<option value='' disabled selected>Select label</option>}
        {React.Children.toArray(
          labels.map((el: any) => (
            <option key={el.id} value={el.id}>{el.name}</option>
          ))
        )}
      </select>
    </>
  )
}

export default TemplateSelector