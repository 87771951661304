import * as React from 'react'
import styles from "./../TemplateConfigDialog.module.scss";
import { IkeyValuePair } from 'api/requestTypes';
enum KEY_TYPES { FIXED_KEY = 'Fixed Key', LOCATION_KEY = 'Location Key' }
const LOCATION_KEYS = [
    "abbreviation", "destination", "establishedDate", "establishedDateFirstTwo",
    "establishedDateLastTwo", "name", "stackedName", "location",
]
interface IProps {
    keyValueList: IkeyValuePair[];
    templateLayers: any;
    defaultFRLayers: any;
    setDefaultFRLayers: any;
    defaultBKLayers: any;
    setDefaultBKLayers: any;
}
interface ITemplateLayer {
    design: string;
    keyValues: IkeyValuePair[],
    layers: any;
    setLayers: any;
}
const capFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

const DefaultTemplateVariable: React.FC<IProps> = (props): JSX.Element => {

    const { defaultFRLayers, defaultBKLayers, setDefaultFRLayers, setDefaultBKLayers } = props

    React.useEffect(() => {
        setDefaultFRLayers(props.templateLayers.frontLayers || [])
        setDefaultBKLayers(props.templateLayers.backLayers || [])
    }, [props.templateLayers])


    return (
        <>
            {defaultFRLayers?.length === 0 && defaultBKLayers?.length === 0 && (
                <p style={{ textAlign: "center" }}>No layers</p>
            )}
            {defaultFRLayers?.length > 0 ? (
                <>
                    <p style={{ fontWeight: 'bold', marginBottom: '7px' }}>Front variables</p>
                    <TemplateLayer
                        design='front'
                        layers={defaultFRLayers}
                        setLayers={setDefaultFRLayers}
                        keyValues={props.keyValueList}
                    />
                </>
            ) : null}
            {defaultBKLayers?.length > 0 ? (
                <>
                    <br />
                    <p style={{ fontWeight: 'bold', marginBottom: '7px' }}>Back variables</p>
                    <TemplateLayer
                        design='back'
                        layers={defaultBKLayers}
                        setLayers={setDefaultBKLayers}
                        keyValues={props.keyValueList}
                    />
                </>

            ) : null}
        </>
    )
}

export default DefaultTemplateVariable

const TemplateLayer: React.FC<ITemplateLayer> = (props: any): JSX.Element => {

    const keyValueMap = React.useMemo(() => {
        return props.keyValues.reduce((a: any, c: any) => {
            a[c.key] = c.value?.split(',') || []
            return a
        }, {})
    }, [props.keyValues])

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, idx: number) => {

        props.setLayers((prev: any) => {
            const vList = [...prev] as any
            vList[idx][e.target.name] = e.target.value
            if (e.target.name === 'type') {
                vList[idx].keyName = ''
                vList[idx].defaultLayerValue = ''
            }
            return vList
        })
    }

    return (
        <>
            <ul
                className={`list-group list-group-flush list-style-none mb-2 ${styles.templateConfigList}`}
            >
                <div className="row">
                    <div className="col-lg-3">
                        <label>Variable Name</label>
                    </div>
                    <div className="col-lg-3">
                        <label>Key Type</label>
                    </div>
                    <div className="col-lg-3">
                        <label>Variable Key</label>
                    </div>
                    <div className="col-lg-3">
                        <label>Default Value</label>
                    </div>
                </div>
                {props.layers.map((layer: any, idx: any) => (
                    <div className="row align-items-center">
                        <div className="col-lg-3" style={{ marginBottom: '10px' }}>
                            <select className="form-select" disabled>
                                <option value='ok'>
                                    {layer.layerName}
                                </option>
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <select
                                className="form-select"
                                title={""}
                                onChange={(e) => handleChange(e, idx)}
                                name='type'
                                value={layer.type}
                            >
                                <option value='' disabled>Select</option>
                                <option value={KEY_TYPES.FIXED_KEY}>Fixed Key</option>
                                <option value={KEY_TYPES.LOCATION_KEY}>Location Key</option>
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <select
                                className="form-select"
                                title={""}
                                onChange={(e) => handleChange(e, idx)}
                                value={layer.keyName}
                                name='keyName'
                            >
                                <option value='' disabled>Select</option>
                                {layer.type === KEY_TYPES.FIXED_KEY && (
                                    <>
                                        {props.keyValues?.map((item: any) => (
                                            <option value={item.key}>{item.key} : {layer.type}</option>
                                        ))}
                                    </>
                                )}
                                {layer.type === KEY_TYPES.LOCATION_KEY && (
                                    <>
                                        {LOCATION_KEYS?.map((item: any) => (
                                            <option value={item}>{item} : {layer.type}</option>
                                        ))}
                                    </>
                                )}
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <select
                                className="form-select"
                                title={""}
                                onChange={(e) => handleChange(e, idx)}
                                name='defaultLayerValue'
                            >
                                <option value='' disabled selected>Select</option>
                                {keyValueMap[capFirst(layer.keyName || '')]?.map((value: string, i: any) => (
                                    <option
                                        key={i}
                                        value={value}
                                        selected={value === layer.defaultLayerValue}
                                    >
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                ))}
            </ul>
        </>
    )

}