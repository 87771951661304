import * as React from "react";
import { toast } from "react-toastify";
import { Container, Modal } from "react-bootstrap";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import * as customerService from "api/customerService";
type Logo = { id: number; logoUrl: string; fileName: string, isFrontDefault: boolean, isFrontActive: boolean, isBackDefault: boolean, isBackActive: boolean };
type GroupedLogo = { name: string; logos: { [key: string]: Logo } };
type Selection = {
  defaultFrontLogo: string;
  defaultBackLogo: string;
  allFrontLogos: boolean;
  allBackLogos: boolean;
  front: { [key: string]: boolean },
  back: { [key: string]: boolean }
}
enum LogoMapper {
  MONO_LT = "1Color_lt.png",
  MONO_DK = "1Color_dk.png",
  MULTI_LT = "FullColor_lt.png",
  MULTI_DK = "FullColor_dk.png",
}

const OneAndFullColorMap = {
  [LogoMapper.MONO_LT]: "1Color",
  [LogoMapper.MONO_DK]: "1Color",
  [LogoMapper.MULTI_LT]: "FullColor",
  [LogoMapper.MULTI_DK]: "FullColor",
} as { [key: string]: string };

const logoShadeMap = {
  [LogoMapper.MONO_LT]: "lt",
  [LogoMapper.MONO_DK]: "dk",
  [LogoMapper.MULTI_LT]: "lt",
  [LogoMapper.MULTI_DK]: "dk",
} as { [key: string]: string };

const styles = {
  table: { width: "100%" },
  logoCol: { width: "20%", color: "rgba(0,0,0,0.7)" },
  fileNameCol: { width: "40%", color: "rgba(0,0,0,0.7)" },
  frontLogoCol: { width: "20" },
  backLogoCol: { width: "20%" },
  modalImage: { width: "60px", height: "60px", margin: "0 auto" },
};

const AccountLogoDefaults = (props: any) => {
  const { logos, setLogoList } = props;
  const { accessToken } = useAppSelector(getUserDetails)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selection, setSelection] = React.useState<Selection>({
    defaultFrontLogo: '',
    defaultBackLogo: '',
    allFrontLogos: false,
    allBackLogos: false,
    front: {},
    back: {}
  })
  const [groupedLogos, groupedLogoMap, logoIdShadeMap] = React.useMemo(() => {
    const grouped = logos.reduce((a: any, c: Logo) => {
      const found = Object.entries(LogoMapper).find(([k, v]) =>
        c.logoUrl.endsWith(v)
      );
      if (!found) return a;
      const [_, logoSuffix] = found;
      const _re = new RegExp(`_${logoSuffix}$`);
      let logoName = c.logoUrl.replace(_re, "");
      logoName = logoName.substring(logoName.lastIndexOf("/") + 1);
      const colorType = OneAndFullColorMap[logoSuffix];
      const logoShade = logoShadeMap[logoSuffix];
      const groupName = `${logoName}_${colorType}`;
      const fileName = `${logoName}_${logoSuffix.replace(/.png$/, "")}`;
      if (!a[groupName]) a[groupName] = {};
      a[groupName][logoShade] = { ...c, fileName: fileName };
      // a[groupName][logoShade] = { ...c, fileName: fileName };
      return a;
    }, {});
    const logoIdShadeMap = logos.reduce((a: any, c: Logo) => {
      const found = Object.entries(LogoMapper).find(([k, v]) =>
        c.logoUrl.endsWith(v)
      );
      if (!found) return a;
      const [_, logoSuffix] = found;
      a[c.id] = logoShadeMap[logoSuffix] || '';
      return a;
    }, {});
    return [
      Object.entries(grouped).map(([name, logos]) => ({
        name,
        logos,
      })).sort((a, b) => a.name.localeCompare(b.name)) as GroupedLogo[],
      grouped,
      logoIdShadeMap,
    ];
  }, [logos]);

  const logoGroupNameToIdMap = React.useCallback(
    (key: string | string[]) => {
      const map = groupedLogoMap;
      const logoIdMap = {} as { [key: string]: boolean };
      if (Array.isArray(key)) {
        key.forEach((__k) => {
          if (map[__k]?.["lt"]?.id) logoIdMap[map[__k]["lt"].id] = true;
          if (map[__k]?.["dk"]?.id) logoIdMap[map[__k]["dk"].id] = true;
        });
      } else if (map[key]) {
        if (map[key]?.["lt"]?.id) logoIdMap[map[key]["lt"].id] = true;
        if (map[key]?.["dk"]?.id) logoIdMap[map[key]["dk"].id] = true;
      }
      return logoIdMap;
    },
    [groupedLogoMap]
  );

  const updateLogosBySelection = (_selection: Selection, logos: any, logoIdShadeMap: any) => {
    const frontDefaultLogoGroupName: string = _selection.defaultFrontLogo
    const backDefaultLogoGroupName: string = _selection.defaultBackLogo
    const frontActiveLogoGroupNames: string[] = Object.entries(_selection.front).filter(([k, v]) => v).map(([k]) => k)
    const backActiveLogoGroupNames: string[] = Object.entries(_selection.back).filter(([k, v]) => v).map(([k]) => k)

    const frontDefaultLogoMap = logoGroupNameToIdMap(frontDefaultLogoGroupName)
    const backDefaultLogoMap = logoGroupNameToIdMap(backDefaultLogoGroupName)
    const frontActiveLogoMap = logoGroupNameToIdMap(frontActiveLogoGroupNames)
    const backActiveLogoMap = logoGroupNameToIdMap(backActiveLogoGroupNames)
    const _logos = logos.reduce((a: any, c: any) => {
      const logoObj = { id: c.id } as { [key: string]: boolean | number }
      logoObj.logoUrl = c.logoUrl
      logoObj.isFrontDefault = !!frontDefaultLogoMap[c.id]
      logoObj.isFrontActive = !!frontActiveLogoMap[c.id]
      logoObj.isBackDefault = !!backDefaultLogoMap[c.id]
      logoObj.isBackActive = !!backActiveLogoMap[c.id]
      logoObj.shade = logoIdShadeMap[c.id] || ''
      a.push(logoObj)
      return a
    }, [])

    return _logos
  }

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'front' | 'back') => {
    if (e.target.name === 'allFrontLogos' || e.target.name === 'allBackLogos') {

      setSelection((prev: any) => {
        const _singleTypeSelection = Object.keys(prev[type]).reduce((a, c) => {
          a[type][c] = e.target.checked
          return a
        }, { ...prev })
        return { ..._singleTypeSelection, [e.target.name]: e.target.checked }
      })

    } else {
      const _singleTypeSelection = { ...selection[type], [e.target.name]: e.target.checked }
      const checkAllValue = Object.values(_singleTypeSelection).every((val) => val)
      const checkAllKey = type === 'front' ? 'allFrontLogos' : 'allBackLogos'
      setSelection((prev: any) => ({ ...prev, [checkAllKey]: checkAllValue, [type]: _singleTypeSelection }))
    }
  }

  const handleDefaultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.name, e.target.value)
    setSelection((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  React.useEffect(() => {

    const _selection: Selection = groupedLogos.reduce((a: any, c: GroupedLogo) => {
      const _isFrontDefault = c.logos.lt?.isFrontDefault || c.logos.dk?.isFrontDefault
      const _isBackDefault = c.logos.lt?.isBackDefault || c.logos.dk?.isBackDefault
      const _isFrontActive = c.logos.lt?.isFrontActive || c.logos.dk?.isFrontActive
      const _isBackActive = c.logos.lt?.isBackActive || c.logos.dk?.isBackActive

      a.allFrontLogos = a.allFrontLogos && _isFrontActive
      a.allBackLogos = a.allFrontLogos && _isBackActive

      if (_isFrontDefault) a.defaultFrontLogo = c.name
      if (_isBackDefault) a.defaultBackLogo = c.name

      a.front[c.name] = _isFrontActive
      a.back[c.name] = _isBackActive
      return a
    }, { allFrontLogos: true, front: {}, allBackLogos: true, back: {} })

    setSelection(_selection)

  }, [groupedLogos])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      const updatedLogos = updateLogosBySelection(selection, logos, logoIdShadeMap)
      await customerService.updateLogoActiveAndDefault(accessToken,
        { logos: updatedLogos?.map(({ logoUrl, ...rest }: any) => rest) }
      )
      toast.success('Logo defaults updated successfully')
      setLogoList(updatedLogos)
      props.handleClose()
    } catch (err) {
      console.error(err)
      toast.error('Failed to update Logo defaults')
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={props.open}
      onHide={props.handleClose}
      aria-labelledby="account-logo-defaults"
      backdrop="static"
      size={"lg"}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <h4 className="mb-0">Customer Account Logo Defaults</h4>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div
              className="default-logos"
              style={{ maxHeight: "60vh", overflow: "auto" }}
            >
              <table style={styles.table}>
                <tr>
                  <td align="center" style={styles.logoCol}></td>
                  <td align="center" style={styles.fileNameCol}></td>
                  <td
                    align="center"
                    style={styles.frontLogoCol}
                    className=" heading4-bold"
                  >
                    Front Logo
                  </td>
                  <td
                    align="center"
                    style={styles.backLogoCol}
                    className=" heading4-bold"
                  >
                    Back Logo
                  </td>
                </tr>
                <tr>
                  <td align="center" style={styles.logoCol}>
                    Logo
                  </td>
                  <td align="center" style={styles.fileNameCol}>
                    Filename
                  </td>
                  <td
                    style={styles.frontLogoCol}
                  >
                    <table style={{ width: '100%' }}>
                      <tr>
                        <td>Default</td>
                        <td>Active</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <div className="custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="allFrontLogos"
                              name="allFrontLogos"
                              checked={selection.allFrontLogos}
                              onChange={(e) => handleActiveChange(e, 'front')}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="allFrontLogos"
                            >
                            </label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td
                    className="space10"
                    style={styles.backLogoCol}
                  >
                    <table style={{ width: '100%' }}>
                      <tr>
                        <td>Default</td>
                        <td>Active</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <div className="custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="allBackLogos"
                              name="allBackLogos"
                              checked={selection.allBackLogos}
                              onChange={(e) => handleActiveChange(e, 'back')}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="allBackLogos"
                            >
                            </label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table className="bordered-table" style={styles.table} border={1}>
                {groupedLogos.map((group) => (
                  <tr>
                    <td style={styles.logoCol}>
                      <div style={styles.modalImage}>
                        <img
                          width="100%"
                          height="auto"
                          src={group.logos["lt"]?.logoUrl || group.logos["dk"]?.logoUrl}
                          alt={group.logos["lt"]?.fileName || group.logos["dk"]?.fileName}
                        />
                      </div>
                    </td>
                    <td style={styles.fileNameCol}>
                      <p>{group.logos["lt"]?.fileName}</p>
                      <p>{group.logos["dk"]?.fileName}</p>
                    </td>
                    <td style={styles.frontLogoCol}>
                      <div className="d-flex justify-content-evenly">
                        <div className="custom-radio">
                          <input
                            className="custom-control-input"
                            type="radio"
                            name="defaultFrontLogo"
                            id="defaultFrontLogo"
                            value={group.name}
                            checked={selection.defaultFrontLogo === group.name}
                            onChange={handleDefaultChange}
                          // defaultChecked={group.logos["lt"].isFrontDefault || group.logos["dk"].isFrontDefault}
                          />
                          <label
                            className="custom-control-label text-capitalize"
                            htmlFor="defaultFrontLogo"
                          ></label>
                        </div>
                        <div className="custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id={group.name}
                            name={group.name}
                            value={group.name}
                            checked={selection.front[group.name]}
                            // defaultChecked={group.logos["lt"].isFrontActive || group.logos["dk"].isBackActive}
                            onChange={(e) => handleActiveChange(e, 'front')}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={group.name}
                          ></label>
                        </div>
                      </div>
                    </td>
                    <td style={styles.backLogoCol}>
                      <div className="d-flex justify-content-evenly">
                        <div className="custom-radio">
                          <input
                            className="custom-control-input"
                            type="radio"
                            name="defaultBackLogo"
                            id="defaultBackLogo"
                            value={group.name}
                            checked={selection.defaultBackLogo === group.name}
                            onChange={handleDefaultChange}
                          // defaultChecked={group.logos["lt"].isBackDefault || group.logos["dk"].isBackDefault}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="defaultBackLogo"
                          ></label>
                        </div>
                        <div className="custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id={group.name}
                            name={group.name}
                            value={group.name}
                            checked={selection.back[group.name]}
                            onChange={(e) => handleActiveChange(e, 'back')}
                          // defaultChecked={group.logos["lt"].isBackActive || group.logos["dk"].isBackActive}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={group.name}
                          ></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-danger me-2" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={props.handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AccountLogoDefaults;
