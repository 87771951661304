import React, { FC, useEffect, useState } from 'react';
import Input from '../../../ui/input/Input';
import { Dropdown, Modal } from 'react-bootstrap';
import { IState } from './States';
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import { ImageListType } from 'react-images-uploading';
import { uploadCustomerContactLogosService } from 'api/customerService';
import { ICountryStateLocationRequest } from 'api/requestTypes';
import {
  addCustomerLocationStateCountryService,
  fetchAllCountryService,
  updateCustomerCountryStateLocationService,
} from 'api/locationService';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import ImageUploadPrviewer from 'ui/image/ImageUploadPreviwer';
import { ICountry } from '../countries/Countries';
import { toast } from 'react-toastify';

interface IStateModal  {
    open:boolean;
    setOpen: Function;
    stateList: IState[];
    setStateList: Function;
    currentIndex: number;
    setCurrentIndex: Function;
    callBack:Function;
  };
const initStateInfo: IState = {
  name: '',
  abbreviation: '',
  twoLetter: '',
  establishedDate: '',
  establishedDateFirstTwo: '',
  establishedDateLastTwo: '',
  region: '',
  subRegion: '',
  logos: '',
  countryId: '',
  updatedAt: '',
  createdAt: '',
  stateLogos:[]
};
const AddStateModal: FC<IStateModal> = (props): JSX.Element => {
  const {
    open,
    setOpen,
    stateList,
    setStateList,
    currentIndex,
    setCurrentIndex,
    callBack
  } = props;
  const [removedLogoIds,setRemovedLogoIds]=useState<(string|number)[]>([]);
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<ImageListType>([]);
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [state, setState] = useState(
    currentIndex > -1 ? stateList[currentIndex] : initStateInfo
  );
  const [errorMsg, setErrorMsg] = useState(initStateInfo);
  const handleInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let { name, value } = e.target;
    let errors = {...errorMsg};
    switch (name) {
      case "name":
        errors.name =
        value.trim().length < 1
        ? `Name can not be empty`
        : ``;
        break;
      case "countryId":
        errors.countryId = 
        value.trim().length < 1
        ? 'Please select a country'
        :``
        break;
        default:
          break;
        }
        
        setErrorMsg(errors);
        setState({ ...state, [name]: value });
  };
  const onChangeImageUploadPreview = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    //console.log('data for submit:', imageList, addUpdateIndex);
    setImages(imageList);
    //if (imageList.length && imageList[0] && imageList[0].file)
    //  uploadCustomerLogos(imageList[0].file);
  };

  const handleImageRemove = (index: number) => {
    let array = state.stateLogos!
    //let array = urls.split(',');
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i != index) newArray.push(array[i]);
      else setRemovedLogoIds([...removedLogoIds,array[i].id]);
    }
    setState({ ...state, stateLogos: newArray });
  };

  const uploadCustomerLogos = async () => {
    const logos = [];
    for (let i = 0; i < images.length; i++) {
      try {
        const formData = new FormData();
        formData.append('file', images[i].file!);

        const res = await uploadCustomerContactLogosService(
          formData,
          accessToken
        );
        const data = res?.data?.data;
        if (data) {
          logos.push(data.url);
        }
      } catch (error) {
        console.error(error);
      }
    }
    
    return logos.join(',');
  };

  const handleSubmit = async () => {
    
    if(!state.name.trim() || !state.countryId){
        toast.warn('please fill all required fields');
        return;
      }
    setLoading(true);
    
    uploadCustomerLogos().then((logoUrl) => {
      
      if (currentIndex <= -1) save(logoUrl);
      else update(logoUrl);
    });
  };

  const update = async (logourl: string) => {
    try {

      const payload: ICountryStateLocationRequest = {
        type: 'state',
        data: {
          ...state
        },
        newLogoUrls:logourl?logourl.split(','):[],
        removedlogoIds:removedLogoIds
      };
      const { data } = await updateCustomerCountryStateLocationService(
        state.id!,
        payload,
        accessToken
      );
      if (data.data) {
        toast.success('State Updated Successfully');
        setCurrentIndex(-1);
        setImages([]);
        setOpen(false);
        setLoading(false);
        callBack();
      }
    } catch (e) {
      toast.warn('Unable to Update State');
      setLoading(false);
    }
  };
  const save = async (uploadLogoUrl: string) => {
    try {
      const payload: ICountryStateLocationRequest = {
        type: 'state',
        data: { ...state },
        logoUrls:uploadLogoUrl?uploadLogoUrl.split(','):[]
      };
      console.log(payload);
      const { data } = await addCustomerLocationStateCountryService(
        payload,
        accessToken
      );
      if (data.data) {
        toast.success('State Added Successfully');
        setCurrentIndex(-1);
        setImages([]);
        setOpen(false);
        setLoading(false);
        callBack();
      }
    } catch (e) {
      toast.warn('Unable to Add State');
      setLoading(false);
    }
  };
  useEffect(() => {
    const getCountryList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllCountryService(accessToken);
        if (data.data) {
          setCountryList(data.data);
        }
        
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the locations');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCountryList();
  }, [accessToken]);

  
  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
      <div className="border-bottom pb-1 mb-3">
        <h4 className="mb-0 heading4-bold">Add States</h4>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Name*</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.name}
            onChange={handleInput}
            name="name"
            placeholder="Abc"
            errorMessage={errorMsg.name}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Abbreviation</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.abbreviation}
            onChange={handleInput}
            name="abbreviation"
            placeholder="Abc"
            errorMessage={errorMsg.abbreviation}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">2 Letter</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.twoLetter}
            onChange={handleInput}
            name="twoLetter"
            placeholder="Abc"
            errorMessage={errorMsg.twoLetter}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Est. Date</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.establishedDate}
            onChange={handleInput}
            name="establishedDate"
            placeholder="Abc"
            errorMessage={errorMsg.establishedDate}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Est. (1st 2)</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.establishedDateFirstTwo}
            onChange={handleInput}
            name="establishedDateFirstTwo"
            placeholder="Abc"
            errorMessage={errorMsg.establishedDateFirstTwo}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Est. (last 2)</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.establishedDateLastTwo}
            onChange={handleInput}
            name="establishedDateLastTwo"
            placeholder="Abc"
            errorMessage={errorMsg.establishedDateLastTwo}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Region</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.region}
            onChange={handleInput}
            name="region"
            placeholder="Abc"
            errorMessage={errorMsg.region}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Sub region</label>
        </div>
        <div className="col-md-9">
          <Input
            value={state.subRegion}
            onChange={handleInput}
            name="subRegion"
            placeholder="Abc"
            errorMessage={errorMsg.subRegion}
          />
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Country*</label>
        </div>
        <div className="col-md-9">
          {/* <Dropdown className="custom-dropdown">
                        <Dropdown.Toggle
                            className="btn btn-white no-caret-icon toggle-left w-100"
                            id="country"
                        >
                            Select Country*
                            <i className="fa-solid fa-caret-up text-danger toggle-icon"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="mw-100">
                            <div className="border-bottom pb-1 mb-1">
                                <Dropdown.Item>
                                    <i className="fa-solid fa-circle-plus text-danger me-2"></i>
                                    Add Country
                                </Dropdown.Item>
                            </div>

                            <div className="menu-scroll">
                                <Dropdown.Item>
                                    TBD
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown> */}
          <select
            className="mb-3 form-select"
            title={''}
            onChange={handleInput}
            value={state.countryId}
            name={'countryId'}
          >
            {<option value="">{'Select Country'}</option>}
            {React.Children.toArray(
              countryList.map((el) => <option value={el.id}>{el.name}</option>)
            )}
          </select>
          {errorMsg.countryId  && (
        <span style={{ color: 'red' }}>{errorMsg.countryId}</span>
            )}
        </div>
      </div>
      <div className="row g-3 align-items-center mb-3">
        <div className="col-md-3">
          <label className="text-capitalize">Logo</label>
        </div>
        <div className="col-md-9">
          <div className="row g-3">
            <ImageUploadPrviewer
              onChange={onChangeImageUploadPreview}
              images={images}
            />
            {state.stateLogos && state.stateLogos.length > 0 &&
              state.stateLogos.map((imageSrc, index) => (
                <div className="col-auto" key={index}>
                  <div
                    key={index}
                    className="avatar position-relative overflow-visible"
                  >
                    <img className="rounded " src={imageSrc.logoUrl} alt="logo" />
                    <button
                      className="btn btn-link-danger btn-x"
                      onClick={() => handleImageRemove(index)}
                    >
                      {''}
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button
          type="button"
          className="btn btn-danger px-4"
          onClick={handleSubmit}
        >
          {currentIndex <= -1 ? `Save` : `Update`}
        </button>
        <button
          type="button"
          className="btn btn-outline-danger px-4 ms-3"
          onClick={() => {
            setCurrentIndex(-1);
            setOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStateModal;
