import React, {FC, useEffect, useState} from "react";
import Input from "../../ui/input/Input";
import {Dropdown, Modal} from "react-bootstrap";
import { ICountry } from "./countries/Countries";
import { ImageListType } from 'react-images-uploading';
import { ILocation } from "./Locations";
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";
import { uploadCustomerContactLogosService } from "api/customerService";
import { ICountryStateLocationRequest } from "api/requestTypes";
import { addCustomerLocationStateCountryService, fetchAllStateService, updateCustomerCountryStateLocationService } from "api/locationService";
import ImageUploadPrviewer from "ui/image/ImageUploadPreviwer";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import { IState } from "./states/States";
import MultipleValueInput from "ui/MultipleValueInput/MultipleValueInput";
import { callbackify } from "util";
import { toast } from "react-toastify";

type LocationsType = { 
    open:boolean;
    setOpen: Function; 
    locationList: ILocation[];
    setLocationList: Function;
    currentIndex: number;
    setCurrentIndex: Function;
    callBack: Function;
}


const initLocationInfo:ILocation={
    name: '',
    stackedName:'',
    destination:'',
    location:'',
    stateId:'',
    abbreviation: '',
    establishedDate: '',
    establishedDateFirstTwo: '',
    establishedDateLastTwo: '',
    logos: '',
    createdAt: '',
    updatedAt: '',
}


const AddLocationModal: FC<LocationsType> = (props) => {
    const { open,setOpen, locationList, setLocationList, currentIndex,setCurrentIndex,callBack } = props;
    const [removedLogoIds,setRemovedLogoIds]=useState<(string|number)[]>([]);
    const { accessToken } = useAppSelector(getUserDetails);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState<ImageListType>([]);
    const [stateList,setStateList] = useState<IState[]>([]);
    const [location, setLocation] = useState(
        currentIndex > -1 ? locationList[currentIndex] : initLocationInfo
      );
      const [errorMsg, setErrorMsg] = useState<ILocation>(initLocationInfo);
      const [country,setCountry] = useState<string>('');
      const handleInput = (e: React.ChangeEvent<HTMLInputElement>|React.ChangeEvent<HTMLTextAreaElement>|React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        let errors = {...errorMsg};
        switch (name) {
          case "location":
            errors.location =
              value.trim().length < 1
                ? `Location can not be empty`
                : ``;
            break;
            case "stateId":
              errors.stateId =
                value.trim().length < 1
                  ? `State can not be empty`
                  : ``;
              break;  
          default:
            break;
        }
        setErrorMsg(errors);
        setLocation({ ...location, [name]: value });
        
        // if(name=='stateId'){

        //   const temp=stateList.find((state)=>state.id==value);
        //   if(temp && temp.country) setCountry(temp.country.name);
        // }
        //if (value.trim().length < 1) setError('Name can not be empty');
        //else setError('');
      };
      const onChangeMultiInput=(name:string,value:string)=>{
        setLocation({...location,[name]:value});
      }
      const onChangeImageUploadPreview = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
      ) => {
        //console.log('data for submit:', imageList, addUpdateIndex);
        setImages(imageList);
        //if (imageList.length && imageList[0] && imageList[0].file)
        //  uploadCustomerLogos(imageList[0].file);
      };
      const handleImageRemove = (index: number) => {
        let array = location.locationLogos!;
        //let array = urls.split(',');
        let newArray = [];
        for (let i = 0; i < array.length; i++) {
          if (i != index) newArray.push(array[i]);
          else setRemovedLogoIds([...removedLogoIds,array[i].id]);
        }
        //urls = newArray.join(',');
        setLocation({ ...location, locationLogos: newArray });
      };
      const uploadCustomerLogos = async () => {
        const logos = [];
        for (let i = 0; i < images.length; i++) {
          try {
            const formData = new FormData();
            formData.append('file', images[i].file!);
    
            const res = await uploadCustomerContactLogosService(
              formData,
              accessToken
            );
            const data = res?.data?.data;
            if (data) {
              logos.push(data.url);
            }
          } catch (error) {
            console.error(error);
          }
        }
        return logos.join(',');
      };
      
      const handleSubmit = async () => {
        
        if(!location.location.trim() || !location.stateId){//stateId is string/number
            toast.warn('please fill all the required fields');
            return;
       }
       
        setLoading(true);
        uploadCustomerLogos().then((logoUrl) => {
          if (currentIndex <= -1) save(logoUrl);
          else update(logoUrl);
        });
      };
      const update = async (logourl: string) => {
        try {
          const payload: ICountryStateLocationRequest = {
            type: 'location',
            data: {
              ...location
            },
            newLogoUrls:logourl?logourl.split(','):[],
            removedlogoIds:removedLogoIds
          };
          setLoading(true);
          const { data } = await updateCustomerCountryStateLocationService(
            location.id!,
            payload,
            accessToken
          );
          if (data.data) {
            toast.success('Location Updated Successfully');
            setCurrentIndex(-1);
            setImages([]);
            setOpen(false);
            setLoading(false);
            callBack();
          }
        } catch (e) {
          toast.warn('Unable to Update Country');
          setLoading(false);
        }
      };
      const save = async (uploadLogoUrl: string) => {
        
        try {
          const payload: ICountryStateLocationRequest = {
            type: 'location',
            data: { ...location, logos: uploadLogoUrl },
            logoUrls:uploadLogoUrl?uploadLogoUrl.split(','):[]
          };
          const { data } = await addCustomerLocationStateCountryService(
            payload,
            accessToken
          );
          if (data.data) {
            toast.success('Location Added Successfully');
            setCurrentIndex(-1);
            setImages([]);
            setOpen(false);
            setLoading(false);
            callBack();
          }
        } catch (e) {
          toast.warn('Unable to Add Location');
          setLoading(false);
        }
      };
      useEffect(() => {
        const getStateList = async () => {
          try {
            setLoading(true);
            const { data } = await fetchAllStateService(accessToken);
            if (data.data) {
              setStateList(data.data);
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
            toast.warn('Could notfetch the locations');
            setLoading(false);
          } finally {
            setLoading(false);
          }
        };
    
        getStateList();
      }, [accessToken]);
      
      useEffect(()=>{
        const temp=stateList.find((state)=>state.id==location.stateId);
          if(temp && temp.country) setCountry(temp.country.name);
      },[location.stateId, stateList])
      
      if (loading) return <SpinnerLoader />;
    return(
        <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
            <div className="border-bottom pb-1 mb-3">
                <h4 className="mb-0 heading4-bold">Add Location</h4>
            </div>
            
            <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Location*</label>
                </div>
                <div className="col-md-9">
                <Input 
                    name="location"
                    value={location.location}
                    onChange={handleInput}
                    placeholder="Abc"
                    errorMessage={errorMsg.location}
            />
            </div>
            </div>
            {/* <MultipleValueInput 
            name={'location'} 
            defaultValue={location.location} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Location'}
            errorMessage={error.location}
            /> */}
            {/* <MultipleValueInput 
            name={'name'} 
            defaultValue={location.name} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Name'}
            errorMessage={errorMsg.name}
            /> */}
            {/* <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Stacked Name</label>
                </div>
                <div className="col-md-9">
                    <textarea value={location.stackedName}
                    onChange={handleInput}
                    placeholder={"Abc"}
                    className="form-control" 
                    name="stackedName" 
                    id="" cols={30} rows={2}></textarea>
                </div>
            </div> */}
            <MultipleValueInput 
            name={'stackedName'} 
            defaultValue={location.stackedName} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Stacked Name'}
            errorMessage={errorMsg.stackedName}
            />
            {/* <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Abbreviation</label>
                </div>
                <div className="col-md-9">
                    <Input 
                    name="abbreviation"
                    value={location.abbreviation}
                    onChnage={handleInput}
                    placeholder="Abc"
                    errorMessage={error.abbreviation}
            />
                </div>
            </div> */}
            <MultipleValueInput 
            name={'abbreviation'} 
            defaultValue={location.abbreviation} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Abbreviation'}
            errorMessage={errorMsg.abbreviation}
            />
            {/* <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Est. Date</label>
                </div>
                <div className="col-md-9">
                <Input 
                    name="establishedDate"
                    value={location.establishedDate}
                    onChnage={handleInput}
                    placeholder="Abc"
                    errorMessage={error.establishedDate}
            />
                </div>
            </div> */}
            <MultipleValueInput 
            name={'establishedDate'} 
            defaultValue={location.establishedDate} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Est. Date'}
            errorMessage={errorMsg.establishedDate}
            />
            {/* <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Est. (1st 2)</label>
                </div>
                <div className="col-md-9">
                <Input 
                    name="establishedDateFirstTwo"
                    value={location.establishedDateFirstTwo}
                    onChnage={handleInput}
                    placeholder="Abc"
                    errorMessage={error.establishedDateFirstTwo}
            />
                </div>
            </div> */}
            <MultipleValueInput 
            name={'establishedDateFirstTwo'} 
            defaultValue={location.establishedDateFirstTwo} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Est. (1st 2)'}
            errorMessage={errorMsg.establishedDateFirstTwo}
            />
            {/* <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Est. (last 2)</label>
                </div>
                <div className="col-md-9">
                <Input 
                    name="establishedDateLastTwo"
                    value={location.establishedDateLastTwo}
                    onChnage={handleInput}
                    placeholder="Abc"
                    errorMessage={error.establishedDateLastTwo}
            />
                </div>
            </div> */}
            <MultipleValueInput 
            name={'establishedDateLastTwo'} 
            defaultValue={location.establishedDateLastTwo} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Est. (last 2)'}
            errorMessage={errorMsg.establishedDateLastTwo}
            />
            {/* <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Destinations</label>
                </div>
                <div className="col-md-9">
                <Input 
                    name="destination"
                    value={location.destination}
                    onChnage={handleInput}
                    placeholder="Abc"
                    errorMessage={error.destination}
            />
                </div>
                </div> */}
                {/* <MultipleValueInput 
            name={'destination'} 
            defaultValue={location.destination} 
            onChangeMultiInput={onChangeMultiInput} 
            label={'Destinations'}
            errorMessage={errorMsg.destination}
            /> */}
            <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">State*</label>
                </div>
                <div className="col-md-9">
          <select
          className="mb-3 form-select"
          title={''}
          onChange={handleInput}
          value={location.stateId}
          name={'stateId'}
        >
          {<option value="">{'Select State'}</option>}
          {React.Children.toArray(
            stateList.map((el) => (
              <option value={el.id}>{el.name}</option>
            ))
          )}
          </select>
          {errorMsg.stateId.trim().length > 0 && (
          <span style={{ color: 'red' }}>
            {errorMsg.stateId}
          </span>
        )}
                </div>
            </div>
            <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Country</label>
                </div>
                <div className="col-md-9">
                <Input 
                    name="country"
                    value={country}
                    //onChnage={handleInput}
                    placeholder="Abc"
                    //errorMessage={error.destination}
                    disabled={true}
            />
                </div>
                </div>
            
            <div className="row g-3 align-items-center mb-3">
                <div className="col-md-3">
                    <label className="text-capitalize">Logo</label>
                </div>
                <div className="col-md-9">
                <div className="row g-3">
            {/*TODO: Use the same image uploader component here too */}

            <ImageUploadPrviewer
              onChange={onChangeImageUploadPreview}
              images={images}
            />
            {location.locationLogos && location.locationLogos.length > 0 &&
              location.locationLogos.map((imageSrc, index) => (
                <div className="col-auto" key={index}>
                  <div
                    key={index}
                    className="avatar position-relative overflow-visible"
                  >
                    <img className="rounded " src={imageSrc.logoUrl} alt="logo" />
                    <button
                      className="btn btn-link-danger btn-x"
                      onClick={() => handleImageRemove(index)}
                    >
                      {''}
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>
                </div>
            </div>
            <div className="mt-3">
                <button type="button"
                        className="btn btn-danger px-4"
                        onClick={handleSubmit}
                >
                    {currentIndex <= -1 ?`Save`:`Update`}
                </button>
                <button
          type="button"
          className="btn btn-outline-danger px-4 ms-3"
          onClick={() => {
            setCurrentIndex(-1);
            setOpen(false);
          }}
        >
                    Cancel
                </button>
            </div>
            </Modal.Body>
    </Modal>
    )
}

export default AddLocationModal;