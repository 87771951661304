import {FC} from "react";
import {Spinner} from "react-bootstrap";
import styles from './SpinnerLoader.module.scss';
interface Props {
    message?:string;
}

const SpinnerLoader: FC<Props> = (props) =>{
    const {
        message="Loading..."
    } = props;
    return(
        <div className={styles.fullPageLoader}>
            <p><Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            {message}    </p>
        </div>
    )
}

export default SpinnerLoader;