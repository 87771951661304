import { deleteCustomerLocationService, fetchAllLocationService } from "api/locationService";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import React, {FC, useEffect, useState} from "react";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import AddLocationModal from "./AddLocationModal";
import { toast } from "react-toastify";

export interface ILocation{
    id?: string|number,
    name: string,
    abbreviation: string,
    stackedName: string,
    establishedDate: string,
    establishedDateFirstTwo: string,
    establishedDateLastTwo: string,
    destination: string,
    logos: string,
    createdAt: string,
    updatedAt: string,
    location: string,
    stateId: string,
    locationLogos?:{id:string,logoUrl:string}[]
  }
const Locations: FC = (): JSX.Element => {
    const { accessToken } = useAppSelector(getUserDetails);
    const [loading, setLoading] = useState(false);
    const [isAddLocations, setIsAddLocations] = useState(false);
    const [locationList, setLocationList] = useState<ILocation[]>([]);
    const [currentIndex,setCurrentIndex] = useState<number>(-1);
    const [openConfirmation,setOpenConfirmation] = useState(false);
    const [deleteId,setDeleteId] = useState<number|string>('');
  
    const handleDeleteLocation = async (id: number | string) => {
      try {
        setLoading(true);
        const { data } = await deleteCustomerLocationService(id, accessToken);
        if (data.data) {
          
          let rslt = locationList.filter((el) => el.id != id);
          setLocationList(rslt);
          setLoading(false);
          toast.success('Deleted successfully');
        }
      } catch (error) {
        console.error(error);
        toast.warn('Unable to delete');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      const getLocationList = async () => {
        try {
          setLoading(true);
          const { data } = await fetchAllLocationService(accessToken);          
          
          if (data.data) {
            setLocationList(data.data);
            
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
          toast.warn('Could notfetch the locations');
          setLoading(false);
        } finally {
          setLoading(false);
        }
      };
  
      getLocationList();
    }, [accessToken]);
    const getLocationList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllLocationService(accessToken);          
        if (data.data) {
          setLocationList(data.data);
          
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the locations');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const handleConfirmCallBack = () => {
      handleDeleteLocation(deleteId);
    };
  
    if (loading) return <SpinnerLoader />;
  
    return(
        <div className="content-sm page-bottom-space">
            <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
                <h4 className="mb-0 heading4-bold">Locations</h4>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={()=> setIsAddLocations(true)}
                >
                    <i className="fa-solid fa-circle-plus text-danger me-2"></i>
                    <span className="text-dark">Add Location</span>
                </button>
            </div>
            {locationList.length>0 &&
      <ul className="list-group list-group-flush list-style-none mb-2">
      {React.Children.toArray(
        
          locationList.map((location, index) => {
            return (
              <li className="mb-2" key={location.id}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">{location.location}</p>
                  <div className="action-wrap">
                    <button className="btn btn-link-danger" type="button"
                    onClick={() => {setCurrentIndex(index);setIsAddLocations(true)}}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button className="btn btn-link-danger ms-3" type="button"
                    onClick={()=>{setCurrentIndex(-1);setIsAddLocations(false);setOpenConfirmation(true);setDeleteId(location.id!)}}>
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            );
          }))
        }
      </ul>
        }
            {/* {isAddLocations && (
        <AddLocations
          setIsAddLocations={setIsAddLocations}
          locationList={locationList}
          setLocationList={setLocationList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )} */}
      {isAddLocations && (
        <AddLocationModal
         open={isAddLocations} 
         setOpen={setIsAddLocations}
          locationList={locationList}
          setLocationList={setLocationList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          callBack={getLocationList}
        />
      )}
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
        </div>
    )
}

export default Locations;
