import { ChangeEvent, FC, useState } from "react";
import { Modal } from "react-bootstrap";

import Input from "../../ui/input/Input";
import {
  isEmailAlreadyExist,
  validateEmail,
} from "../../utils/helperFunctions";
import { useAppSelector } from "../../store/hooks";
import { getUserDetails } from "../../store/User.slice";
import { registerUserService } from "../../api/userService";
import { AVAILABLE_ROLE } from "../../utils/constants";
import SpinnerLoader from "../../ui/loader/SpinnerLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "ui/select/Select";

interface IAddUser {
  title?: string;
  open: boolean;
  setOpen: Function;
  setList?: Function;
  parentId?: string | number;
  setRefetch?: Function;
}

const AddUserModal: FC<IAddUser> = ({
  title,
  open,
  setOpen,
  setList,
  parentId,
  setRefetch,
}) => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    email: "",
    // role: parentId ? USER_ROLE.CONTACT_PERSON : USER_ROLE.MARKETING_PERSON,
    role: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    role: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
    switch (name) {
      case "name":
        return !value.trim()
          ? setError({ ...error, [name]: "Name can not be empty" })
          : setError({
              ...error,
              [name]: "",
            });
      case "email":
        return !value.trim() || !validateEmail(value.trim())
          ? setError({
              ...error,
              [name]: "Enter valid email id",
            })
          : setError({ ...error, [name]: "" });
      default:
        break;
    }
  };

  const handleChangeRole = (val: string) => {
    if (!val) {
      setError({ ...error, role: "Must select a role" });
      setInfo({ ...info, role: "" });
    } else if (val) {
      setError({ ...error, role: "" });
      setInfo({
        ...info,
        role: AVAILABLE_ROLE.find((x) => x.title == val)?.shortCode || "MP",
      });
    }
  };

  const isButtonDisabled = () => {
    let data = { ...info };
    //return data.name.trim() && data.email.trim() && validateEmail(data.email.trim()) && data.password.trim().length > 7
    return (
      // data.phone.trim() &&
      data.role.trim() &&
      data.name.trim() &&
      data.email.trim() &&
      validateEmail(data.email.trim())
    );
  };

  const handleSubmit = async () => {
    console.log(info);
    setLoading(true);
    const { email } = info;
    const isExist = await isEmailAlreadyExist(accessToken, email);
    if (isExist) {
      setError({ ...error, email: "Email already registered" });
      setLoading(false);
      return;
    }
    try {
      const payload = {
        ...info,
        parentId: parentId ? parentId : "",
      };
      const { data } = await registerUserService(payload);
      if (data.data) {
        // if(setList) setList(data.data);
        toast.success("Added Successfully");
        setOpen(false);
        typeof setRefetch === "function" && setRefetch(true);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError({ ...error, email: "Email already registered" });
        setLoading(false);
      } else {
        console.error("Registration Failed:", e);
      }
    }
  };

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="border-bottom mb-3">
          <p className="mb-0">{title}</p>
        </div>
        <Input
          sectionClass="mb-3"
          value={info.name}
          onChange={handleChange}
          label="User Account Name*"
          name="name"
          placeholder="Abc"
          errorMessage={error.name}
        />
        <Input
          sectionClass="mb-3"
          value={info.email}
          onChange={handleChange}
          label="User Email*"
          name="email"
          placeholder="email@web.com"
          errorMessage={error.email}
        />
        <Select
          startOption="User Role*"
          onChange={(e) => {
            handleChangeRole(e.target.value);
          }}
          name="role"
          data={AVAILABLE_ROLE.map((x) => x.title)}
          value={AVAILABLE_ROLE.find((x) => x.shortCode == info.role)?.title}
          errorMessage={error.role}
        />
        <div className="mt-3">
          <button
            className="btn btn-danger px-4"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isButtonDisabled()}
          >
            Save
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
