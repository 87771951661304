import { fetchLocationDetailsService } from "api/locationService";
import {
  ICustomerDetails,
  IkeyValuePair,
  ITemplate,
  ITemplateConfigPayload,
} from "api/requestTypes";
import * as templateService from 'api/templateService'
import { ILocation } from "pages/locations/Locations";
import * as React from 'react'
import { Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import templateConfigUtils from "./templateConfigUtils";
import { toast } from "react-toastify";
import SelectTemplateLogos from "./tabs/SelectTemplateLogos";
import DefaultTemplateColor from "./tabs/DefaultTemplateColor";
import useTemplateJSONColors from "./tabs/useTemplateJSONColors";
import DefaultTemplateVariable from "./tabs/DefaultTemplateVariable";
type LogoSelection = { [key: string]: boolean };
type DefaultColors = {
  body: string;
  shade: string;
  graphic?: string;
  graphic2?: string;
  backGraphic?: string;
  backGraphic2?: string;
} | null;

type TemplateImage = {
  backgroundImage: string;
  graphicImage: string;
  backBackgroundImage: string;
  backGraphicImage: string;
};

type DefaultLogos = { shade: string; FR: string, BK: string } | null

export type NameValuePair = {
  id?: string | number;
  type?: string;
  layerName: string;
  keyName: string;
  value?: string;
  defaultLayerValue?: string;
};

type TemplateConfigModalType = {
  open: boolean;
  setOpen: Function;
  templateListAll: ITemplate[];
  keyListAll: IkeyValuePair[];
  customInfo: ICustomerDetails;
  locationList: ILocation[];
  currentTemplateId: string | number;
  updateTemplateStatus: (id: string | number) => void;
  updateTemplateImages: (id: string | number, images: TemplateImage) => void;
  logos: any;
};
export enum KEY_TYPE {
  LOCATION_KEYS = "Location Key",
  FIXED_KEYS = "Fixed Key",
}
export const keyTypes = () => [
  {
    label: KEY_TYPE.LOCATION_KEYS,
  },
  {
    label: KEY_TYPE.FIXED_KEYS,
  },
];
type IPayload = {
  info: {
    childId: number | string,
    templateId: number | string,
  },
  logoUrls: string[],
  backLogoUrls: string[],
}

const TemplateConfigDialogV2: React.FC<TemplateConfigModalType> = (props): JSX.Element => {
  const { open, setOpen, templateListAll, keyListAll, customInfo, currentTemplateId } = props
  const { accessToken } = useAppSelector(getUserDetails);
  const [isSaving, setIsSaving] = React.useState(false)
  const [selFRLogos, setSelFRLogos] = React.useState<LogoSelection>({})
  const [selBKLogos, setSelBKLogos] = React.useState<LogoSelection>({})
  const [defaultLogos, setDefaultLogos] = React.useState<DefaultLogos>(null)
  const [defaultColors, setDefaultColors] = React.useState<DefaultColors>(null)
  const [loading, setLoading] = React.useState(false)
  const [logos, setLogos] = React.useState<string[]>([])
  const [templateLayers, setTemplateLayers] = React.useState<any>({ front: [], back: [] })
  const [defaultFRLayers, setDefaultFRLayers] = React.useState<any>(null)
  const [defaultBKLayers, setDefaultBKLayers] = React.useState<any>(null)
  const [graphicRules, setGraphicRules] = React.useState<any>([])
  const [tabKey, setTabKey] = React.useState<string>("map-variable");
  const matchedTemplate = templateListAll.find((e) => e.id === currentTemplateId)
  const templateColors = useTemplateJSONColors(matchedTemplate)
  
  const handleSave = async() => {
    setIsSaving(true)
    try{
      const templateLayersPayload = {
        info: {
          childId: customInfo.id,
          templateId: currentTemplateId,
        },
        mappings: [
          ...defaultFRLayers.filter((l: any) => l.type === KEY_TYPE.FIXED_KEYS),
          ...defaultBKLayers.filter((l: any) => l.type === KEY_TYPE.FIXED_KEYS),
        ],
        locationMappings: [
          ...defaultFRLayers.filter((l: any) => l.type === KEY_TYPE.LOCATION_KEYS),
          ...defaultBKLayers.filter((l: any) => l.type === KEY_TYPE.LOCATION_KEYS),
        ],
      }
      const templateLogosPayload = {
        info: {
          childId: customInfo.id,
          templateId: currentTemplateId,
        },
        logoUrls: logos?.map((logo: any) => logo.logoUrl).filter((logo, idx) => selFRLogos[`${idx + 1}`]) || [],
        backLogoUrls: logos?.map((logo: any) => logo.logoUrl).filter((logo, idx) => selBKLogos[`${idx + 1}`]) || [],
      }
      const templateDefaultsPayload = {
        body: defaultColors?.body || '',
        shade: defaultColors?.shade || '',
        backBody: defaultColors?.body || '',
        backShade: defaultColors?.shade || '',
        graphic: defaultColors?.graphic || '',
        graphic2: defaultColors?.graphic2 || '',
        backGraphic: defaultColors?.backGraphic || '',
        backGraphic2: defaultColors?.backGraphic2 || '',
        logo: defaultLogos?.shade === defaultColors?.shade ? (defaultLogos?.FR || '') : '',
        backLogo: defaultLogos?.shade === defaultColors?.shade ? (defaultLogos?.BK || '') : '',
      }
      await Promise.all([
        await templateService.configureTemplateLogosService(templateLogosPayload, accessToken),
        await templateService.createTemplateDefaultSettings(accessToken, customInfo.id, currentTemplateId, templateDefaultsPayload)
      ])
      const response = await templateService.configureTemplateService(templateLayersPayload, accessToken)
      const tempImages = response.data.data
      props.updateTemplateImages(currentTemplateId, tempImages)
      setOpen(false)
      setIsSaving(false)
      toast.success('Template configured successfully.')
    }catch(err){
      console.log(err)
      setIsSaving(false)
      toast.error('Failed to configure template.')
    }finally{
    }
  }


  React.useEffect(() => {
    async function fetchInitialData() {
      try{
        setLoading(true)
        const _colorsOptions = await templateConfigUtils.getTemplateColorOptions(accessToken, 0, currentTemplateId)
        const _templateLayers = await templateConfigUtils.fetchTemplateLayers(customInfo, matchedTemplate, accessToken)
        const _activeLogos = await templateConfigUtils.getTemplateLogos(customInfo, matchedTemplate, props.logos, accessToken)
        setLogos(_activeLogos)
        setGraphicRules(_colorsOptions)
        setTemplateLayers(_templateLayers)
        setLoading(false)
      }catch(err){
        console.log(err)
        setLoading(false)
      }
 
    }
    fetchInitialData()
  }, [])


  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">
              {matchedTemplate?.name}
            </h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>

        <div className="custom-tabs1">
          <Tab.Container
            activeKey={tabKey}
            onSelect={(k: any) => setTabKey(k)}
          >
            <Nav variant="tabs" style={{ marginBottom: '30px' }}>
              <Nav.Item>
                <Nav.Link eventKey="map-variable">Map Variable</Nav.Link>
              </Nav.Item>
              {(matchedTemplate?.logos || matchedTemplate?.backLogos) && (
                <Nav.Item>
                  <Nav.Link eventKey="choose-logo">Choose Logo</Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="color-config">Color Config</Nav.Link>
              </Nav.Item>
            </Nav>
            {loading ? (<p style={{ textAlign: 'center' }}>Loading...</p>) : (
              <Tab.Content>
                <Tab.Pane eventKey="map-variable">
                  <DefaultTemplateVariable
                    keyValueList={keyListAll}
                    templateLayers={templateLayers}
                    defaultFRLayers={defaultFRLayers}
                    setDefaultFRLayers={setDefaultFRLayers}
                    defaultBKLayers={defaultBKLayers}
                    setDefaultBKLayers={setDefaultBKLayers}
                  />
                </Tab.Pane>
                {(matchedTemplate?.logos || matchedTemplate?.backLogos) && (
                  <Tab.Pane eventKey="choose-logo">
                    <SelectTemplateLogos
                      logos={logos}
                      logoList={logos}
                      hasFRLogo={matchedTemplate?.logos as boolean}
                      hasBKLogo={matchedTemplate?.backLogos as boolean}
                      selFRLogos={selFRLogos}
                      selBKLogos={selBKLogos}
                      setSelFRLogos={setSelFRLogos}
                      setSelBKLogos={setSelBKLogos}
                    />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="color-config">
                  <DefaultTemplateColor
                    logos={logos}
                    logoList={logos}
                    graphicRules={graphicRules}
                    colors={templateColors}
                    defaultColors={defaultColors}
                    setDefaultColors={setDefaultColors}
                    isGraphicLinked={matchedTemplate?.isGraphicLinked || false}
                    isGraphic2Linked={matchedTemplate?.isGraphic2Linked || false}
                    hasFRLogo={matchedTemplate?.logos as boolean}
                    hasBKLogo={matchedTemplate?.backLogos as boolean}
                    selFRLogos={selFRLogos}
                    selBKLogos={selBKLogos}
                    defaultLogos={defaultLogos}
                    setDefaultLogos={setDefaultLogos}
                    accessToken={accessToken}
                    childId={customInfo.id}
                    templateId={currentTemplateId}
                  />
                </Tab.Pane>
              </Tab.Content>
            )}

          </Tab.Container>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
          <button
            type="button"
            className="btn btn-danger px-4"
            disabled={loading || isSaving}
            onClick={handleSave}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setOpen(false)}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateConfigDialogV2;

