import * as React from "react";
import clsx from "clsx";
import * as Yup from 'yup';
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import FileUpload from "./FileUpload";
import UploadFileList from "./UploadFileList";
import { niceBytes } from "utils/helperFunctions";
import * as svgTemplateService from "api/svgTemplateService";
import { uploadFileToPreSignedUrlWithProgress } from "api/utilService";

interface IProp {
  show: boolean;
  onHide: () => void;
  blankGarment: any;
}

export enum KeyNames {
  front_body_url='front_body_url', 
  front_label_url='front_label_url', 
  front_texture_url='front_texture_url', 
  back_body_url='back_body_url', 
  back_label_url='back_label_url', 
  back_texture_url='back_texture_url'
}
type FileInfo = {
  id?: string; name: string; size: string; type: string, status: string, keyName: KeyNames
}

export interface FormValues {
  progress: number;
  currentFile: string;
  isFilesUploaded: boolean;
  blankTemplateId: number;
  files: FileInfo[];
  front_body_url?: string;
  front_label_url?: string;
  front_texture_url?: string;
  back_body_url?: string;
  back_label_url?: string;
  back_texture_url?: string;
}

const getFileNameMap = (styleNumber: string) => {
  const altCode = styleNumber.match(/(\w+)\sBlank/)?.[1]
  if(!altCode) return {} as { [key: string]: KeyNames }
  return {
    [`${altCode}-FR-Body.svg`]: 'front_body_url',
    [`${altCode}-FR-Label.png`]: 'front_label_url' ,
    [`${altCode}-FR-Texture.png`]: 'front_texture_url',
    [`${altCode}-BK-Body.svg`]:'back_body_url',
    [`${altCode}-BK-Label.png`]: 'back_label_url',
    [`${altCode}-BK-Texture.png`]: 'back_texture_url',
  } as { [key: string]: KeyNames }
}

const schema = Yup.object({
  blankTemplateId: Yup.string().required(),
  files: Yup.array().of(Yup.object({
    id: Yup.string(),
    name: Yup.string(),
    size: Yup.string(),
    status: Yup.string().oneOf(["valid"]),
  })),
  front_body_url: Yup.string(),
  front_label_url: Yup.string(),
  front_texture_url: Yup.string(),
  back_body_url: Yup.string(),
  back_label_url: Yup.string(),
  back_texture_url: Yup.string(),
})

const BodyTemplateSetup: React.FC<IProp> = ({ show, onHide, blankGarment }): JSX.Element => {

  const [files, setFiles] = React.useState<File[] | null>(null)
  const fileNameMap = React.useMemo(() => getFileNameMap(blankGarment.styleNumber as string), [])

  const methods = useForm<FormValues>({
    defaultValues: {
      isFilesUploaded: false,
      blankTemplateId: 0,
      progress: 0,
      currentFile: '',
      files: []
    }
  })

  const readyToUpload = methods.watch('files')?.length > 0
  const isFilesUploaded = methods.watch('isFilesUploaded')

  const hideUploadBtn = Boolean(isFilesUploaded || methods.formState.errors?.root)
  const isSubmitting = methods.formState.isSubmitting

  React.useEffect(() => {
    if(!show){
      methods.reset()
      setFiles(null)
    }
  }, [show])

  React.useEffect(() => {
    if (!!files?.length) {
      const __files = [];
      const __values = { blankTemplateId: blankGarment.id } as FormValues;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileKeyName = fileNameMap[file.name] ?? "";
        const fileStatus = !!fileKeyName ? "valid" : "invalid";
        __files.push({
          name: file.name,
          size: niceBytes(file.size),
          type: file.type,
          keyName: fileKeyName,
          status: fileStatus,
          progress: null
        });
        if (!!fileKeyName) __values[fileKeyName] = String(i);
      }
      methods.reset(__values);
      methods.setValue('files', __files);
    }
  }, [fileNameMap, files]);


  const handleUpload = async (values: FormValues) => {
    const onUploadProgress = (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      methods.setValue('progress', percentCompleted, { shouldDirty: true })
    }
    try{
      await schema.validate(values)   
      const filesToBeUploaded = {} as Record<KeyNames, File>
      const params = { blankTemplateId: blankGarment.id } as svgTemplateService.BodyPreSignUrlParams
      for (const key in KeyNames) {
        const fileIndex = parseInt(values[key as KeyNames] as any, 10)
        if (!Number.isNaN(fileIndex)) {
          const file = (files as File[])[fileIndex]
          filesToBeUploaded[key as KeyNames] = file
          params[key as KeyNames] = file.name.split('.').at(-1) as string
        }
      }
      const urlInfoObj:svgTemplateService.BodyPreSignUrlResult = await svgTemplateService.getBodyPreSignedUrls(params)
      for (const key in urlInfoObj) {
        const info = urlInfoObj[key as KeyNames]
        methods.setValue('currentFile', filesToBeUploaded[key as KeyNames].name)
        await uploadFileToPreSignedUrlWithProgress(info.preSignedUrl, filesToBeUploaded[key as KeyNames], onUploadProgress)
        methods.resetField('progress')
        methods.resetField('currentFile')
      }
      methods.setValue('isFilesUploaded', true)
    }catch(err){
      console.error(err)
      if(err instanceof Yup.ValidationError){
        const match = err.message.match(/^files\[\d+\]\.status/)
        if(match){
          methods.setError('root', { type: 'custom', message: 'Please follow correct file naming and use correct file extension' })
          return
        }
      }
      methods.setError('root', { type: 'custom', message: 'Something is not correct with these files' })
      return
    }
  };

  return (
    <Modal
      show={show}
      size={Boolean(files?.length) ? 'xl' : 'lg'}
      centered
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleUpload)}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {blankGarment.description}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!!files?.length && (<UploadFileList  />)}
            <FileUpload files={files} setFiles={setFiles} />
          </Modal.Body>
          <Modal.Footer>
          
            <button type="button" onClick={onHide} disabled={isSubmitting} className="btn btn-outline-danger">
              {isFilesUploaded ? 'Done' : 'Cancel'}
            </button>
            <button type="submit" className={clsx("btn btn-danger", { "d-none": hideUploadBtn })} disabled={!readyToUpload}>
              {isSubmitting ? 'Uploading...' : 'Upload'}
            </button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default BodyTemplateSetup;
