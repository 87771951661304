import axios, { AxiosInstance, AxiosResponse } from "axios";
import Cookies from 'js-cookie'
import { toast } from "react-toastify";
import { BASE_URL } from "api/apiConstants";

export const client: AxiosInstance = axios.create({ baseURL: BASE_URL })

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

// Function to get a new access token using the refresh token
async function getNewTokens() {
    try {
        const response = await axios.post(`${BASE_URL}/users/token`, {
            refreshToken: Cookies.get('refresh_token')
        });
        Cookies.set('access_token', response.data?.data?.accessToken);
        Cookies.set('refresh_token', response.data?.data?.refreshToken);
        return response.data.accessToken;
    } catch (error) {
        throw new Error('Unable to refresh token');
    }
}

client.interceptors.request.use(function (config: any) {
    // Do something before request is sent
    config.headers.Authorization = Cookies.get('access_token')
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Response interceptor
client.interceptors.response.use(function (response: AxiosResponse) {
    return response
}, function (error) {
    const originalRequest = error.config;
    let _errMsg = 'Something went wrong';
    
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest.headers.Authorization = token;
                return client.request(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
            getNewTokens().then((token) => {
                originalRequest.headers.Authorization = token
                processQueue(null, token);
                resolve(client.request(originalRequest));
            }).catch((err) => {
                processQueue(err, null);
                reject(err);
            }).finally(() => {
                isRefreshing = false;
            });
        });
    }

    if (error.response) {
        if (error.response.data?.msg) _errMsg = error.response.data.msg
    } else if (error.request) {
        toast.error('Probably server is down')
    }
    // return Promise.reject(new Error(_errMsg))
    return Promise.reject(error)
});