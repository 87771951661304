import React, { FC, useEffect,  useState } from 'react';
import EmptyList from '../../ui/empty-list/EmptyList';
import AttributeModal from './AttributeModal';
import { Attribute } from 'api/requestTypes';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import {
  addCustomerAttributeService,
  deleteCustomerAttributeService,
  fetchAllCustomerAttributeService,
  updateCustomerAttributeService,
} from 'api/masterDataService';
import { getUserDetails } from 'store/User.slice';
import { useAppSelector } from 'store/hooks';

import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import { toast } from 'react-toastify';
const initAttr: Attribute = {
  id: '',
  attributeType: '',
  attributeName: '',
};
// const initLoc: ILocation = {
//   id: '',
//   country: '',
//   state: '',
//   location: '',
// };
const CustomerAttributes: FC = () => {
  const [loading, setLoading] = useState(false);
  const { accessToken, role } = useAppSelector(getUserDetails);

  const [categoryList, setCategoryList] = useState<Attribute[]>([]);
  //const [ilocationList, setiLocationList] = useState<ILocation[]>([]);
  const [tagsList, setTagsList] = useState<Attribute[]>([]);
  const [keysList, setKeysList] = useState<Attribute[]>([]);

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  //const [isiLocationOpen, setIsiLocationOpen] = useState(false);
  //const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [isKeysOpen, setIsKeysOpen] = useState(false);

  //const [ilocation, setiLocation] = useState<ILocation>(initLoc);
  const [attribute, setAttribute] = useState<Attribute>(initAttr);
  
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId,setDeleteId] = useState<string | number>('');
  const [deleteEntity,setDeleteEntity] = useState<string>('');
  
  const handleSaveUpdateAttribute = async (attribute: Attribute) => {
    //add
    if (!attribute.id) {
      try {
        setLoading(true);
        const { data } = await addCustomerAttributeService(
          [{attributeName:attribute.attributeName,attributeType:attribute.attributeType}],
          accessToken
        );
        if (data.data) {
          const details: Attribute[] = data.data;
          if (details[0].attributeType === 'category')
            setCategoryList([...categoryList, ...details]);
          //else if(details[0].attributeType==='tag') 
            //setTagsList([...tagsList, ...details]);
          else //key
            setKeysList([...keysList,...details]);
          toast.warn('Added successfully');

          if (details[0].attributeType === 'category') {
            setIsCategoryOpen(false);
            setAttribute(initAttr);
          } //else if(details[0].attributeType === 'tag') {
            //setIsTagsOpen(false);
            //setAttribute(initAttr);
          //}
          else{//key
            setIsKeysOpen(false);
            setAttribute(initAttr);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Unable to add');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    //update
    else {
      try {
        setLoading(true);
        const { data } = await updateCustomerAttributeService(
          attribute.id,
          attribute,
          accessToken
        );
        if (data.data) {
          const details = data.data;
          if (attribute.attributeType === 'category') {
            let rslt = categoryList.filter((el) => el.id != attribute.id);
            setCategoryList([...rslt, attribute]);
          } //else if(attribute.attributeType === 'tag') {
            //let rslt = tagsList.filter((el) => el.id != attribute.id);
            //setTagsList([...rslt, attribute]);
          //}
          else{//key
            let rslt = keysList.filter((el) => el.id != attribute.id);
            setKeysList([...rslt, attribute]);
          }
          toast.warn('Updated successfully');
          setLoading(false);
          if (attribute.attributeType === 'category') {
            setIsCategoryOpen(false);
            setAttribute(initAttr);
          } //else if(attribute.attributeType === 'tag') {
            //setIsTagsOpen(false);
            //setAttribute(initAttr);
          //}
          else{
            setIsKeysOpen(false);
            setAttribute(initAttr);
          }
        }
      } catch (error) {
        console.error(error);
        toast.warn('Unable to update');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDeleteAttribute = async (id: number | string) => {
    try {
      setLoading(true);
      const { data } = await deleteCustomerAttributeService(id, accessToken);
      if (data.data) {
        let rslta = categoryList.filter((el) => el.id != id);
        setCategoryList([...rslta]);
        //let rsltt = tagsList.filter((el) => el.id != id);
        //setTagsList([...rsltt]);
        let rslt = keysList.filter((el) => el.id != id);
        setKeysList([...rslt]);
        setLoading(false);
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
      toast.warn('Unable to delete');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // const handleSaveUpdateiLocation = async (ilocation: ILocation) => {
  //   //add
  //   if (!ilocation.id) {
  //     try {
  //       setLoading(true);
  //       const { data } = await addCustomerLocationService(
  //         ilocation,
  //         accessToken
  //       );
  //       if (data.data) {
  //         const details: ILocation = data.data;
  //         setiLocationList([...ilocationList, details]);
  //         toast.warn('Added successfully');
  //         setIsiLocationOpen(false);
  //         setiLocation(initLoc);
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       toast.warn('Unable to add');
  //       setLoading(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  //   //update
  //   else {
  //     try {
  //       setLoading(true);
  //       const { data } = await updateCustomerLocationService(
  //         ilocation.id,
  //         ilocation,
  //         accessToken
  //       );
  //       if (data.data) {
  //         const details = data.data;
  //         let rslt = ilocationList.filter((el) => el.id != ilocation.id);
  //         setiLocationList([...rslt, ilocation]);
  //         toast.warn('Updated successfully');
  //         setLoading(false);
  //         setIsiLocationOpen(false);
  //         setiLocation(initLoc);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       toast.warn('Unable to update');
  //       setLoading(false);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };
  // const handleDeleteiLocation = async (id: number | string) => {
  //   try {
  //     setLoading(true);
  //     const { data } = await deleteCustomerLocationService(id, accessToken);
  //     if (data.data) {
  //       let rslta = ilocationList.filter((el) => el.id != id);
  //       setiLocationList([...rslta]);
  //       setLoading(false);
  //       toast.warn('Deleted successfully');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.warn('Unable to delete');
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleConfirmCallBack =()=>{
    
    if(deleteEntity==='Attribute')
    handleDeleteAttribute(deleteId);
    //else if(deleteEntity==='Location')
    //handleDeleteiLocation(deleteId);
    else{
      console.log('No entity');
    }
  }

  useEffect(() => {
    const getAttributeList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllCustomerAttributeService(accessToken);
        if (data.data) {
          //console.log(data.data);
          const { categories, tags,keys } = data.data;
          setCategoryList(categories);
          //setTagsList(tags);
          setKeysList(keys);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the attributes');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    // const getiLocationList = async () => {
    //   try {
    //     setLoading(true);

    //     const { data } = await fetchAllCustomerLocationService(accessToken);
    //     if (data.data) {
    //       const ilocations = data.data;
    //       setiLocationList(ilocations);
    //     }
    //     setLoading(false);
    //   } catch (error) {
    //     console.error(error);
    //     toast.warn('Could notfetch the locations');
    //     setLoading(false);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    getAttributeList();
    //getiLocationList();
  }, [accessToken]);

  if (loading) return <SpinnerLoader />;
  return (
    <div className="content-sm page-bottom-space">
      <section>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4-bold">Category</h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setIsCategoryOpen(true)}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Category</span>
          </button>
        </div>
        {!!categoryList.length ? (
          <ul className="list-group list-group-flush list-style-none mb-2">
            {categoryList.map((category, index) => (
              <li className="mb-2" key={category.id!}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">
                    {category.attributeName}
                  </p>
                  <div className="action-wrap">
                    <button
                      className="btn btn-link-danger"
                      type="button"
                      onClick={() => {
                        setAttribute({
                          id: category.id,
                          attributeName: category.attributeName,
                          attributeType: category.attributeType,
                        });
                        setIsCategoryOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button
                      className="btn btn-link-danger ms-3"
                      type="button"
                      //onClick={() => handleDeleteAttribute(category.id!)}
                       onClick={()=>{setDeleteId(category.id!);setDeleteEntity('Attribute');setOpenConfirmation(true)}}
                    >
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <EmptyList title="Nothing Here" />
        )}
        {isCategoryOpen && (
          <AttributeModal
            open={isCategoryOpen}
            setOpen={setIsCategoryOpen}
            attribute={{ ...attribute, attributeType: 'category' }}
            setAttribute={setAttribute}
            onSave={handleSaveUpdateAttribute}
            loading={loading}
          />
        )}
      </section>
      {/* <section> */}
        {/* <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4">Location</h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setIsiLocationOpen(true)}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Location</span>
          </button>
        </div> */}
        {/* {!!ilocationList.length ? (
          <ul className="list-group list-group-flush list-style-none mb-2">
            {ilocationList.map((ilocation, index) => (
              <li className="mb-2" key={ilocation.id!}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">
                    {ilocation.location +
                      ', ' +
                      ilocation.state +
                      ', ' +
                      ilocation.country}
                      {
                        ilocation.location
                      }
                  </p>
                  <div className="action-wrap">
                    <button
                      className="btn btn-link-danger"
                      type="button"
                      onClick={() => {
                        setiLocation(ilocation);
                        setIsiLocationOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button
                      className="btn btn-link-danger ms-3"
                      type="button"
                      //onClick={() => handleDeleteiLocation(ilocation.id!)}
                      onClick={()=>{setDeleteId(ilocation.id!);setDeleteEntity('Location');setOpenConfirmation(true)}}
                    >
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <EmptyList title="Nothing Here" />
        )} */}
        {/* {isiLocationOpen && (
          <AddLocationModal
            open={isiLocationOpen}
            setOpen={setIsiLocationOpen}
            onSave={handleSaveUpdateiLocation}
            ilocation={ilocation}
            setiLocation={setiLocation}
            loading={loading}
          />
        )} */}
      {/* </section> */}
      {/* <section>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4-bold">Tags</h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setIsTagsOpen(true)}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Tags</span>
          </button>
        </div>
        {!!tagsList.length ? (
          <ul className="list-group list-group-flush list-style-none mb-2">
            {tagsList.map((tag, index) => (
              <li className="mb-2" key={tag.id!}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">
                    {tag.attributeName}
                  </p>
                  <div className="action-wrap">
                    <button
                      className="btn btn-link-danger"
                      type="button"
                      onClick={() => {
                        setAttribute(tag);
                        setIsTagsOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button
                      className="btn btn-link-danger ms-3"
                      type="button"
                      //onClick={() => handleDeleteAttribute(category.id!)}
                      onClick={()=>{setDeleteId(tag.id!);setDeleteEntity('Attribute');setOpenConfirmation(true)}}
                    >
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <EmptyList title="Nothing Here" />
        )}
        {isTagsOpen && (
          <AttributeModal
            open={isTagsOpen}
            setOpen={setIsTagsOpen}
            attribute={{ ...attribute, attributeType: 'tag' }}
            setAttribute={setAttribute}
            onSave={handleSaveUpdateAttribute}
            loading={loading}
          />
        )}
      </section> */}
      <section>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4 heading4-bold">Keys</h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setIsKeysOpen(true)}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Keys</span>
          </button>
        </div>
        {!!keysList.length ? (
          <ul className="list-group list-group-flush list-style-none mb-2">
            {keysList.map((key, index) => (
              <li className="mb-2" key={key.id!}>
                <div className="has-right-action">
                  <p className="mb-0 text-capitalize">
                    {key.attributeName}
                  </p>
                  <div className="action-wrap">
                    <button
                      className="btn btn-link-danger"
                      type="button"
                      onClick={() => {
                        setAttribute(key);
                        setIsKeysOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>
                    <button
                      className="btn btn-link-danger ms-3"
                      type="button"
                      //onClick={() => handleDeleteAttribute(category.id!)}
                      onClick={()=>{setDeleteId(key.id!);setDeleteEntity('Attribute');setOpenConfirmation(true)}}
                    >
                      <i className="fa-solid fa-trash-can">{''}</i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <EmptyList title="Nothing Here" />
        )}
        {isKeysOpen && (
          <AttributeModal
            open={isKeysOpen}
            setOpen={setIsKeysOpen}
            attribute={{ ...attribute, attributeType: 'key' }}
            setAttribute={setAttribute}
            onSave={handleSaveUpdateAttribute}
            loading={loading}
          />
        )}
      </section>
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
    </div>
  );
};

export default CustomerAttributes;
