import * as React from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'

import Input from 'ui/input/Input';
import { graphicColorService } from 'api';
import { useColorGroupCreate, useColorGroupQuery, useColorGroupUpdate } from 'react-query';

type ModalAction = 'Add' | 'Update' | null

interface IProps {
  modalAction: ModalAction;
  open: boolean;
  onHide: () => void;
  groupId: number | null;
}

const AddUpdateGraphicColorGroup: React.FC<IProps> = (props): JSX.Element => {
  const modalAction: ModalAction = props.modalAction
  const { data: group, isLoading } = useColorGroupQuery(props.groupId as number)
  const { mutate: createColorGroup, isPending: isCreating } = useColorGroupCreate()
  const { mutate: updateColorGroup, isPending: isUpdating } = useColorGroupUpdate()
  const [groupName, setGroupName] = React.useState('');
  
  React.useLayoutEffect(() => {
    setGroupName('')
  }, [props.open])

    React.useEffect(() => {
    if(group?.id){
      setGroupName(group.group_name)
    }
  }, [group])
  
  const disableSubmit = !groupName?.trim().length || isCreating || isUpdating || isLoading
    
  const handleSubmit = async(e: any) => {
    e.preventDefault()
    if(modalAction === 'Add'){
      createColorGroup({ group_name: groupName })
    }else if(modalAction === 'Update'){
      updateColorGroup({ id: props.groupId as number, dataToUpdate: { group_name: groupName } })
    }
    props.onHide()

  }

  return (
    <Modal
    show={props.open}
    onHide={props.onHide}
    className="custom-drawer date-picker-modal"
    aria-labelledby="custom-modal"
    backdrop="static"
    size={"lg"}
  >
    <Modal.Body>
      <form onSubmit={handleSubmit}>
        <div className="border-bottom pb-1 mb-3">
          <h4 className="mb-0 heading4-bold">{modalAction} Group</h4>
        </div>
        <div className="row g-3 align-items-center mb-3">
          <div className="col-md-3">
            <label className="text-capitalize">Group Name</label>
          </div>
          <div className="col-md-9">
            <Input
              name="groupName"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              placeholder="Enter group name"
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="mt-3">
          <button
            type="submit"
            className="btn btn-danger px-4"
            disabled={disableSubmit}
          >
            {/* {submitting ? 'Saving...' : 'Save'} */}
            Save
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
  )
}

export default AddUpdateGraphicColorGroup