import React, { ChangeEvent, FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IStyleModal } from 'types/components/customerAttributesType';
import Input from 'ui/input/Input';
import SpinnerLoader from 'ui/loader/SpinnerLoader';



const StyleModal: FC<IStyleModal> = ({
  open,
  setOpen,
  style,
  setStyle,
  onSave,
  loading
}) => {
  const [error, setError] = useState({styleName:'',subStyleName:''});
  const [styleInfo, setStyleInfo] = useState({ ...style });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let errors = error;
    switch (name) {
      case 'styleName':
        errors.styleName =
          value.length < 1
            ? `Style name can not be empty`
            : ``;
        break;
      case 'subStyleName':
       errors.subStyleName =
              value.length < 1
                ? `Sub style name can not be empty`
                : ``;
            break;
      default:
        break;
    }
    setError(errors);
    setStyleInfo({ ...styleInfo, [name]: value });
  };
  const isButtonDisabled = () => {
        
    let data = {...styleInfo};
    return data.styleName.trim() && data.subStyleName.trim();
    
}
  const handleSubmit = () => {
      onSave(styleInfo);
  }
  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="border-bottom mb-3">
          <p className="mb-0">
            {styleInfo.id
              ? `Update Style`
              : `Add Style`}
          </p>
        </div>
        <Input
              sectionClass="mb-3"
              value={styleInfo.styleName}
              onChange={handleChange}
              label={"Style Name*"}
              name={'styleName'}
              placeholder="Abc"
              errorMessage={error.styleName} 
          />
          <Input
              sectionClass="mb-3"
              value={styleInfo.subStyleName}
              onChange={handleChange}
              label={"Sub Style Name*"}
              name={'subStyleName'}
              placeholder="Abc"
              errorMessage={error.subStyleName}
          />
        <div className="mt-3">
          <button className="btn btn-danger px-4" onClick={handleSubmit} disabled={!isButtonDisabled()}>
            {style?.id ? 'Update' : 'Save'}
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => {setStyle({styleName:'',subStyleName:''});setOpen(false)}}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StyleModal;
