import { ChangeEvent, FC, useState } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import Input from 'ui/input/Input';

type Pricing={
    id?:string|number;
    dozenValue:string;
    pricePerUnit:string;
}
type PricingAddUpdateModalType = {
  open: boolean;
  setOpen: Function;
  pricing: Pricing;
  handleAddUpdateCallBack:(pricing:Pricing)=>void
};
const PricingAddUpdateModal: FC<PricingAddUpdateModalType> = (
  props
): JSX.Element => {
  const {
    open,
    setOpen,
    pricing:price,
    handleAddUpdateCallBack,
  } = props;
  const [pricing,setPricing]=useState<Pricing>({...price});
  const [errMsg,setErrMsg]=useState({dozenValue:'',pricePerUnit:''});
  const handleChange = ( e: ChangeEvent<HTMLInputElement>)=>{
    const {name,value}=e.target;
    let errors = {...errMsg};
    switch (name) {
      case 'dozenValue':
        errors.dozenValue = value.length < 1 ? 'Value can not be empty' : '';
        break;
      case 'pricePerUnit':
        errors.pricePerUnit= value.length < 1 ? 'Price can not be empty' : '';
        break;
      default:
        break;
    }
    setErrMsg(errors);
    setPricing({...pricing,[name]:value});
  }
  const handleSubmit=()=>{
    if( !(/^[0-9]+$/.test(pricing.dozenValue)))
        {
            setErrMsg({dozenValue:'Invalid Input',pricePerUnit:''});
            return;
        }

    if( !(/^\d+(\.\d{1,2})?$/.test(pricing.pricePerUnit)))
        {
            setErrMsg({dozenValue:'',pricePerUnit:'Invalid Input'});
            return;
        }
    handleAddUpdateCallBack(pricing);
  }

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'sm'}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">
              {pricing.id?'Update Pricing':'Add Pricing'}
            </h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
      <Input
        sectionClass="mb-3"
        value={pricing.dozenValue}
        onChange={handleChange}
        label="Number of dozen*"
        name="dozenValue"
        placeholder="123"
        errorMessage={errMsg.dozenValue}
      />
      <Input
        sectionClass="mb-3"
        value={pricing.pricePerUnit}
        onChange={handleChange}
        label="Price per unit($)*"
        name="pricePerUnit"
        placeholder="123"
        errorMessage={errMsg.pricePerUnit}
      />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
        <button
          type="button"
          className="btn btn-danger px-4"
          onClick={handleSubmit}
        >
          {pricing.id ? `Update` : `Save`}
        </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PricingAddUpdateModal;
