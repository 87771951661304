import axios from 'axios';
import { BASE_URL } from './apiConstants';
import { ICustomSize } from './requestTypes';
import { client } from 'utils/client';



export const addCustomSizeService = (
  data: ICustomSize,
  token: string
) => {
  return client.post('/custom-size/save', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/custom-size/save',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchAllCustomSizeService = (token: string) => {
  return client.get('/custom-size/list')
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + '/custom-size/list',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};
export const updateCustomSizeService = (
  id: string | number,
  data: ICustomSize,
  token: string
) => {
  return client.put(`/custom-size/${id}`, data)
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/custom-size/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};
export const deleteCustomSizeService = (
  id: string | number,
  token: string
) => {
  return client.delete(`/custom-size/${id}`)
  // return axios({
  //   method: 'delete',
  //   url: BASE_URL + `/custom-size/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  // });
};