import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  FC,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import Table from "react-bootstrap/Table";
import { getAllOrders, getSingleOrder } from "api/orderService";
import User from "pages/user/User";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "utils/constants";
import ImageCell from "./ImageCell";
import {
  convertSingleOrderDataToAgGridData,
  OrderItem,
  singleOrderPageColDefs,
  singleOrderPageDefaultColDefs,
} from "./orderTable.utils";
import DeleteOrderConfirm from "./DeleteOrderConfirm";
import UpdateOrder from "./UpdateOrder";

type IOrder = {
  user_id: string;
  createdate: string;
  ip_address: string;
  browser_user_agent: string;
  cart_item_id: string;
  order_status_id: string;
  comments: string;
  checkout_flag: string;
  payment_id: string;
  shipment_id: string;
};

const defaultValue: IOrder = {
  user_id: "",
  createdate: "",
  ip_address: "",
  browser_user_agent: "",
  cart_item_id: "",
  order_status_id: "",
  comments: "",
  checkout_flag: "",
  payment_id: "",
  shipment_id: "",
};
//TODO need to add pagination , searching etc. to this table
const SingleOrderTable: FC = (): JSX.Element => {
  const { id = 0 } = useParams();
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [orderNumber, setOrderNumber] = useState("");
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(true);

  const [rowData, setRowData] = useState<OrderItem[]>([]); // Set rowData to Array of Objects, one Object per Row

  // Each Column Definition results in one Column.
  const [columnDefs] = useState(singleOrderPageColDefs);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => singleOrderPageDefaultColDefs, []);

  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event: any) => {
    console.log(event);
  }, []);


  // Example load data from sever
  useEffect(() => {
    if(!refetch || !id || !accessToken) return
    const getData = async () => {
      try {
        setLoading(true);
        const {
          data: { data },
        } = await getSingleOrder(+id, accessToken);
        setLoading(false);
        setRowData(convertSingleOrderDataToAgGridData(data));
        setOrderNumber(data.orderNumber)
        setRefetch(false)
      } catch (e) {
        console.log(e);
        setLoading(false);
        setRefetch(false)
      }
    };
    getData();
  }, [accessToken, id, refetch]);

  if (loading) return <SpinnerLoader />;
  return (
    <>
      <div className='d-flex mb-2 align-items-center'>
        <p className="mb-0">Order ID: {orderNumber}</p>
        <button
          className="btn btn-link-danger ms-3"
          type="button"
          onClick={() => setOpenUpdateModal(true)}
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        <button
          className="btn btn-link-danger ms-3"
          type="button"
          onClick={() => setOpenDeleteModal(true)}
        >
          <i className="fa-solid fa-trash-can"></i>
        </button>
      </div>

      <div className="ag-theme-alpine" style={{ width: "90%", height: "80vh" }}>
        <AgGridReact
          // ref={gridRef} // Ref for accessing Grid's API

          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          getRowHeight={() => 180}
          onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          pagination={true}
          paginationPageSize={20}
          cacheBlockSize={10}
        />
      </div>
      {openUpdateModal && (
        <UpdateOrder
          id={id}
          orderNumber={orderNumber}
          open={openUpdateModal}
          onHide={() => setOpenUpdateModal(false)}
          setRefetch={setRefetch as any}
        />
      )}
      {openDeleteModal && (
        <DeleteOrderConfirm
          id={id}
          orderNumber={orderNumber}
          open={openDeleteModal}
          onHide={() => setOpenDeleteModal(false)}
        />
      )}

    </>
  );
};

export default SingleOrderTable;
