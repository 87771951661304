
type DozenWisePrices = { pricePerUnit: number, templateId: number, dozenValue: number }[]

export const DOZEN = 12

export const generatePricing = (params: { dozenWisePrice: DozenWisePrices }) => {
    const dozenWisePrice = params.dozenWisePrice.map((item: any) => ({
        ...item,
        pricePerUnit: Number(item.pricePerUnit)
    }))
    const sortedPriceArr = dozenWisePrice.sort((a, b) => a.dozenValue - b.dozenValue)

    const baseRate = sortedPriceArr?.[0]?.pricePerUnit
    const baseQty = sortedPriceArr?.[0]?.dozenValue * DOZEN

    const forQty = (qty: number) => {
        let rate = baseRate
        for (let item of sortedPriceArr) {
            const currQty = DOZEN * item.dozenValue
            if (currQty > qty) break
            rate = item.pricePerUnit
        }

        const mrp = Number((baseRate * qty).toFixed(2))
        const price = Number((rate * qty).toFixed(2))
        const saving = Number((mrp - price).toFixed(2))
        return {
            qty,
            rate,
            baseRate,
            mrp,
            price,
            saving,
            hasSaving: saving > 0
        }
    }

    return {
        baseQty,
        forQty
    }

}