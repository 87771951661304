import axios from 'axios';
import { BASE_URL } from './apiConstants';
import {
  Attribute, ICountryStateLocationRequest
} from './requestTypes';
import { client } from 'utils/client';



// export const addCustomerLocationService = (
//   data: ILocation,
//   token: string
// ) => {
//   return axios({
//     method: 'post',
//     url: BASE_URL + '/customerLocation/save',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//     data,
//   });
// };
export const addCustomerLocationStateCountryService = (
  data: ICountryStateLocationRequest,
  token: string
) => {
  return client.post('/customer-location/save', data)
  // return axios({
  //   method: 'post',
  //   url: BASE_URL + '/customer-location/save',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data,
  // });
};
// export const fetchAllCustomerLocationService = (token: string) => {
    
//   return axios({
//     method: 'get',
//     url: BASE_URL + '/customerLocation/list',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//   });
// };

// export const updateCustomerLocationService = (
//   id: string | number,
//   data: ILocation,//TODO to include other fields
//   token: string
// ) => {
//   return axios({
//     method: 'put',
//     url: BASE_URL + `/customerLocation/update/${id}`,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//     data,
//   });
// };

 export const updateCustomerCountryStateLocationService = (
    id: string | number,
    data: ICountryStateLocationRequest,
    token: string
  ) => {
    return client.put(`/customer-location/update/${id}`, data)
    // return axios({
    //   method: 'put',
    //   url: BASE_URL + `/customer-location/update/${id}`,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: token,
    //   },
    //   data,
    // });
   };
  

// export const deleteCustomerLocationService = (
//   id: string | number,
//   token: string
// ) => {
//   return axios({
//     method: 'put',
//     url: BASE_URL + `/customerLocation/remove/${id}`,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//   });
// };

export const deleteCustomerCountryService = (
  id: string | number,
  token: string
) => {
  return client.put(`/customer-location/remove/${id}`, {type:"country"})
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/customer-location/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data:{type:"country"}
  // });
};
export const deleteCustomerStateService = (
  id: string | number,
  token: string
) => {
  return client.put(`/customer-location/remove/${id}`, { type: 'state' })
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/customer-location/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data:{type:"state"}
  // });
};
export const deleteCustomerLocationService = (
  id: string | number,
  token: string
) => {
  return client.put(`/customer-location/remove/${id}`, { type: 'location' })
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + `/customer-location/remove/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data:{type:"location"}
  // });
};

// export const fetchAllCountryService = (token: string) => {
    
//   return axios({
//     method: 'get',
//     url: BASE_URL + '/customerLocation/countries',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//   });
// };

export const fetchAllCountryService = (token: string) => {
  return client.put('/customer-location/list', { type: 'country' })
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + '/customer-location/list',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data:{type:'country'}
  // });
};
// export const fetchAllStateService = (id:number|string,token: string) => {
    
//   return axios({
//     method: 'get',
//     url: BASE_URL + `/customerLocation/states/${id}`,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//   });
// };
export const fetchAllStateService = (token: string) => {
  return client.put('/customer-location/list', { type: 'state' })
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + '/customer-location/list',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data:{type:'state'}
  // });
};
// export const fetchAllStateLocationService = (id:number|string,token: string) => {
    
//   return axios({
//     method: 'get',
//     url: BASE_URL + `/customerLocation/locations/${id}`,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: token,
//     },
//   });
// };
export const fetchAllLocationService = (token: string) => {
  return client.put('/customer-location/list', {type:'location'})
  // return axios({
  //   method: 'put',
  //   url: BASE_URL + '/customer-location/list',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   },
  //   data:{type:'location'}
  // });
};
export const fetchLocationDetailsService = (id:number|string,token: string) => {
  return client.get(`/customer-location/location-by-id/${id}`)
  // return axios({
  //   method: 'get',
  //   url: BASE_URL + `/customer-location/location-by-id/${id}`,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: token,
  //   }
  // });
};