import { Modal } from "react-bootstrap";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";

import Input from "ui/input/Input";

import { ICustomerDetails, ITemplate } from "api/requestTypes";
import { changeTemplateStatus, getS3BucketUrl } from "api/templateService";

import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";

type TemplateInventoryModalType = {
  open: boolean;
  onClose: Function;
  template: ITemplate;
  customInfo: ICustomerDetails;
};
const S3BucketModal: FC<TemplateInventoryModalType> = (props): JSX.Element => {
  const { open, onClose, template, customInfo } = props;

  const { accessToken } = useAppSelector(getUserDetails);

  const [s3url, setS3Url] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<"Draft" | "Ready">(
    template.status === "Ready" ? "Ready" : "Draft"
  );

  const handleForm = (e: FormEvent) => {
    e.preventDefault();
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    setS3Url(value);
  };

  const handleSave = async () => {
    try {
      await changeTemplateStatus(
        {
          childId: customInfo.id,
          templateId: template?.id || 0,
          status: selectedStatus,
        },
        accessToken
      );
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const copyToClipboard = async (design: string) => {
    if (design === "Front") {
      await navigator.clipboard.writeText(s3url);
    } else {
      await navigator.clipboard.writeText(`${s3url}Back/`);
    }
    alert("Copied to clipboard");
  };

  const handleChangeTemplates = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    switch (value) {
      case "Ready":
        setSelectedStatus(value);
        break;

      default:
        setSelectedStatus("Draft");
        break;
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getS3BucketUrl(
          {
            parentId: customInfo.parentId,
            childId: customInfo.id,
            templateId: template?.id || 0,
          },
          accessToken
        );

        setS3Url(data.data);
      } catch (e) {}
    };
    getData();
  }, [accessToken, customInfo, template.id]);

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => onClose()}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">{template.name}</h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="form-group" onSubmit={handleForm}>
          <div>
            <p>Status : </p>
            <select
              className="mb-3 form-select"
              title={""}
              onChange={handleChangeTemplates}
              value={selectedStatus}
              name={"template"}
            >
              <option value="Draft">{"Draft"}</option>
              <option value="Ready">{"Ready"}</option>
            </select>
          </div>
          <p>S3 Bucket URL (Front) : </p>
          <div className="d-flex justify-content-start justify-content-center">
            <div style={{ width: "100%" }}>
              <Input
                value={s3url}
                onChange={handleInput}
                name="s3url"
                placeholder="url"
                disabled
              />
            </div>
            <button
              className="btn btn-outline-danger px-4 ms-3"
              onClick={() => copyToClipboard("Front")}
            >
              Copy
            </button>
          </div>
          {template?.design === "Front and Back" && (
            <div className="mt-3">
              <p>S3 Bucket URL (Back) : </p>
              <div className="d-flex justify-content-start justify-content-center">
                <div style={{ width: "100%" }}>
                  <Input
                    value={`${s3url}BK/`}
                    onChange={handleInput}
                    name="s3url"
                    placeholder="url"
                    disabled
                  />
                </div>
                <button
                  className="btn btn-outline-danger px-4 ms-3"
                  onClick={() => copyToClipboard("Back")}
                >
                  Copy
                </button>
              </div>
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
          <button
            type="button"
            className="btn btn-danger px-4"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default S3BucketModal;
