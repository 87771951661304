import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./UpdateOrder.module.scss";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getItemInfo,
  getTemplatePriceSlabs,
  updateTemplatePriceSlabs,
} from "store/OrderUpdateData.slice";
import RHFEditable from "./RHFEditable";
import { FormProvider, useForm } from "react-hook-form";
import { isEmptyObj } from "utils/helperFunctions";
import SpinnerLoader from "ui/loader/SpinnerLoader";

interface Slab {
  dozenValue: number;
  perUnitPrice: number;
}

interface ItemInfo {
  [key: number]: {
    styleNumber: string;
    templateId: number;
  };
}

interface iProp {
  onHide: () => void;
}

interface UpdateFormProp {
  onHide: () => void;
  itemInfo: ItemInfo;
  templatePriceSlabs: any;
  styleWisePriceSlabs: any[];
}

const sanitizePriceSlabs = (slabs: any) => {
  return slabs.map((item: any) => ({
    dozenValue: item.dozenValue,
    pricePerUnit: item.pricePerUnit,
  })) as Slab[];
};

const toFormValues = (values: any) => {
  const obj = {} as any;
  for (let item of values) {
    for (let slab of item.priceSlabs) {
      obj[`slab/${item.styleNumber}/${slab.dozenValue}`] = slab.pricePerUnit;
    }
  }
  return obj;
};

const toPayload = (
  values: any,
  itemInfo: ItemInfo,
  templatePriceSlabs: any
) => {
  const updatedFor = {} as { [key: number]: boolean };
  const toBeUpdated = JSON.parse(JSON.stringify(templatePriceSlabs));
  for (let id in itemInfo) {
    const { templateId, styleNumber } = itemInfo[id];
    if (updatedFor[templateId]) continue;

    for (let i = 0; i < toBeUpdated[templateId].length; i++) {
      const slab = toBeUpdated[templateId][i];
      const key = `slab/${styleNumber}/${slab.dozenValue}`;
      if (values[key]) {
        toBeUpdated[templateId][i].pricePerUnit = Number(values[key]);
      }
    }
    updatedFor[templateId] = true;
  }
  return toBeUpdated;
};

const UpdateForm: React.FC<UpdateFormProp> = (props): JSX.Element => {
  const { itemInfo, templatePriceSlabs, styleWisePriceSlabs } = props;
  const dispatch = useAppDispatch();
  const methods = useForm({
    defaultValues: toFormValues(styleWisePriceSlabs),
  });

  const handleSubmit = (values: any): any => {
    const payload = toPayload(values, itemInfo, templatePriceSlabs);
    dispatch(updateTemplatePriceSlabs({ templatePriceSlabs: payload }));
    props.onHide();
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Modal.Header>Update Price Slabs</Modal.Header>
        <Modal.Body>
          <div style={{ marginTop: "-30px" }}>
            {styleWisePriceSlabs.map((item: any, index: number) => (
              <div style={{ paddingInline: "30px", marginTop: "50px" }}>
                <h6>{item.styleNumber}</h6>
                <table className="table">
                  <thead>
                    <tr>
                      <th className={styles.pr_td_sr}>#</th>
                      <th className={styles.pr_td}>Dozen</th>
                      <th className={styles.pr_td}>Price/Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.priceSlabs.map((el: any, i: number) => (
                      <tr key={el.dozenValue}>
                        <td className={styles.pr_td_sr}>{i + 1}</td>
                        <td className={styles.pr_td}>{el.dozenValue}</td>
                        <td className={styles.pr_td}>
                          <RHFEditable
                            fieldName={`slab/${item.styleNumber}/${el.dozenValue}`}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={methods.handleSubmit(handleSubmit)}
              className="btn btn-danger me-2"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

const UpdateItemPriceModal: React.FC<iProp> = ({ onHide }): JSX.Element => {
  const itemInfo = useAppSelector(getItemInfo);
  const templatePriceSlabs = useAppSelector(getTemplatePriceSlabs);

  const styleWisePriceSlabs = React.useMemo(() => {
    if (!templatePriceSlabs || !itemInfo) return null;
    const obj = {} as any,
      result = [] as any;
    for (let key in itemInfo) {
      const { templateId, styleNumber } = itemInfo[key];
      const priceSlabs = templatePriceSlabs[templateId];
      if (!obj[styleNumber]) {
        result.push({
          styleNumber,
          priceSlabs: sanitizePriceSlabs(priceSlabs),
        });
        obj[styleNumber] = true;
      }
    }
    return isEmptyObj(result) ? null : result;
  }, [itemInfo, templatePriceSlabs]);

  return (
    <Modal
      className="custom-modal nestedModalL1"
      onHide={onHide}
      aria-labelledby="custom-modal"
      backdrop="static"
      centered={true}
      backdropClassName="nestedModalL1"
      size="lg"
      show
    >
      {styleWisePriceSlabs ? (
        <UpdateForm
          itemInfo={itemInfo}
          templatePriceSlabs={templatePriceSlabs}
          styleWisePriceSlabs={styleWisePriceSlabs}
          onHide={onHide}
        />
      ) : (
        <SpinnerLoader />
      )}
    </Modal>
  );
};

export default UpdateItemPriceModal;
