import { IkeyValuePair } from "api/requestTypes";
import { IMAGES } from "assets/images/images";
import React, { useState } from "react";

type Props = {
    design: string;
    keys: IkeyValuePair[];
    frontLayers: any[]
    backLayers: any[];
    handleChange: Function;
}

type LayersViewProps = { design: 'front'|'back', layers: any[], keys: any[], handleChange: Function }

const MapVariable = (props: Props) : JSX.Element => {
    const { design, keys, frontLayers, backLayers, handleChange } = props

    return (
      <div className="mb-3">
        {frontLayers.length ? <b>Front</b> : null}
        <LayersView design={'front'} layers={frontLayers} keys={keys} handleChange={handleChange} />

        {backLayers.length && design === 'Front and Back' ? <b>BacK</b> : null}
        <LayersView design={'back'} layers={backLayers} keys={keys} handleChange={handleChange} />
      </div>
    );
}

const LayersView: React.FC<LayersViewProps> = ({ design, layers, keys, handleChange }): JSX.Element => {
    return (
        <ul
          className={`list-group list-group-flush list-style-none mb-3`}
        >
          {React.Children.toArray(
            layers.map((layer, key) => (
              <li key={`var-${key}`} className="mb-3" >
                <div className="row align-items-center">
                  <div className="col">
                    <select className="form-select" disabled>
                      <option value={layer.layerName}>
                        {layer.layerName}
                      </option>
      
                    </select>
                  </div>
                  <div className="col-auto">
                    <img src={IMAGES.ArrowRight} alt="" />
                  </div>
                  <div className="col">
                    <select
                      className="form-select"
                      title={''}
                      value={layer.keyName}
                      name={layer.layerName}
                      onChange={(e) => handleChange(e, design)}
                    >
                      <option value="">{'Select'}</option>
                      {React.Children.toArray(
                        keys.map((el) => (
                          <option value={el.key}>
                            {el.key}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
    )
}

export default MapVariable