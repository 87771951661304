import * as React from 'react'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'

import Input from 'ui/input/Input';
import { graphicColorService } from 'api';
import { useGraphicColorCreate, useGraphicColorQuery, useGraphicColorUpdate } from 'react-query';

type ModalAction = 'Add' | 'Update' | null

interface Fields {
  id: number | null;
  color_name: string;
  color_code: string;
}

interface IProps {
  modalAction: ModalAction;
  open: boolean;
  onHide: () => void;
  colorId: number | null;
}

const NullFields = { id: null, color_name: '', color_code: '' }

const AddUpdateGraphicColor: React.FC<IProps> = (props): JSX.Element => {
  const modalAction: ModalAction = props.modalAction
  const { data: color, isLoading } = useGraphicColorQuery(props.colorId as number)
  const { mutate: addGraphicColor, isPending: isCreating } = useGraphicColorCreate()
  const { mutate: updateGraphicColor, isPending: isUpdating } = useGraphicColorUpdate()
  const [fields, setFields] = React.useState<Fields>(NullFields);

  
  React.useLayoutEffect(() => {
    setFields(NullFields)
  }, [props.open])

  React.useEffect(() => {
    if(color?.id){
      setFields({ id: color.id, color_name: color.color_name, color_code: color.color_code })
    }
  }, [color])
  
  const disableSubmit = !fields?.color_name?.trim().length || !fields?.color_code?.trim().length || isLoading

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  
  
  const handleSubmit = async(e: any) => {
    e.preventDefault()
    const payload = { color_name: fields.color_name, color_code: fields.color_code }
    if(modalAction === 'Add'){
      addGraphicColor(payload)
    }else if(modalAction === 'Update'){
      updateGraphicColor({ id: props.colorId as number, dataToUpdate: payload  })
    }
    props.onHide()

  }

  return (
    <Modal
    show={props.open}
    onHide={props.onHide}
    className="custom-drawer date-picker-modal"
    aria-labelledby="custom-modal"
    backdrop="static"
    size={"lg"}
  >
    <Modal.Body>
      <form onSubmit={handleSubmit}>
        <div className="border-bottom pb-1 mb-3">
          <h4 className="mb-0 heading4-bold">{modalAction} graphic color</h4>
        </div>
        <div className="row g-3 align-items-center mb-3">
          <div className="col-md-3">
            <label className="text-capitalize">Color name</label>
          </div>
          <div className="col-md-9">
            <Input
              name="color_name"
              value={fields.color_name}
              onChange={handleFieldChange}
              placeholder="Enter color name"
              disabled={isLoading}
            />
          </div>
          <div className="col-md-3">
            <label className="text-capitalize">Color code</label>
          </div>
          <div className="col-md-9">
            <Input
              name="color_code"
              value={fields.color_code}
              onChange={handleFieldChange}
              placeholder="Enter color code"
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="mt-3">
          <button
            type="submit"
            className="btn btn-danger px-4"
            disabled={disableSubmit}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal.Body>
  </Modal>
  )
}

export default AddUpdateGraphicColor