import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import AddUpdateGraphicColor from "./AddUpdateGraphicColor";
import AddUpdateGraphicColorGroup from "./AddUpdateGraphicColorGroup";

import { useModal } from "hooks/useModal";
import MoveColorToGroup from "./MoveColorToGroup";
import { useColorGroupDelete, useColorGroupsQuery, useGraphicColorDelete, useGraphicColorGroupItemsQuery, useGraphicColorsQuery } from "react-query";

interface GraphicColorProp {}
type ModalAction = 'Add' | 'Update' | null
const UN_GROUPED = 'un_grouped'

const GraphicColors: React.FC<GraphicColorProp> = (): JSX.Element => {

  const { data: colors = [] } = useGraphicColorsQuery()
  const { data: groups = [] } = useColorGroupsQuery()
  const { data: groupItems = [] } = useGraphicColorGroupItemsQuery()
  const { mutate: deleteGraphicColor } = useGraphicColorDelete()
  const { mutate: deleteColorGroup } = useColorGroupDelete()

  const [groupId, setGroupId] = React.useState<number | null>(null)
  const [colorId, setColorId] = React.useState<number | null>(null)

  const groupModal = useModal()
  const colorModal = useModal()
  const colorMoveModal = useModal()
  const [modalAction, setModalAction] = React.useState<ModalAction>(null)

  const groupedColors = React.useMemo(() => {
    const isColorGroupedObj: any = {}
    const resultObj: any = {
      [UN_GROUPED]: { id: UN_GROUPED, group_name: 'Ungrouped', colors: [] }
    }
    for(let item of colors){ isColorGroupedObj[item.id] = false }
    for(let item of groups){
      if(!resultObj[item.id]) resultObj[item.id] = { id: item.id, group_name: item.group_name, colors: [] }
    }
    for(let item of groupItems){
      if(resultObj[item?.group_id]){
        isColorGroupedObj[item.color_id] = true
        resultObj[item?.group_id].colors.push({ 
          id: item.color_id,
          color_name: item.color_name,
          color_code: item.color_code
        })
      }
    }
    for(let item of colors){
      if (!isColorGroupedObj[item.id]) resultObj[UN_GROUPED].colors.push({
        id: item.id,
        color_name: item.color_name,
        color_code: item.color_code
      });
    }

    if(resultObj[UN_GROUPED].colors.length === 0){
      delete resultObj[UN_GROUPED]
    }

    return Object.values(resultObj)

  }, [colors, groups, groupItems])


  const handleOpenGroupModel = (modalAction: ModalAction, groupId: number | null) => {
    groupModal.onShow()
    setGroupId(groupId)
    setModalAction(modalAction)
  }

  const handleCloseGroupModel = () => {
    groupModal.onHide()
    setGroupId(null)
    setModalAction(null)
  }

  const handleOpenColorModel = (modalAction: ModalAction, colorId: number | null) => {
    colorModal.onShow()
    setColorId(colorId)
    setModalAction(modalAction)
  }

  const handleCloseColorModel = () => {
    colorModal.onHide()
    setColorId(null)
    setModalAction(null)
  }

  const handleOpenColorMoveModel = (groupId: number, colorId: number) => {
    colorMoveModal.onShow()
    setColorId(colorId)
    setGroupId(groupId)
  }
  
  const handleCloseColorMoveModel = () => {
    colorMoveModal.onHide()
    setColorId(null)
    setGroupId(null)
  }

  return (
    <>
      <div className="graphic-color-box">
        <div className="graphic-color-cntr">
          <div className="graphic-color-top">
            <div className="graphic-top-left">
              <h5 className="graphic-color-heads">Graphic Colors</h5>
            </div>
            <div className="graphic-top-right">
              <ul className="graphic-list-wrap">
                <li className="graphic-list-item">
                  <button
                    className="color-group-btn"
                    onClick={() => handleOpenColorModel("Add", null)}
                  >
                    <span
                      className="fa fa-plus-circle icon"
                      aria-hidden="true"
                    ></span>
                    <span>New Color</span>
                  </button>
                </li>
                <li className="graphic-list-item">
                  <button
                    className="color-group-btn"
                    onClick={() => handleOpenGroupModel("Add", null)}
                  >
                    <span
                      className="fa fa-plus-circle icon"
                      aria-hidden="true"
                    ></span>
                    <span>New Group</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="graphic-color-bottom">
            <ul className="color-group-list">
              {groupedColors.map((item: any) => (
                <li className="color-group-item" key={item.id}>
                  <div className="color-group-card">
                    <div className="color-group-top">
                      <h6 className="text-with-icon">{item.group_name}</h6>
                      <Dropdown className="icon-dropdown" style={{ display: item.id === UN_GROUPED ? 'none' : 'block' }}>
                        <Dropdown.Toggle
                          className="edit-name-btn"
                          disabled={!item.id}
                        >
                          <span
                            className="fa fa-pencil edit"
                            aria-hidden="true"
                          ></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="dropdown-container">
                            <Dropdown.Item
                              onClick={() =>
                                handleOpenGroupModel("Update", item.id)
                              }
                            >
                              Edit Group Name
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => deleteColorGroup(item.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="color-group-bottom">
                      <div className="color-name-list">
                        {/* repeated items */}
                        {item.colors.map((color: any) => (
                          <div className="color-name-item" key={color.id}>
                            <div className="color-name-wrap">
                              <button className="dragable btn">
                                <span className="material-symbols-outlined">
                                  equal
                                </span>
                              </button>
                              <h5 className="color-name-text">
                                <span>{color.color_name}</span>
                              </h5>
                              <Dropdown className="icon-dropdown">
                                <Dropdown.Toggle className="edit-color btn">
                                  <span className="material-symbols-outlined">
                                    more_vert
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div className="dropdown-container">
                                    <Dropdown.Item onClick={() => handleOpenColorModel('Update', color.id)}>
                                      Edit Color
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleOpenColorMoveModel(item.id, color.id)}>
                                      Move to Group
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteGraphicColor(color.id)}>
                                      Delete
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        ))}
                        {/* repeated items */}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <AddUpdateGraphicColor
        open={colorModal.open}
        onHide={handleCloseColorModel}
        modalAction={modalAction}
        colorId={colorId}
      />
      <AddUpdateGraphicColorGroup
        open={groupModal.open}
        onHide={handleCloseGroupModel}
        modalAction={modalAction}
        groupId={groupId}
      />
      <MoveColorToGroup
        open={colorMoveModal.open}
        onHide={handleCloseColorMoveModel}
        colorId={colorId}
        groupId={groupId}
        groups={groups}
      />
    </>
  );
};

export default GraphicColors;
