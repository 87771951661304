import { FC, useState } from 'react';
import styles from './MultipleValueInput.module.scss';
interface IMultipleValueInput {
  sectionClass?: string;
  defaultValue: string;
  onChangeMultiInput:(name:string,value:string)=>void
  label: string;
  name: string;
  errorMessage?:string;
}

const MultipleValueInput: FC<IMultipleValueInput> = ({
  sectionClass = 'align-items-center mb-3',
  defaultValue,
  onChangeMultiInput,
  label,
  name,
  errorMessage='',
  
}) => {
  const [currentValue, setCurrentValue] = useState<string[]>(defaultValue?defaultValue.trim().split(','):[]);
  const [currentChip, setCurrentChip] = useState<string>('');
  const removeChip = (index: number) => {
    let rslt = currentValue.filter((chip, idx) => idx !== index);
    onChangeMultiInput(name,rslt.toString());
    setCurrentValue(rslt);
  };
  const handleBlur = () => {
    if (currentChip.trim()){
      onChangeMultiInput(name,[...currentValue, currentChip.trim()].toString());
      setCurrentValue([...currentValue, currentChip.trim()]);
    }
    setCurrentChip('');
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentChip.trim()){
        onChangeMultiInput(name,[...currentValue, currentChip.trim()].toString());
        setCurrentValue([...currentValue, currentChip.trim()]);
      }
      setCurrentChip('');
    }
  };

  return (
    <div className={`row g-3 ${sectionClass}`}>
      <div className="col-md-3">
        <label className="text-capitalize">{label}</label>
      </div>
      <div className="col-md-9">
        <label
          className={`card bg-gray ${styles.valuesCard}`}
          htmlFor={`currentChip-${currentChip}`}
        >
          <div className="d-flex align-items-center flex-wrap">
            {
              <>
                {currentValue.length > 0 &&
                  currentValue.map((chip, index) => {
                    return (
                      <span
                        className="badge badge-rounded bg-white text-dark font-size-xs mx-2 my-1"
                        key={`chip-${index}`}
                      >
                        {chip.trim()}
                        <button
                          className="btn btn-link bg-gray text-dark font-size-sm btn-close-circle ms-1"
                          onClick={() => removeChip(index)}
                        >
                          <i className="fa-solid fa-xmark">{''}</i>
                        </button>
                      </span>
                    );
                  })}
              </>
            }
            <input
              onChange={(e) => {
                setCurrentChip(e.target.value);
              }}
              className={styles.valuesInput}
              id={'currentChip'}
              type="text"
              name={'currentChip'}
              value={currentChip}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              placeholder={currentValue.length?"":"Abc"}
              
            />
          </div>
        </label>
        {/* values error*/}
        {errorMessage && (
            <span style={{ color: 'red' }}>{errorMessage}</span>
          )}
      </div>
    </div>
  );
};
export default MultipleValueInput;
