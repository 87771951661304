import React, { ChangeEvent, FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { isValidPhoneNumber } from "react-phone-number-input";

import Input from "../../ui/input/Input";
import InputPhone from "../../ui/phone/InputPhone";
import {
  checkExistingEmail,
  isAlreadyExisted,
  isEmailAlreadyExist,
  validateEmail,
} from "../../utils/helperFunctions";
import { useAppSelector } from "../../store/hooks";
import { getUserDetails, setUserDetails } from "../../store/User.slice";
import {
  fetchUserExistanceService,
  registerUserService,
} from "../../api/userService";
import { USER_ROLE } from "../../utils/constants";
import SpinnerLoader from "../../ui/loader/SpinnerLoader";
import axios from "axios";
import { AccountInfo } from "types/AccountsSlice";
import { toast } from "react-toastify";

interface IAddUser {
  title?: string;
  open: boolean;
  setOpen: Function;
  setList?: Function;
  parentId?: string | number;
  setRefetch?: Function;
}

const AddUserModal: FC<IAddUser> = ({
  title,
  open,
  setOpen,
  setList,
  parentId,
  setRefetch,
}) => {
  const { accessToken } = useAppSelector(getUserDetails);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    email: "",
    // phone: '',
    role: parentId ? USER_ROLE.CONTACT_PERSON : USER_ROLE.SALES_PERSON,
    //password: ''
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    //password: '',
    // phone: ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
    switch (name) {
      case "name":
        return !value.trim()
          ? setError({ ...error, [name]: "Name can not be empty" })
          : setError({
              ...error,
              [name]: "",
            });
      case "email":
        return !value.trim() || !validateEmail(value.trim())
          ? setError({
              ...error,
              [name]: "Enter valid email id",
            })
          : setError({ ...error, [name]: "" });
      // case "password":
      //     return value.trim().length < 8 ? setError({
      //         ...error,
      //         [name]: 'Password should have minimum 8 character'
      //     }) : setError({...error, [name]: ''});
      default:
        break;
    }
  };
  // const handlePhoneInfo=(val:any)=>{

  //     if(val && !isValidPhoneNumber(val))
  //         setError({...error,phone:"Invalid Phone Number"});
  //     else if(val){
  //         setError({...error,phone:''})
  //         setInfo({...info,phone:val});
  //     }
  // }

  const isButtonDisabled = () => {
    let data = { ...info };
    //return data.name.trim() && data.email.trim() && validateEmail(data.email.trim()) && data.password.trim().length > 7
    return (
      data.name.trim() && data.email.trim() && validateEmail(data.email.trim())
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { email } = info;
    const existingUser = await checkExistingEmail(accessToken, email);

    if (existingUser) {
      if (!(existingUser.name == info.name)) {
        const permission = window.confirm(
          `Contact person with same email (${existingUser.email}) is already registered as ${existingUser.name}. Do you want to add this user in this account?`
        );
        if (!permission) {
          setInfo({
            name: "",
            email: "",
            role: parentId ? USER_ROLE.CONTACT_PERSON : USER_ROLE.SALES_PERSON,
          });
          setLoading(false);
          return;
        } else {
          setInfo({
            name: existingUser.name,
            email: existingUser.email,
            role: parentId ? USER_ROLE.CONTACT_PERSON : USER_ROLE.SALES_PERSON,
          });
        }
      }
    }
    try {
      const payload = {
        ...info,
        parentId: parentId ? parentId : "",
      };
      const { data } = await registerUserService(payload);
      if (data.data) {
        // if(setList) setList(data.data);
        toast.success("Added Successfully");
        setOpen(false);
        typeof setRefetch === "function" && setRefetch(true);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setError({ ...error, email: (e.response?.data as any)?.msg });
        setLoading(false);
      } else {
        console.error("Registration Failed:", e);
      }
    }
  };

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Body>
        {loading && <SpinnerLoader />}
        <div className="border-bottom mb-3">
          <p className="mb-0">{title}</p>
        </div>
        <Input
          sectionClass="mb-3"
          value={info.name}
          onChange={handleChange}
          label="Contact Account Name*"
          name="name"
          placeholder="Abc"
          errorMessage={error.name}
        />
        <Input
          sectionClass="mb-3"
          value={info.email}
          onChange={handleChange}
          label="Contact Email*"
          name="email"
          placeholder="email@web.com"
          errorMessage={error.email}
        />
        {/* <InputPhone
          sectionClass="mb-3"
          label="Input Phone Number"
          onChange={handlePhoneInfo}
          value={info.phone}
          errorMessage={error.phone}
        /> */}
        {/* <Input
                    sectionClass="mb-3"
                    value={info.password}
                    onChnage={handleChange}
                    label="Password*"
                    name="password"
                    type="password"
                    errorMessage={error.password}
                /> */}
        <div className="mt-3">
          <button
            className="btn btn-danger px-4"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isButtonDisabled()}
          >
            Save
          </button>
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
