import { StylePrice, FormValues } from './interface'
const PriceFieldRegex = /item_(\d+)_(\d+)/;

export function transformAsFormValues(dozen: {id: string, value: string}[], stylePrices: StylePrice[]){
    const idToIdxMap = dozen.reduce((a: any, c, idx) => { a[c.id] = idx; return a },{});
    return {
      dozen: dozen.map(({ id, value }) => ({ dozenId: id, value })),
      data: stylePrices.reduce((a: Record<string, any>, c) => {
        if (idToIdxMap.hasOwnProperty(c.dozenId)) {
          a[`item_${c.styleId}_${idToIdxMap[c.dozenId]}`] = c.price;
        }
        return a;
      }, {}),
    }
  }
  
export function getPayload(dirtyFields:any, allValues: FormValues): any {
    const idxToIdMap = allValues.dozen.reduce((a: any, c: any, idx) => {
      a[idx] = c.dozenId;
      return a;
    }, {});
    return Object.entries(dirtyFields).reduce(
      (a: any, [key, value]: any) => {
        if (key === "dozen") {
          value.forEach((_: any, index: number) => {
            a.dozen.push({
              index,
              id: allValues.dozen[index].dozenId,
              value: allValues.dozen[index].value,
            });
          });
        } else if (key === "data") {
          Object.entries(value).forEach(([fieldName]) => {
            const priceField = fieldName.match(PriceFieldRegex);
            if (!priceField) return
            const styleId = Number(priceField[1]);
            const dozenIdx = Number(priceField[2]);
            a.stylePrices.push({
              styleId,
              dozenIdx,
              dozenId: idxToIdMap[dozenIdx],
              price: allValues.data[fieldName]
            });
          });
        }
        return a;
      },
      { stylePrices: [], dozen: [] }
    );
  }