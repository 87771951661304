import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./UpdateOrder.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateShippingRates } from "store/OrderUpdateData.slice";
import {
  getItemInfo,
  getShippingRates,
} from "store/OrderUpdateData.slice";
import { FormProvider, useForm } from "react-hook-form";
import RHFEditable from "./RHFEditable";
import { isEmptyObj } from "utils/helperFunctions";

interface iProp {
  onHide: () => void;
}

interface iUpdateFormProp { shippingRates: { [key: string]: number}, onDone: () => void }

const resolver = async (values: { [key: string]: any }) => {
    const valuesObj = {} as { [key: string]: number }
    const errorsObj = {} as {[key: string]: { type: string, message: string }}
    try {
      for(let key in values){
        const value = Number(values[key])
        if(isNaN(value) || value === 0){
          errorsObj[key] = { type: 'validation', message: 'Must be a non zero number' }
        }
        valuesObj[key] = value
      }
      if(!isEmptyObj(errorsObj)){
        throw new Error('Validation Error')
      }
      return {
        values: valuesObj,
        errors: {},
      };
    } catch (err) {
      return {
        values: {},
        errors: errorsObj,
      };
    }
  }

const UpdateForm: React.FC<iUpdateFormProp> = ({ shippingRates, onDone }): JSX.Element => {
  const blankStyles = Object.keys(shippingRates)

  return (
    <>
      <Modal.Header>
        <h5>Update Shipping Cost</h5>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "100%" }}>
          <table className="table">
            <thead>
              <tr>
                <th className={styles.sp_td_sr}>#</th>
                <th className={styles.sp_td}>Style</th>
                <th className={styles.sp_td}>Cost / Unit</th>
              </tr>
            </thead>
            <tbody>
              {blankStyles?.map((blankStyle: any, i) => (
                <tr key={blankStyle}>
                  <td className={styles.sp_td_sr}>{i + 1}</td>
                  <td className={styles.sp_td}>{blankStyle}</td>
                  <td className={styles.sp_td}>
                    <input type='hidden' defaultValue={blankStyle} />
                    <RHFEditable fieldName={blankStyle} defaultValue={shippingRates[blankStyle]} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={onDone}
            className="btn btn-danger me-2"
          >
            Done
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

const UpdateItemShippingCostModal: React.FC<iProp> = ({
  onHide,
}): JSX.Element => {

  const dispatch = useAppDispatch()
  const itemInfo = useAppSelector(getItemInfo);
  const shippingRates = useAppSelector(getShippingRates);

  const applicableRates = React.useMemo(() => {
    const applicableBlanks = {} as { [key: string]: boolean };
    for (let key in itemInfo) {
      applicableBlanks[itemInfo[key].blankStyleNumber] = true;
    }
    const _applicableRates = {} as  { [key: string]: number };
    for (let blankStyle in shippingRates) {
      if (applicableBlanks[blankStyle] === true) {
        _applicableRates[blankStyle] = shippingRates[blankStyle]
      }
    }
    return _applicableRates
  }, [itemInfo, shippingRates]);

  const methods = useForm({
    resolver: resolver,
    defaultValues: applicableRates,
    shouldUnregister: true
  });

  const onSubmit = (values: any) => {

    const clone = { ...shippingRates }
    for(let blankStyle in values){
      clone[blankStyle] = Number(values[blankStyle])
    }

    dispatch(updateShippingRates({ shippingRates: clone }))
    onHide()
  };

  return (
    <Modal
      className="custom-modal nestedModalL1"
      show={true}
      onHide={onHide}
      aria-labelledby="custom-modal"
      backdrop="static"
      centered={true}
      size="lg"
      backdropClassName="nestedBackdropL1"
    >
      <FormProvider {...methods}>
        <form>
          <UpdateForm shippingRates={applicableRates} onDone={methods.handleSubmit(onSubmit)} />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default UpdateItemShippingCostModal;
