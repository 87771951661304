import React, {FC, ReactElement} from "react";

type Props = {
    headingTitle: string;
    wrapperClassName?: string;
    children?: ReactElement
};

const  Heading: FC<Props> = (props) => {
    const {headingTitle, children, wrapperClassName} = props;
    return(
        <div className={`d-flex justify-content-between align-items-center ${wrapperClassName ? wrapperClassName : ''}`}>
            <h4 className="mb-0 heading4-bold">{headingTitle}</h4>
            <div>{children}</div>
        </div>
    )
}

export default Heading;