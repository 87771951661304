import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from 'components/navBar/NavBar';
import 'App.tsx';
import Sidebar from "../components/sideBar/Sidebar";

interface LayoutProps {}
const Layout: React.FC<LayoutProps> = () => {
  return (
    <div>
      <NavBar />
        <Sidebar/>
      <main className="main-content">
          <Outlet />
      </main>
    </div>
  );
};

export default Layout;
