import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import Input from '../../ui/input/Input';
import EmptyList from 'ui/empty-list/EmptyList';
import styles from './KeyValuesPair.module.scss';
import { Attribute, IkeyValuePair } from 'api/requestTypes';
import Select from 'ui/select/Select';

interface IKeyValuePairInput {
  sectionClass?: string;
  customData: IkeyValuePair[];
  setCustomData: Function;
}

const KeyValuePairInput: FC<IKeyValuePairInput> = ({
  sectionClass,
  customData,
  setCustomData,
}) => {


  const [currentKey, setCurrentKey] = useState<string>('');
  const [currentValue, setCurrentValue] = useState<string[]>([]);
  const [currentChip, setCurrentChip] = useState<string>('');

  const [keyError, setKeyError] = useState<string>('');
  const [valueError, setValueError] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string | number>('');
  const removePair = (key: string) => {
    let rslt = customData.filter((el) => el.key !== key);
    setCustomData(rslt);

  };
  const updatePair = (value: string, key: string, id: string | number) => {
    let rslt = customData.filter((el) => el.key !== key);
    setCustomData(rslt);
    setCurrentKey(key);
    if (value.trim().length) {
      let temp = value.split(',');
      setCurrentValue(temp); //value
    }
    setCurrentId(id);
  };

  const addNew = () => {
    if (currentKey.trim() && currentValue.length) {
      let duplicates = customData.filter(
        (d) => d.key.toLowerCase() === currentKey.toLowerCase()
      );
      if (duplicates.length) setKeyError('This key already exists');
      else {
        //console.log(currentValue.toString());
        setCustomData([
          ...customData,
          { id: currentId, key: currentKey, value: currentValue.toString().trim() },
        ]);
        setCurrentKey('');
        setCurrentValue([]);
        setCurrentChip('');
        setCurrentId('');
        setKeyError('');

      }
    } else if (currentKey.trim()) {
      setValueError(true);
    } else if (currentValue.length) {
      setKeyError('Please Enter key');
    }
  };
  const removeChip = (index: number) => {
    let rslt = currentValue.filter((chip, idx) => idx !== index);
    setCurrentValue(rslt);
  };
  const handleBlur = () => {
    if (currentChip.trim())
      setCurrentValue([...currentValue, currentChip.trim()]);
    setCurrentChip('');
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentChip.trim())
        setCurrentValue([...currentValue, currentChip.trim()]);
      setCurrentChip('');
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    setCurrentKey(value);
    setKeyError('');
  };

  return (
    <div>

      <div className={`row g-3 ${sectionClass}`}>
        <div className="col-md-6">
          <Input
            value={currentKey}
            name={'currentKey'}
            label="Key"
            placeholder="Abc"
            onChange={handleInput}
            errorMessage={keyError}
          />
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-capitalize" htmlFor="values">
              Values
            </label>
            <button type="button" className="btn btn-link" onClick={addNew}>
              <i className="fa-solid fa-circle-plus text-danger me-2">{''}</i>

              <span className="text-dark">Add</span>
            </button>
          </div>
          <label
            className={`card bg-gray ${styles.valuesCard}`}
            htmlFor={currentChip}
          >
            <div className="d-flex align-items-center flex-wrap">
              {
                <>
                  {currentValue.length > 0 &&
                    currentValue.map((chip, index) => {
                      return (
                        <span
                          className="badge badge-rounded bg-white text-dark font-size-xs mx-2 my-1"
                          key={`chip-${index}`}
                        >
                          {chip.trim()}
                          <button
                            className="btn btn-link bg-gray text-dark font-size-sm btn-close-circle ms-1"
                            onClick={() => removeChip(index)}
                          >
                            <i className="fa-solid fa-xmark">{''}</i>
                          </button>
                        </span>
                      );
                    })}
                </>
              }
              <div className={`d-inline-block mr-2 my-1 align-middle mx-2`}>
                <input
                  onChange={(e) => {
                    setCurrentChip(e.target.value);
                    setValueError(false);
                  }}
                  className={styles.valuesInput}
                  id={'currentChip'}
                  type="text"
                  name={'currentChip'}
                  value={currentChip}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </label>
          {/* values error*/}
          {valueError && (
            <span style={{ color: 'red' }}>{'Please enter values'}</span>
          )}
        </div>
      </div>
      <div>
        {customData.length > 0 ? (
          <ul className="list-group list-group-flush list-style-none">
            {React.Children.toArray(
              customData.map((data, key) => (
                <li className="has-right-action mb-2" key={key}>
                  {/* <p className="mb-0 text-capitalize">{`${data.key} : ${data.value}`}</p> */}
                  <p className="mb-0">{`${data.key} : ${data.value}`}</p>
                  <div className="action-wrap">
                    <button
                      onClick={() =>
                        updatePair(data.value, data.key, data.id ? data.id : '')
                      }
                      className="btn btn-link-danger"
                      type="button"
                    >
                      <i className="fa-solid fa-pen">{''}</i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-link-danger ms-3"
                      onClick={() => removePair(data.key)}
                    >
                      <i className="fa-solid fa-xmark">{''}</i>
                    </button>
                  </div>
                </li>
              ))
            )}
          </ul>
        ) : (
          <EmptyList title={'No key added'} />
        )}
      </div>
    </div>
  );
};

export default KeyValuePairInput;
