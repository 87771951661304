import { ITemplate } from "api/requestTypes";
import { fetchApparelMagicStyles } from "api/style";
import React, { SyntheticEvent, useCallback, useEffect } from "react";
import { FC, useState } from "react";
import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";
import Input from "ui/input/Input";
import SpinnerLoader from "ui/loader/SpinnerLoader";
interface Iprops {
  styleList: { styleName: string; subStyleName: string }[];
  selectedStyle: {
    styleName: string;
    subStyleName: string;
    styleNumber: string;
    b2bWebTitle: string;
  };
  setSelectedStyle: Function;
  template: ITemplate;
  setTemplate: React.Dispatch<React.SetStateAction<ITemplate>>;
  blankTemplates: { id: number, productId: string, styleNumber: string; description: string }[]
}

interface IApparelMagicStyle {
  b2bWebTitle: string;
  description: string;
  productId: string;
  sizeRangeId: string;
  styleNumber: string;
}
const StyleSelectUI: FC<Iprops> = ({
  styleList,
  selectedStyle,
  setSelectedStyle,
  template,
  setTemplate,
  blankTemplates
}): JSX.Element => {
  const { accessToken } = useAppSelector(getUserDetails);

  const [loading, setLoading] = useState(false);
  const [apparelMagicStyles, setApparelMagicStyles] = useState<
    IApparelMagicStyle[]
  >([]);
  const handleChangeStyle = (e: any) => {
    setSelectedStyle((prev: IApparelMagicStyle[]) => ({
      ...prev,
      styleName: e.target.value,
      subStyleName: "",
    }));
  };

  const handleChangeSubstyle = (e: any) => {
    setSelectedStyle((prev: IApparelMagicStyle[]) => ({
      ...prev,
      styleName: selectedStyle.styleName,
      subStyleName: e.target.value,
    }));
  };

  const getApparelMagicStyle = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { altCode: template.altCode };
      const { data } = await fetchApparelMagicStyles(payload, accessToken);
      setLoading(false);
      setApparelMagicStyles(data.data);
      // console.log(data.data);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [accessToken, template.altCode]);

  const onApparelMagicStyleSelection = (e: any) => {
    const item = apparelMagicStyles.find(style => style.styleNumber === e.target.value);
    setTemplate((prev: ITemplate) => ({
      ...prev,
      // styleNumber: item.styleNumber,
      // b2bWebTitle: item.b2bWebTitle,
      ...item,
    }));
  };

  const onBlankTemplateSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(blankTemplates)
    const blankTemplate = blankTemplates.find(item => parseInt(item.productId) === parseInt(e.target.value));
    setTemplate((prev: ITemplate) => ({
      ...prev,
      blankGarmentId: Number(blankTemplate?.productId),
      blankGarmentStyleNumber: String(blankTemplate?.styleNumber),
    }));
  };

  console.log(template)

  useEffect(() => {
    if (template.altCode) {
      getApparelMagicStyle();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <SpinnerLoader />;

  return (
    <div>
      <select
        className="mb-3 form-select"
        title={"Select Style"}
        onChange={handleChangeStyle}
        value={selectedStyle.styleName}
        name={"styleName"}
      >
        {<option value="">{"Select Style"}</option>}
        {React.Children.toArray(
          Array.from(new Set(styleList.map((style) => style.styleName))).map(
            (el) => <option value={el}>{el}</option>
          )
        )}
      </select>

      {selectedStyle.styleName && <p className="mb-2">Sub Style*</p>}
      {selectedStyle.styleName && (
        <select
          className="mb-3 form-select"
          title={"Select Substyle"}
          onChange={handleChangeSubstyle}
          value={selectedStyle.subStyleName}
          name={"subStyleName"}
        >
          {<option value="">{"Select Sub Style"}</option>}
          {React.Children.toArray(
            styleList.map(
              (el) =>
                el.styleName === selectedStyle.styleName && (
                  <option value={el.subStyleName}>{el.subStyleName}</option>
                )
            )
          )}
        </select>
      )}
      <p className="mb-2">Style from Apparel Magic</p>
      <div className="row mb-3 mt-3">
        <div className="col-lg-9">
          <input
            type="text"
            value={template.altCode}
            className="form-control"
            onChange={(e) =>
              setTemplate((prev) => ({ ...prev, altCode: e.target.value }))
            }
            placeholder="Enter altCode "
          />
        </div>
        <div className="col-lg-3 d-flex justify-content-start align-items-center">
          <button
            className="btn btn-outline-danger px-4 ms-3"
            type="button"
            disabled={!template.altCode}
            onClick={() => getApparelMagicStyle()}
          >
            {" "}
            Fetch Style
          </button>
        </div>
      </div>
      {apparelMagicStyles.length > 0 && (
        <select
          defaultValue={template.styleNumber}
          onChange={onApparelMagicStyleSelection}
          className="mb-3 form-select"
          title={"Select Style"}
        >
          <option value="">Select</option>
          {apparelMagicStyles.map((el) => (
            <option
              key={el.styleNumber}
              value={el.styleNumber}
              // onChange={(e) => onApparelMagicStyleSelection(e, el)}
            >
              {el.styleNumber}
            </option>
          ))}
        </select>
      )}
      <p className="mb-2">Blank Template*</p>
      {blankTemplates.length > 0 && (
        <select
          defaultValue={template.blankGarmentId}
          onChange={onBlankTemplateSelection}
          className="mb-3 form-select"
          title={"Select Blank Template"}
          name={"blankGarmentId"}
        >
          <option value={0} disabled selected>Select</option>
          {blankTemplates.map((el) => (
            <option
              key={el.styleNumber}
              value={el.productId}
            >
              {el.styleNumber} - {el.description}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default StyleSelectUI;
