import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const getInventoryListByTemplateId = (
  id: number | string,
  token: string
) => {
  return client.get(`/inventory/list?templateId=${id}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/inventory/list?templateId=${id}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  // });
};

export const saveToInventory = (data: any, token: string) => {
  return client.post('/inventory/save', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/inventory/save",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchBlankGarments = (token: string) => {
  return client.get('/inventory/blank-garments')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/inventory/blank-garments",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const fetchLocalBlankGarments = (token: string) => {
  return client.get('/inventory/local-blank-garments')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/inventory/local-blank-garments",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const addBlankGarment = (data: any, token: string) => {
  return client.post('/inventory/add-blank-garment', data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/inventory/add-blank-garment",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};


export const fetchBlankGarmentInventory = (productId: any, token: string) => {
  return client.get(`inventory/blank-garments-inventor/${productId}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/inventory/blank-garments-inventory/"+ productId,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const fetchLocalBlankGarmentInventory = (productId: number | null, token: string) => {
  return client.get(`/inventory/local-blank-garments-inventory/${productId}`)
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/inventory/local-blank-garments-inventory/"+ productId,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const startInventorySyncFromAM = (token: string) => {
  return client.post('/events/trigger', { eventName: "SYNC_INVENTORY_FROM_AM" })
  // return axios({
  //   method: "post",
  //   url: BASE_URL + "/events/trigger",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: { "eventName": "SYNC_INVENTORY_FROM_AM" }
  // });
};

export const checkInventorySyncStatus = (token: string) => {
  return client.get('/events/status/SYNC_INVENTORY_FROM_AM')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/events/status/SYNC_INVENTORY_FROM_AM",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const fetchAllAMAltCodes = (token: string) => {
  return client.get('/am-alt-codes')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/am-alt-codes",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const fetchAllAMStyles = () => {
  return client.get('/inventory/styles').then((res) => res?.data?.data)
};

export const fetchAllDozen = () => {
  return client.get('/inventory/dozen').then((res) => res?.data?.data)
};

export const addDozen = (payload: { value: string }) => {
  return client.post('/inventory/dozen', payload).then((res) => res?.data?.data)
};

export const updateDozen = (id: string, payload: { value: string }) => {
  return client.put(`/inventory/dozen/${id}`, payload).then((res) => res?.data?.data)
};

export const fetchAllStylePrices = () => {
  return client.get('/inventory/style-prices').then((res) => res?.data?.data)
};

export const fetchStylePriceByStyleDozen = (query: { styleId: number, dozenId: number }) => {
  return client.get(`/inventory/style-prices/${query.styleId}/${query.dozenId}`).then((res) => res?.data?.data)
};

export const updateStylePrice = (payload: any) => {
  return client.put(`/inventory/style-prices`, payload).then((res) => res?.data?.data)
};

export const startStyleSync = () => {
  return client.post(`/events/trigger`, { eventName: "SYNC_AM_STYLES" }).then((res) => res?.data?.data)
};

export const checkStyleSync = (option: Object) => {
  return client.get(`/events/status/SYNC_AM_STYLES`, option).then((res) => res?.data?.data)
};

export const getAllAMAltStyle = () => {
  return client.get('/inventory/alt-styles').then((res) => res?.data?.data)
};

export const fetchCategories = () => {
  return client.get('/inventory/categories').then((res) => res?.data?.data)
};

export const fetchCategory = (id: number) => {
  return client.get(`/inventory/categories/${id}`).then((res) => res?.data?.data)
};

export const createCategory = (dataToCreate: any) => {
  return client.post('/inventory/categories', dataToCreate).then((res) => res?.data?.data)
};

export const updateCategory = (id: number, dataToUpdate: any) => {
  return client.put(`/inventory/categories/${id}`, dataToUpdate).then((res) => res?.data?.data)
};

export const deleteCategory = (id: number) => {
  return client.delete(`/inventory/categories/${id}`).then((res) => res?.data?.data)
};

export const getAllAltStyle = () => {
  return client.get(`/inventory/alt-styles`).then((res) => res?.data?.data)
};

export const updateAltStyleImageDetail = (id: number, payload: { image?: string, detail: string }) => {
  return client.put(`/inventory/alt-styles/${id}`, payload).then((res) => res?.data?.data)
};

export const getAllStyleCategories = () => {
  return client.get(`/inventory/style-categories`).then((res) => res?.data?.data)
};

export const updateStyleCategories = (payload: { altStyleId: number, categoryId: number, isActive: boolean }[]) => {
  return client.put(`/inventory/style-categories`, payload).then((res) => res?.data?.data)
};

export const getAltStyleDetail = (id: number) => {
  return client.get(`/inventory/alt-style-detail`, { params: { fieldValue: id } }).then((res) => res?.data?.data)
};

export const updateAltStyleDetail = (payload: any) => {
  return client.put('/inventory/alt-style-detail', payload).then((res) => res?.data?.data)
};

export const getBlankStyleFit = (id: string | number) => {
  return client.get(`/inventory/blank-style-fit/${id}`).then((res) => res?.data?.data)
};


export const fetchAllBlankStyleShipping = () => {
  return client.get('alt-style-shipping').then((res) => res?.data?.data)
}

export const fetchBlankStyleShipping = (blankGarmentStyleNumber: any) => {
  return client.get(`alt-style-shipping/blank-garment-style-number/${blankGarmentStyleNumber}`).then((res) => res?.data?.data)
}

export const updateBlankStyleShipping = (blankGarmentStyleNumber: any, payload: any) => {
  return client.put(`alt-style-shipping/blank-garment-style-number/${blankGarmentStyleNumber}`, payload).then((res) => res?.data?.data)
}



export const updateBlankStyleFit = (payload: any) => {
  return client.put('/inventory/blank-style-fit', payload).then((res) => res?.data?.data)
};