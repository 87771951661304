import * as React from 'react'
import { Modal } from 'react-bootstrap'

import { useGraphicColorMoveToGroup } from 'react-query';

interface IProps {
  open: boolean;
  onHide: () => void;
  colorId: number | null;
  groupId: number | null;
  groups: any;
}

const MoveColorToGroup: React.FC<IProps> = (props): JSX.Element => {

  // const [loading, setLoading] = React.useState(false);
  const [targetGroupId, setTargetGroupId] = React.useState<any>(null)
  const { mutate: moveToGroup, isPending: isMoving } = useGraphicColorMoveToGroup()

  const groupList = React.useMemo(() => {
    return props.groups.filter((group: any) => group.id !== props.groupId)
  }, [props.groups, props.groupId])

  React.useEffect(() => {
    if(props.open) setTargetGroupId(null)
  }, [props.open])

  const leaveGroup = () => {
    moveToGroup({ group_id: 0, color_id: props.colorId })
    props.onHide();
  }
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const payload = { group_id: targetGroupId, color_id: props.colorId };
    moveToGroup(payload)
    props.onHide();
  };

  return (
    <Modal
      show={props.open}
      onHide={props.onHide}
      className="custom-drawer date-picker-modal"
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"lg"}
    >
      <Modal.Body>
        <form onSubmit={handleSubmit}>
        <div className="border-bottom pb-1 mb-3">
          <h4 className="mb-0 heading4-bold">Choose destination group</h4>
        </div>
          {groupList.map((item: any) => (
            <div className="custom-radio" key={item.id} style={{ display: item.id === props.groupId ? 'none' : 'block' }}>
              <input
                className="custom-control-input"
                type="radio"
                id={`group_${item.id}`}
                name="group_id"
                value={item.id}
                disabled={item.id === props.groupId}
                onChange={(e) => setTargetGroupId(Number(e.target.value)) }
              />
              <label className="custom-control-label" htmlFor={`group_${item.id}`}>{item.group_name}</label>
            </div>
          ))}
          {!groupList.length && (
            <p>Please create group first in order to move</p>
          )}
          <div className="mt-3">
            <button
              type="submit"
              className="btn btn-danger px-4"
              disabled={isMoving || !targetGroupId}   
            >
              Move
            </button>
            <button
              type="button"
              className="btn btn-outline-danger px-4 ms-3"
              onClick={leaveGroup}
              disabled={String(props.groupId) === 'un_grouped' || isMoving}
            >
              Un group
            </button>
            <button
              type="button"
              className="btn btn-outline-danger px-4 ms-3"
              onClick={props.onHide}
              disabled={isMoving}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default MoveColorToGroup