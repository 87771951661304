import { FC } from "react";

  
  const ShipmentDetails: FC = (): JSX.Element => {
    
    return (
      <>
        
      </>
    );
  };
  
  export default ShipmentDetails;
  