import * as React from 'react'
import { Col, Row, Stack } from 'react-bootstrap'
import { Controller, useController, useFormContext } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getItemStats, getShippingRate, setItemPrices, updateItemQuantity } from 'store/OrderUpdateData.slice'
import styles from './UpdateOrder.module.scss'
import { useSelector } from 'react-redux'

const SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL']

const ItemQtyBox = React.memo(({ size, avail, totalQty, fieldName }) => {
    const { control } = useFormContext()
    const { field, fieldState: { error } } = useController({ control, name: fieldName })
    return (
      <Stack style={{ textAlign: "center", width: "50px" }}>
        <span style={{ fontSize: "12px" }}>{size}</span>
        <Stack style={error ? { border: '1px solid red' } : { border: "1px solid black" }}>
        <input
            placeholder="0"
            className={styles.sizeQtyInput}
            value={field.value}
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target.value)) {
                field.onChange(e.target.value.slice(0, 5));
              }
            }}
            ref={field.ref}
          />
          <span style={{ fontSize: "12px", color: "grey" }}>{avail}</span>
        </Stack>
        {/* <span style={{ fontSize: "12px", color: "grey" }}>{totalQty}</span> */}
      </Stack>
    );
})

const ItemStats = React.memo(({ id }) => {
  const itemStats = useSelector(getItemStats)
  const stats = itemStats[id]

  return (
    <>
      <td style={{ width: "15%", textAlign: "center" }}>{stats?.quantity}</td>
      <td style={{ width: "15%", textAlign: "center" }}>${stats?.price}</td>
      <td style={{ width: "15%", textAlign: "center" }}>${stats?.shipping}</td>
    </>
  );
});

const UpdateOrderItem = ({ item }) => {
    const dispatch = useAppDispatch()
    const { watch } = useFormContext()
    const isMount = React.useRef(false)
    const [quantity, setQuantity] = React.useState(item.quantity)

    const [xs, s, m, l, xl, xxl] = watch(SIZES.map(size => `quantities.item_${item.id}.${size}`))

    React.useMemo(() => {
        const quantity = [xs, s, m, l, xl, xxl].reduce((a, c) => {
            const n = Number(c)
            if(typeof n === 'number') a += n
            return a
        }, 0)
        setQuantity(quantity)
    }, [xs, s, m, l, xl, xxl])

    React.useEffect(() => {
        function updateQuantity(){
            if(isMount.current === false){
                isMount.current = true
                return
            }
            dispatch(updateItemQuantity({ id: item.id, quantity }))
        }
        updateQuantity()
    }, [quantity])

    return (
        <tr style={{ marginBottom: '10px' }}>
            <td width='35%'>
                <Stack direction='horizontal'>
                    <img width='80px' height='auto' src={item.imageUrl} />
                    <div className='d-flex justify-content-center align-items-center ms-3' >
                        <Stack>
                            <p className='m-0' >
                                <span>{item.altCode} - {item.b2bWebTitle}</span>
                                <br />
                                <span className={styles.itemColorGraphic}>{item.bodyColorName} ({item.apparelNotes})</span>
                            </p>
                        </Stack>
                    </div>
                </Stack>
            </td>
            <td style={{ width: '35%', textAlign: 'center' }}>
                <Row>
                    {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
                        <Col key={size} xs={2}>
                            <ItemQtyBox
                                fieldName={`quantities.item_${item.id}.${size}`}
                                size={size}
                                avail={'x'}
                                totalQty={'TBD'}
                            />
                        </Col>
                    ))}
                </Row>
            </td>
            <ItemStats id={item.id} quantity={quantity} />
        </tr>
    )
}

export default UpdateOrderItem