import * as React from 'react'
import { Container } from 'react-bootstrap';
import styles from '../TemplateConfigDialog.module.scss'
type Logo = { id: number; logoUrl: string; fileName: string, isFrontDefault: boolean, isFrontActive: boolean, isBackDefault: boolean, isBackActive: boolean };
type GroupedLogo = { name: string; logos: { [key: string]: { _idx: string, logoUrl: string, fileName: string } } };
type LogoSelection = { [key: string]: boolean };

enum LogoMapper {
    MONO_LT = "1Color_lt.png",
    MONO_DK = "1Color_dk.png",
    MULTI_LT = "FullColor_lt.png",
    MULTI_DK = "FullColor_dk.png",
}

const OneAndFullColorMap = {
    [LogoMapper.MONO_LT]: "1Color",
    [LogoMapper.MONO_DK]: "1Color",
    [LogoMapper.MULTI_LT]: "FullColor",
    [LogoMapper.MULTI_DK]: "FullColor",
} as { [key: string]: string };

const logoShadeMap = {
    [LogoMapper.MONO_LT]: "lt",
    [LogoMapper.MONO_DK]: "dk",
    [LogoMapper.MULTI_LT]: "lt",
    [LogoMapper.MULTI_DK]: "dk",
} as { [key: string]: string };

type Props = {
    logos: string[];
    logoList: any;
    hasFRLogo: boolean;
    hasBKLogo: boolean;
    selFRLogos: LogoSelection;
    selBKLogos: LogoSelection;
    setSelFRLogos: React.Dispatch<React.SetStateAction<LogoSelection>>;
    setSelBKLogos: React.Dispatch<React.SetStateAction<LogoSelection>>;
};

const SelectTemplateLogos: React.FC<Props> = (props) => {

    const { hasFRLogo, hasBKLogo, logos, selFRLogos, selBKLogos, setSelFRLogos, setSelBKLogos } = props

    const groupedLogos = React.useMemo(() => {
        const grouped = props.logoList.reduce((a: any, c: Logo, idx: number) => {
            const found = Object.entries(LogoMapper).find(([k, v]) =>
                c.logoUrl.endsWith(v)
            );
            if (!found) return a;
            const [_, logoSuffix] = found;
            const _re = new RegExp(`_${logoSuffix}$`);
            let logoName = c.logoUrl.replace(_re, "");
            logoName = logoName.substring(logoName.lastIndexOf("/") + 1);
            const colorType = OneAndFullColorMap[logoSuffix];
            const logoShade = logoShadeMap[logoSuffix];
            const groupName = `${logoName}_${colorType}`;
            const fileName = `${logoName}_${logoSuffix.replace(/.png$/, "")}`;
            if (!a[groupName]) a[groupName] = {};
            a[groupName][logoShade] = { _idx: String(idx + 1), ...c, fileName };
            return a;
        }, {});
        return Object.entries(grouped).map(([name, logos]) => ({
            name,
            logos,
        })).sort((a, b) => a.name.localeCompare(b.name)) as GroupedLogo[]

    }, [props.logoList])

    React.useEffect(() => {
        const _selection = groupedLogos.reduce((a: any, c: GroupedLogo) => {
            const ltShadeLogo = c.logos.lt as any
            const dkShadeLogo = c.logos.dk as any
            if(hasFRLogo){
                if(ltShadeLogo){
                    a.allFRLogos = a.allFRLogos && ltShadeLogo.isFrontActive
                    a.front[ltShadeLogo._idx] = ltShadeLogo.isFrontActive
                }
                if(dkShadeLogo){
                    a.allFRLogos = a.allFRLogos && dkShadeLogo.isFrontActive
                    a.front[dkShadeLogo._idx] = dkShadeLogo.isFrontActive
                }
            }
            if(hasBKLogo){
                if(ltShadeLogo){
                    a.allBKLogos = a.allBKLogos && ltShadeLogo.isBackActive
                    a.back[ltShadeLogo._idx] = ltShadeLogo.isBackActive
                }
                if(dkShadeLogo){
                    a.allBKLogos = a.allBKLogos && dkShadeLogo.isBackActive
                    a.back[dkShadeLogo._idx] = dkShadeLogo.isBackActive
                }
            }
            return a
        }, { allFRLogos: hasFRLogo, allBKLogos: hasBKLogo, front: {}, back: {} })

        setSelFRLogos({ allFRLogos: _selection.allFRLogos, ..._selection.front })
        setSelBKLogos({ allBKLogos: _selection.allBKLogos, ..._selection.back })

    }, [groupedLogos])

    const handleFRLogoSelect = (name: string, checked: boolean) => {
        if(name === 'allFRLogos'){
            const _logoSelection = groupedLogos.reduce((a, c: GroupedLogo) => {
                a[c.logos['lt']._idx] = checked
                a[c.logos['dk']._idx] = checked
                return a
            }, { allFRLogos: checked } as LogoSelection)
            setSelFRLogos(_logoSelection)
        }else{
           const _logoSelection = { ...selFRLogos, [name]: checked }
            const allFRLogos = groupedLogos.every((c: GroupedLogo) => {
                let [ltName, dkName] = [c.logos['lt']?._idx, c.logos['dk']?._idx]
                return _logoSelection[ltName] && _logoSelection[dkName]
        
            })
            _logoSelection.allFRLogos = allFRLogos
            setSelFRLogos(_logoSelection)
        }
    }
    
    const handleBKLogoSelect = (name: string, checked: boolean) => {
        if(name === 'allBKLogos'){
            const _logoSelection = groupedLogos.reduce((a, c: GroupedLogo) => {
                a[c.logos['lt']._idx] = checked
                a[c.logos['dk']._idx] = checked
                return a
            }, { allFRLogos: checked } as LogoSelection)
            setSelBKLogos(_logoSelection)
        }else{
           const _logoSelection = { ...selBKLogos, [name]: checked }
            const allBKLogos = groupedLogos.every((c: GroupedLogo) => {
                let [ltName, dkName] = [c.logos['lt']._idx, c.logos['dk']._idx]
                return _logoSelection[ltName] && _logoSelection[dkName]
        
            })
            _logoSelection.allBKLogos = allBKLogos
            setSelBKLogos(_logoSelection)
        }
    }

    return (
        <Container>
            <div className="default-logos">
                <table className={styles.table}>
                    <tr>
                        <td align="center" className={styles.logoCol}></td>
                        <td align="center" className={styles.fileNameCol}></td>
                        <td
                            align="center"
                            className={styles.frontLogoCol}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>Front Logo</div>
                                <div className="custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="allFRLogos"
                                        name="allFRLogos"
                                        onChange={(e) => handleFRLogoSelect(e.target.name, e.target.checked)}
                                        checked={selFRLogos["allFRLogos"]}
                                        disabled={!hasFRLogo}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="frontLogos"
                                    ></label>
                                </div>
                            </div>
                        </td>
                        <td
                            align="center"
                            className={styles.backLogoCol}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>Back Logo</div>
                                <div className="custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="allBKLogos"
                                        name="allBKLogos"
                                        onChange={(e) => handleBKLogoSelect(e.target.name, e.target.checked)}
                                        checked={selBKLogos["allBKLogos"]}
                                        disabled={!hasBKLogo}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="frontLogos"
                                    ></label>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>

                <table
                    className={`bordered-table ${styles.table}`}
                    border={1}
                >
                    {groupedLogos.map((group) => (
                        <>
                            <tr>
                                <td className={styles.logoCol} rowSpan={2}>
                                    <div className={styles.logoImgWrap}>
                                        <img
                                            className={styles.logoImg}
                                            src={group.logos["lt"]?.logoUrl || group.logos["dk"]?.logoUrl}
                                            alt={group.logos["lt"]?.fileName || group.logos["dk"]?.fileName}
                                        />
                                    </div>

                                </td>
                                <td className={styles.fileNameCol}>
                                    <span>{group.logos["lt"]?.fileName}</span>
                                </td>
                                <td className={styles.frontLogoCol}>
                                    <div className="d-flex justify-content-evenly">
                                        <div className="custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id="front_lt_logo"
                                                name={group.logos["lt"]?._idx}
                                                onChange={(e) => handleFRLogoSelect(e.target.name, e.target.checked)}
                                                checked={selFRLogos[group.logos["lt"]?._idx]}
                                                disabled={!group.logos["lt"]?._idx || !hasFRLogo}

                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="front_lt_logo"
                                            ></label>
                                        </div>
                                    </div>
                                </td>
                                <td className={styles.backLogoCol}>
                                    <div className="d-flex justify-content-evenly">
                                        <div className="custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id="back_lt_logo"
                                                name={group.logos["lt"]?._idx}
                                                onChange={(e) => handleBKLogoSelect(e.target.name, e.target.checked)}
                                                checked={selBKLogos[group.logos["lt"]?._idx]}
                                                disabled={!group.logos["lt"]?._idx || !hasBKLogo}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="back_lt_logo"
                                            ></label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.fileNameCol}>
                                    <p>{group.logos["dk"]?.fileName}</p>
                                </td>
                                <td className={styles.frontLogoCol}>
                                    <div className="d-flex justify-content-evenly">
                                        <div className="custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id="front_dk_logo"
                                                name={group.logos["dk"]?._idx}
                                                onChange={(e) => handleFRLogoSelect(e.target.name, e.target.checked)}
                                                checked={selFRLogos[group.logos["dk"]?._idx]}
                                                disabled={!group.logos["dk"]?._idx || !hasFRLogo}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="front_dk_logo"
                                            ></label>
                                        </div>
                                    </div>
                                </td>
                                <td className={styles.backLogoCol}>
                                    <div className="d-flex justify-content-evenly">
                                        <div className="custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                type="checkbox"
                                                id="back_dk_logo"
                                                name={group.logos["dk"]?._idx}
                                                onChange={(e) => handleBKLogoSelect(e.target.name, e.target.checked)}
                                                checked={selBKLogos[group.logos["dk"]?._idx]}
                                                disabled={!group.logos["dk"]?._idx || !hasBKLogo}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="back_dk_logo"
                                            ></label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </>

                    ))}
                </table>
            </div>
        </Container>
    )
}

export default SelectTemplateLogos