import * as React from 'react'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import { useController, useFormContext } from 'react-hook-form'
import clsx from 'clsx'

interface IObj { [key: string]: any }
interface IProps extends IObj { name: string; label?: string }

const RHFRichTextEditor: React.FC<IProps> = ({ name, label, ...props }) => {
    const { control } = useFormContext()
    const { field, fieldState } = useController({ control, name })
    return (
        <>
            <label className="text-capitalize" htmlFor={name}>{label || null}</label>
            <RichTextEditor value={field.value} onChange={field.onChange} />
            <span className='text-danger'>{fieldState.error?.message}</span>
        </>

    )
}

export default RHFRichTextEditor