import axios from "axios";
import { BASE_URL } from "./apiConstants";
import { client } from "utils/client";

export const createColorService = (data: any, token: string) => {
  return client.post(`/graphic-rules/colors/save`, data)
  // return axios({
  //   method: "post",
  //   url: BASE_URL + `/graphic-rules/colors/save`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data,
  // });
};

export const fetchColorsService = (token: string, params: any) => {
  return client.get('/graphic-rules/colors/list')
  // return axios({
  //   method: "get",
  //   url: BASE_URL + "/graphic-rules/colors/list",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   params
  // });
};

export const updateColorsService = (token: string, id: any, payload: any) => {
  return client.put(`/graphic-rules/colors/${id}`)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + "/graphic-rules/colors/" + id,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload
  // });
};

export const deleteColorsService = (token: string, id: any) => {
  return client.delete(`/graphic-rules/colors/${id}`)
  // return axios({
  //   method: "delete",
  //   url: BASE_URL + "/graphic-rules/colors/" + id,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   }
  // });
};

export const updateGraphicRules = (token: string, payload: any) => {
  return client.put('/graphic-rules', payload)
  // return axios({
  //   method: "put",
  //   url: BASE_URL + `/graphic-rules`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   data: payload,
  // });
};

export const fetchGraphicRules = (token: string, query: any) => {
  return client.get('/graphic-rules', { params: query })
  // return axios({
  //   method: "get",
  //   url: BASE_URL + `/graphic-rules`,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token,
  //   },
  //   params: query
  // });
};
