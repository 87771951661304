
import {
   ITemplate,
} from 'api/requestTypes';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import PricingTable from './PricingTable';

type TemplatePricingModalType = {
  open: boolean;
  setOpen: Function;
  template:ITemplate;
  currentTemplateId: string | number;
};
const TemplatePricing: FC<TemplatePricingModalType> = (
  props
): JSX.Element => {
  const {
    open,
    setOpen,
    template,
    currentTemplateId,
  } = props;


  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Header>
        <div className="row">
          <div className="col-auto">
            <h2 className="heading4-bold mb-0">
              {template.name}
            </h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
       <PricingTable templateId={currentTemplateId}/>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="me-3">
          <button
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplatePricing;
