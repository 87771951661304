import * as React from 'react'
import styles from "./Templates.module.scss";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';


const ShowUpTo = 4

const TemplateLabelChips = (props: any) => {


    if (props.templateLabels.length <= ShowUpTo) {
        return (
            <>
                {props.templateLabels.map((item: any) => (
                    <span className={styles.chipsItem}>
                        {item.Label.title}
                    </span>
                ))}
            </>
        )
    }

    return (
        <>
            <OverlayTrigger
                key='top'
                placement='top'
                overlay={
                    <Popover id="popover-basic">
                        <Popover.Body>
                            {props.templateLabels.map((item: any) => (
                                <span className={styles.chipsItem}>
                                    {item.Label.title}
                                </span>
                            ))}
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button variant='text' style={{ border: 'none' }}>
                    {props.templateLabels.slice(0, ShowUpTo).map((item: any) => (
                        <span className={styles.chipsItem}>
                            {item.Label.title}
                        </span>
                    ))}
                    <span className={styles.chipsItemRest}>
                        ...
                    </span>
                </Button>

            </OverlayTrigger>
        </>

    )
}

export default TemplateLabelChips