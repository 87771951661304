import clsx from "clsx";
import * as React from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";

import * as Service from "api/inventoryService";

import CategoryModal from "./CategoryModal";
import { transformToFormValues } from "./helper";
import { AltStyle, Category, ModalAction, StyleCategory } from "./interface";

interface FormValues {
  data: Record<string, any>;
}

const CategoryList: React.FC = (): JSX.Element => {
  const [refetch, setRefetch] = React.useState<boolean>(true);
  const [isSyncing, setIsSyncing] = React.useState<boolean>(false);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [altStyles, setAltStyles] = React.useState<AltStyle[]>([]);
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [categoryId, setCategoryId] = React.useState<number | null>(null);
  const [modalAction, setModalAction] = React.useState<ModalAction>(null);

  const methods = useForm<FormValues>({
    defaultValues: {
      data: {},
    },
  });
  const dirtyFields = methods.formState.dirtyFields;
  const isSubmitting = methods.formState.isSubmitting;
  const { control, handleSubmit } = methods;

  const open = Boolean(modalAction);

  const handleSave = () => {
    if (!formRef.current) return;
    if (typeof formRef.current?.requestSubmit === "function") {
      formRef.current.requestSubmit();
    } else {
      formRef.current?.dispatchEvent(
        new CustomEvent("submit", { cancelable: true })
      );
    }
  };

  const startSyncing = async () => {
    setIsSyncing(true);
    Service.startStyleSync()
      .then(() => {
        setIsSyncing(true);
        toast.success("Style sync started...");
      })
      .catch((err) => {
        toast.error("failed to sync");
      });
  };

  const onSubmit = async (values: any) => {
    const payload = getPayload(dirtyFields, values);
    try {
      await Service.updateStyleCategories(payload);
      toast.success("Changes saved successfully");
      setRefetch(true);
    } catch (err) {
      toast.error("Failed to saved");
    }
  };

  React.useEffect(() => {
    async function fetchInitials() {
      if (!refetch) return;
      const [_categories, _altStyles, _styleCategories] = await Promise.all([
        Service.fetchCategories() as unknown as Promise<Category[]>,
        Service.getAllAltStyle() as unknown as Promise<AltStyle[]>,
        Service.getAllStyleCategories() as unknown as Promise<StyleCategory[]>,
      ]);
      if (_styleCategories?.length) {
        methods.reset(transformToFormValues(_styleCategories), {
          keepDirty: false,
        });
      }
      setRefetch(false);
      setAltStyles(_altStyles);
      setCategories(_categories);
    }
    fetchInitials();
  }, [refetch]);

  React.useEffect(() => {
    if (isSyncing) {
      const controller = new AbortController();
      const signal = controller.signal;
      let id = setInterval(() => {
        (
          Service.checkStyleSync({ signal }) as unknown as Promise<
            "ACTIVE" | "INACTIVE"
          >
        )
          .then((status) => {
            if (status === "INACTIVE") {
              controller.abort();
              setRefetch(true);
              setIsSyncing(false);
              toast.success("Styles synced.");
            }
          })
          .catch((err) => console.log(err));
      }, 500);
      return function cleanup() {
        clearInterval(id);
      };
    }
  }, [isSyncing]);

  return (
    <>
      <div className="page-bottom-space" style={{ width: "991px" }}>
        <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
          <h4 className="mb-0 heading4-bold">Categories</h4>
          <div className="">
            <button
              className="btn btn-danger me-2"
              onClick={startSyncing}
              disabled={isSubmitting || isSyncing}
            >
              <i
                className={clsx("fas fa-sync me-2", { "fa-spin": isSyncing })}
              ></i>
              Alt Style
            </button>
            <button
              className="btn btn-danger me-2"
              disabled={isSubmitting || isSyncing}
              onClick={() => {
                setModalAction("Add");
                setCategoryId(null);
              }}
            >
              <i className="fa fa-plus"></i>&nbsp;Category
            </button>
            <button
              className="btn btn-danger"
              onClick={handleSave}
              disabled={isSubmitting || isSyncing}
            >
              <>
                <i className="fa-regular fa-floppy-disk me-2"></i>
                {isSubmitting ? "Saving" : "Save"}
              </>
            </button>
          </div>
        </div>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className="overflow-auto" style={{ maxHeight: "600px" }}>
            <table className="table custom-new-table">
              <thead>
                <tr>
                  <th>Alt Styles</th>
                  <th colSpan={categories.length}>Categories</th>
                </tr>
                <tr>
                  <th>
                    {/* <div style={{ position: "relative" }}>
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "10px",
                        }}
                      ></i>
                      <input
                        className="form-control"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                      />
                    </div> */}
                  </th>
                  {categories.map((item) => (
                    <th key={item.id}>
                      {item.title}
                      <i
                        className="ms-2 fa fa-pencil text-danger"
                        onClick={() => {
                          setCategoryId(item.id);
                          setModalAction("Update");
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {altStyles.map((altStyle) => (
                  <tr key={altStyle.id}>
                    <th style={{ minWidth: "150px" }}>
                      <strong>{altStyle.altCode}</strong>
                      <br />
                      <small style={{ opacity: ".5" }}>{altStyle.name}</small>
                    </th>
                    {categories.map((item) => (
                      <td key={`item_${altStyle.id}_${item.id}`}>
                        <Controller
                          control={control}
                          name={`data.item_${altStyle.id}_${item.id}`}
                          render={({ field }) => (
                            <div className="custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                {...field}
                                defaultChecked={field.value}
                              />
                              <label className="custom-control-label"></label>
                            </div>
                          )}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      </div>
      {open && (
        <CategoryModal
          modalAction={modalAction}
          setCategoryId={setCategoryId}
          setModalAction={setModalAction}
          categoryId={categoryId}
          setRefetch={setRefetch}
        />
      )}
    </>
  );
};

export default CategoryList;

function getPayload(
  dirtyFields: any,
  allValues: any
): { altStyleId: number; categoryId: number; isActive: boolean }[] {
  const payload = Object.entries(dirtyFields).reduce(
    (a: any, [key, value]: any) => {
      if (key === "data")
        Object.entries(value).forEach(([field, isDirty]: any) => {
          const matches = isDirty && field.match(/item_(\d+)_(\d+)/);
          if (matches) {
            const [altStyleId, categoryId] = [
              Number(matches[1]),
              Number(matches[2]),
            ];
            a.push({
              altStyleId,
              categoryId,
              isActive: Boolean(allValues.data[field]),
            });
          }
        });
      return a;
    },
    []
  );
  return payload;
}
