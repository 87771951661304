import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type IOrder = {
    id: number,
    orderNumber: string,
    purchaseOrders: {
        id: number,
        orderId: number,
        orderItems: {
            id: number,
            orderId: number,
            purchaseOrderId: number,
            templateId: number,
            blankGarmentStyleNumber: string,
            styleNumber: string,
            price: number,
        }[],
        amountTotal: number,
        amountShipping: number,
        amountSubTotal: number,
        amountDiscount: number,
    }[],
    amountTotal: number,
    amountShipping: number,
    amountSubTotal: number,
    amountDiscount: number,
} | null

type ShippingRates = {[key: string]: number} | null
type TemplatePriceSlabs = {[key: string]: {
    templateId: number,
    pricePerUnit: number,
    dozenValue: number,
}[] } | null

type ShippingAddresses = { id: number, name: string }[]

// { [OrderItemId]: OrderItemQuantity }
type ItemQuantities = { [key: number]: number } 

// { [OrderItemId]: OrderItemPrice }
type ItemPrices = { [key: number]: number }

// { [OrderItemId]: Info }
type ItemInfo = { [key: number]: { templateId: number, blankStyleNumber: string, styleNumber: string } }

type ItemStats = { [key: number]: { mrp: number, price: number, shipping: number } }
type POStats = { [key: number]: { amountSubTotal: number, amountDiscount: number, amountShipping: number, amountTotal: number } }
type OrderStats = { amountSubTotal: number, amountDiscount: number, amountShipping: number, amountTotal: number }

type InitialState = {
  orderData: IOrder,
  shippingRates: ShippingRates,
  templatePriceSlabs: TemplatePriceSlabs,
  shippingAddresses: ShippingAddresses,
  itemInfo: ItemInfo,
  itemQuantities: ItemQuantities,
  itemPrices: ItemPrices,
  itemStats: {},
  poStats: {},
  orderStats: {}
}

const initialState: InitialState = {
    orderData: null,
    shippingRates: null,
    templatePriceSlabs: null,
    shippingAddresses: [],
    itemInfo: {},
    itemQuantities: {},
    itemPrices: {},
    itemStats: {},
    poStats: {},
    orderStats: {}
};

const OrderUpdateDataSlice = createSlice({
  name: 'OrderUpdateData',
  initialState,
  reducers: {
    setOrderUpdateData: (
      state: InitialState,
      { payload }: PayloadAction<Pick<InitialState, 'orderData' | 'shippingRates' | 'templatePriceSlabs' | 'shippingAddresses' | 'itemInfo' | 'itemQuantities'>>
    ) => {
      state.orderData = payload.orderData
      state.shippingRates = payload.shippingRates
      state.templatePriceSlabs = payload.templatePriceSlabs
      state.shippingAddresses = payload.shippingAddresses
      state.itemQuantities = payload.itemQuantities
      state.itemInfo = payload.itemInfo
    },
    updateItemQuantity: (
      state: InitialState,
      { payload }: PayloadAction<{ id: number, quantity: number }>
    ) => {
      const temp = { ...state.itemQuantities }
      temp[payload.id] = payload.quantity
      state.itemQuantities = temp
    },
    updateShippingRates: (
      state: InitialState,
      { payload }: PayloadAction<Pick<InitialState, 'shippingRates'>>
    ) => {
      state.shippingRates = payload.shippingRates
    },
    updateTemplatePriceSlabs: (
      state: InitialState,
      { payload }: PayloadAction<Pick<InitialState, 'templatePriceSlabs'>>
    ) => {
      state.templatePriceSlabs = payload.templatePriceSlabs
    },
    setStats: (
      state: InitialState,
      { payload }: PayloadAction<Pick<InitialState, 'itemStats' | 'poStats' | 'orderStats'>>
    ) => {
      state.itemStats = payload.itemStats
      state.poStats = payload.poStats
      state.orderStats = payload.orderStats
    },
    resetOrderUpdateData: (state: InitialState) => {
      state.orderData = null
      state.shippingRates = null
      state.templatePriceSlabs = null
      state.shippingAddresses = []
      state.itemInfo = {}
      state.itemQuantities = {}
      state.itemPrices = {}
      state.itemStats = {}
      state.poStats = {}
      state.orderStats = {}
    },
  },
});

// Selectors
export const getOrderData = (state: RootState) => state.orderUpdateData.orderData as IOrder
export const getShippingRates = (state: RootState) => state.orderUpdateData.shippingRates as ShippingRates
export const getTemplatePriceSlabs = (state: RootState) => state.orderUpdateData.templatePriceSlabs as TemplatePriceSlabs
export const getShippingAddresses = (state: RootState) => state.orderUpdateData.shippingAddresses as ShippingAddresses
export const getItemQuantities = (state: RootState) => state.orderUpdateData.itemQuantities as ItemQuantities
export const getItemInfo = (state: RootState) => state.orderUpdateData.itemInfo as ItemInfo

export const getItemStats = (state: RootState) => state.orderUpdateData.itemStats as ItemStats
export const getPOStats = (state: RootState) => state.orderUpdateData.poStats as POStats
export const getOrderStats = (state: RootState) => state.orderUpdateData.orderStats as OrderStats

// Actions
export const { setOrderUpdateData, updateItemQuantity, setStats, updateShippingRates, updateTemplatePriceSlabs, resetOrderUpdateData } = OrderUpdateDataSlice.actions;

export default OrderUpdateDataSlice.reducer
