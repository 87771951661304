import React from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";

type Props<T> = {
  list: T[];
  setList: React.Dispatch<React.SetStateAction<T[]>>;
  callback?: (newStyle: T[]) => Promise<void>;
  children: (item: T) => React.ReactNode;
};

const DragDrop = <T,>(props: Props<T>) => {
  const { list, setList, callback, children } = props;

  const onDragEnd = (param: any) => {
    const srcI = param.source.index;
    const desI = param.destination?.index;
    if (desI) {
      setList((prev) => {
        const _list = [...prev];
        _list.splice(desI, 0, _list.splice(srcI, 1)[0]);
        callback?.(_list);
        return _list;
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable-1">
        {(provided, _) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list.map((item, i) => (
              <Draggable
                key={"draggable-" + i + Math.random()}
                draggableId={"draggable-" + i}
                index={i}
              >
                {(provided, snapshot) => (
                  <div
                    className="mb-2"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      boxShadow: snapshot.isDragging
                        ? "0 0 .4rem #666"
                        : "none",
                    }}
                  >
                    {children(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default DragDrop;
