import { fetchCustomerContactDetailsService } from 'api/customerService';
import { fetchLocationDetailsService } from 'api/locationService';
import { IContactDetails, ICustomerDetails, ITemplate } from 'api/requestTypes';
import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { setContactPersonsInfo, setSalesPersonsInfo } from 'store/Accounts.slice';
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import CompanyLogo from 'svgs/CompanyLogo.svg';
import EmptyList from 'ui/empty-list/EmptyList';
import SpinnerLoader from 'ui/loader/SpinnerLoader';

interface ImageFile {
  file: File;
  src: string;
}

const Account: React.FC<ICustomerDetails> = (customerDetails) => {
  const [loading,setLoading] = useState(false);
  const [customInfo, setCustomInfo] = useState({ ...customerDetails });
  const { accessToken } = useAppSelector(getUserDetails);
  const [contactPersons, setContactPersons] = useState<IContactDetails[]>([]);
  const [salesPersons, setSalesPersons] = useState<IContactDetails[]>([]);
  const [templateList, setTemplateList] = useState<ITemplate[]>([]);
  useEffect(() => {
    const getChildAccountDetails = async () => {
      try {
        setLoading(true);
        const { data } = await fetchCustomerContactDetailsService(
          accessToken,
          customerDetails.id
        );
        //console.log("Child Accoutn Details:",data.data);
        if (data.data) {
          setLoading(false);
          return data.data
        }
        setLoading(false);
        return null;
      } catch (error) {
        setLoading(false);
        toast.error('Could not fetch the contact and sales persons list');
        return null;
      }
    };
    const getLocationStateLogos = async (childInfo:any) => {
      if(childInfo){
      try {
        setLoading(true);
        const { data } = await fetchLocationDetailsService(
          childInfo.ChildAccount.locationId,
          accessToken
        );
        if (data.data) {
          const { locationLogos,state:{stateLogos} } = data.data;
          const { contactPersons,salesPersons,templates,logos } = childInfo;
          const combinedLogos=logos.concat(locationLogos).concat(stateLogos);
          const logoUrl = combinedLogos.map( (l:{logoUrl:string,id:string|number}) => l.logoUrl ).join(",") ;
          setCustomInfo({...customerDetails,logoUrls:logoUrl})
          setTemplateList(templates);
          setContactPersons(contactPersons);
          setSalesPersons(salesPersons);
          
          setLoading(false);
        }
        
      } catch (error) {
        setLoading(false);
      }
    }
  };
    
    getChildAccountDetails().then((res)=>{
      getLocationStateLogos(res); 
    });
  }, [accessToken, customerDetails]);
  if(loading)
    return <SpinnerLoader></SpinnerLoader>
  return (
    <div key={customerDetails.id}>
      <ul className="list-group list-group-flush list-style-none mt-2 detail-list">
        <li className="row g-0" key={`item-category`}>
          <div className="col-6">
            <p className="mb-0 px-3 py-2">Category</p>
          </div>
          <div className="col-6">
            <p className="mb-0 px-3 py-2">{customInfo.category}</p>
          </div>
        </li>
        
        {customInfo.childData &&
          customInfo.childData.map((pair, index) => (
            <li className="row g-0" key={`customdata-${index}`}>
              <div className="col-6">
                <p className="mb-0 px-3 py-2">{pair.key}</p>
              </div>
              <div className="col-6">
                <p className="mb-0 px-3 py-2">{pair.value}</p>
              </div>
            </li>
          ))}
        
        <li key={`logos`}>
          <p className="mb-0 px-3 py-2">Logos</p>
          <div className="mb-0 px-3 py-2">
            <div className="row g-2">
              {customInfo.logoUrls &&
                customInfo.logoUrls.split(',').map(
                  (image, index) =>
                  image.includes('pdf') || image.includes('ai') || image.includes('json') ? (
                    <ul className="list-group list-group-flush list-style-none mt-2">
                      <li className="has-right-action list-item-mb-2" key={index}>
                        <p className="mb-0 text-capitalize">
                          {image.split('/').pop()}
                        </p>
                        <div className="action-wrap">
                          <button
                            type="button"
                            className="btn btn-link-danger"
                          >
                          </button>
                        </div>
                      </li>
                    </ul>
                  ) 
                  :(
                      <div className="col-auto" key={index}>
                        <div
                          key={index}
                          className="avatar position-relative overflow-visible"
                        >
                          <img className="rounded " src={image} alt="" />
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </li>
      
      </ul>
      <div>
          <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Contact Person</h4>
          </div>
          <ul className="list-group list-group-flush list-style-none">
            
              {
                contactPersons.length > 0?
                    React.Children.toArray(
                      contactPersons.map((contactPerson, key) => (
                        <li className="has-right-action mb-2" key={key}>
                          <p className="mb-0 text-capitalize">{contactPerson.name}</p>
                        </li>
                      ))
                    ):(<div className="pt-2">
                    <EmptyList />
                  </div>)
              }
               
          </ul>
        </div>
        <div>
          <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Sales Person</h4>
          </div>
          <ul className="list-group list-group-flush list-style-none">
            
              {
                salesPersons.length > 0?
                    React.Children.toArray(
                      salesPersons.map((salesPerson, key) => (
                        <li className="has-right-action mb-2" key={key}>
                          <p className="mb-0 text-capitalize">{salesPerson.name}</p>
                        </li>
                      ))
                    ):(<div className="pt-2">
                    <EmptyList />
                  </div>)
              }
               
          </ul>
        </div>
        <div>
          <div className="border-bottom pb-1 mb-1 d-flex justify-content-between align-items-center">
            <h4 className="mb-0 heading4-bold">Templates</h4>
          </div>
          <ul className="list-group list-group-flush list-style-none">
            
              {
                templateList.length > 0?
                    React.Children.toArray(
                      templateList.map((template, key) => (
                        <li className="has-right-action mb-2" key={key}>
                          <p className="mb-0 text-capitalize">{template.name}</p>
                        </li>
                      ))
                    ):(<div className="pt-2">
                    <EmptyList />
                  </div>)
              }
               
          </ul>
        </div>

    </div>
  );
};

export default Account;
