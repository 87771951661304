import * as React from "react";
import FileUploadPrviewer from "ui/file-upload/FileUploadPreviewer";
import Heading from "ui/Heading/Heading";
import AccountLogoDefaults from "./AccountLogoDefaults";

type Logo = { id: number; logoUrl: string };
type GroupedLogo = { name: string; logos: { [key: string]: Logo } };
enum LogoMapper {
  MONO_LT = "1Color_lt.png",
  MONO_DK = "1Color_dk.png",
  MULTI_LT = "FullColor_lt.png",
  MULTI_DK = "FullColor_dk.png",
}

interface iProps {
  logos: Logo[];
  files: File[];
  setFiles: React.SetStateAction<any>;
  setLogoList: React.SetStateAction<any>;
}

const AccountLogos: React.FC<any> = (props): JSX.Element => {
  const { logos, files, setFiles } = props as iProps;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const onChangeFileUploadPreview = (files: File[]) => setFiles(files);

  const groupedLogos = React.useMemo(() => {
    const grouped = logos.reduce((a: any, c: Logo) => {
      const found = Object.entries(LogoMapper).find(([k, v]) =>
        c.logoUrl.endsWith(v)
      );
      if (!found) return a;
      const [logoType, logoSuffix] = found;
      const _re = new RegExp(`_${logoSuffix}$`);
      let logoName = c.logoUrl.replace(_re, "");
      logoName = logoName.substring(logoName.lastIndexOf("/") + 1);
      if (!a[logoName]) a[logoName] = {};
      a[logoName][logoType] = c;
      return a;
    }, {});
    return Object.entries(grouped).map(([name, logos]) => ({
      name,
      logos,
    })) as GroupedLogo[];
  }, [logos]);

  return (
    <div className="logos-template-wrap">
      <Heading headingTitle="Logos" wrapperClassName="mb-2" />
      <FileUploadPrviewer onChange={onChangeFileUploadPreview} files={files} />
      <div className="row four-spacing mt-3 mb-3 g-3">
        {groupedLogos.map((logoGroup) => (
          <div className="col-6" key={logoGroup.name}>
            <div className="row">
              {Object.keys(LogoMapper).map((logoType, index) => (
                <>
                  {logoGroup.logos[logoType]?.id ? (
                    <div className="col-3" key={logoGroup.logos[logoType]?.id}>
                      <div className="avatar position-relative overflow-visible">
                        <img
                          className="rounded"
                          style={{ objectFit: 'contain' }}
                          src={logoGroup.logos[logoType]?.logoUrl}
                          alt={logoGroup.name}
                        />
                        <button className="logo-remove-btn" onClick={() => props.handleRemove(logoGroup.logos[logoType]?.id)}>
                          <i className='fa-solid fa-xmark'></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="col-3" key={index} ></div>
                  )}
                </>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-text p-0 heading4-bold"
          style={{ border: "none" }}
          onClick={() => setOpen(true)}
        >
          Select Logo Defaults
        </button>
      </div>
      {open && (
        <AccountLogoDefaults
          open={open}
          handleClose={handleClose}
          logos={props.logos}
          setLogoList={props.setLogoList}
        />
      )}
    </div>
  );
};

export default AccountLogos;
