import * as React from 'react'
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

interface AccordianHeaderProps {
    eventKey: string;
    PO: string;
    openPriceModal: () => void;
    openShippingModal: () => void;
}


const AccordianHeader: React.FC<AccordianHeaderProps> = ({ eventKey, PO, openPriceModal, openShippingModal }): JSX.Element => {
    const { activeEventKey } = React.useContext(AccordionContext);
  
    const toggleBtn = useAccordionButton(eventKey, () =>
      console.log("Custom Button")
    );

    return (
        <div
          style={{ width: "100%", paddingRight: "10px" }}
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            PO: <span>{PO}</span>
          </div>
          <div>
            <span
              onClick={openPriceModal}
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginRight: "20px",
              }}
            >
              <i className="fa-solid fa-money-bill text-danger"></i>
            </span>
            <span
              onClick={openShippingModal}
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginRight: "20px",
              }}
            >
              <i className="fa-solid fa-truck-fast text-danger"></i>
            </span>
            <span
              onClick={toggleBtn}
              style={{ display: "inline-block", cursor: "pointer" }}
            >
              {activeEventKey === eventKey ? (
                <i className="fa-solid fa-chevron-up"></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )}
            </span>
          </div>
        </div>
      );
}

export default AccordianHeader