import React from 'react';

// import AddIcon from 'svgs/icon_add_circle_red.svg';
import RedIcon from 'svgs/icon_add_circle_red.svg';
import BlueIcon from 'svgs/icon_add_circle_blue.svg';
import GreenIcon from 'svgs/icon_add_circle_green.svg';
import OrangeIcon from 'svgs/icon_add_circle_orange.svg';
import './AddSection.css';

const colors = {
  red: RedIcon,
  blue: BlueIcon,
  green: GreenIcon,
  orange: OrangeIcon,
};

export interface IAddSection {
  className?: string;
  label: string;
  color?: 'red' | 'blue' | 'orange' | 'green';
  onClick: () => void;
}
const AddSection: React.FC<IAddSection> = ({
  className,
  label,
  color = 'red',
  onClick,
}) => {
  return (
    <button type="button"
      className={`btn btn-link-danger d-flex align-items-center ${className ? className : ''}`}
      onClick={onClick}
    >
      <i className="fa-solid fa-circle-plus text-danger me-2"></i>
      <span className="mb-0 align-middle">{label}</span>
    </button>
  );
};

export default AddSection;
