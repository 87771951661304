import { Link, useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1>Oops! page not found</h1>
      <Link to="/">Home</Link>
      <button style={{ marginLeft: '10px' }} onClick={() => navigate(-1)}>
        Back
      </button>
    </div>
  );
};

export default NotFoundPage;
