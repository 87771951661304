import * as React from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { FormValues } from "./interface";
import * as inventoryService from "api/inventoryService";
import * as utilService from "api/utilService";
import { RHFFileUpload } from "components/form-hook";
import { DEFAULT_VALUES, FIT_TYPES, SIZES, schema } from "./helper";

interface IProp {
  blankGarment: any;
  handleClose: () => void;
}

interface IPayload extends FormValues {
  blankStyleId: number;
  blankStyle: string;
}

const BlankStyleFitModel: React.FC<IProp> = (props): JSX.Element => {
  const blankStyleId = props.blankGarment.id;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });
  const isSubmitting = methods.formState.isSubmitting;

  React.useEffect(() => {
    async function fetchBlankStyleFit() {
      if (!blankStyleId) return;
      setIsLoading(true);
      inventoryService
        .getBlankStyleFit(blankStyleId)
        .then((data: any) => {
          const _values: any = {};
          for (let k in DEFAULT_VALUES) {
            if (data[k]) _values[k] = data[k];
          }
          methods.reset(_values);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    fetchBlankStyleFit();
  }, [blankStyleId]);

  const onSubmit = async (values: FormValues) => {
    try {
      await schema.validate(values)
      const { image, ...restValues } = values;
      const payload: IPayload = {
        ...restValues,
        blankStyleId,
        blankStyle: props.blankGarment.styleNumber,
      };
      const parsedUrl = image ? new URL(image) : null;
      if (parsedUrl?.protocol === "blob:") {
        const blob = await fetch(parsedUrl.href).then((r) => r.blob());
        const params = {
          contentType: blob.type,
          resourceType: "BLANK_STYLE_FIT_IMAGE",
          blankStyleNumber: props.blankGarment.styleNumber,
          extension: values.extension,
        };
        const urls = (await utilService.getPreSignedUrl(params).catch((err) => {
          toast.error(err.message);
          throw err;
        })) as unknown as {
          preSignedUrl: string;
          objectUrl: string;
        };
        await utilService.uploadFileToPreSignedUrl(urls.preSignedUrl, blob);
        payload.image = urls.objectUrl;
      }
      await inventoryService.updateBlankStyleFit(payload);
      toast.success('Saved successfully')
      props.handleClose();
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={true}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={"xl"}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Header>
            <div className="row">
              <div className="col-auto">
                <h2 className="heading4-bold mb-0">
                  Fit {props.blankGarment.styleNumber}
                </h2>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <>
              <RHFFileUpload name="image" label="Image" />
              <div className="overflow-auto" style={{ maxHeight: "600px" }}>
                <table className="table custom-new-table">
                  <thead>
                    <tr>
                      <th>Fit</th>
                      {SIZES.map((size) => (
                        <th key={size}>{size}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {FIT_TYPES.map((fitType) => (
                      <tr key={fitType}>
                        <th style={{ minWidth: "150px" }}>{fitType}</th>
                        {SIZES.map((size) => (
                          <td key={`${fitType}_${size}`}>
                            <Controller
                              control={methods.control}
                              name={`${fitType}_${size}`}
                              render={({ field }) => (
                                <input className="form-control" {...field} />
                              )}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <div className="me-3">
              <button
                type="submit"
                className="btn btn-danger px-4 me-3"
                disabled={isSubmitting || isLoading}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger px-4"
                onClick={props.handleClose}
                disabled={isSubmitting || isLoading}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default BlankStyleFitModel;
