import { useState, useEffect } from "react";
import AddGraphicsRuleModal from "./AddGraphicsRuleModal";
import * as GraphicRuleService from '../../api/graphicRule'
import { getUserDetails } from "store/User.slice";
import { useAppSelector } from "store/hooks";
import Colour from "./Colour";
import { useParams } from "react-router-dom";
import SpinnerLoader from "ui/loader/SpinnerLoader";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import { toast } from "react-toastify";

const GraphicsRuleColors = () => {
  const { category } = useParams<any>()
  const [refech, setRefetch] = useState(true);
  const [selColor, setSelColor] = useState<any>(null);
  const [openColorModal, setOpenColorModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [colors, setColors] = useState<any>({ body: [], graphic: [], graphic2: [] })
  const { accessToken } = useAppSelector(getUserDetails);

  const categoriseColors = (colors: any) => colors.reduce((a: any, c: any) => {
    a?.[c.category].push(c)
    return a
  }, { body: [], graphic: [], graphic2: [] })

  const handleConfirmCallBack = () => {
    GraphicRuleService.deleteColorsService(accessToken, selColor?.id)
    .then((ok) => {
      toast.success('Color deleted successfully')
    }).catch((err) => {
      toast.error('Failed to delete color')
    }).finally(() => {
      setSelColor(null)
      setRefetch(true)
    })
  }

  useEffect(() => {
    function fetchColors() {
      GraphicRuleService.fetchColorsService(accessToken, {})
        .then((res) => {
          const categorised = categoriseColors(res?.data.data)
          setColors(categorised)
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          setRefetch(false)
        })
    }
    if (refech) {
      fetchColors()
    }
  }, [refech, accessToken])

  useEffect(() => {
    if(!openColorModal){
      setSelColor(null)
    }
  }, [openColorModal])

  if (refech) {
    return <SpinnerLoader />
  }

  return (
    <>
      <div className="content-sm page-bottom-space">
        <div className="border-bottom pb-1 mb-3 d-flex justify-content-between align-items-center">
          <h4 className="mb-0 heading4-bold">Colors</h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setOpenColorModal(true)}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Colors</span>
          </button>
        </div>
        {colors?.[`${category}`].length === 0 && <p>No colours found...</p>}
        <ul className="list-group list-group-flush list-style-none mb-2">
          {colors?.[`${category}`].map((color: any) => (
            <Colour
              key={color.id}
              color={color}
              setOpenColorModal={setOpenColorModal}
              setOpenConfirmModal={setOpenConfirmModal}
              setSelColor={setSelColor}
            />
          ))}
        </ul>
      </div>
      {openColorModal && <AddGraphicsRuleModal
        onHide={() => setOpenColorModal(false)}
        selColor={selColor}
        modalAction={selColor ? 'Update' : 'Add'}
        colorCategory={category}
        handleSuccess={() => {
          setOpenColorModal(false)
          setRefetch(true)
        }}
      />}
      {openConfirmModal && <ConfirmationModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        confirmationMessage="Are you sure you want to delete?"
        handleCallBack={handleConfirmCallBack}
      />}
    </>
  );
};

export default GraphicsRuleColors;


