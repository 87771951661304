import React, { ChangeEvent } from "react";

import "./Select.css";

export interface ISelect {
  className?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  data: any[];
  name?: string;
  value: any;
  startOption?: string;
  errorMessage?: string;
  disable?: boolean;
}

const Select: React.FC<ISelect> = ({
  className,
  onChange,
  data = [],
  value,
  name,
  startOption,
  errorMessage,
  disable,
  ...props
}) => {
  return (
    <>
      <select
        className={`form-select ${className ? className : ""}`}
        onChange={onChange}
        value={value}
        name={name}
        disabled={disable ? disable : false}
      >
        {startOption && <option value="">{startOption}</option>}
        {React.Children.toArray(
          data.map((el) => <option value={el}>{el}</option>)
        )}
      </select>
      {errorMessage && errorMessage.length > 0 && (
        <span style={{ color: "red" }}>{errorMessage}</span>
      )}
    </>
  );
};

export default Select;
