import { ImageListType } from 'react-images-uploading';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react';

import Input from 'ui/input/Input';
import Select from 'ui/select/Select';
import { AccountInfo } from 'types/AccountsSlice';
import AddContactModal from 'components/modal/Modal';
import AddSection from 'components/addSection/AddSection';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ImageUploadPrviewer from 'ui/image/ImageUploadPreviwer';

import {
  festchAllParentsService,
  fetchContactListService,
  fetchParentAccountDetailsService,
} from 'api/parentService';
import { getUserDetails } from 'store/User.slice';
import {
  Attribute,
  IAllParentAccountResponse,
  IContactDetails,
  ICreateCustomerAccountPayload,
  ICustomerDetailsCreate,
  IkeyValuePair,
  ITemplate,
} from 'api/requestTypes';
import {
  createCustomerContactService,
  uploadCustomerContactLogosService,
} from 'api/customerService';
import SpinnerLoader from '../../ui/loader/SpinnerLoader';
import { fetchUserExistanceService } from 'api/userService';
import { USER_ROLE } from 'utils/constants';
import { fetchAllCustomerAttributeService } from 'api/masterDataService';
import KeyValuePairInput from 'components/keyValuePair/KeyValuePairInput';
import {
  fetchAllLocationService,
  fetchAllStateService,
} from 'api/locationService';
import { ILocation } from 'pages/locations/Locations';
import TemplateList from './templateList/TemplateList';
import Heading from '../../ui/Heading/Heading';
import InfoColList from './infoColList/infoColList';
import { fetchAllTemplatesService } from 'api/templateService';
import { IState } from 'pages/locations/states/States';
import { isAlreadyExisted, isEmailAlreadyExist } from 'utils/helperFunctions';
import FileUploadPrviewer from 'ui/file-upload/FileUploadPreviewer';
import { toast } from 'react-toastify';

const initialCustomInfo: ICustomerDetailsCreate = {
  category: '',
  //location: '',
  parentId: '',
  // fullName: '',
  // nickName: '',
  // text: '',
  // abbreviation: '',
  //tags: '',
  logoUrls: '',
  //establishedDate:'',
  locationId: '',
  //templateId:'',
  updatedAt: '',
  createdAt: '',
  deletedAt: '',
  customerAccountName: '',
};

type LocationState = { id: string | number };
const Customer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { id } = state as LocationState;
  const { accessToken } = useAppSelector(getUserDetails);
  
  const [categoryList, setCategoryList] = useState<Attribute[]>([]);
  
  const [locationList, setLocationList] = useState<ILocation[]>([]);
  const [stateList, setStateList] = useState<IState[]>([]);

  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customInfo, setCustomInfo] = useState<ICustomerDetailsCreate>({
    ...initialCustomInfo,
    parentId: id ? id : '',
  });
  const [customData, setCustomData] = useState<IkeyValuePair[]>([]);
  const [customInfoError, setCustomInfoError] =
    useState<ICustomerDetailsCreate>(initialCustomInfo);
  //const [images, setImages] = useState<ImageListType>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [parrentAccounts, setParentAccounts] = useState<
    IAllParentAccountResponse[]
  >([]);
  const [addContactPerson, setAddContactPerson] = useState(false);
  const [contactPersonsSelected, setContactPersonsSelected] = useState<
    IContactDetails[]
  >([]);
  const [contactPersons, setContactPersons] = useState<IContactDetails[]>([]);
  const [contactPersonAccountInfo, setContactPersonAccountInfo] = useState<
    AccountInfo[]
  >([]);

  //const [addSalesPerson, setAddSalesPerson] = useState(false);
  const [salesPersonsSelected, setSalesPersonSelected] = useState<
    IContactDetails[]
  >([]);
  const [salesPersons, setSalesPersons] = useState<IContactDetails[]>([]);
  //const [salesPersonAccountInfo,setSalesPersonAccountInfo] = useState<AccountInfo[]>([])
  const [templateSelected, setTemplateSelected] = useState<ITemplate[]>([]);
  const [templateList, setTemplateList] = useState<ITemplate[]>([]);
  const [toggleLocationDetails,setToggleLocationDetails]=useState(false);
  // const onChangeImageUploadPreview = (
  //   imageList: ImageListType,
  //   addUpdateIndex: number[] | undefined
  // ) => {
  //   setImages(imageList);
  // };
  const onChangeFileUploadPreview = (
    fileList: File[],
    addUpdateIndex: number[] | undefined
  ) => {
    setFiles(fileList);
  };
  const handleChange = (
    e:
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    //console.log(name,value);
    let errors = {...customInfoError};

    switch (name) {
      case 'parentId':
        errors.parentId = value.length < 1 ? 'Please select a parent' : '';
        break;
      case 'locationId':
        errors.locationId = value.length < 1 ? 'Please select a location' : '';
        break;
      case 'category':
        errors.category = value.length < 1 ? 'Please select a Category' : '';
        break;
      case 'customerAccountName':
        errors.customerAccountName =
          value.length < 1
            ? 'Customer Account Name can not be empty'
            : '';
        break;
      default:
        break;
    }
    setCustomInfoError(errors);

    setCustomInfo({ ...customInfo, [name]: value });
  };
  // const handleChangeCheckBox = (index: number) => {
  //   if (!categoryCheckedList[index]) {
  //     //if not checked previously
  //     const category = categoryList[index];
  //     const newVal = `${
  //       category.attributeName
  //     }`;
  //     setCustomInfo({ ...customInfo, category: newVal });
  //     let categorylist = categoryCheckedList;
  //     for(let i=0;i<categorylist.length;i++)categorylist[i]=false;
  //     categorylist[index] = true;
  //     setCategoryCheckedList(categorylist);
  //     //console.log(newVal);
  //   } else {
      
  //     setCustomInfo({ ...customInfo, category: '' });
  //     let categorylist = categoryCheckedList;
  //     categorylist[index] = false;
  //     setCategoryCheckedList(categorylist);
  //     //console.log(val);
  //   }
  // };
  // const handleChangeCheckBox = (index: number) => {
  //   if (!categoryCheckedList[index]) {
  //     //if not checked previously
  //     const category = categoryList[index];
  //     const newVal = `${customInfo.category ? customInfo.category + ',' : ''}${
  //       category.attributeName
  //     }`;
  //     setCustomInfo({ ...customInfo, category: newVal });
  //     let categorylist = categoryCheckedList;
  //     categorylist[index] = true;
  //     setCategoryCheckedList(categorylist);
  //     //console.log(newVal);
  //   } else {
  //     let val = customInfo.category;
  //     val = val
  //       .split(',')
  //       .filter((e) => e !== categoryList[index].attributeName)
  //       .join(',');
  //     setCustomInfo({ ...customInfo, category: val });
  //     let categorylist = categoryCheckedList;
  //     categorylist[index] = false;
  //     setCategoryCheckedList(categorylist);
  //     //console.log(val);
  //   }
  // };
  const removeContact = (id: string | number) => {
    const removed = contactPersonsSelected.filter((obj) => {
      return obj.id == id;
    });
    const result = contactPersonsSelected.filter((obj) => {
      return obj.id != id;
    });
    setContactPersonsSelected(result);

    setContactPersons([...contactPersons, ...removed]);
  };
  const handleAddContact = () => {
    setAddContactPerson(true);
  };
  const handleChangeContact = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = contactPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setContactPersonsSelected([...contactPersonsSelected, ...res]);

    const result = contactPersons.filter((obj) => {
      return obj.id != e.target.value;
    });

    setContactPersons(result);
  };
  const handleAddContactPersons = async (datas: AccountInfo) => {
    
      setLoading(true);
      const isExist = await isEmailAlreadyExist(accessToken,datas.email);
      if (isExist) {
        alert(
          `User with ${datas.email} email id is already existed. Please use different email`
        );
      }
      else{
          setAddContactPerson(false);
          setContactPersonAccountInfo([
            ...contactPersonAccountInfo,
            { ...datas, role: USER_ROLE.CONTACT_PERSON },
          ]);
      }
      setLoading(false);
         
  };
  const removeContactAccountInfo = (email: string) => {
    const result = contactPersonAccountInfo.filter((obj) => {
      return obj.email !== email;
    });
    setContactPersonAccountInfo([...result]);
  };
  const handleCancelAddContactPersons = () => {
    setAddContactPerson(false);
  };

  const removeSales = (id: string | number) => {
    const removed = salesPersonsSelected.filter((obj) => {
      return obj.id == id;
    });
    const result = salesPersonsSelected.filter((obj) => {
      return obj.id != id;
    });
    setSalesPersonSelected(result);
    setSalesPersons([...salesPersons, ...removed]);
  };
  // const handleAddSales = () => {
  //   setAddSalesPerson(true);
  // };
  const handleChangeSales = (e: ChangeEvent<HTMLSelectElement>) => {
    const res = salesPersons.filter((obj) => {
      return obj.id == e.target.value;
    });
    setSalesPersonSelected([...salesPersonsSelected, ...res]);
    const result = salesPersons.filter((obj) => {
      return obj.id != e.target.value;
    });

    setSalesPersons(result);
    //setAddSalesPerson(false);
  };
  // const handleAddSalesPersons = (data: AccountInfo) => {
  //     setSalesPersonAccountInfo([...salesPersonAccountInfo,data]);
  //     setAddSalesPerson(false);
  // };
  // const removeSalesAccountInfo = (email:string)=> {
  //   const result = salesPersonAccountInfo.filter((obj) => {
  //     return obj.email !== email;
  //   });
  //   setSalesPersonAccountInfo([...result]);
  //  }
  // const handleCancelAddSalesPersons = () => {
  //   setAddSalesPerson(false);
  // };

  // const handleCustomerContact = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   dispatch(setCustomerInfo({ name, value }));
  // };
  const validateForm = (errors: ICustomerDetailsCreate, values: ICustomerDetailsCreate) => {
    if (values.parentId && values.customerAccountName && values.locationId && values.category) {
      let valid = true;
      Object.values(errors).forEach(
        (val) => val && val.length > 0 && (valid = false)
      );
      return valid;
    }
    return false;
  };
  const save = () => {
    if (validateForm(customInfoError, customInfo)) {
      setSubmit(true);
    } else {
      toast.warn('please fill required fields');
    }
  };

  useEffect(() => {
    const fettchAccountdetails = async () => {
      try {
        setLoading(true);
        const res = await festchAllParentsService(accessToken);
        const data: IAllParentAccountResponse[] | undefined = res?.data?.data;
        if (data?.length) {
          data.sort((a, b) => (a.accountName < b.accountName ? -1 : 1));
          setParentAccounts(data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const getAttributeList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllCustomerAttributeService(accessToken);
        if (data.data) {
          const { categories, keys } = data.data;
          const customDataKey = keys.map((key:{id?:string,attributeName:string})=>({id:"",key:key.attributeName,value:""}))
          setCustomData(customDataKey);
          setCategoryList(categories);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the attributes');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getLocationList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllLocationService(accessToken);
        if (data.data) {
          setLocationList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the locations');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getStateList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllStateService(accessToken);
        if (data.data) {
          setStateList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the states');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    const getTemplateList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllTemplatesService(accessToken);
        if (data.data) {
          setTemplateList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.warn('Could notfetch the templates');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fettchAccountdetails();
    getAttributeList();
    getLocationList();
    getStateList();
    getTemplateList();
  }, [accessToken]);

  useEffect(() => {
    const getContactAndSalesList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchParentAccountDetailsService(
          accessToken,
          customInfo.parentId
        );
        if (data.data) {
          const { contactPersons, salesPersons } = data.data;
          setSalesPersons(salesPersons);
          setContactPersons(contactPersons);
          setContactPersonsSelected([]);
          setSalesPersonSelected([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.warn('Could notfetch the contact and sales persons list');
      }
    };
    if (customInfo.parentId) getContactAndSalesList();
  }, [accessToken, customInfo.parentId]);

  useEffect(() => {
    const uploadCustomerLogos = async () => {
      const logos = [];
      for (let i = 0; i < files.length; i++) {
        try {
          const formData = new FormData();
          formData.append('file', files[i]);

          const res = await uploadCustomerContactLogosService(
            formData,
            accessToken
          );
          const data = res?.data?.data;
          if (data) {
            logos.push(data.url);
          }
        } catch (error) {
          console.error(error);
        }
      }
      return logos.join(',');// return "" when logos=[]
    };
    const createChildAccount = async (logourl: string) => {
      //const contactPersonss = contactPersonsSelected.map((obj) => obj.id);
      const salesPersonss = salesPersonsSelected.map((obj) => obj.id);
      const templates = templateSelected.map((obj) => obj.id!);
      const payload: ICreateCustomerAccountPayload = {
        accountInfo: {
          ...customInfo,
          parentId: +customInfo.parentId,
          deletedAt: null
        },
        //contactPersons: contactPersonss.length ? contactPersonss : [],
        contactPersons: contactPersonAccountInfo.length
          ? []
          : contactPersons.map((obj) => obj.id), //if no new contact person added parent default selected
        salesPersons: salesPersonss.length ? salesPersonss : [],
        newContacts: contactPersonAccountInfo.length
          ? contactPersonAccountInfo
          : [],
        customData: customData.map((item)=>({key:item.key,value:item.value})),
        templates: templates,
        logoUrls: logourl?logourl.split(','):[],
      };
      try {
        setLoading(true);
        const { data } = await createCustomerContactService(
          payload,
          accessToken
        );
        if (data?.data) {
          toast.warn('Account created successfully');
          //dispatch(clearCustomerAccountDetails());
          //navigate('/customer-account');
          setLoading(false);
          navigate(-1);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (submit) {
      setLoading(true);
      uploadCustomerLogos().then((logourl) => {
        setLoading(false);
        createChildAccount(logourl);
      });
      setSubmit(false);
    }
  }, [
    contactPersons,
    salesPersons,
    submit,
    dispatch,
    navigate,
    accessToken,
    contactPersonsSelected,
    salesPersonsSelected,
    customInfo,
    contactPersonAccountInfo,
    files,
    customData,
    templateSelected,
  ]);

  if (loading) return <SpinnerLoader />;
  return (

    <div className="content-sm page-bottom-space">
      <div className="form-wrap">
        <Heading
          headingTitle="Create Customer Account"
          wrapperClassName="border-bottom pb-1 mb-3"
        >
          <p className="mb-0 font-size-13">*required field</p>
        </Heading>
        {/* <Select
        startOption="Parent Account Name*"
        onChange={handleChange}
        name={'parent'}
        data={parents}
        value={customInfo.parent}
        errorMessage={customInfoError.parent}
      /> */}
        <select
          className="mb-3 form-select"
          title={''}
          onChange={handleChange}
          value={customInfo.parentId}
          name={'parentId'}
          disabled
        >
          {<option value="">{'Parent Account*'}</option>}
          {React.Children.toArray(
            parrentAccounts.map((el) => (
              <option value={el.id}>{el.accountName}</option>
            ))
          )}
        </select>
        {customInfoError.parentId.toString().length > 0 && (
          <span style={{ color: 'red' }}>
            {customInfoError.parentId.toString()}
          </span>
        )}
        <section className="mb-3">
          <Heading
            headingTitle="Customer Account Name*"
            wrapperClassName="mb-2"
          />
          <Input
            value={customInfo.customerAccountName}
            onChange={handleChange}
            name="customerAccountName"
            placeholder="Abc"
            errorMessage={customInfoError.customerAccountName}
          />
          {/* {customInfoError.customerAccountName.length > 0 && (
            <span style={{ color: 'red' }}>
              {customInfoError.customerAccountName}
            </span>
          )} */}
        </section>
        {/* <select
          className="mb-3 form-select"
          title={''}
          onChange={handleChangeContact}
          value={''}
          name={'contact'}
        >
          {<option value="">{'Select Contact Person *'}</option>}
          {React.Children.toArray(
            contactPersons.map((el) => <option value={el.id}>{el.name}</option>)
          )}
        </select> */}

        <section>
          {addContactPerson && (
            <AddContactModal
              title="New Account Contact"
              onSave={handleAddContactPersons}
              open={addContactPerson}
              setOpen={setAddContactPerson}
            />
          )}

          {contactPersonsSelected.length > 0 && (
            <ul className="list-group list-group-flush list-style-none mt-2">
              {React.Children.toArray(
                contactPersonsSelected.map((contact, key) => (
                  <li className="has-right-action list-item-mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{contact.name}</p>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="btn btn-link-danger"
                        onClick={() => removeContact(contact.id)}
                      >
                        <i className="fa-solid fa-xmark">{''}</i>
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
        </section>
        <section className="mb-3 mt-3">
          <Heading headingTitle="Account Contact">
            <AddSection
              label="New Account Contact"
              onClick={handleAddContact}
            />
          </Heading>
          {!!contactPersonAccountInfo.length ? (
            <ul className="list-group list-group-flush list-style-none mt-2">
              {React.Children.toArray(
                contactPersonAccountInfo.map((contact, key) => (
                  <li className="has-right-action list-item-mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{contact.name}</p>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="btn btn-link-danger"
                        onClick={() => removeContactAccountInfo(contact.email)}
                      >
                        <i className="fa-solid fa-xmark"> </i>
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          ) : (
            <div className="pt-2">
              {/* {contactPersonsSelected.length <= 0 && <EmptyList />} */}
            </div>
          )}
        </section>
        <section className="mb-3">
          <Heading headingTitle="Sales Person" wrapperClassName="mb-2" />
          <select
            className="form-select"
            title={''}
            onChange={handleChangeSales}
            value={''}
            name={'sales'}
          >
            {<option value="">{'Select Sales Person'}</option>}
            {React.Children.toArray(
              salesPersons.map((el) => <option value={el.id}>{el.name}</option>)
            )}
          </select>

          {salesPersonsSelected.length > 0 && (
            <ul className="list-group list-group-flush list-style-none mt-2">
              {React.Children.toArray(
                salesPersonsSelected.map((salePerson, key) => (
                  <li className="has-right-action list-item-mb-2" key={key}>
                    <p className="mb-0 text-capitalize">{salePerson.name}</p>
                    <div className="action-wrap">
                      <button
                        type="button"
                        className="btn btn-link-danger"
                        onClick={() => removeSales(salePerson.id)}
                      >
                        <i className="fa-solid fa-xmark">{''}</i>
                      </button>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
        </section>
        <section className="mb-3">
          <Heading headingTitle="Category*" wrapperClassName="mb-2" />
        <select
          className="mb-3 form-select"
          title={''}
          onChange={handleChange}
          value={customInfo.category}
          name={'category'}
        >
          {<option value="">{'Select Category'}</option>}
          {React.Children.toArray(
            categoryList.map((el) => (
              <option value={el.attributeName}>{el.attributeName}</option>
            ))
          )}
        </select>
        {customInfoError.category && (
            <span style={{ color: 'red' }}>
              {customInfoError.category}
            </span>
          )}
        </section>
        
        <section className="mb-3">
          <Heading headingTitle="Location*" wrapperClassName="mb-2" />
          <select
            className="form-select"
            title={''}
            onChange={handleChange}
            value={customInfo.locationId}
            name={'locationId'}
          >
            {<option value="">{'Select Location'}</option>}
            {React.Children.toArray(
              locationList.map((el) => (
                <option value={el.id}>{el.location}</option>
              ))
            )}
          </select>
          {customInfoError.locationId && (
            <span style={{ color: 'red' }}>
              {customInfoError.locationId}
            </span>
          )}
          {customInfo.locationId &&
          <div className="has-right-action">
                    <p
                      onClick={() => setToggleLocationDetails(prevState=>!prevState)}
                      className="mb-0 text-capitalize"
                    >
                      <i
                        className={`fa-solid text-danger me-2 ${
                          toggleLocationDetails
                            ? 'fa-caret-down'
                            : 'fa-caret-right'
                        }`}
                      ></i>
                      {/* {findName(child.childData!)} */}
                      {customInfo.locationId && locationList.length &&
                      locationList.find((e) => e.id == customInfo.locationId)!
                    .location
                      }
                    </p>
                    {/* Action buttons UI*/}
                    
                  </div>}
          {toggleLocationDetails && customInfo.locationId && locationList.length > 0 && (
            <div className="row mt-2">
              {stateList.length && (
                <InfoColList
                  label="country:"
                  value={
                    stateList.find(
                      (s) =>
                        s.id ==
                        locationList.find((e) => e.id == customInfo.locationId)!
                          .stateId
                    )!.country!.name
                  }
                />
              )}
              {stateList.length && (
                <InfoColList
                  label="state:"
                  value={
                    stateList.find(
                      (s) =>
                        s.id ==
                        locationList.find((e) => e.id == customInfo.locationId)!
                          .stateId
                    )!.name
                  }
                />
              )}
              <InfoColList
                label="location:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .location
                }
              />
              {/* <InfoColList
                label="name:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!.name
                }
              /> */}
              <InfoColList
                label="abbreviation:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .abbreviation
                }
              />
              <InfoColList
                label="establishedDate:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .establishedDate
                }
              />
              <InfoColList
                label="establishedDateFirstTwo:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .establishedDateFirstTwo
                }
              />
              <InfoColList
                label="establishedDateLastTwo:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .establishedDateLastTwo
                }
              />

              <InfoColList
                label="stackedName:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .stackedName
                }
              />
              {/* <InfoColList
                label="destination:"
                value={
                  locationList.find((e) => e.id == customInfo.locationId)!
                    .destination
                }
              /> */}
            </div>
          )}
        </section>
        
        <Heading headingTitle="Keys" wrapperClassName="mb-2" />
        {
          <KeyValuePairInput
            sectionClass="mb-3"
            customData={customData}
            setCustomData={setCustomData}
          />
        }

       
        <Heading headingTitle="Logos" wrapperClassName="mb-2" />
        {/* <ImageUploadPrviewer
          onChange={onChangeImageUploadPreview}
          images={images}
        /> */}
        <FileUploadPrviewer
          onChange={onChangeFileUploadPreview}
          files={files}
        />
        <TemplateList
          templateSelected={templateSelected as any[]}
          setTemplateSelected={setTemplateSelected as SetStateAction<any>}
          templateList={templateList}
          setTemplateList={setTemplateList}
        />
       
        <div className="mt-3">
        <button className="btn btn-danger px-4" onClick={save}>
            {'Save'}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => navigate(-1)}
          >
            {`Cancel`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Customer;
