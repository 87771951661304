import React, { FC, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/constants';
import TemplateDetailBox from './TemplateDetailBox';
import { IMAGES } from '../../assets/images/images';
import { ITemplate } from 'api/requestTypes';
import { Modal } from 'react-bootstrap';
import SpinnerLoader from 'ui/loader/SpinnerLoader';
import { useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/User.slice';
import { fetchTemplateDetailsService } from 'api/templateService';

export interface ITemplateDetailsModal {
  open: boolean;
  setOpen: Function;
  template: ITemplate;
  handleCallBack: () => void;
}

const TemplatesDetailsModal: FC<ITemplateDetailsModal> = ({
  open,
  setOpen,
  template,
  handleCallBack,
}): JSX.Element => {
  return (
    <Modal
      className="custom-drawer date-picker-modal"
      show={open}
      onHide={() => setOpen(false)}
      aria-labelledby="custom-modal"
      backdrop="static"
      size={'lg'}
    >
      <Modal.Body>
        <div className="content-sm page-bottom-space">
          <div className="border-bottom pb-1 mb-3">
            <h4 className="mb-0 heading4-bold">Template details</h4>
          </div>
          <div className="has-right-action d-flex align-items-center mb-3">
            <p className="mb-0 text-capitalize">{template.name}</p>
          </div>
          <div className="mb-3">
            <p className="mb-0">Styles</p>
            {template.style + "(" +template.subStyle +")"}
          </div>
          <div className="mb-3">
            <p className="mb-2">Front</p>
            <TemplateDetailBox
              title="Thumbnail"
              imgUrl={IMAGES.JPG}
              name={template.frontThumbnail.split('/').pop()!}
            />
            <TemplateDetailBox
              title="Document"
              imgUrl={IMAGES.AI}
              name={template.frontDocument.split('/').pop()!}
            />
            <TemplateDetailBox
              title="Description"
              imgUrl={IMAGES.JSON}
              name={template.frontDescription.split('/').pop()!}
            />
          </div>
          {template.design !== 'Front' && (
            <div className="mb-3">
              <p className="mb-2">Back</p>
              <TemplateDetailBox
                title="Thumbnail"
                imgUrl={IMAGES.JPG}
                name={template.backThumbnail.split('/').pop()!}
              />
              <TemplateDetailBox
                title="Document"
                imgUrl={IMAGES.AI}
                name={template.backDocument.split('/').pop()!}
              />
              <TemplateDetailBox
                title="Description"
                imgUrl={IMAGES.JSON}
                name={template.backDescription.split('/').pop()!}
              />
            </div>
          )}
        </div>
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-danger px-4"
            onClick={() => handleCallBack()}
          >
            {`Edit`}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger px-4 ms-3"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TemplatesDetailsModal;
