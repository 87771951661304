import { IStyle } from "api/requestTypes";
import { FC, useEffect, useRef, useState } from "react";
import SpinnerLoader from "ui/loader/SpinnerLoader";

import { useAppSelector } from "store/hooks";
import { getUserDetails } from "store/User.slice";

import {
  addStyleService,
  deleteStyleService,
  fetchAllStyleService,
  postChangeTemplateOrder,
  updateStyleService,
} from "api/style";
import ConfirmationModal from "components/confirmationModal/ConfirmationModal";
import DragDrop from "components/dragDrop/DragDrop";
import EmptyList from "ui/empty-list/EmptyList";
import StyleModal from "./StyleModal";
import { toast } from "react-toastify";
const initStyle: IStyle = {
  styleName: "",
  subStyleName: "",
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 8,
  width: 250,
});

const Styles: FC = () => {
  const [loading, setLoading] = useState(false);
  const { accessToken, role } = useAppSelector(getUserDetails);

  const [styleList, setStyleList] = useState<IStyle[]>([]);

  const [isStyleOpen, setIsStyleOpen] = useState(false);

  const [style, setStyle] = useState<IStyle>(initStyle);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState<string | number>("");

  const handleSaveUpdateAttribute = async (style: IStyle) => {
    //add
    if (!style.id) {
      try {
        setLoading(true);
        const { data } = await addStyleService(style, accessToken);
        if (data.data) {
          const details: IStyle = data.data;

          setStyleList([...styleList, details]);

          alert("Added successfully");

          setIsStyleOpen(false);
          setStyle(initStyle);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Unable to add");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    //update
    else {
      try {
        setLoading(true);
        const { data } = await updateStyleService(style.id, style, accessToken);
        if (data.data) {
          const details = data.data;

          let rslt = styleList.filter((el) => el.id != style.id);
          setStyleList([...rslt, style]);

          alert("Updated successfully");
          setLoading(false);

          setIsStyleOpen(false);
          setStyle(style);
        }
      } catch (error) {
        console.error(error);
        alert("Unable to update");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDeleteAttribute = async (id: number | string) => {
    try {
      setLoading(true);
      const { data } = await deleteStyleService(id, accessToken);
      if (data.data) {
        let rslta = styleList.filter((el) => el.id != id);
        setStyleList([...rslta]);
        setLoading(false);
        alert("Deleted successfully");
      }
    } catch (error) {
      console.error(error);
      alert("Unable to delete");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmCallBack = () => {
    handleDeleteAttribute(deleteId);
  };

  const onDragEndCallback = async (newStyle: IStyle[], ) => {
    try {
      const newStyleOrder = newStyle.map((x) => x.id);
      const payload = { newStyleOrder };
      setLoading(true)
      await postChangeTemplateOrder(payload, accessToken);
      setLoading(false)
      toast.success('Success')
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  useEffect(() => {
    const getStyleList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAllStyleService(accessToken);
        if (data.data) {
          setStyleList(data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        alert("Could not fetch the attributes");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getStyleList();
  }, [accessToken]);

  if (loading) return <SpinnerLoader />;
  return (
    <div className="content-sm page-bottom-space">
      <section>
        <div className="border-bottom pb-1 d-flex justify-content-between align-items-center mb-2">
          <h4 className="mb-0 heading4-bold">Styles</h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setIsStyleOpen(true)}
          >
            <i className="fa-solid fa-circle-plus text-danger me-2"></i>
            <span className="text-dark">Add Style</span>
          </button>
        </div>
        {!!styleList.length ? (
          <DragDrop<IStyle> list={styleList} setList={setStyleList} callback={onDragEndCallback}>
            {(item: IStyle) => (
              <div className="has-right-action">
                <p className="mb-0 text-capitalize">
                  {item.styleName + "-" + item.subStyleName}
                </p>
                <div className="action-wrap">
                  <button
                    className="btn btn-link-danger"
                    type="button"
                    onClick={() => {
                      setStyle({
                        id: item.id,
                        styleName: item.styleName,
                        subStyleName: item.subStyleName,
                      });
                      setIsStyleOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-pen">{""}</i>
                  </button>
                  <button
                    className="btn btn-link-danger ms-3"
                    type="button"
                    //onClick={() => handleDeleteAttribute(style.id!)}
                    onClick={() => {
                      setDeleteId(item.id!);
                      setOpenConfirmation(true);
                    }}
                  >
                    <i className="fa-solid fa-trash-can">{""}</i>
                  </button>
                </div>
              </div>
            )}
          </DragDrop>
        ) : (
          <EmptyList title="Nothing Here" />
        )}

        {isStyleOpen && (
          <StyleModal
            open={isStyleOpen}
            setOpen={setIsStyleOpen}
            style={{ ...style }}
            setStyle={setStyle}
            onSave={handleSaveUpdateAttribute}
            loading={loading}
          />
        )}
      </section>
      {openConfirmation && (
        <ConfirmationModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          confirmationMessage="Are you sure you want to delete?"
          handleCallBack={handleConfirmCallBack}
        />
      )}
    </div>
  );
};

export function DragHandle(props: any) {
  return <div {...props}>{/* <DragHandleIcon /> */}=</div>;
}

export default Styles;
